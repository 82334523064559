import { Component, OnInit, OnDestroy } from '@angular/core';
import { AssessmentService } from '../assessment-service/assessment.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { SharedDataService } from 'src/app/shared-services/shared-data/shared-data.service';

@Component({
  selector: 'app-domain',
  templateUrl: './domain.component.html',
  styleUrls: ['./domain.component.scss'],
})
export class DomainComponent implements OnInit, OnDestroy {
  domains = [
    {
      domain_name: 'Sales',
    },
    {
      domain_name: 'Marketing',
    },
    {
      domain_name: 'Operations',
    },
    {
      domain_name: 'Finance',
    },
    {
      domain_name: 'Research and Development',
    },
    {
      domain_name: 'Quality Assessment',
    },
    {
      domain_name: 'Other',
    },
  ];
  selectedAssessment = this.as.getAssessmentValue;
  subscriptions: Subscription[] = [];
  company =  localStorage.latestCompanyData ? {
    ...JSON.parse(localStorage.user),
    company: JSON.parse(localStorage.latestCompanyData),
  } : JSON.parse(localStorage.user)
  constructor(private as: AssessmentService, private _router: Router, private shareService: SharedDataService) {}


  ngOnInit(): void {
    this.shareService.setActiveTab('Assessments');
  }
  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
  domainSelected(domain) {
    // this.selectedAssessment.domain_name = domain.domain_name;
    // this.selectedAssessment.job_requirment = {};

    if (domain.domain_name === this.selectedAssessment.domain_name) {
      // if selected domain is same as pervious selected domain just set value
      this.selectedAssessment.domain_name = domain.domain_name;
    } else {
      // if selected domain is same as pervious selected domain set value and set job_requirment value to empty object for next page
      this.selectedAssessment.domain_name = domain.domain_name;
      this.selectedAssessment.job_requirment = {};
      this.selectedAssessment.department = ''
    }
  }

  setActive(val) {
    if (this.selectedAssessment.domain_name === val) {
      return 'active';
    }
  }

  saveAssessmentData() {
  
    this.subscriptions.push(
      this.as
        .setAssessmentField('domain_name', this.selectedAssessment.domain_name)
        .subscribe((_1) => {
          if(this.company?.company?.departments?.length > 0 ){
            this._router.navigate(['departments']);
          }else{
            this._router.navigate(['job-requirements']);
          }
        }),
    );
  }
}
