import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AuthGuard } from './shared-services/auth/auth-guard.service';
import { PurposeAssessmentComponent } from './assessment/purpose-assessment/purpose-assessment.component';
import { JobTitleComponent } from './assessment/job-title/job-title.component';
import { CompetencyComponent } from './assessment/competency/competency.component';
import { ProfileComponent } from './assessment/profile/profile.component';
import { SummaryComponent } from './assessment/summary/summary.component';
import { CongratulationsComponent } from './assessment/congratulations/congratulations.component';

import { DomainComponent } from './assessment/domain/domain.component';
import { JobRequrementComponent } from './assessment/job-requrement/job-requrement.component';
import { AssessmentTypeComponent } from './assessment/assessment-type/assessment-type.component';
import { ReportsModule } from './reports/reports.module';
import { LearningComponent } from './learning/learning.component';
import { JobTitleLearningComponent } from './assessment/job-title-learning/job-title-learning.component';
import { SummaryLearningComponent } from './assessment/summary-learning/summary-learning.component';
import { AptitudeAssessmentsComponent } from './assessment/aptitude-assessments/aptitude-assessments.component';
import { TechAssessmentsComponent } from './assessment/tech-assessments/tech-assessments.component';
import { ValuesComponent } from './assessment/values/values.component';
import { TvsReportsModule } from './tvs-report/tvs-reports.module';
import { DepartmentComponent } from './assessment/department/department.component';

const routes: Routes = [
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'learning',
    component: LearningComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'dashboard/:type',
    component: DashboardComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
  },
  {
    path: 'purpose-assessment',
    component: PurposeAssessmentComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'job-title',
    component: JobTitleComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'job-title-learning',
    component: JobTitleLearningComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'competency',
    component: CompetencyComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'aptiGameList',
    component: AptitudeAssessmentsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'techAssessmentsList',
    component: TechAssessmentsComponent,
    canActivate: [AuthGuard],
  },

  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'summary',
    component: SummaryComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'summary-learning',
    component: SummaryLearningComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'congratulation',
    component: CongratulationsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'domain',
    component: DomainComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'job-requirements',
    component: JobRequrementComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'departments',
    component: DepartmentComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'values',
    component: ValuesComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'assessment-type',
    component: AssessmentTypeComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'candidate',
    loadChildren: () =>
      import('./candidates/candidates.module').then((m) => m.CandidatesModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'report',
    loadChildren: () => ReportsModule,
  },
  {
    path: 'tvs-report',
    loadChildren: () => TvsReportsModule,
  },
  {
    path: 'account',
    loadChildren: () =>
      import('./account/account.module').then((m) => m.AccountModule),
    canActivate: [AuthGuard],
  },
  {
    path: '**',
    redirectTo: 'dashboard',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
