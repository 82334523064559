<!-- <input [owlDateTime]="dt1" []='startDate' [selectMode]="'range'" [owlDateTimeTrigger]="dt1" placeholder="Date Time"> -->
<owl-date-time #dt1></owl-date-time>
<div class="HeadStrip">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="WASummaryHed">Assessment Summary</div>
        <div class="WASummarySubHed1">
          Review and confirm your assessment details.
        </div>
      </div>
    </div>
    <!--  row  -->
  </div>
  <!--  container  -->
</div>
<!--  HeadStrip -->
<!--  Start Section  -->

<div class="container">
  <div class="row">
    <div class="loading" *ngIf="showloader">Loading&#8230;</div>
    <div class="col-md-12">
      <div class="row">
        <div class="col-md-3"><span class="WASummaryIco"></span></div>
        <div class="col-md-9 row">
          <div class="col-md-6">
            <div class="WAppSGrid">
              <div class="WAppSBox">
                <div class="WASNo">1</div>
                <div class="WASInfo">
                  <h2>Job Title</h2>
                  <p>{{ selectedAssessment.job_title }}</p>
                </div>
                <!--  WASNo  -->
              </div>
            </div>
            <!--  WAppSGrid  -->
            <a routerLink="../job-title">
              <div class="WAppSCircle"></div>
            </a>
            <div class="WAppSGrid" *ngIf="selectedAssessment.job_type == 'Customised Profile Fit'">
              <div class="WAppSBox">
                <div class="WASNo">2</div>
                <div class="WASInfo">
                  <h2>Profile</h2>
                  <p>{{ selectedAssessment.profile_name }}</p>
                </div>
                <!--  WASNo  -->
              </div>
              <a routerLink="../profile">
                <div class="WAppSCircle"></div>
              </a>
            </div>
            <!--  WAppSGrid  -->
            <div class="WAppSGrid" *ngIf="selectedAssessment.job_type == 'Customised Profile Fit'">
              <div class="WAppSBox">
                <div class="WASNo">3</div>
                <div class="WASInfo">
                  <h2>Organisational Competency</h2>
                  <p>{{ selectedAssessment.competency_name }}</p>
                </div>
                <!--  WASNo  -->
              </div>
              <a routerLink="../competency">
                <div class="WAppSCircle"></div>
              </a>
            </div>

            <div class="WAppSGrid"
              *ngIf="companyDetail.company.departments?.length && selectedAssessment.job_type == 'Campus Recruitment'">
              <div class="WAppSBox">
                <div class="WASNo">3</div>
                <div class="WASInfo">
                  <h2>Technical Assessment</h2>
                  <p>{{ selectedDepartmentName }}</p>
                </div>
                <!--  WASNo  -->
              </div>
              <a routerLink="../departments">
                <div class="WAppSCircle"></div>
              </a>
            </div>


            <!--  WAppSGrid  -->
          </div>
          <div class="col-md-6">
            <div class="WAppSGrid" *ngIf="selectedAssessment.job_type == 'Customised Profile Fit'">
              <div class="WAppSBox">
                <div class="WASNo">4</div>
                <div class="WASInfo">
                  <h2>Domain</h2>
                  <p>{{ selectedAssessment.domain_name }}</p>
                </div>
                <!--  WASNo  -->
              </div>
              <a routerLink="../domain">
                <div class="WAppSCircle"></div>
              </a>
            </div>
            <!--  WAppSGrid  -->

            <div class="WAppSGrid"
              *ngIf="companyDetail.company.departments?.length && selectedAssessment.job_type == 'Customised Profile Fit'">
              <div class="WAppSBox">
                <div class="WASNo">5</div>
                <div class="WASInfo">
                  <h2>Technical Assessment</h2>
                  <p>
                    {{ selectedDepartmentName}}
                  </p>
                </div>
                <!--  WASNo  -->
              </div>
              <a routerLink="../departments">
                <div class="WAppSCircle"></div>
              </a>
            </div>
            <!--  WAppSGrid  -->

            <div class="WAppSGrid" *ngIf="selectedAssessment.job_type == 'Customised Profile Fit'">
              <div class="WAppSBox">
                <div class="WASNo">
                  {{ companyDetail.company.departments?.length? 6 : 5 }}
                </div>
                <div class="WASInfo">
                  <h2>Job Role <br />Requirements</h2>
                  <p>Task selected ({{ getJobReqirmentCount() }})</p>
                </div>
                <!--  WASNo  -->
              </div>
              <a routerLink="../job-requirements">
                <div class="WAppSCircle"></div>
              </a>
            </div>
            <!--  WAppSGrid  -->

            <div class="WAppSGrid" *ngIf="selectedAssessment.job_type=='Customised Profile Fit' && isTvs ">
              <div class="WAppSBox">
                <div class="WASNo">6</div>
                <div class="WASInfo">
                  <h2>Values Assessment</h2>
                  <p>
                    {{ this.valuesText[selectedAssessment.values] || 'None' }}
                  </p>
                </div>
                <!--  WASNo  -->
              </div>
              <a routerLink="../values">
                <div class="WAppSCircle"></div>
              </a>
            </div>
            <!--  WAppSGrid  -->

            <div class="WAppSGrid" *ngIf="
                selectedAssessment.assessment_typeCode != 'apti_assessment' &&
                selectedAssessment.job_type != 'Customised Profile Fit' &&
                selectedAssessment.job_type !== 'Aptitude Assessments'
              ">
              <div class="WAppSBox">
                <div class="WASNo">2</div>
                <div class="WASInfo">
                  <h2>Organisational Competency</h2>
                  <p>{{ selectedAssessment.competency_name }}</p>
                </div>
                <!--  WASNo  -->
              </div>
              <a routerLink="../competency">
                <div class="WAppSCircle"></div>
              </a>
            </div>
            <!--  WAppSGrid  -->

            <div class="WAppSGrid" *ngIf="selectedAssessment.job_type === 'Aptitude Assessments'">
              <div class="WAppSBox">
                <div class="WASNo">2</div>
                <div class="WASInfo">
                  <h2>Tech Assessment</h2>
                  <p>
                    Assessment Selected ({{
                      selectedAssessment.techAssessments.length
                    }})
                  </p>
                </div>
              </div>
              <a routerLink="../techAssessmentsList">
                <div class="WAppSCircle"></div>
              </a>
            </div>
          </div>

          <form [formGroup]="summaryForm" novalidate ng-submit="summaryForm.$valid && postAssessment()">
            <div class="col-md-12">
              <h2 class="oGrid-hed">
                Access time
                <small>(Note: These time limits are applicable to all candidates
                  applying to the test)</small>
              </h2>
              <div class="styled-select dropdown oGrid-1" style="width: fit-content !important">
                <!-- <label> -->
                <!-- Date Time: -->
                <input formControlName="startDatetime" style="
                    width: 100%;
                    border-bottom: 0.5px solid #9e9d9d;
                    cursor: pointer;
                  " [owlDateTimeTrigger]="dt6" [owlDateTime]="dt6" />
                <owl-date-time [hour12Timer]="true" #dt6></owl-date-time>
                <!-- </label> -->
                <!--
                    <input [ngxTimepicker]="picker1" formControlName="startTime">
                    <ngx-material-timepicker  #picker1></ngx-material-timepicker> -->
              </div>
              <div flex-gt-xs>
                <!-- <input [ngxTimepicker]="picker">
                    <ngx-material-timepicker formControlName="startDatetime" #picker></ngx-material-timepicker> -->
                <!-- md-min-date="startMinDat"     -->
                <!-- <md-datepicker onkeydown="return false" formControlName="startDatetime" md-placeholder="Enter date" required > </md-datepicker> -->
              </div>
            </div>
            <div class="oGrid-3">to</div>
            <div class="styled-select oGrid-2">
              <div class="styled-select dropdown oGrid-1" style="width: fit-content !important; margin-right: 100px">
                <!-- <input [ngxTimepicker]="picker" [(ngModel)]='startDate'>
                    <ngx-material-timepicker  #picker></ngx-material-timepicker> -->
                <input formControlName="endDatetime" style="
                    width: 100%;
                    border-bottom: 0.5px solid #9e9d9d;
                    cursor: pointer;
                  " [owlDateTimeTrigger]="dt5" [owlDateTime]="dt5" />
                <owl-date-time [hour12Timer]="true" #dt5></owl-date-time>
                <!-- <div class="ngx-material-timepicker-example__form-group"> -->
                <!-- <input formControlName='startTime'
                            [ngxTimepicker]="defaultTime" readonly>
                        <ngx-material-timepicker #defaultTime  [defaultTime]="'11:11 pm'"></ngx-material-timepicker> -->

                <!-- <input [owlDateTime]="dt1" formControlName='startTime' [selectMode]="'range'" [owlDateTimeTrigger]="dt1" placeholder="Date Time">
<owl-date-time #dt1></owl-date-time> -->
                <!-- </div> -->
                <!-- <input onkeydown="return false" formControlName="endTime" ui-timepicker id="inputbaseborder" required/> -->
              </div>
              <div class="styled-select">
                <!-- <md-datepicker onkeydown="return false" formControlName="endDatetime" md-min-date="ctrl.minDate" md-placeholder="Enter date" required ></md-datepicker> -->
              </div>
            </div>
            <div class="col-md-12">
              <div class="WAppS-btn-grid">
                <button (click)="postAssessment()" type="submit" class="new-btn" style="margin-top: 40px;"
                  [ngClass]="{ 'disabled-button': summaryForm.invalid }">
                  Confirm
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <!--  row  -->
    </div>
  </div>
  <!-- row  -->
</div>
<!--  End Section  -->
