import { Component, OnInit } from '@angular/core';
import { AssessmentService } from '../assessment-service/assessment.service';
import { ApiService } from 'src/app/shared-services/api/api/api.service';
import { Pipe, PipeTransform } from '@angular/core';
import { ToasterService } from 'src/app/shared-services/toaster/toaster.service';
import { Router } from '@angular/router';
import { SharedDataService } from 'src/app/shared-services/shared-data/shared-data.service';
import { COMPANY_ID } from 'src/app/shared-services/constants/globalEnums.enums';

@Component({
  selector: 'app-job-requrement',
  templateUrl: './job-requrement.component.html',
  styleUrls: ['./job-requrement.component.scss'],
})
export class JobRequrementComponent implements OnInit {
  activityData = [];
  selectedAssessment = this.as.getAssessmentValue;
  jobRequirmentCount: any = 0;
  toggleInstruction = true;
  currentCompany = JSON.parse(localStorage.getItem('user')).user;
  company = JSON.parse(localStorage.user);
  constructor(
    private as: AssessmentService,
    private _api: ApiService,
    private _toastr: ToasterService,
    private _router: Router,
    private shareService: SharedDataService,
  ) {}

  ngOnInit(): void {
    this.shareService.setActiveTab('Assessments');

    this.selectedAssessment = this.as.getAssessmentValue;
    this.getActivities();
    console.log(this.currentCompany);
  }
  getActivities() {
    this._api
      .getActivityList(this.selectedAssessment.domain_name)
      .subscribe((res) => {
        let newJobRequirementCount = 0;
        const activityData = res;
        const assesmentActivity = activityData.map((val) => {
          if (this.selectedAssessment.job_requirment !== undefined) {
            if (
              typeof this.selectedAssessment.job_requirment[val._id] !==
              'undefined'
            ) {
              val.requirment_type = this.selectedAssessment.job_requirment[
                val._id
              ];
              newJobRequirementCount += 1;
            } else {
              val.requirment_type = 'not_required';
            }
          } else {
            this.selectedAssessment.job_requirment = {};
            val.requirment_type = 'not_required';
          }

          this.jobRequirmentCount = newJobRequirementCount;
          return val;
        });

        // bug reported on old dashboard
        this.activityData = assesmentActivity;
        const z = Object.keys(this.selectedAssessment.job_requirment).filter(
          (o1) => !this.activityData.some((o2) => o1 === o2._id),
        );
        z.forEach((element) => {
          delete this.selectedAssessment.job_requirment[element];
        });
        setTimeout(() => {
          this.as.setAssessmentData(this.selectedAssessment);
        }, 0);
      });
  }

  setSelectedRequirment(e, id, value, acitivities) {
    const jobRequirment = this.selectedAssessment.job_requirment;
    let jobReq;
    let previous = 0;
    if (this.jobRequirmentCount >= 15) {
      for (jobReq in jobRequirment) {
        if (jobReq === id && jobRequirment[jobReq] !== value) {
          previous = 1;
        }
      }
      if (previous === 1) {
        acitivities.requirment_type = value;
        this.selectedAssessment.job_requirment[id] = value;
        if (value === 'not_required' && this.jobRequirmentCount > 0) {
          this.jobRequirmentCount =
            Object.keys(this.selectedAssessment.job_requirment).length - 1;
          delete this.selectedAssessment.job_requirment[id];
        }
        return true;
      } else {
        this._toastr.showError(
          'You cannot select more than 15 responsibilities.',
        );
        e.preventDefault();
        return false;
      }
    } else {
      acitivities.requirment_type = value;
      this.selectedAssessment.job_requirment[id] = value;
      if (value !== 'not_required') {
        this.jobRequirmentCount = Object.keys(
          this.selectedAssessment.job_requirment,
        ).length;
      } else if (
        value === 'not_required' &&
        Object.keys(this.selectedAssessment.job_requirment).length > 0
      ) {
        this.jobRequirmentCount =
          Object.keys(this.selectedAssessment.job_requirment).length - 1;
        delete this.selectedAssessment.job_requirment[id];
      }
    }
  }

  saveJobRequirment() {
    this.as.setAssessmentData(this.selectedAssessment);
    if (
      this.currentCompany.company_id === COMPANY_ID.TVS_MOTOR ||
      this.currentCompany.company_id === COMPANY_ID.TVS_MOTOR_PRIVATE
    ) {
      this._router.navigate(['values']);
    } else {
      this._router.navigate(['summary']);
    }
  }
}
