import { Component, OnInit, OnDestroy } from '@angular/core';
import { AssessmentService } from '../assessment-service/assessment.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { SharedDataService } from 'src/app/shared-services/shared-data/shared-data.service';

@Component({
  selector: 'app-department',
  templateUrl: './department.component.html',
  styleUrls: ['./department.component.scss'],
})
export class DepartmentComponent implements OnInit, OnDestroy {
  selectedAssessment = this.as.getAssessmentValue;
  subscriptions: Subscription[] = [];
  company = localStorage.latestCompanyData
    ? {
        ...JSON.parse(localStorage.user),
        company: JSON.parse(localStorage.latestCompanyData),
      }
    : JSON.parse(localStorage.user);
  constructor(
    private as: AssessmentService,
    private _router: Router,
    private shareService: SharedDataService,
  ) {}

  ngOnInit(): void {
    this.shareService.setActiveTab('Assessments');
  }
  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
  departmentSelected(department) {
    if (department._id === this.selectedAssessment.department) {
      // if selected department is same as pervious selected department just set value
      this.selectedAssessment.department = undefined
      this.setActive(undefined)
    } else {
      // if selected department is same as pervious selected department set value and set job_requirment value to empty object for next page
      this.selectedAssessment.department = department._id
      this.selectedAssessment.job_requirment = {};
    }
  }

  setActive(val) {
    if (this.selectedAssessment.department === val) {
      return 'active';
    }
  }

  saveAssessmentData() {
    this.subscriptions.push(
      this.as
        .setAssessmentField('department', this.selectedAssessment.department || null)
        .subscribe((_1) => {
          if (this.selectedAssessment.job_type === 'Campus Recruitment'){
            this._router.navigate(['summary']);
          }else{
            this._router.navigate(['job-requirements']);
          }
         
        }),
    );
  }
}
