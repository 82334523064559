import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import {
  CompanyDivisionType,
  TRequestCohorts,
} from 'src/app/learning/cohort.interface';

@Injectable({
  providedIn: 'root',
})
export class SharedDataService {

  constructor() {
    this.CollaboratorSharedData = new BehaviorSubject<any>(
      JSON.parse(sessionStorage.getItem('dataShared')) || null,
    );
    this.CandidatesSharedData = new BehaviorSubject<any>(
      JSON.parse(sessionStorage.getItem('Candidate')) || null,
    );
    this.LandingSharedData = new BehaviorSubject<any>(null);
  }
  CompanyImage: BehaviorSubject<any>;
  LandingSharedData: BehaviorSubject<any>;
  CollaboratorSharedData: BehaviorSubject<any>;
  CandidatesSharedData: BehaviorSubject<any>;
  selectedTab: BehaviorSubject<
    'Assessments' | 'Orientation' | 'Settings'
  > = new BehaviorSubject('Assessments');
  cohortData = null;
  companyDivisionData = [];
  // we will use this in aptitude component
  candidateImageData: CandidateImageDataType = null;
  companyId = '';

  private selectedCohort: any;

  resetStateData() {
    sessionStorage.removeItem('dataShared');
    sessionStorage.removeItem('Candidate');

    this.CompanyImage = new BehaviorSubject<any>(null);
    this.CollaboratorSharedData = new BehaviorSubject<any>(null);
    this.CandidatesSharedData = new BehaviorSubject<any>(null);
    this.LandingSharedData = new BehaviorSubject<any>(null);
    this.cohortData = null;
    this.companyDivisionData = [];
  }

  getData(): Observable<any> {
    return this.CollaboratorSharedData;
    // return JSON.parse(sessionStorage.getItem('dataShared'));
  }

  setActiveTab(value: 'Assessments' | 'Orientation' | 'Settings') {
    this.selectedTab.next(value);
  }

  clearData() {
    this.CollaboratorSharedData.next(null);
    // sessionStorage.setItem('dataShared', null);
    // return true;
  }
  setData(data) {
    this.CollaboratorSharedData.next(data);
    return sessionStorage.setItem('dataShared', JSON.stringify(data));
  }

  getCandidatesData(): Observable<any> {
    return this.CandidatesSharedData;
    // return JSON.parse(sessionStorage.getItem('dataShared'));
  }

  clearCandidatesData() {
    this.CandidatesSharedData.next(null);
    // sessionStorage.setItem('dataShared', null);
    // return true;
  }

  setCandidatesData(data) {
    this.CandidatesSharedData.next(data);
    sessionStorage.setItem('Candidate', JSON.stringify(data));
    return this.CandidatesSharedData;
  }

  getCandidatesValue() {
    return this.CandidatesSharedData.value;
  }

  // dashboard-landing-data
  getLandingData(): Observable<any> {
    return this.LandingSharedData;
    // return JSON.parse(sessionStorage.getItem('dataShared'));
  }

  clearLandingData() {
    this.LandingSharedData.next(null);
    // sessionStorage.setItem('dataShared', null);
    // return true;
  }

  setLandingData(data) {
    this.LandingSharedData.next(data);
    // sessionStorage.setItem('Candidate', JSON.stringify(data));
    return this.LandingSharedData;
  }

  getLandingValue() {
    return this.LandingSharedData.value;
  }

  setCohortList(data: TRequestCohorts) {
    this.cohortData = data;
  }

  getCohortList() {
    return this.cohortData;
  }

  setCompanyDivisionData(data: CompanyDivisionType[]) {
    this.companyDivisionData = data;
  }
  getCompanyDivisionData() {
    return this.companyDivisionData;
  }

  setSelectedCohort(cohortData) {
    this.selectedCohort = cohortData;
  }
  getSelectedCohort() {
    return this.selectedCohort;
  }
}

export interface CandidateImageDataType {
  assessmentTitle: string;
  candidateName: string;
  createdOn: string;
  candidateImages: string[];
}
