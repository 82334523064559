import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/shared-services/api/api/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';

@Component({
  selector: 'app-lnd-reports',
  templateUrl: './lnd-reports.component.html',
  styleUrls: ['./lnd-reports.component.scss'],
  animations: [
    trigger('expandCollapse', [
      state('open', style({ height: '100%', opacity: 1 })),
      state('closed', style({ height: 0, opacity: 0 })),
      transition('* => *', [animate('100ms')]),
    ]),
  ],
})
export class LndReportsComponent implements OnInit {
  @ViewChild('Modal') private lndModal;

  @ViewChild('leadershipReportContainer') private leadershipReportElement;

  constructor(
    private _api: ApiService,
    private _route: ActivatedRoute,
    private _router: Router,
    private modal: NgbModal,
  ) {
    this._route.params.subscribe((val) => {
      // this.getLndReport();

      if (this._route.snapshot.params.page) {
        this.visiblePageIndex = parseInt(this._route.snapshot.params.page, 10);
      }
      if (this.visiblePageIndex === 0) {
        this.ldInitForIndex = false;
      }
    });
  }
  showReport = false;
  viewReport: any;
  ldInit = false;
  ldInitForIndex = false;
  isCollapsed = true;
  popVideos = [];
  visiblePageIndex = 0;
  personaDataForCurrentIndex = { key: 'b', desc: 'b', data: '' };
  tips = 1;
  popType = 'highComp';
  popAveragePer = 100;
  personaKey = 0;
  ModalData = {
    popHeader: '',
    description: '',
    popAveragePer: 0,
    popHeadDesc: '',
    popVideos: [],
    tips: [],
    popType: '',
  };

  ngOnInit(): void {
    console.log('dekh lena');

    this.getLndReport();
  }

  // ngAfterViewInit(){
  //   this.setHeightOfElements();
  // }

  setHeightOfElements() {
    this.leadershipReportElement.nativeElement.style.height = `calc(100vh + ${
      document.querySelector(
        'body > app-root > div > app-lnd-reports > div > div > main > div > div.position-relative.d-flex.justify-content-center.ng-tns-c78-0 > div.container.driver-content.ng-tns-c78-0',
      ).clientHeight
    }px)`;
  }

  getReportHeight(type: string) {
    return `calc(100vh + ${
      document.querySelector('.driver-content').clientHeight
    }px)`;
  }

  getLndReport() {
    const assessmentId = this._route.snapshot.params.id;
    const email = this._route.snapshot.params.email;
    if (this._route.snapshot.params.page) {
      this.visiblePageIndex = parseInt(this._route.snapshot.params.page, 10);
    }
    this._api.viewLdReport(email, assessmentId).subscribe((res) => {
      this.showReport = true;
      if (this.visiblePageIndex !== 0) {
        this.ldInitForIndex = true;
      }

      this.viewReport = res;
      this.personaDataForCurrentIndex = res.response.persona.data[0];
    });
  }

  // TODO  On modal
  getVideoURLSafe(video) {
    return video;
  }
  getColorBox(score) {
    if (score < 34) {
      return 'red';
    } else {
      if (score >= 34 && score < 67) {
        return 'orange';
      } else {
        return 'green';
      }
    }
  }

  goToPersona(pageIndex) {
    this.isCollapsed = true;
    if (pageIndex === 0) {
      this.ldInitForIndex = false;
    } else {
      this.ldInitForIndex = true;
    }

    const assessmentId = this._route.snapshot.params.id;
    const email = this._route.snapshot.params.email;
    const baseValue =
      'report/view-lnd-report/' + email + '/' + assessmentId + '/';
    // tslint:disable-next-line: radix
    switch (parseInt(pageIndex)) {
      case 0:
        this.visiblePageIndex = 0;
        this._router.navigate([baseValue + '0']);
        break;
      case 1:
        this.visiblePageIndex = 1;
        this._router.navigate([baseValue + '1']);

        // this._router.navigate([assessmentId + '/' + email + '/' + '1']);

        break;
      case 2:
        this.visiblePageIndex = 2;
        this._router.navigate([baseValue + '2']);
        break;
      case 3:
        this.visiblePageIndex = 3;
        this._router.navigate([baseValue + '3']);
        break;
      case 4:
        this.visiblePageIndex = 4;
        this._router.navigate([baseValue + '4']);

        break;
      case 5:
        this.visiblePageIndex = 5;
        this._router.navigate([baseValue + '5']);

        break;
      case 6:
        this._router.navigate([baseValue + '6']);
        this.visiblePageIndex = 6;
        break;
    }
  }
  changeLabelOfPersonaData(index) {
    this.personaKey = index;
    this.personaDataForCurrentIndex = this.viewReport.response.persona.data[
      index
    ];
  }

  // TODO modal integrations
  openModal(data, option) {
    this.ModalData.popHeader = '';
    this.ModalData.description = '';
    this.ModalData.popAveragePer = 0;
    this.ModalData.popHeadDesc = '';

    if (option === 'highComp') {
      this.ModalData.popType = 'highComp';
      this.ModalData.popHeader = data.success_factor;
      this.ModalData.popAveragePer = data.average_per;
      this.ModalData.description = data.description;
      this.ModalData.popVideos = [];
      this.ModalData.tips = [];
      if (data.rating === 'High') {
        this.ModalData.popHeadDesc = this.getReplacedText(data.high_match);
        this.ModalData.tips =
          data.high_improvement_tips !== undefined
            ? data.high_improvement_tips
            : '';
      }
      if (data.rating === 'Medium') {
        this.ModalData.popHeadDesc = this.getReplacedText(data.medium_match);
        this.ModalData.tips =
          data.improvement_tips !== undefined ? data.improvement_tips : '';
      }
      if (data.rating === 'Low') {
        this.ModalData.popHeadDesc = this.getReplacedText(data.low_match);
        this.ModalData.tips =
          data.improvement_tips !== undefined ? data.improvement_tips : '';
      }
      if (data.video !== undefined) {
        this.ModalData.popVideos = data.video;
      }
    }
    if (option === 'orgComp') {
      this.ModalData.popType = 'orgComp';
      this.ModalData.popHeader = data.org_name;
      this.ModalData.description = data.org_description;
      this.ModalData.popAveragePer = data.org_competency.overall_average_per;
      this.ModalData.popHeadDesc = this.getReplacedText(data.rating_text);
    }
    if (option === 'leadership') {
      this.ModalData.popType = 'leadership';
      this.ModalData.popHeader = data.factor;
      this.ModalData.description = data.description;
      this.ModalData.tips = data.improvement_tips;
      this.ModalData.popAveragePer = data.average_per;
      if (data.rating === 'High') {
        this.ModalData.popHeadDesc = this.getReplacedText(data.high_match);
        this.ModalData.tips =
          data.high_improvement_tips !== undefined
            ? data.high_improvement_tips
            : '';
      }
      if (data.rating === 'Medium') {
        this.ModalData.popHeadDesc = this.getReplacedText(data.medium_match);
        this.ModalData.tips =
          data.improvement_tips !== undefined ? data.improvement_tips : '';
      }
      if (data.rating === 'Low') {
        this.ModalData.popHeadDesc = this.getReplacedText(data.low_match);
        this.ModalData.tips =
          data.improvement_tips !== undefined ? data.improvement_tips : '';
      }
    }
    let scoreValue = this.ModalData.popAveragePer;
    if (scoreValue >= 45) {
      scoreValue -= 10;
    }

    this.modal.open(this.lndModal, {
      windowClass: 'dark-modal',
      backdrop: 'static',
      size: 'xl',
    });
  }

  getReplacedText(text) {
    let strText = '';
    if (text !== '' && text !== undefined) {
      if (this.viewReport.gender !== undefined) {
        if (this.viewReport.gender === 'Male') {
          strText = text
            .replace(/{@pronoun}/g, 'He')
            .replace(/{@spronoun}/g, 'he')
            .replace(/{@ppronoun}/g, 'His')
            .replace(/{@sppronoun}/g, 'his');
        } else if (this.viewReport.gender === 'Female') {
          strText = text
            .replace(/{@pronoun}/g, 'She')
            .replace(/{@spronoun}/g, 'she')
            .replace(/{@ppronoun}/g, 'Her')
            .replace(/{@sppronoun}/g, 'her');
        } else {
          strText = text
            .replace(/{@pronoun}/g, this.viewReport.candidate_name)
            .replace(/{@spronoun}/g, this.viewReport.candidate_name)
            .replace(/{@ppronoun}/g, 'Their')
            .replace(/{@sppronoun}/g, 'their');
        }
      } else {
        strText = text;
      }
    }
    const strname = strText
      .split('{@name}')
      .join(this.viewReport.candidate_name);
    return strname;
  }

  collapseIt() {
    this.isCollapsed = !this.isCollapsed;
  }
}
