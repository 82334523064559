<div class="loading" *ngIf="isLoading">

</div>
<div class="l-d" *ngIf="!isLoading">
        <div class=" header-top">
            <div class="container">
                <h3> Analytics Page </h3>
                
                <div class="d-flex justify-content-between position-relative">
                    <ul class="nav nav-tabs nav-justified" style="    width: 80%;">
                        <li *ngIf="reportData.highComp.data" active role="presentation" class="headersection" [ngClass]="personaKey==2?'active':''" (click)="changeLabelOfPersonaData(2)" style="width: 270px;">
                            <a href="javascript:void(0)" style="border-bottom:none;">{{reportData.highComp.title}}</a>
                            <span class="highlight-selected"></span>
                        </li>
                        <li *ngIf="reportData.leadership.data" active role="presentation" class="headersection" [ngClass]="personaKey==1?'active':''" (click)="changeLabelOfPersonaData(1)" style="width: 270px;">
                            <a href="javascript:void(0)" style="border-bottom:none;">{{reportData.leadership.title}}</a>
                            <span class="highlight-selected"></span>
                        </li>
                        <li *ngIf="reportData.orgComp.data" active role="presentation" class="headersection" [ngClass]="personaKey==3?'active':''" (click)="changeLabelOfPersonaData(3)" style="width: 270px;">
                            <a href="javascript:void(0)" style="border-bottom:none;">{{reportData.orgComp.title}}</a>
                            <span class="highlight-selected"></span>
                        </li>
                    </ul>
                    <a href="{{SERVER_URL}}/api/candidates/analytic_report_export/{{assessmentId}}" > <span class="excel-download"> </span></a>
                </div>
            </div>
        </div>
        <div class="container analytics-container">
                <div class="d-flex help-icons ">
                        <div class="d-flex guide-wrapper">
                            <div class="red-gradient guide-border-left-radius">
                                <p class="text-white guide-text"> 0 - 33 </p>
                            </div>
                            <div class="bg-white guide-border-right-radius">
                                <p class="text-gray-brown guide-text guide-white-patch">
                                    Development Area
                                </p>
                            </div>
                        </div>
            
                        <div class="d-flex guide-wrapper">
                            <div class="orange-gradient guide-border-left-radius">
                                <p class="text-white guide-text"> 34 - 66 </p>
                            </div>
                            <div class="bg-white guide-border-right-radius">
                                <p class="text-gray-brown guide-text guide-white-patch">
                                    Potential Strength
                                </p>
                            </div>
                        </div>
            
                        <div class="d-flex guide-wrapper">
                            <div class="green-gradient guide-border-left-radius">
                                <p class="text-white guide-text"> 67 - 100 </p>
                            </div>
                            <div class="bg-white guide-border-right-radius">
                                <p class="text-gray-brown guide-text guide-white-patch">
                                    Strength
                                </p>
                            </div>
                        </div>
                    </div>
        
        <div *ngIf="personaKey==1" class="table-responsive">
                <!-- <table class="table table-striped table-bordered">
    
                        <thead>
                            <tr class="table-header">
                                <th style="width: 190px;">Average scores across all candidates</th>
                                <th style="width: 190px;"></th>
                                <th style="width: 100px;"class="text-center" *ngFor="let leadershiphead of reportData.leadership.header" ><span class="circle circle-gray" *ngIf="leadershiphead.key=='_average'">{{leadershiphead._average}}</span></th>
                                <th class="text-center" *ngFor="let leadershiphead of reportData.leadership.header" ><span class="circle circle-gray" *ngIf="leadershiphead.key!='_'&& leadershiphead.key!='_average'&&leadershiphead.key!='_email'">{{leadershiphead._average}}</span></th>
                            </tr>
                        </thead>
            
                        <tbody>
                            <tr class="table-header-blue">
                                    <td>Compentencies</td>
                                    <td style="width: 100px;" class="text-center" ></td>
                                    <td style="width: 100px;" class="text-center" >Average</td>
                                    <td class="text-center" *ngFor="let leadershiphead of reportData.leadership.header"
                                      ><div   *ngIf="leadershiphead.key!='_' && leadershiphead.key!='_average' && leadershiphead.key!='_email'">{{leadershiphead.key}}</div></td>
                                </tr>
    
                            <tr *ngFor="let leadershipdata of reportData.leadership.data">
                                <td *ngFor="let data of leadershipdata | keyvalue" ><div *ngIf="data.key === '_name'">{{data.value}}</div></td>
                                <td *ngFor="let data of leadershipdata | keyvalue"><div  *ngIf="data.key === '_email'">{{data.value}}</div></td>
                                <td class="text-center" *ngFor="let data of leadershipdata | keyvalue" >
                                    <span class="circle circle-gray" *ngIf="data.key === '_average'" >{{data.value}}</span>
                                </td>
                                <td class="text-center" *ngFor="let data of leadershipdata | keyvalue" >
                                    <span class="circle" *ngIf="data.key !== '_name'&& data.key !== '_average'&& data.key !== '_email'" [style]="{ 'border' : (data.value < 34) ? '1px solid #e02715' : (data.value < 67 && data.value > 34) ? '1px solid #ff8620' :  '1px solid #00c593' }">{{data.value}}</span>
                                </td>
                            </tr>
            
                            
                        </tbody>
                    </table> -->
                    <table class="table table-striped table-bordered">
                        <thead>
                            <tr class="table-header">
                                <th style="width: 190px;">Average scores across all candidates</th>
                                    <th style="width: 190px;"></th>
                                    <ng-container *ngFor="let leadershiphead of reportData.leadership.header">
                                <th *ngIf="leadershiphead.key=='_average'"  style="width: 100px;" class="text-center"  >
                                        <span class="circle circle-gray">{{leadershiphead._average}}</span>
                                </th>
                            </ng-container>
                                <ng-container *ngFor="let leadershiphead of reportData.leadership.header" >
                                <th class="text-center" *ngIf="leadershiphead.key!='_'&&leadershiphead.key!='_average'&&leadershiphead.key!='_email'" >
                                        <span  class="circle circle-gray">{{leadershiphead._average}}</span>
                                </th>
                            </ng-container>
                            </tr>
                            
                        </thead>
                        <tbody>
                                <tr class="table-header-blue">
                                        <td>Compentencies</td>
                                        <td style="width: 100px;" class="text-center" ></td>
                                        <td style="width: 100px;" class="text-center" >Average</td>
                                        <ng-container  *ngFor="let leadershiphead of reportData.leadership.header">
                                        <td class="text-center" *ngIf="leadershiphead.key!='_' && leadershiphead.key!='_average' && leadershiphead.key!='_email'">{{leadershiphead.key}}</td>
                                        </ng-container>
                                    </tr>
                            <tr *ngFor="let data of reportData.leadership.data ">
                                <td *ngIf="data?._name"> {{data?._name}} </td>
                                <td  *ngIf="data?._email">{{data?._email}}</td>
                                <td class="text-center" *ngIf="data?._average">
                                    <span class="circle circle-gray">{{data?._average}}</span>
                                </td>
                                <ng-container *ngFor="let data1 of data | keyvalue"  >
                                <td class="text-center" *ngIf="data1.key!='_name'&& data1.key!='_average'&&data1.key!='_email'" >
                                        <span class="circle"   [style]="{ 'border' : (data1.value < 34) ? '1px solid #e02715' : (data1.value < 67 && data1.value > 34) ? '1px solid #ff8620' :  '1px solid #00c593' }">{{data1.value}}</span>
                                </td>
                                </ng-container>
                            </tr>
                        </tbody>
                    </table>
        </div>
        <div *ngIf="personaKey==2" class="table-responsive">
                <table class="table table-striped table-bordered">
                        <thead>
                            <tr class="table-header">
                                <th style="width: 190px;">Average scores across all candidates</th>
                                    <th style="width: 190px;"></th>
                                    <ng-container *ngFor="let leadershiphead of reportData.highComp.header">
                                <th *ngIf="leadershiphead.key=='_average'"  style="width: 100px;" class="text-center"  >
                                        <span class="circle circle-gray">{{leadershiphead._average}}</span>
                                </th>
                            </ng-container>
                                <ng-container *ngFor="let leadershiphead of reportData.highComp.header" >
                                <th class="text-center" *ngIf="leadershiphead.key!='_'&&leadershiphead.key!='_average'&&leadershiphead.key!='_email'" >
                                        <span  class="circle circle-gray">{{leadershiphead._average}}</span>
                                </th>
                            </ng-container>
                            </tr>
                            
                        </thead>
                        <tbody>
                                <tr class="table-header-blue">
                                        <td>Compentencies</td>
                                        <td style="width: 100px;" class="text-center" ></td>
                                        <td style="width: 100px;" class="text-center" >Average</td>
                                        <ng-container  *ngFor="let leadershiphead of reportData.highComp.header">
                                        <td class="text-center" *ngIf="leadershiphead.key!='_' && leadershiphead.key!='_average' && leadershiphead.key!='_email'">{{leadershiphead.key}}</td>
                                        </ng-container>
                                    </tr>
                            <tr *ngFor="let data of reportData.highComp.data ">
                                <!-- <td>{{data.key}}</td>
                                <td>{{data.value}}</td> -->
                                <td *ngIf="data?._name"> {{data?._name}} </td>
                                <td  *ngIf="data?._email">{{data?._email}}</td>
                                <td class="text-center" *ngIf="data?._average">
                                    <span class="circle circle-gray">{{data?._average}}</span>
                                </td>
                                <ng-container *ngFor="let data1 of data | keyvalue"  >
                                <td class="text-center" *ngIf="data1.key!='_name'&& data1.key!='_average'&&data1.key!='_email'" >
                                        <span class="circle"   [style]="{ 'border' : (data1.value < 34) ? '1px solid #e02715' : (data1.value < 67 && data1.value > 34) ? '1px solid #ff8620' :  '1px solid #00c593' }">{{data1.value}}</span>
                                </td>
                                </ng-container>
                            </tr>
                        </tbody>
                    </table>
        </div>
        
        <div *ngIf="personaKey==3" class="table-responsive">
                <!-- <table class="table table-striped table-bordered">
                        <thead>
                            <tr class="table-header">
                                <th style="width: 190px;">Avearge scores across all candidates</th>
                                <th style="width: 190px;"></th>
                                <th style="width: 100px;" class="text-center" *ngFor="let leadershiphead of reportData.orgComp.header">
                                        <span  *ngIf="leadershiphead.key=='_average'" class="circle circle-gray" >{{leadershiphead._average}}</span>
                                </th>
                                <th class="text-center" *ngFor="let leadershiphead of reportData.orgComp.header" >
                                        <span *ngIf="leadershiphead.key!='_'&&leadershiphead.key!='_average'&&leadershiphead.key!='_email'" class="circle circle-gray" >{{leadershiphead._average}}</span>
                                </th>
                            </tr>
                            <tr class="table-header-blue">
                                <td>Compentencies</td>
                                <td style="width: 100px;" class="text-center" ></td>
                                <td style="width: 100px;" class="text-center" >Average</td>
                                <td class="text-center"  *ngFor="let leadershiphead of reportData.orgComp.header"
                                    ><div *ngIf="leadershiphead.key!='_' && leadershiphead.key!='_average' && leadershiphead.key!='_email'">{{leadershiphead.key}} </div></td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let leadershipdata of reportData.orgComp.data">
                                <td  *ngFor="let data of leadershipdata | keyvalue" ><div *ngIf="data.key === '_name'">{{data.value}}</div></td>
                                <td  *ngFor="let data of leadershipdata | keyvalue"><div *ngIf="data.key ==='_email'">{{data.value}}</div></td>
                                <td class="text-center" *ngFor="let data of leadershipdata | keyvalue">
                                        <span  *ngIf="data.key === '_average'" class="circle circle-gray">{{data.value}}</span>
                                </td>
                                <td class="text-center" *ngFor="let data in leadershipdata | keyvalue" >
                                        <span *ngIf="data.key !== '_name'&& data.key !== '_average'&& data.key!== '_email'" class="circle" [style]="{ 'border' : (data.value < 34) ? '1px solid #e02715' : (data.value < 67 && data.value > 34) ? '1px solid #ff8620' :  '1px solid #00c593' }">{{data.value}}</span>
                                </td>
                            </tr>
                        </tbody>
                    </table> -->

                    <table class="table table-striped table-bordered">
                        <thead>
                            <tr class="table-header">
                                <th style="width: 190px;">Average scores across all candidates</th>
                                    <th style="width: 190px;"></th>
                                    <ng-container *ngFor="let leadershiphead of reportData.orgComp.header">
                                <th *ngIf="leadershiphead.key=='_average'"  style="width: 100px;" class="text-center"  >
                                        <span class="circle circle-gray">{{leadershiphead._average}}</span>
                                </th>
                            </ng-container>
                                <ng-container *ngFor="let leadershiphead of reportData.orgComp.header" >
                                <th class="text-center" *ngIf="leadershiphead.key!='_'&&leadershiphead.key!='_average'&&leadershiphead.key!='_email'" >
                                        <span  class="circle circle-gray">{{leadershiphead._average}}</span>
                                </th>
                            </ng-container>
                            </tr>
                            
                        </thead>
                        <tbody>
                                <tr class="table-header-blue">
                                        <td>Compentencies</td>
                                        <td style="width: 100px;" class="text-center" ></td>
                                        <td style="width: 100px;" class="text-center" >Average</td>
                                        <ng-container  *ngFor="let leadershiphead of reportData.orgComp.header">
                                        <td class="text-center" *ngIf="leadershiphead.key!='_' && leadershiphead.key!='_average' && leadershiphead.key!='_email'">{{leadershiphead.key}}</td>
                                        </ng-container>
                                    </tr>
                            <tr *ngFor="let data of reportData.orgComp.data ">
                                <td *ngIf="data?._name"> {{data?._name}} </td>
                                <td  *ngIf="data?._email">{{data?._email}}</td>
                                <td class="text-center" *ngIf="data?._average">
                                    <span class="circle circle-gray">{{data?._average}}</span>
                                </td>
                                <ng-container *ngFor="let data1 of data | keyvalue"  >
                                <td class="text-center" *ngIf="data1.key!='_name'&& data1.key!='_average'&&data1.key!='_email'" >
                                        <span class="circle"   [style]="{ 'border' : (data1.value < 34) ? '1px solid #e02715' : (data1.value < 67 && data1.value > 34) ? '1px solid #ff8620' :  '1px solid #00c593' }">{{data1.value}}</span>
                                </td>
                                </ng-container>
                            </tr>
                        </tbody>
                    </table>
        </div>
    </div>
    </div>
