<!-- start header -->
<div class="mainDiv" [ngClass]="(!user || excludedRoute) ? 'signup':''">
  <!-- <header autoscroll="true" ng-clock class="header display-none" ng-class="beforeLogin ? 'display-block' : ''"> -->
  <header class="header" *ngIf='user && !excludedRoute' [ngClass]=" user ? 'display-block' : '' ">
    <div class="container">
      <div class="row">
        <div class="col-md-7 col-sm-8">
          <div class="TopLink">
            <ul>
              <li><a routerLink="../dashboard"
                  [ngClass]="{'active-link': selectedTab==='Assessments'}">Assessments</a></li>
              <li><a routerLink="../learning" [ngClass]="{'active-link': selectedTab==='Orientation'}">Orientation</a>
              </li>
              <li><a routerLink="../account/company-profile-detail"
                  [ngClass]="{'active-link': selectedTab==='Settings'}">Settings</a></li>
            </ul>
          </div><!--  TopLink  -->
        </div>
        <div class="col-md-5 col-sm-4">
          <div class="TopRightGrid" ngbDropdown>
            <div ngbDropdownToggle id="userMenu">
              <div class="circle">
                <img *ngIf='companyLogo' [src]='companyLogo'
                  style="height: 50px; width: 50px;border-radius: 50%;object-fit: cover;">
              </div>
              <p> {{user?.user?.name}} </p>
            </div>

            <div id="toggle-menu" class="DetailBox" [ngClass]="{'height-75': showSettingOption}" ngbDropdownMenu
              aria-labelledby="userMenu">
              <ul>
                <li ngbDropdownItem *ngIf="!showSettingOption"><a routerLink="../account/company-profile-detail">Company
                    Profile </a></li>
                <li ngbDropdownItem *ngIf="!showSettingOption"><a
                    routerLink="../account/collaborators">Collaborators</a></li>
                <li ngbDropdownItem><a routerLink="../account/help-Centre">Help Centre</a></li>
                <li ngbDropdownItem><a routerLink="../account/changepassword">Change password</a></li>
                <li ngbDropdownItem (click)='logout()' style="border-bottom: 0px"><a> Logout </a></li>
              </ul>
            </div>
          </div>

        </div><!--  TopRightGrid  -->

      </div>
    </div><!--  row  -->
  </header>



  <header class="header" *ngIf='!user && !excludedRoute' [ngClass]=" !user ? 'display-block' : '' ">
    <div class="container">
      <div class="row">
        <div class="col-md-7 col-sm-8">
          <div class="TopLink">
            <ul>
              <li><a href={{downloadLink}}>Download</a></li>
            </ul>
          </div><!--  TopLink  -->
        </div>
    

      </div>
    </div><!--  row  -->
  </header>



  <!-- </header>end header -->
  <router-outlet></router-outlet>
  <footer *ngIf='user && !excludedRoute' id="FooterGrid">
    <!-- *ngIf="beforeLogin&&(ldInit==false||ldInit==undefined)" class="display-none" *ngClass="beforeLogin ? 'display-block' : ''" -->
    <div class="container">
      <div class="row"></div>
    </div>
  </footer>
</div>
