<section style="height: 100vh;">
	<div class="SignupGrid" >
		<div class="STopLine"></div>
	    <div class="SCircle">
	    	<h2>Talent<br /><span>Litmus</span></h2>
	    	</div><!--  SCircle  -->
	        <div class="SignUpBox">
						<form class="margin-zero"  [formGroup]='loginForm'>
		        	<div class="FormGrid">
                        <!-- ng-show="loginVM.error" -->
								<div><span >{{errors}}</span></div>
		          	<div class="SF">
		            	<input type="text" class="form-control Sup" formControlName='email' placeholder="EMAIL" required>
		            </div>
		            <div class="SF">
		            	<input type="password" class="form-control Sup" formControlName="password" placeholder="PASSWORD" required>
		            </div>
                    <div class="Forget"><a routerLink="/forgot-password"> Forgot Password ? </a></div>
                    <button
                    type="submit"
					(click)="onSubmit()"
					[disabled]="(loginForm.invalid || isLoading)"
					class="SupBut center-block"
                    >LOGIN</button>
                    <!-- [ngClass]="(loginForm.invalid || isLoading) ? 'SupBut center-block disabled-button' : 'SupBut center-block'" -->
                </div>
                </form>
	        </div><!--  SignUpBox -->
	</div><!--  SignupGrid  -->
</section>

