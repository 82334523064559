import { Injectable } from '@angular/core';
import { ApiBaseService } from '../api-base/api-base.service';
// import { Observable, BehaviorSubject } from 'rxjs';
import {
  CompanyDivisionType,
  TRequestCohorts,
} from 'src/app/learning/cohort.interface';
import { TNewCohort } from 'src/app/assessment/assessment-service/assessment.service';

import { map } from 'rxjs/operators';
import {
  TAddAptitudeGameData,
  TAddedAptitudeData,
  TAptiCandidateListResponse,
  TAptitudeGameList,
  TCompanyAssessmentResponse,
} from 'src/app/assessment/aptitude-assessments/aptitude.interface';
import { TQuestionGameList } from 'src/app/assessment/tech-assessments/tech-assessments.interface';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TSearchResult } from 'src/app/dashboard/search-bar/search-bar.component';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(private _api: ApiBaseService) {}

  // dashboard apis

  getAssessments(data) {
    return this._api.get('api/assessment/' + data, {}, {});
  }

  getAptitudeGames() {
    return this._api.getV2<TAptitudeGameList>('aptitude/games/list', {}, {});
  }

  getTechAssessmentsList() {
    return this._api.getV2<TQuestionGameList>('questions-game/list', {}, {});
  }

  getCompanyAptitudeGames(companyId: string) {
    return this._api.getV2<TCompanyAssessmentResponse>(
      `companies/${companyId}`,
    );
  }
  getAddedAptitudeAssessments(companyId: string) {
    return this._api.getV2<TAddedAptitudeData>(
      `aptitude/assessments/list/${companyId}`,
    );
  }
  aptitudeSendInvite(data: {
    assessmentId: string;
    companyId: string;
    companyName: string;
    candidates: { email: string; name: string }[];
  }) {
    return this._api.postV2('aptitude/invites/send-invite', data, {
      responseType: 'text',
    });
  }

  aptitudeCancelInvite(data: { email: string; assessmentId: string }) {
    return this._api.deleteV2('aptitude/invites/', data);
  }

  addAptitudeAssessment(data: TAddAptitudeGameData) {
    return this._api.postV2('aptitude/assessments/add', data);
  }

  editAptitudeAssessment(data: any) {
    return this._api.putV2('aptitude/assessments/edit', data);
  }
  deleteAptitudeAssessment(data: { _id: string }) {
    return this._api.deleteV2('aptitude/assessments/', data);
  }

  aptitudeUpdateInvite(data: any) {
    return this._api.postV2('aptitude/invites/update', data);
  }

  aptitudeAssessmentListCandidates(assessmentId) {
    return this._api.getV2<TAptiCandidateListResponse>(
      `aptitude/assessments/list-candidates/${assessmentId}`,
    );
  }

  getCohortsList(id) {
    return this._api.getV2<TRequestCohorts>(`cohorts/list/${id}`, {}, {});
  }
  getCohortById(cohortId) {
    return this._api.getV2<any>(`cohorts/${cohortId}`).pipe(
      map((data) => {
        data.candidates = data.candidates.list;
        return data;
      }),
    );
  }
  getDivisionForCompany(id: string) {
    return this._api.getV2<CompanyDivisionType[]>(`divisions/company/${id}`);
  }

  addCohort(cohortData: TNewCohort) {
    return this._api.postV2(`cohorts/add`, cohortData);
  }

  deleteCohort(data: { _id: string }) {
    return this._api.deleteV2('cohorts/', data);
  }

  cancelCohortInvite(data: { _id: string }) {
    return this._api.deleteV2('orientation-invites/', data);
  }

  updateCohortCandidate(data: { _id: string; starred: boolean }) {
    return this._api.postV2('orientation-invites/update', data);
  }

  editCohort(updatedData: {
    _id: string;
    name?: string;
    divisionId?: string;
    startDate?: string;
    endDate?: string;
    collaborator?: { collaboratorId: string }[];
  }) {
    return this._api.putV2('cohorts/edit', updatedData);
  }

  sendInvitesCohorts(data: TSendInvitedBody) {
    return this._api.postV2('orientation-invites/send-invite', data, {
      responseType: 'text',
    });
  }

  deleteCAssessment(id) {
    return this._api.delete('api/assessment/' + id, {});
  }
  removeCandidateFromAssessment(id, candidateiId) {
    return this._api.delete('api/candidates/' + candidateiId + '/' + id, {});
  }
  // assessments
  getCompetencies(companyId) {
    return this._api.get(
      'api/compframeworks/findAllByCompanyId/' + companyId,
      {},
      {},
    );
  }

  updateAssessment(id, data) {
    return this._api.put('api/assessment/' + id, data, {});
  }

  getAssessmentDetails(id) {
    return this._api.get(`api/assessment/detail/${id}`, {}, {});
  }

  postAssessment(data) {
    return this._api.post('api/assessment', data, {});
  }

  downloadExcelReportForHDFC(assessmentId: string, emailList: string[]) {
    // https://admins.talentlitmus.com/api/candidates/downloadAssessmentReportofCandidates/HDFC/:assessmentId
    const data = {
      email_list: emailList,
    };
    return this._api.post(
      `api/candidates/downloadAssessmentReportofCandidates/HDFC/${assessmentId}`,
      data,
      { responseType: 'blob' },
    );
  }

  // candidates

  getListCandidates(id: any, status?: any) {
    if (status !== undefined) {
      return this._api.get(
        'api/candidates/candidateList/listAssessmentCandidates/' +
          id +
          '/' +
          status,
        {},
        {},
      );
    } else {
      return this._api.get(
        'api/candidates/candidateList/listAssessmentCandidates/' + id,
        {},
        {},
      );
    }
  }

  getSjtCandidates(id: any) {
    return this._api.get(
      'api/candidates/valuesPlayer/list/' + id,
      {},
      {},
    );
  }

  getCandidatesAfterRefresh(id: string): any {
    return this._api.get('api/candidates/getCandidates/' + id, {}, {});
  }

  getCandidateData(id) {
    return this._api.get('api/candidates/' + id, {}, {});
  }
  downloadCandidateImages(id) {
    return this._api.get('api/candidates/fileDownload/' + id, {}, {});
  }

  getCompanyData(id: any) {
    return this._api.get('api/company/' + id, {}, {});
  }

  getTechnicalAssessment(email: string , assessmentId:string) {
    return this._api.get('api/mcq/player/byEmail/' + email + '/' + assessmentId, {}, {});
  }


  getActivityList(id: any) {
    return this._api.get('api/activities/list/' + id, {}, {});
  }
  getAssessmentUsers(id: any) {
    return this._api.get('api/assessment/getUsers/' + id, {}, {});
  }

  resendInvitation(dataPayload) {
    return this._api.post('api/candidates/resendInvitation/', dataPayload, {});
  }
  markStarred(dataPayload, urlPayload) {
    return this._api.put('api/candidates/' + urlPayload, dataPayload, {});
  }
  listCandidates(id, starFlag) {
    //  id => assessmentdetails_id
    const url =
      'api/candidates/candidateList/listAssessmentCandidates/' +
      id +
      '/' +
      starFlag;
    return this._api.get(url, {}, {});
  }
  getUsers(id) {
    const url = 'api/assessment/getUsers/' + id;
    return this._api.get(url, {}, {});
  }

  IndividualInvites(id, dataPayload) {
    return this._api.put(
      'api/candidates/addCandidates/assessment/' + id,
      dataPayload,
      {},
    );
  }

  // report api
  viewReport(email, id) {
    return this._api.get(
      'api/candidates/report/viewCandidateReport/' + email + '/' + id,
      {},
      {},
    );
  }

  getAssessmentDetail(id) {
    return this._api.get(`api/assessment/detail/${id}`, {}, {});
  }

  viewAptitudeReport(email: string, id: string): Observable<AptiReportType> {
    return this._api.getV2(`aptitude/invites/report/${email}/${id}`);
  }

  viewAptitudeReportForV1Candidates(
    email: string,
    id: string,
  ): Observable<AptiReportType> {
    return this._api.getV2(`aptitude/invites/report/v1/${email}/${id}`);
  }
  // analytic report api
  getAnalyticReport(id) {
    return this._api.get('api/candidates/analytic_report/' + id, {}, {});
  }
  downloadAnalyticExcel(id) {
    return this._api.get('api/candidates/analytic_report_export/' + id, {}, {});
  }

  viewLdReport(email, id) {
    return this._api.get(
      'api/candidates/report/learning_development/' + email + '/' + id,
      {},
      {},
    );
  }

  getTvsSjtScore(email, assessmentId) {
    return this._api.get(`api/gsjt/tvs/score/${email}/${assessmentId}`, {}, {});
  }
  // Account component
  updateCompanyData(id, dataPayload) {
    return this._api.put('api/company/' + id, dataPayload, {});
  }
  uploadImage(data) {
    return this._api.post('api/company/fileUpload', data, {});
  }
  updatePassword(dataPayload, id) {
    return this._api.put('api/users/' + id + '/password', dataPayload, {
      observe: 'body',
    });
  }

  resetPassword(dataPayload, id) {
    return this._api.put('api/users/' + id + '/resetpassword', dataPayload, {
      observe: 'body',
    });
  }
  // collaborators component
  listCollaborators(id) {
    return this._api.get('api/company/collaborators/' + id, {}, {});
  }

  deleteCollaborator(id) {
    return this._api.delete('api/users/deleteCollaborator/' + id, {});
  }
  addcollaborator(data) {
    return this._api.post('api/company/addCollaborator/', data, {});
  }
  uploadExcel(id, dataPayload) {
    return this._api.post('api/candidates/sendInvite/' + id, dataPayload, {});
  }

  uploadExcelAxis(id, dataPayload) {
    return this._api.post(
      `api/candidates/inviteCandidates/${id}`,
      dataPayload,
      {},
    );
  }

  uploadExcelOrientation(data: {
    companyId: string;
    cohortId: string;
    companyName: string;
    file: FormData;
  }) {
    let params = new HttpParams();
    params = params.append('companyName', data.companyName);
    return this._api.postV2(
      `orientation-invites/invitebulk/${data.companyId}/${data.cohortId}`,
      data.file,
      { params, responseType: 'text' },
    );
  }

  uploadExcelAptitude(data: {
    companyId: string;
    assessmentId: string;
    companyName: string;
    file: FormData;
  }) {
    let params = new HttpParams();
    params = params.append('companyName', data.companyName);
    return this._api.postV2(
      `aptitude/invites/invitebulk/${data.companyId}/${data.assessmentId}`,
      data.file,
      { params, responseType: 'text' },
    );
  }

  // candidate component
  updateCollaborator(id, data) {
    return this._api.put('api/assessment/addCollaborator/' + id, data, {});
  }

  sendEmail(dataPayload) {
    return this._api.post('api/company/sendMail', dataPayload, {});
  }

  downloadUsageReport(companyId: string) {
    return this._api.get(
      'api/assessment/usagereport/' + companyId,
      {},
      { responseType: 'blob' as 'json' },
    );
  }

  editCompany(data) {
    return this._api.putV2('companies/edit', data);
  }

  candidateSearch(
    search: string,
    companyId: string,
  ): Observable<TSearchResult[]> {
    return this._api.getV2(
      'candidate/search',
      {
        companyId,
        search,
      },
      {},
    );
  }

  forgotPassword(email) {
    return this._api.get('api/users/forgotPassword/' + email);
  }
}

interface TSendInvitedBody {
  cohortId: string;
  companyId: string;
  companyName: string;
  candidates: { email: string; name: string }[];
}

interface AptiReportType {
  assessmentId: {
    name: string;
    _id: string;
  };
  companyId: {
    Proctoring: boolean;
    name: string;
    _id: string;
  };
  email: string;
  name: string;
  processedScoreAvg: {
    gameName: 'quant' | 'language' | 'aptitude';
    score: number;
    _id: string;
  }[];
  processedScores: {
    gameName: string;
    gameType: 'quant' | 'language' | 'aptitude';
    rating: number;
    _id: string;
  }[];
  proctoringImages: string[];

  _id: string;
}
