import { Component, OnInit } from '@angular/core';
import { AssessmentService } from '../assessment-service/assessment.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-assessment-type',
  templateUrl: './assessment-type.component.html',
  styleUrls: ['./assessment-type.component.scss'],
})
export class AssessmentTypeComponent implements OnInit {
  selectedAssessment = this.as.getAssessmentValue;
  assessmentTypes = this.as.assessments.assessment_type;
  constructor(private as: AssessmentService, private _router: Router) {}

  ngOnInit(): void {}

  setActive(val) {
    if (this.selectedAssessment.assessment_type === val) {
      return 'active';
    }
  }
  assessmentSelected(job) {
    this.selectedAssessment.assessment_type = job.type;
    this.selectedAssessment.assessment_typeCode = job.typeCode;
  }

  saveAssessmentType() {
    this.as.setAssessmentData(this.selectedAssessment);
    if (this.selectedAssessment.assessment_typeCode === 'apti_assessment') {
      this._router.navigate(['summary']);
    } else {
      this._router.navigate(['purpose-assessment']);
    }
  }
}
