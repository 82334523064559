import { Component, OnInit } from '@angular/core';
import {
  AssessmentService,
  IAssessment,
} from '../assessment-service/assessment.service';
import { Router } from '@angular/router';
import { SharedDataService } from 'src/app/shared-services/shared-data/shared-data.service';

@Component({
  selector: 'app-purpose-assessment',
  templateUrl: './purpose-assessment.component.html',
  styleUrls: ['./purpose-assessment.component.scss'],
})
export class PurposeAssessmentComponent implements OnInit {
  jobtype = this.assessmentservice.assessments.jobtype;
  assessment = this.assessmentservice.getAssessmentValue;
  selectedJobtype = '';
  constructor(
    private assessmentservice: AssessmentService,
    private _router: Router,
    private shareService: SharedDataService
  ) {}

  ngOnInit(): void {
    this.shareService.setActiveTab('Assessments');

    this.selectedJobtype = this.assessment.job_type || '';
  }
  saveAssessmentPurpose() {
    this.assessment.job_type = this.selectedJobtype;
    this.assessmentservice.setAssessmentData(this.assessment);
    this._router.navigate(['job-title']);
  }

  purposeSelected(purpose) {
    this.selectedJobtype = purpose.job_type;
  }

  setActive(val) {
    if (this.selectedJobtype === val) {
      return 'active';
    }
  }
}
