<div class="HeadStrip">
    <div class="container">
      <div class="row">
        <div class="col-md-2 position-relative">
          <div class="Selectnumber">
            <div *ngIf="selectedAssessment?.assessment_typeCode=='psycho_assessment' || !selectedAssessment?.assessment_typeCode" class="Sub"><a routerLink="../purpose-assessment">
              
              <i class="fa fa-caret-left" aria-hidden="true"></i>Back</a>
            
            </div>
            <!-- <div *ngIf="!(selectedAssessment?.assessment_typeCode=='psycho_assessment' || !selectedAssessment?.assessment_typeCode)" class="Sub"><a routerLink="../assessment-type"><i class="fa fa-caret-left" aria-hidden="true"></i>Back</a></div> -->
            01
          </div><!--  Numbering  -->
        </div>
        <div class="col-md-10">
          <div class="ProfileHedding">
            <h2>Job Title<div class="in-info">State the job role you are assessing for.</div></h2>
          </div><!--  Numbering  -->
        </div>
      </div> <!--  row  -->
    </div><!--  container  -->
  </div><!--  HeadStrip -->
  <!--  Start Section  -->
  
  <div class="container">
    <div class="row JobTitelGrid">
      <div class="col-md-3">
        <span class="JobTiIco"></span>
      </div>
      <div class="col-md-9">
        <div class="col-md-12">
          <h3>Set up an assessment in less <br />than 5 minutes </h3>
            <p class="welcomeInfo">
                Our AI-driven interface understands your role and organisational requirements and maps it to behaviours and abilities needed to succeed. You can define your requirements and deploy assessments in less than 5 minutes.
            </p>
        </div>
        <div class="col-md-12">
          <form  [formGroup]='jobTitleForm' class="margin-zero">
            <div class="jobbox-grid">
              <!-- <div><span ng-show="assessmentVM.error">{{assessmentVM.errorMsg}}<span></div> -->
              <div class="BmInput margin-top-80">
                <input type="text" class="form-control fc-BmInput"  formControlName='jobTitle'  placeholder=" Designation eg: Business Manager" required>
              </div>
              <div class="jobtitel-btn-grid">
                <button type="" class="new-btn" (click)='setJobTitle()' [ngClass]="{ 'disabled-button': jobTitleForm.invalid }" value="Next" >Next</button>
              </div>
            </div><!--  row  -->
          </form>
        </div>
      </div>
    </div><!-- row  -->
  </div>