import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrderPipe } from '../order.pipe';
import { SafePipe } from '../safe/safe.pipe';
import { FilterByGenrePipe } from '../filter-by-genre/filter-by-genre.pipe';

@NgModule({
  declarations: [OrderPipe, FilterByGenrePipe, SafePipe],
  imports: [CommonModule],
  exports: [OrderPipe, FilterByGenrePipe, SafePipe],
})
export class SharedPipesModule {}
