import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ReportsRoutingModule } from './reports-routing.module';
import { ReportsComponent } from './reports.component';
import { LndReportsComponent } from './lnd-reports/lnd-reports.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ViewAnalyticComponent } from './view-analytic/view-analytic.component';
import { SharedPipesModule } from '../pipe/shared-pipes/shared-pipes.module';
import { AptitudeReportComponent } from './aptitude-report/aptitude-report.component';
import { ReportService } from './report.service';
import { GroupReportIpvComponent } from './group-report-ipv/group-report-ipv.component';

@NgModule({
  declarations: [
    ReportsComponent,
    LndReportsComponent,
    ViewAnalyticComponent,
    AptitudeReportComponent,
    GroupReportIpvComponent,
  ],
  imports: [CommonModule, ReportsRoutingModule, NgbModule, SharedPipesModule],
  providers: [ReportService],
})
export class ReportsModule {}
