import { Component, OnInit } from '@angular/core';
import { AssessmentService } from '../assessment-service/assessment.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ApiService } from 'src/app/shared-services/api/api/api.service';
import { Router } from '@angular/router';
import { ToasterService } from 'src/app/shared-services/toaster/toaster.service';
import { SharedDataService } from 'src/app/shared-services/shared-data/shared-data.service';
import { map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { COMPANY_ID } from 'src/app/shared-services/constants/globalEnums.enums';

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss'],
})
export class SummaryComponent implements OnInit {
  selectedAssessment: any;
  summaryForm: FormGroup;
  startDate: any;
  companyDetail = localStorage.latestCompanyData ? {
    ...JSON.parse(localStorage.user),
    company: JSON.parse(localStorage.latestCompanyData),
  }:  JSON.parse(localStorage.getItem('user'));

  showloader = false;
  valuesText = {
    ic: 'Individual Contributor',
    sm: 'Manager',
  };
  isTvs =
    this.companyDetail.user.company_id === COMPANY_ID.TVS_MOTOR ||
    this.companyDetail.user.company_id === COMPANY_ID.TVS_MOTOR_PRIVATE;
  selectedDepartmentName:string
  constructor(
    private assessmentService: AssessmentService,
    private _fb: FormBuilder,
    private _api: ApiService,
    private _router: Router,
    private _toastr: ToasterService,
    private sharedData: SharedDataService,
  ) {
    this.selectedAssessment = JSON.parse(
      JSON.stringify(this.assessmentService.getAssessmentValue),
    );
    this.selectedDepartmentName = this.companyDetail.company.departments?.find(
      (i) => i._id === this.selectedAssessment.department,
    )?.name;
  }

  ngOnInit(): void {
    this.sharedData.setActiveTab('Assessments');
    console.log(this.selectedAssessment);
    // condition for handling variable name change for aptitude game
    // this is for edit mode only
    if (
      this.selectedAssessment.job_type === 'Aptitude Assessments' &&
      this.selectedAssessment._id
    ) {
      this.startDate = this.selectedAssessment.startDate;
      this.summaryForm = this._fb.group({
        // startTime: new Date(this.assessmentService.getAssessmentValue.start_time),
        startDatetime: this.selectedAssessment.startDate,
        // endTime: new Date(this.assessmentService.getAssessmentValue.end_time),
        endDatetime: this.selectedAssessment.endDate,
      });
    } else {
      this.startDate = this.selectedAssessment._id
        ? this.assessmentService.getAssessmentValue.start_time
        : new Date().toISOString();
      this.summaryForm = this._fb.group({
        // startTime: new Date(this.assessmentService.getAssessmentValue.start_time),
        startDatetime: this.selectedAssessment._id
          ? this.assessmentService.getAssessmentValue.start_datetime
          : new Date().toISOString(),
        // endTime: new Date(this.assessmentService.getAssessmentValue.end_time),
        endDatetime: this.selectedAssessment._id
          ? this.assessmentService.getAssessmentValue.end_datetime
          : new Date(new Date().setFullYear(new Date().getFullYear() + 3)),
      });
    }
  }

  getJobReqirmentCount() {
    return this.selectedAssessment.job_requirment
      ? Object.keys(this.selectedAssessment.job_requirment).length
      : 0;
  }

  setTimeData() {
    this.selectedAssessment.start_time = this.summaryForm.controls.startDatetime.value;
    this.selectedAssessment.start_datetime = this.summaryForm.controls.startDatetime.value;
    this.selectedAssessment.end_time = this.summaryForm.controls.endDatetime.value;
    this.selectedAssessment.end_datetime = this.summaryForm.controls.endDatetime.value;
    this.assessmentService.setAssessmentData(this.selectedAssessment);
  }

  postAssessment() {
    this.selectedAssessment.created_by = this.companyDetail?.user?._id;
    this.selectedAssessment.company_id = this.companyDetail?.user?.company_id;
    this.showloader = true;
    this.setTimeData();
    // Aptitude assessment case
    if (this.selectedAssessment.job_type === 'Aptitude Assessments') {
      this.setAptitudeAssessments();
      return;
    }

    if (
      new Date(this.selectedAssessment.start_datetime) >
      new Date(this.selectedAssessment.end_datetime)
    ) {
      this._toastr.showError('End date cannot be before the start date!');
      return;
    }

    if (this.selectedAssessment._id) {
      let isBehavioral$ = of(null);
      if (
        this.selectedAssessment.company_id === COMPANY_ID.TVS_MOTOR ||
        this.selectedAssessment.company_id === COMPANY_ID.TVS_MOTOR_PRIVATE
      ) {
        // only for tvs
        isBehavioral$ = this._api
          .getAssessmentDetails(this.selectedAssessment._id)
          .pipe(
            map((res) => {
              return res.isBehavioralOff;
            }),
          );
      }

      isBehavioral$
        .pipe(
          switchMap((isBehavioral) => {
            delete this.selectedAssessment?.__v;
            const editAssessmentData = {
              ...this.selectedAssessment,
              values: this.selectedAssessment.values || null,
              isBehavioralOff: isBehavioral ? true : false,
            };
            delete editAssessmentData?.created_by;
            return this._api.updateAssessment(
              this.selectedAssessment._id,
              editAssessmentData,
            );
          }),
        )
        .subscribe(
          (_1) => {
            this._toastr.showSuccess('Assessment Updated successfully.');
            this.sharedData.clearLandingData();

            this._api
              .getCandidatesAfterRefresh(this.selectedAssessment._id)
              .subscribe(
                (_1) => {
                  this.showloader = false;
                  // this._toastr.showSuccess('Assessment Updated successfully.');
                  this._router.navigate(['congratulation']);
                },
                (err) => {
                  console.log(err);
                  this.showloader = false;
                  // this._toastr.showSuccess('Assessment Updated successfully.');
                  this._router.navigate(['congratulation']);
                },
              );

            // this._router.navigate(['congratulation']);
          },
          (_1) => {
            this._toastr.showError('something went wrong');
          },
        );
    } else {
      this._api
        .postAssessment({
          ...this.selectedAssessment,
          values: this.selectedAssessment.values || null,
        })
        .subscribe(
          (res) => {
            this.assessmentService.setAssessmentData(res);
            this._toastr.showSuccess('Assessment Added successfully.');
            this.sharedData.clearLandingData();
            this.showloader = false;
            this._router.navigate(['congratulation']);
          },
          (_1) => {
            this._toastr.showError('something went wrong');
          },
        );
    }
    // this.assessmentService.clearAssessmentData();
  }

  setAptitudeAssessments() {
    // if id is present then we will update the assessment

    // check validation for date

    if (
      new Date(this.selectedAssessment.start_datetime) >
      new Date(this.selectedAssessment.end_datetime)
    ) {
      this._toastr.showError('End date cannot be before the start date!');
      return;
    }

    if (this.selectedAssessment._id) {
      this._api
        .editAptitudeAssessment({
          _id: this.selectedAssessment._id,
          companyId: this.selectedAssessment.company_id,
          endDate: this.selectedAssessment.end_datetime,
          startDate: this.selectedAssessment.start_datetime,
          name: this.selectedAssessment.job_title,
          // aptitudeGames: this.selectedAssessment.aptitudeGames.map((el) => ({
          //   gameId: el.gameId,
          //   order: el.order,
          // })),
          techAssessments: this.selectedAssessment.techAssessments,
          collaborator: this.selectedAssessment.collaborator,
        })
        .subscribe(
          (res: any) => {
            console.log(res);
            this._toastr.showSuccess('Assessment Updated successfully.');
            this.sharedData.clearLandingData();
            this.assessmentService.setAssessmentData({
              ...this.selectedAssessment,
              createdAt: res.createdAt,
              _id: res._id,
              startDate: res.startDate,
              endDate: res.endDate,
            });
            this._router.navigate(['congratulation']);
          },
          (err) => {
            console.log(err);
            this._toastr.showError('something went wrong');
          },
        );
    } else {
      this._api
        .addAptitudeAssessment({
          companyId: this.selectedAssessment.company_id,
          createdBy: this.selectedAssessment.created_by,
          endDate: this.selectedAssessment.end_datetime,
          startDate: this.selectedAssessment.start_datetime,
          name: this.selectedAssessment.job_title,
          // aptitudeGames: this.selectedAssessment.aptitudeGames.map((el) => ({
          //   gameId: el.gameId,
          //   order: el.order,
          // })),
          techAssessments: this.selectedAssessment.techAssessments,
        })
        .subscribe(
          (res: any) => {
            console.log(res);

            this.assessmentService.setAssessmentData({
              ...this.selectedAssessment,
              createdAt: res.createdAt,
              _id: res._id,
              startDate: res.startDate,
              endDate: res.endDate,
            });
            this._toastr.showSuccess('Assessment Added successfully.');
            this.sharedData.clearLandingData();
            this._router.navigate(['congratulation']);
          },
          (err) => {
            console.log(err);
            this._toastr.showError('something went wrong');
          },
        );
    }
  }
}
