import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { AssessmentService } from '../assessment-service/assessment.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { SharedDataService } from 'src/app/shared-services/shared-data/shared-data.service';

@Component({
  selector: 'app-job-title-learning',
  templateUrl: './job-title-learning.component.html',
  styleUrls: ['./job-title-learning.component.scss'],
})
export class JobTitleLearningComponent implements OnInit {
  jobTitleForm: FormGroup;
  // selectedAssessment: IAssessment;
  subscriptions: Subscription[] = [];
  isEditMode = false;
  isDuplicate = false;
  constructor(
    private _fb: FormBuilder,
    private as: AssessmentService,
    private _router: Router,
    private _route: ActivatedRoute,
    private sharedService: SharedDataService,
  ) {}

  ngOnInit(): void {
    this.sharedService.setActiveTab('Orientation');

    this.jobTitleForm = this._fb.group({
      jobTitle: ['', [Validators.required]],
    });
    const queryParams = this._route.snapshot.queryParams;

    if (queryParams.edit) {
      this.isEditMode = true;
      const lastAddedCohort = this.as.getLastAddedCohortData();
      this.jobTitleForm.controls.jobTitle.setValue(lastAddedCohort.name);
    } else if (queryParams.duplicate) {
      this.isDuplicate = true;
    }

    // this.Init();
  }
  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  setJobTitle() {
    // const dataAssessment = this.selectedAssessment;
    if (this.isEditMode) {
      const lastAddedCohort = this.as.getLastAddedCohortData();
      const updatedData = {
        ...lastAddedCohort,
        name: this.jobTitleForm.controls.jobTitle.value,
      };
      this.as.setLastAddedCohortData(updatedData);
      this._router.navigate(['summary-learning'], {
        queryParams: { edit: true },
      });
    } else if (this.isDuplicate) {
      const duplicatedCohort = this.as.getLastAddedCohortData();
      this.as.newCohortData.name = this.jobTitleForm.controls.jobTitle.value;
      this.as.newCohortData.companyId = duplicatedCohort.companyId;
      this.as.newCohortData.divisionId = duplicatedCohort.divisionId;
      this.as.newCohortData.startDate = duplicatedCohort.startDate;
      this.as.newCohortData.endDate = duplicatedCohort.endDate;

      this._router.navigate(['summary-learning'], {
        queryParams: { duplicate: true },
      });
    } else {
      this.as.newCohortData.name = this.jobTitleForm.controls.jobTitle.value;
      this._router.navigate(['summary-learning']);
    }

    // if (dataAssessment.job_type === 'Customised Profile Fit') {
    //   this.subscriptions.push(this.as
    //     .setAssessmentField('job_title', this.selectedAssessment.job_title)
    //     .subscribe((res) => {

    //       if (res?.isCloned === 'duplicate') {
    //         this.subscriptions.push(
    //           this.as.setAssessmentField('isCloned', '').subscribe((_1) => {
    //             this._router.navigate(['summary']);
    //           }),
    //         );
    //       } else {
    //         if (res?.assessment_typeCode === 'apti_assessment') {
    //           this._router.navigate(['assessment-type']);
    //         } else {
    //           this._router.navigate(['profile']);
    //         }
    //       }
    //     }))
    // } else {
    //   if (dataAssessment?.isCloned === 'duplicate') {
    //     this.subscriptions.push(
    //       this.as.setAssessmentField('isCloned', '').subscribe((_1) => {
    //         this._router.navigate(['summary']);
    //       }),
    //     );
    //   } else {
    //     this.subscriptions.push(
    //       this.as
    //         .setAssessmentField('job_title', this.selectedAssessment.job_title)
    //         .subscribe((res) => {

    //           if (res?.assessment_typeCode === 'apti_assessment') {
    //             this._router.navigate(['assessment-type']);
    //           } else {
    //             this._router.navigate(['competency']);
    //           }
    //         }),
    //     );
    //   }
    // }
  }

  // Init() {
  //   this.as.getAssessmentData().subscribe((res) => {
  //     if (res) {
  //       if (res?.job_type !== '') {
  //         this.selectedAssessment = res;
  //       } else {
  //         this.selectedAssessment = {
  //           job_type: '',
  //           job_title: '',
  //           profile_name: 'Individual Contributor',
  //           competency_id: 'competency Set 1',
  //           competency_name: 'competency Set 1',
  //           domain_name: 'Sales',
  //           job_requirment: {},
  //           start_time: new Date(),
  //           start_datetime: new Date(),
  //           end_time: new Date(),
  //           end_datetime: new Date(),
  //           // assessment_type: '',
  //           // isCloned: undefined,
  //         };
  //       }

  //       this.jobTitleForm.setValue({
  //         jobTitle: this.selectedAssessment.job_title || '',
  //       });
  //     }
  //   });
  // }
}
