import { Component, OnInit } from '@angular/core';
import {
  AssessmentService,
  TNewCohort,
} from '../assessment-service/assessment.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ApiService } from 'src/app/shared-services/api/api/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToasterService } from 'src/app/shared-services/toaster/toaster.service';
import { SharedDataService } from 'src/app/shared-services/shared-data/shared-data.service';
import { CompanyDivisionType } from 'src/app/learning/cohort.interface';
import { AuthService, IUser } from 'src/app/shared-services/auth/auth.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-summary-learning',
  templateUrl: './summary-learning.component.html',
  styleUrls: ['./summary-learning.component.scss'],
})
export class SummaryLearningComponent implements OnInit {
  selectedAssessment: TNewCohort;
  summaryForm: FormGroup;
  startDate: any;
  companyDetail = JSON.parse(localStorage.getItem('user'));
  companyDivisionData: CompanyDivisionType[] = [];
  selectedDivision = '';
  isEditMode = false;
  subscriptions: Subscription[];
  user: IUser;

  constructor(
    private assessmentService: AssessmentService,
    private _fb: FormBuilder,
    private _api: ApiService,
    private _router: Router,
    private _toastr: ToasterService,
    private sharedData: SharedDataService,
    private _route: ActivatedRoute,
    private _auth: AuthService,
  ) {}

  ngOnInit(): void {
    this.sharedData.setActiveTab('Orientation');

    this._auth.currentUser.subscribe((user) => {
      if (user && user.user) {
        this.user = user;
      } else {
        // this._auth.logout();
        this._router.navigate(['login']);
      }
    });

    const queryParams = this._route.snapshot.queryParams;
    this.selectedAssessment = this.assessmentService.newCohortData;
    // if we are visiting the page from the edit button
    if (queryParams.edit) {
      const cohort = this.assessmentService.getLastAddedCohortData();
      this.selectedAssessment = cohort;
      this.isEditMode = true;
      this.startDate = this.selectedAssessment.startDate;
      this.selectedDivision = this.selectedAssessment.divisionId;
      this.summaryForm = this._fb.group({
        // startTime: new Date(this.assessmentService.getAssessmentValue.start_time),
        startDatetime: this.selectedAssessment.startDate,
        // endTime: new Date(this.assessmentService.getAssessmentValue.end_time),
        endDatetime: this.selectedAssessment.endDate,
      });
    } else if (queryParams.duplicate) {
      this.startDate = this.selectedAssessment.startDate;
      this.selectedDivision = this.selectedAssessment.divisionId;
      this.summaryForm = this._fb.group({
        // startTime: new Date(this.assessmentService.getAssessmentValue.start_time),
        startDatetime: new Date().toISOString(),
        // endTime: new Date(this.assessmentService.getAssessmentValue.end_time),
        endDatetime: new Date(
          new Date().setFullYear(new Date().getFullYear() + 3),
        ),
      });
    } else {
      this.startDate = this.assessmentService.getAssessmentValue.start_time;
      this.summaryForm = this._fb.group({
        // startTime: new Date(this.assessmentService.getAssessmentValue.start_time),
        startDatetime: this.assessmentService.getAssessmentValue.start_datetime,
        // endTime: new Date(this.assessmentService.getAssessmentValue.end_time),
        endDatetime: this.assessmentService.getAssessmentValue.end_datetime,
      });
    }

    this.companyDivisionData = this.sharedData.getCompanyDivisionData();

    const defaultDivision = this.companyDivisionData.filter(
      (el) => el.name === 'Default',
    )[0];
    if (defaultDivision) {
      this.selectedDivision = defaultDivision._id;
    }

    // if page is refreshed, then we need to again fetch the division data
    if (this.companyDivisionData.length === 0) {
      this.fetchCompanyDivisionData();
    }
  }

  // getJobReqirmentCount() {
  //   return this.selectedAssessment.job_requirment
  //     ? Object.keys(this.selectedAssessment.job_requirment).length
  //     : 0;
  // }

  setTimeData() {
    this.selectedAssessment.startDate = this.summaryForm.controls.startDatetime.value;
    this.selectedAssessment.endDate = this.summaryForm.controls.endDatetime.value;

    // this.assessmentService.setAssessmentData(this.selectedAssessment);
  }
  fetchCompanyDivisionData() {
    const userData = JSON.parse(localStorage.getItem('user'));
    this._api
      .getDivisionForCompany(userData.company._id)
      .subscribe((divisionData) => {
        this.companyDivisionData = divisionData;
        this.sharedData.setCompanyDivisionData(divisionData);
      });
  }

  isDataValid(assessmentData: TNewCohort) {
    // fist check if all the data is present
    if (
      !assessmentData.companyId ||
      !assessmentData.divisionId ||
      !assessmentData.startDate ||
      !assessmentData.name
    ) {
      this._toastr.showError('Data missing! Please try again');
      return false;
    }
    // check if the date is valid
    if (new Date(assessmentData.startDate) > new Date(assessmentData.endDate)) {
      this._toastr.showError('End date cannot be before the start date!');
      return false;
    }
    return true;
  }
  handleNameEdit() {
    if (this.isEditMode) {
      this._router.navigate(['job-title-learning'], {
        queryParams: { edit: true },
      });
    } else {
      this._router.navigate(['job-title-learning']);
    }
  }

  postAssessment() {
    // if company id is not present due to any case we will add it here
    let loggedUserAssessment = '';
    if (this.user?.user?.role === 'user') {
      loggedUserAssessment = this.user?.user?._id;
    } else {
      loggedUserAssessment = this.user?.user?.company_id;
    }

    this.selectedAssessment.companyId = this.user.company._id;
    this.selectedAssessment.createdBy = loggedUserAssessment;
    // if (!this.selectedAssessment.companyId) {
    //   this.selectedAssessment.companyId = loggedUserAssessment;
    // }
    this.selectedAssessment.divisionId = this.selectedDivision;

    this.setTimeData();

    if (!this.isDataValid(this.selectedAssessment)) {
      return;
    }

    if (this.isEditMode) {
      this._api
        .editCohort({
          _id: this.selectedAssessment._id,
          name: this.selectedAssessment.name,
          divisionId: this.selectedAssessment.divisionId,
          endDate: this.selectedAssessment.endDate,
          startDate: this.selectedAssessment.startDate,
        })
        .subscribe((response) => {
          console.log(response);
          this._toastr.showSuccess('Group updated Successfully');
          this.assessmentService.setLastAddedCohortData(
            this.selectedAssessment as any,
          );
          this._router.navigate([`congratulation`], {
            queryParams: { cohortId: this.selectedAssessment._id },
          });
        });
    } else {
      this._api.addCohort(this.selectedAssessment).subscribe(
        (res: any) => {
          console.log(res);
          this.assessmentService.setLastAddedCohortData(res);
          this._toastr.showSuccess('Group added successfully.');
          this.assessmentService.resetNewCohortData();
          // will clear the cache
          this.sharedData.setCohortList(null);
          this.sharedData.setSelectedCohort(null);
          this._router.navigate([`congratulation`], {
            queryParams: { cohortId: res._id },
          });
        },
        (error) => {
          this._toastr.showError('something went wrong');
          console.log(error);
        },
      );
    }

    // make api call here

    // if (this.selectedAssessment._id) {
    //   delete this.selectedAssessment?.__v;
    //   this._api
    //     .updateAssessment(this.selectedAssessment._id, this.selectedAssessment)
    //     .subscribe(
    //       (_1) => {
    //         this._toastr.showSuccess('Assessment Updated successfully.');
    //         this.sharedData.clearLandingData();
    //         this._router.navigate(['congratulation']);
    //       },
    //       (_1) => {
    //         this._toastr.showError('something went wrong');
    //       },
    //     );
    // } else {
    //   this._api.postAssessment(this.selectedAssessment).subscribe(
    //     (res) => {
    //       this.assessmentService.setAssessmentData(res);
    //       this._toastr.showSuccess('Assessment Added successfully.');
    //       this.sharedData.clearLandingData();
    //       this._router.navigate(['congratulation']);
    //     },
    //     (_1) => {
    //       this._toastr.showError('something went wrong');
    //     },
    //   );
    // }
    // this.assessmentService.clearAssessmentData();
  }
}
