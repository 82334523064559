import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { ToasterService } from '../toaster/toaster.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private _authService: AuthService,
    private _router: Router,
    private _toast: ToasterService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = this._authService.currentUserTokenValue;

    if (currentUser) {
      // logged in so return true
      if (!currentUser.token) {
        this._toast.showError('Authorized Personnel Only');
        this._router.navigate(['login']);
        return false;
      }

      return true;
    }

    this._router.navigate(['login'], { queryParams: { returnUrl: state.url } });
    // not logged in so redirect to login page with the return url
    return false;
  }
}
