<!-- KUDOS TO DEV -->
<!-- look for *ngFor it is sometimes used with keyvalue pipe, as it was proposed in old angularJs project  :) thanks me later -->
<!-- Look for 3 point and  5 point report saperatly the HTML might be messed up for some instinct -->
<header class="header" *ngIf="!user" [ngClass]="!user ? 'display-block' : ''">
  <div class="container">
    <div class="row">
      <div class="col-md-7 col-sm-8">
        <div class="TopLink">
          <ul>
            <li><a href="{{ downloadLink }}">Download</a></li>
          </ul>
        </div>
        <!--  TopLink  -->
      </div>
    </div>
  </div>
  <!--  row  -->
</header>
<!-- 3 point -->
<div *ngIf="isThreePointReport" style="background: #ccc">  
  <div class="responsiveReportGrid">
    <!-- page1 -->
    <div class="ReportGrid">
      <div class="page1" *ngIf="isPageOne">
        <div class="RTopLeft"></div>
        <div class="RTopRight"></div>
        <div class="ReportHead">
          <div class="RLogoGrid">
            <img src="assets/images/reports/Rlogo.svg" />
          </div>
          <!--  RLogoGrid  -->
          <div class="RDateGrid">
            <h2>
              <span>Date of Assessment</span>
              {{ viewReport?.report_data?.assessment_date }}
            </h2>
          </div>
          <!--  RDateGrid  -->
          <div class="clearfix"></div>
          <div class="RCandidateIm">
            <img
              *ngIf="getVerdictImage(viewReport, 'Unlikely to succeed')"
              src="assets/images/reports/clip_patch-02.png"
              style="width: 200px; margin-top: 5px"
            />
            <img
              *ngIf="
                getVerdictImage(viewReport, 'Restricted likelihood of success')
              "
              src="assets/images/reports/clip_patch-04.png"
              style="width: 200px; margin-top: 5px"
            />
            <img
              *ngIf="getVerdictImage(viewReport, 'Likely to succeed')"
              src="assets/images/reports/clip_patch-01.png"
              style="width: 200px; margin-top: 5px"
            />
            <img
              *ngIf="getVerdictImage(viewReport, 'Very Likely to succeed')"
              src="assets/images/reports/clip_patch-03.png"
              style="width: 200px; margin-top: 5px"
            />
          </div>
          <!-- RCandidateIm  -->
          <div class="RCandidateBox">
            <div class="RCandidateInfo">
              <h2>
                <span>Name of Candidate</span
                >{{ viewReport?.report_data?.candidate_first_name }}
                {{ viewReport?.report_data?.candidate_last_name }}
              </h2>
            </div>
            <!--  RCandidateInfo  -->
            <div class="RCandidateInfo">
              <h2>
                <span>Assessment Title</span
                >{{ viewReport?.report_data?.job_title }}
              </h2>
            </div>
            <!--  RCandidateInfo  -->
            <div class="RCandidateInfo">
              <h2>
                <span>Report</span
                >{{ getReportName(viewReport?.report_data?.job_type) }}
              </h2>
            </div>
            <!--  RCandidateInfo  -->
          </div>
          <!--  RCandidateBox  -->
          <div class="clearfix"></div>
          <div [ngClass]="{ 'reportheading-wrapper-div': true }">
            <!-- For All Job Types -->
            <!-- [ngClass]="headingAlignment(hideOrgComp, viewReport?.report_data?.profile_name, viewReport?.comp_framework)" -->
            <div
              class="RBoxGrid"
              *ngIf="viewReport?.report_data?.title != '0NA'"
              [ngClass]="
                headingAlignment(
                  hideOrgComp,
                  viewReport?.report_data?.profile_name,
                  viewReport?.comp_framework
                )
              "
            >
              <h2
                *ngIf="
                  viewReport?.report_data?.job_type != 'Organisational Match'
                "
              >
                {{ viewReport?.report_data?.title }}
              </h2>
              <h2
                *ngIf="
                  viewReport?.report_data?.job_type == 'Organisational Match'
                "
              >
                {{ viewReport?.report_data?.response.coverpage_name }}
              </h2>
              <p
                *ngIf="
                  viewReport?.report_data?.job_type != 'Organisational Match'
                "
              >
                {{ viewReport?.report_data?.coverpage_subtext }}
              </p>
              <p
                *ngIf="
                  viewReport?.report_data?.job_type == 'Organisational Match'
                "
              >
                {{
                  viewReport?.report_data?.response.orgcompetency_coverpage_text
                }}
              </p>
              <div style="float: left; width: 99%; margin-left: 5px">
                <div
                  *ngIf="
                    viewReport?.report_data?.response.overall_average_rating ===
                    'Low'
                  "
                >
                  <div class="Black-Star"></div>
                  <div class="White-Star"></div>
                  <div class="White-Star"></div>
                </div>

                <div
                  *ngIf="
                    viewReport?.report_data?.response.overall_average_rating ===
                    'Medium'
                  "
                >
                  <div class="Black-Star"></div>
                  <div class="Black-Star"></div>
                  <div class="White-Star"></div>
                </div>

                <div
                  *ngIf="
                    viewReport?.report_data?.response.overall_average_rating ===
                    'High'
                  "
                >
                  <div class="Black-Star"></div>
                  <div class="Black-Star"></div>
                  <div class="Black-Star"></div>
                </div>

                <!-- <div class="Black-Star"></div>
              <div class="Black-Star"></div>
              <div class="White-Star"></div> -->
                <!-- <div [ngClass]="getStarClass(viewReport?.report_data?.response.overall_star_average_rating, 'Medium')"></div>
              <div [ngClass]="getHighStarClass(viewReport?.report_data?.response.overall_star_average_rating)"></div> -->
                <div class="RLow">
                  {{ viewReport?.report_data?.response.overall_average_rating }}
                </div>
              </div>
            </div>
            <!--  RBoxGrid  -->

            <!-- For Managerial Style in case of Customised Profile Fit -->
            <div
              class="RBoxGrid"
              [ngClass]="hideOrgComp ? 'floatLeft' : 'floatLeft'"
              *ngIf="
                viewReport?.report_data?.job_type == 'Customised Profile Fit' &&
                viewReport?.report_data?.profile_name !=
                  'Individual Contributor'
              "
            >
              <h2>{{ viewReport?.report_data?.mparam_response.title }}</h2>
              <p
                *ngIf="
                  viewReport?.report_data?.job_type == 'Customised Profile Fit'
                "
              >
                {{ viewReport?.report_data?.mparam_response.coverpage_subtext }}
              </p>
              <div style="float: left; width: 99%; margin-left: 5px">
                <div
                  *ngIf="
                    viewReport?.report_data?.mparam_response
                      .overall_average_rating === 'Low'
                  "
                >
                  <div class="Black-Star"></div>
                  <div class="White-Star"></div>
                  <div class="White-Star"></div>
                </div>

                <div
                  *ngIf="
                    viewReport?.report_data?.mparam_response
                      .overall_average_rating === 'Medium'
                  "
                >
                  <div class="Black-Star"></div>
                  <div class="Black-Star"></div>
                  <div class="White-Star"></div>
                </div>

                <div
                  *ngIf="
                    viewReport?.report_data?.mparam_response
                      .overall_average_rating === 'High'
                  "
                >
                  <div class="Black-Star"></div>
                  <div class="Black-Star"></div>
                  <div class="Black-Star"></div>
                </div>

                <!-- <div class="Black-Star"></div>
              <div [ngClass]="getStarClass(viewReport?.report_data?.mparam_response.overall_star_average_rating, 'Medium')"></div>
              <div [ngClass]="getHighStarClass(viewReport?.report_data?.mparam_response.overall_star_average_rating)"></div> -->
                <!--div [ngClass]="{
                'White-Star': viewReport?.report_data?.mparam_response.overall_average_rating=='Low',
                'White-Star': viewReport?.report_data?.mparam_response.overall_average_rating=='Medium',
                'Black-Star': viewReport?.report_data?.mparam_response.overall_average_rating=='High'
              }"></div-->
                <div class="RLow">
                  {{
                    viewReport?.report_data?.mparam_response
                      .overall_average_rating
                  }}
                </div>
              </div>
            </div>
            <!--  RBoxGrid  -->

            <!-- For Organizatinal competency in case of Customised Profile Fit -->
            <div
              class="RBoxGrid"
              *ngIf="hideOrgComp"
              [ngClass]="hideOrgComp ? 'floatLeft' : ''"
            >
              <h2>{{ viewReport?.comp_framework?.coverpage_name }}</h2>
              <p>
                {{
                  getReplacedText(
                    viewReport?.comp_framework?.orgcompetency_coverpage_text
                  )
                }}
              </p>
              <div style="float: left; width: 99%; margin-left: 5px">
                <div
                  *ngIf="
                    viewReport?.comp_framework?.overall_average_rating === 'Low'
                  "
                >
                  <div class="Black-Star"></div>
                  <div class="White-Star"></div>
                  <div class="White-Star"></div>
                </div>

                <div
                  *ngIf="
                    viewReport?.comp_framework?.overall_average_rating ===
                    'Medium'
                  "
                >
                  <div class="Black-Star"></div>
                  <div class="Black-Star"></div>
                  <div class="White-Star"></div>
                </div>

                <div
                  *ngIf="
                    viewReport?.comp_framework?.overall_average_rating ===
                    'High'
                  "
                >
                  <div class="Black-Star"></div>
                  <div class="Black-Star"></div>
                  <div class="Black-Star"></div>
                </div>

                <!-- <div class="Black-Star"></div>
              <div [ngClass]="getStarClass(viewReport?.comp_framework?.overall_star_average_rating, 'Medium')"></div>
              <div [ngClass]="getHighStarClass(viewReport?.comp_framework?.overall_star_average_rating)"></div> -->
                <!--div [ngClass]="{
                'White-Star': viewReport?.comp_framework?.overall_average_rating=='Low',
                'White-Star': viewReport?.comp_framework?.overall_average_rating=='Medium',
                'Black-Star': viewReport?.comp_framework?.overall_average_rating=='High'
              }"></div-->
                <div class="RLow">
                  {{ viewReport?.comp_framework?.overall_average_rating }}
                </div>
              </div>
            </div>
            <!--  RBoxGrid  -->
          </div>
          <div class="clearfix"></div>
          <div class="RBotBox">
            <a href="javascript: void(0);">
              <div
                class="RTabBox"
                [ngClass]="getOverallVerdict(viewReport, 'Unlikely to succeed')"
              >
                <!-- <span *ngIf="getOverallVerdict(viewReport,?.report_data?.response.overall_average, 'Unlikely to succeed')">This candidate is</span>-->
                Unlikely to succeed
              </div>
            </a>
            <a href="javascript: void(0);">
              <div
                class="RTabBox"
                [ngClass]="
                  getOverallVerdict(
                    viewReport,
                    'Restricted likelihood of success'
                  )
                "
              >
                <!-- <span *ngIf="getOverallVerdict(viewReport,?.report_data?.response.overall_average, 'Restricted likelihood of success')">This candidate is</span> -->
                Restricted Likelihood of Success
              </div>
            </a>
            <a href="javascript: void(0);">
              <div
                class="RTabBox"
                [ngClass]="getOverallVerdict(viewReport, 'Likely to succeed')"
              >
                <!-- <span *ngIf="getOverallVerdict(viewReport,?.report_data?.response.overall_average, 'Likely to succeed') ">This candidate is</span> -->
                Likely to succeed
              </div>
            </a>
            <a href="javascript: void(0);">
              <div
                class="RTabBox"
                [ngClass]="
                  getOverallVerdict(viewReport, 'Very Likely to succeed')
                "
              >
                <!-- <span *ngIf="getOverallVerdict(viewReport,?.report_data?.response.overall_average, 'Very Likely to succeed')">This candidate is</span> -->
                Very likely to succeed
              </div>
            </a>
            <div class="clearfix"></div>
            <div class="report-legend-div">
              <div class="legend-0-div">
                <div class="legend-0-box">
                  <span class="legend-text-font">0</span>
                </div>
                <div class="legend-0-bar"></div>
              </div>

              <div class="legend-0-div">
                <div class="legend-0-box">
                  <span class="legend-text-font"
                    >{{ companyData.customVerditBoundaries[0].maxValue }}
                  </span>
                </div>
                <div class="legend-0-bar"></div>
              </div>

              <div class="legend-0-div">
                <div class="legend-0-box">
                  <span class="legend-text-font">{{
                    companyData.customVerditBoundaries[1].maxValue
                  }}</span>
                </div>
                <div class="legend-0-bar"></div>
              </div>

              <div class="legend-4-div">
                <div class="legend-0-box">
                  <span class="legend-text-font">{{
                    companyData.customVerditBoundaries[2].maxValue
                  }}</span>
                </div>
                <div class="legend-4-bar"></div>
                <div class="legend-4-box">
                  <span class="legend-text-font">100</span>
                </div>
              </div>
            </div>
            <!-- legen div end -->
          </div>
          <!--  RBotBox  -->
        </div>
        <!--  ReportHead  -->
      </div>
      <!--  ReportGrid  -->

      <div class="clearfix"></div>

      <!-- page2 -->
      <div class="ReportGrid page2s">
        <div
          class="RTopLeftBox"
          *ngIf="viewReport?.report_data?.title != '0NA'"
        ></div>
        <div
          class="RTopRightBox"
          *ngIf="viewReport?.report_data?.title != '0NA'"
        ></div>
        <div
          class="clearfix"
          *ngIf="viewReport?.report_data?.title != '0NA'"
        ></div>

        <div
          class="ManagerialBox"
          *ngIf="viewReport?.report_data?.title != '0NA'"
        >
          <div
            class="MBLeft"
            *ngIf="viewReport?.report_data?.job_type != 'Organisational Match'"
          >
            <h2>{{ viewReport?.report_data?.title }}</h2>
            <p
              *ngIf="
                viewReport?.report_data?.response.overall_average_rating ==
                'Low'
              "
            >
              {{
                getReplacedText(viewReport?.report_data?.section_subtext_low)
              }}
            </p>
            <p
              *ngIf="
                viewReport?.report_data?.response.overall_average_rating ==
                'Medium'
              "
            >
              {{
                getReplacedText(viewReport?.report_data?.section_subtext_medium)
              }}
            </p>
            <p
              *ngIf="
                viewReport?.report_data?.response.overall_average_rating ==
                'High'
              "
            >
              {{
                getReplacedText(viewReport?.report_data?.section_subtext_high)
              }}
            </p>
          </div>
          <!--  MBLeft  -->
          <div
            class="MBLeft"
            *ngIf="viewReport?.report_data?.job_type == 'Organisational Match'"
          >
            <h2
              *ngIf="
                viewReport?.report_data?.job_type != 'Organisational Match'
              "
            >
              {{ viewReport?.report_data?.title }}
            </h2>
            <h2
              *ngIf="
                viewReport?.report_data?.job_type == 'Organisational Match'
              "
            >
              {{ viewReport?.report_data?.response.coverpage_name }}
            </h2>
            <p
              *ngIf="
                viewReport?.report_data?.response.overall_average_rating ==
                'Low'
              "
            >
              {{
                getReplacedText(
                  viewReport?.report_data?.response.section_subtext_low
                )
              }}
            </p>
            <p
              *ngIf="
                viewReport?.report_data?.response.overall_average_rating ==
                'Medium'
              "
            >
              {{
                getReplacedText(
                  viewReport?.report_data?.response.section_subtext_medium
                )
              }}
            </p>
            <p
              *ngIf="
                viewReport?.report_data?.response.overall_average_rating ==
                'High'
              "
            >
              {{
                getReplacedText(
                  viewReport?.report_data?.response.section_subtext_high
                )
              }}
            </p>
          </div>
          <!--  MBLeft  -->
          <div class="MBRight_three_scale">
            <div class="StarBox_three_scale">
              <div
                *ngIf="
                  viewReport?.report_data?.response.overall_average_rating ===
                  'Low'
                "
              >
                <div class="Black-Star"></div>
                <div class="White-Star"></div>
                <div class="White-Star"></div>
              </div>

              <div
                *ngIf="
                  viewReport?.report_data?.response.overall_average_rating ===
                  'Medium'
                "
              >
                <div class="Black-Star"></div>
                <div class="Black-Star"></div>
                <div class="White-Star"></div>
              </div>

              <div
                *ngIf="
                  viewReport?.report_data?.response.overall_average_rating ===
                  'High'
                "
              >
                <div class="Black-Star"></div>
                <div class="Black-Star"></div>
                <div class="Black-Star"></div>
              </div>
              <!-- <span class="BlackStar"></span>
              <span [ngClass]="getStarClass(viewReport?.report_data?.response.overall_star_average_rating, 'Medium', 'span')"></span>
              <span [ngClass]="getWhiteStar(viewReport?.report_data?.response.overall_star_average_rating)"></span> -->
              <div class="clearfix"></div>
            </div>
            <!--  StarBox  -->
            <div class="MediumBox_three_scale">
              {{ viewReport?.report_data?.response.overall_average_rating }}
            </div>
          </div>
          <!--  MBRight  -->
        </div>
        <!--  ManagerialBox -->

        <div class="clearfix"></div>
        <div
          *ngIf="viewReport?.report_data?.job_type != 'Organisational Match'"
        >
          <div
            class="CoreJob"
            *ngFor="
              let candidateActivity of viewReport?.report_data?.response
                .response | keyvalue
            "
          >
            <!-- TODO check here -->
            <h2 *ngIf="viewReport?.report_data?.title != 'Managerial Ability'">
              {{ candidateActivity.key }}
            </h2>
            <div
              class="CoreJobBox"
              *ngFor="
                let activity of candidateActivity.value
                  | orderBy: 'activity_name'
              "
            >
              <div class="HeddingGrid">
                <div class="HD-Option-Grid">
                  <div class="CircleBox">
                    <div
                      [ngClass]="{
                        GraySmallCircle: activity.rating != 'Low',
                        RedCircle: activity.rating == 'Low'
                      }"
                    ></div>
                    <div
                      [ngClass]="{
                        GraySmallCircle: activity.rating != 'Medium',
                        YellowCircle: activity.rating == 'Medium'
                      }"
                    ></div>
                    <div
                      [ngClass]="{
                        GraySmallCircle: activity.rating != 'High',
                        GreenCircle: activity.rating == 'High'
                      }"
                    ></div>
                  </div>
                  <!--  CircleBox  -->
                  <div class="CircleBoxMed">{{ activity.rating }}</div>
                </div>
                <!--  HD-Option-Grid  -->
                <div class="HD-Title">{{ activity.activity_name }}</div>
                <!--  HD-Title  -->
              </div>
              <!--  HeddingGrid  -->

              <div class="HD-Info">
                {{ getReplacedText(activity.ratingtext) }}
              </div>
            </div>
            <!--  CoreJobBox -->
          </div>
          <!--  CoreJob  -->
        </div>

        <!-- Job Type == Organsational Match -->
        <div
          class="ReportGrid"
          *ngFor="
            let orgdetail of viewReport?.report_data?.response.org_data
              | orderBy: 'org_name';
            let i = index
          "
        >
          <div class="PeopleOriGrid" [ngClass]="i == 0 ? 'marginTop60' : ''">
            <div class="POriBox POBlanck">
              <div class="POriBoxLeft">
                <h3>{{ orgdetail.org_name }}</h3>
                <div class="POriBox-Info">
                  {{ getReplacedText(orgdetail.rating_text) }}
                </div>
              </div>
              <!-- POriBoxLeft -->
              <div class="HD-Option-Grid">
                <div class="CircleBox">
                  <div
                    [ngClass]="{
                      GraySmallCircle:
                        orgdetail.org_competency.overall_average_rating !=
                        'Low',
                      RedCircle:
                        orgdetail.org_competency.overall_average_rating == 'Low'
                    }"
                  ></div>
                  <div
                    [ngClass]="{
                      GraySmallCircle:
                        orgdetail.org_competency.overall_average_rating !=
                        'Medium',
                      YellowCircle:
                        orgdetail.org_competency.overall_average_rating ==
                        'Medium'
                    }"
                  ></div>
                  <div
                    [ngClass]="{
                      GraySmallCircle:
                        orgdetail.org_competency.overall_average_rating !=
                        'High',
                      GreenCircle:
                        orgdetail.org_competency.overall_average_rating ==
                        'High'
                    }"
                  ></div>
                </div>
                <!--  CircleBox  -->
                <div class="CircleBoxMed">
                  {{ orgdetail.org_competency.overall_average_rating }}
                </div>
              </div>
              <!--  HD-Option-Grid  -->
            </div>
            <!--  POriBox  -->
            <div
              class="POriBox"
              *ngFor="
                let subcompetency of orgdetail.org_competency.response
                  | orderBy: 'activity_name'
              "
            >
              <div class="POriBoxLeft">
                <h2>{{ subcompetency.activity_name }}</h2>
                <div class="POriBox-Info">
                  {{ getReplacedText(subcompetency.ratingtext) }}
                </div>
              </div>
              <!-- POriBoxLeft -->
              <div class="HD-Option-Grid">
                <div class="CircleBox">
                  <div
                    [ngClass]="{
                      GraySmallCircle: subcompetency.rating != 'Low',
                      RedCircle: subcompetency.rating == 'Low'
                    }"
                  ></div>
                  <div
                    [ngClass]="{
                      GraySmallCircle: subcompetency.rating != 'Medium',
                      YellowCircle: subcompetency.rating == 'Medium'
                    }"
                  ></div>
                  <div
                    [ngClass]="{
                      GraySmallCircle: subcompetency.rating != 'High',
                      GreenCircle: subcompetency.rating == 'High'
                    }"
                  ></div>
                </div>
                <!--  CircleBox  -->
                <div class="CircleBoxMed">{{ subcompetency.rating }}</div>
              </div>
              <!--  HD-Option-Grid  -->
            </div>
            <!--  POriBox  -->
          </div>
          <!--  PeopleOriGrid  -->
        </div>
        <!--  Organsational Match  -->

        <!-- Managerial Ability in case of Customised Job Fit -->
        <div
          class="ReportGrid page2s"
          *ngIf="
            viewReport?.report_data?.job_type == 'Customised Profile Fit' &&
            viewReport?.report_data?.profile_name != 'Individual Contributor'
          "
        >
          <div class="RTopLeftBox"></div>
          <div class="RTopRightBox"></div>
          <div class="clearfix"></div>
          <div class="ManagerialBox" style="height: auto">
            <div class="MBLeft">
              <h2>{{ viewReport?.report_data?.mparam_response.title }}</h2>
              <p
                *ngIf="
                  viewReport?.report_data?.mparam_response
                    .overall_average_rating == 'Low'
                "
              >
                {{
                  viewReport?.report_data?.mparam_response.section_subtext_low
                }}
              </p>
              <p
                *ngIf="
                  viewReport?.report_data?.mparam_response
                    .overall_average_rating == 'Medium'
                "
              >
                {{
                  viewReport?.report_data?.mparam_response
                    .section_subtext_medium
                }}
              </p>
              <p
                *ngIf="
                  viewReport?.report_data?.mparam_response
                    .overall_average_rating == 'High'
                "
              >
                {{
                  viewReport?.report_data?.mparam_response.section_subtext_high
                }}
              </p>
            </div>
            <!--  MBLeft  -->
            <div class="MBRight_three_scale">
              <div class="StarBox_three_scale">
                <div
                  *ngIf="
                    viewReport?.report_data?.mparam_response
                      .overall_average_rating === 'Low'
                  "
                >
                  <div class="Black-Star"></div>
                  <div class="White-Star"></div>
                  <div class="White-Star"></div>
                </div>

                <div
                  *ngIf="
                    viewReport?.report_data?.mparam_response
                      .overall_average_rating === 'Medium'
                  "
                >
                  <div class="Black-Star"></div>
                  <div class="Black-Star"></div>
                  <div class="White-Star"></div>
                </div>

                <div
                  *ngIf="
                    viewReport?.report_data?.mparam_response
                      .overall_average_rating === 'High'
                  "
                >
                  <div class="Black-Star"></div>
                  <div class="Black-Star"></div>
                  <div class="Black-Star"></div>
                </div>

                <!-- <span class="BlackStar"></span>
                <span [ngClass]="getStarClass(viewReport?.report_data?.mparam_response.overall_star_average_rating, 'Medium', 'span')"></span>
                <span [ngClass]="getWhiteStar(viewReport?.report_data?.mparam_response.overall_star_average_rating)"></span> -->
                <div class="clearfix"></div>
              </div>
              <!--  StarBox  -->
              <div class="MediumBox_three_scale">
                {{
                  viewReport?.report_data?.mparam_response
                    .overall_average_rating
                }}
              </div>
            </div>
            <!--  MBRight  -->
          </div>
          <!--  ManagerialBox -->
          <div class="clearfix"></div>
          <div class="CoreJob" style="margin-top: 60px">
            <!-- TODO check it here -->
            <div
              class="CoreJobBox"
              *ngFor="
                let activityComp of viewReport?.report_data?.mparam_response
                  .response | orderBy: 'activity_name'
              "
            >
              <div class="HeddingGrid">
                <div class="HD-Option-Grid">
                  <div class="CircleBox">
                    <div
                      [ngClass]="{
                        GraySmallCircle: activityComp.rating != 'Low',
                        RedCircle: activityComp.rating == 'Low'
                      }"
                    ></div>
                    <div
                      [ngClass]="{
                        GraySmallCircle: activityComp.rating != 'Medium',
                        YellowCircle: activityComp.rating == 'Medium'
                      }"
                    ></div>
                    <div
                      [ngClass]="{
                        GraySmallCircle: activityComp.rating != 'High',
                        GreenCircle: activityComp.rating == 'High'
                      }"
                    ></div>
                  </div>
                  <!--  CircleBox  -->
                  <div class="CircleBoxMed">{{ activityComp.rating }}</div>
                </div>
                <!--  HD-Option-Grid  -->
                <div class="HD-Title">{{ activityComp.activity_name }}</div>
                <!--  HD-Title  -->
              </div>
              <!--  HeddingGrid  -->
              <div class="HD-Info">
                {{ getReplacedText(activityComp.ratingtext) }}
              </div>
            </div>
            <!--  CoreJobBox -->
          </div>
          <!--  CoreJob  -->
        </div>

        <!-- Managerial Style in case of Customised Job Fit -->
        <div
          class="DelegativeGrid"
          *ngIf="
            viewReport?.report_data?.job_type == 'Customised Profile Fit' &&
            viewReport?.report_data?.profile_name != 'Individual Contributor'
          "
          style="padding-top: 18px"
        >
          <h2>
            {{
              viewReport?.report_data?.working_style['Managerial Style']
                .main_heading
            }}
          </h2>
          <div class="DelegativeBox">
            <p>
              {{
                getReplacedText(
                  viewReport?.report_data?.working_style['Managerial Style']
                    .main_sub_text
                )
              }}
            </p>
            <p
              [innerHTML]="
                safeHtml(
                  viewReport?.report_data?.working_style['Managerial Style'][
                    'Leadership (democratic)'
                  ]?.sub_heading_description
                )
              "
            ></p>
            <p
              [innerHTML]="
                safeHtml(
                  viewReport?.report_data?.working_style['Managerial Style'][
                    'Leadership (transformational)'
                  ]?.sub_heading_description
                )
              "
            ></p>
            <p
              [innerHTML]="
                safeHtml(
                  viewReport?.report_data?.working_style['Managerial Style'][
                    'Leadership (Laissez Faire)'
                  ]?.sub_heading_description
                )
              "
            ></p>
            <p
              [innerHTML]="
                safeHtml(
                  viewReport?.report_data?.working_style['Managerial Style'][
                    'Leadership (autocratic)'
                  ]?.sub_heading_description
                )
              "
            ></p>
          </div>
        </div>

        <!-- Organizatonl competencies in case of job fit and campus Recruitment-->
        <div class="ReportGrid page2s" *ngIf="hideOrgComp">
          <div class="RTopLeftBox"></div>
          <div class="RTopRightBox"></div>
          <div class="clearfix"></div>
          <div class="ManagerialBox" style="height: auto">
            <div class="MBLeft">
              <h2>{{ viewReport?.comp_framework?.coverpage_name }}</h2>
              <p
                *ngIf="
                  viewReport?.comp_framework?.overall_average_rating == 'Low'
                "
              >
                {{
                  getReplacedText(
                    viewReport?.comp_framework?.section_subtext_low
                  )
                }}
              </p>
              <p
                *ngIf="
                  viewReport?.comp_framework?.overall_average_rating == 'Medium'
                "
              >
                {{
                  getReplacedText(
                    viewReport?.comp_framework?.section_subtext_medium
                  )
                }}
              </p>
              <p
                *ngIf="
                  viewReport?.comp_framework?.overall_average_rating == 'High'
                "
              >
                {{
                  getReplacedText(
                    viewReport?.comp_framework?.section_subtext_high
                  )
                }}
              </p>
            </div>
            <!--  MBLeft  -->
            <div class="MBRight_three_scale">
              <div class="StarBox_three_scale">
                <!-- <span class="BlackStar"></span>
                <span [ngClass]="getStarClass(viewReport?.comp_framework?.overall_star_average_rating, 'Medium', 'span')"></span>
                <span [ngClass]="getWhiteStar(viewReport?.comp_framework?.overall_star_average_rating)"></span> -->

                <div
                  *ngIf="
                    viewReport?.comp_framework?.overall_average_rating === 'Low'
                  "
                >
                  <div class="Black-Star"></div>
                  <div class="White-Star"></div>
                  <div class="White-Star"></div>
                </div>

                <div
                  *ngIf="
                    viewReport?.comp_framework?.overall_average_rating ===
                    'Medium'
                  "
                >
                  <div class="Black-Star"></div>
                  <div class="Black-Star"></div>
                  <div class="White-Star"></div>
                </div>

                <div
                  *ngIf="
                    viewReport?.comp_framework?.overall_average_rating ===
                    'High'
                  "
                >
                  <div class="Black-Star"></div>
                  <div class="Black-Star"></div>
                  <div class="Black-Star"></div>
                </div>

                <div class="clearfix"></div>
              </div>
              <!--  StarBox  -->
              <div class="MediumBox_three_scale">
                {{ viewReport?.comp_framework?.overall_average_rating }}
              </div>
            </div>
            <!--  MBRight  -->
          </div>
          <!--  ManagerialBox -->
          <div class="clearfix"></div>

          <div class="CoreJob" style="margin-top: 60px">
            <!-- <h2> {{ candidateActivity.org_name }} </h2> -->
            <!-- {{candidateActivity}} -->
            <div
              class="CoreJobBox"
              *ngFor="
                let activityComp of viewReport?.comp_framework?.org_data
                  | orderBy: 'org_name'
              "
            >
              <div class="HeddingGrid">
                <div class="HD-Option-Grid">
                  <div class="CircleBox">
                    <div
                      [ngClass]="{
                        GraySmallCircle:
                          activityComp.org_competency.overall_average_rating !=
                          'Low',
                        RedCircle:
                          activityComp.org_competency.overall_average_rating ==
                          'Low'
                      }"
                    ></div>
                    <div
                      [ngClass]="{
                        GraySmallCircle:
                          activityComp.org_competency.overall_average_rating !=
                          'Medium',
                        YellowCircle:
                          activityComp.org_competency.overall_average_rating ==
                          'Medium'
                      }"
                    ></div>
                    <div
                      [ngClass]="{
                        GraySmallCircle:
                          activityComp.org_competency.overall_average_rating !=
                          'High',
                        GreenCircle:
                          activityComp.org_competency.overall_average_rating ==
                          'High'
                      }"
                    ></div>
                  </div>
                  <!--  CircleBox  -->
                  <div class="CircleBoxMed">
                    {{ activityComp.org_competency.overall_average_rating }}
                  </div>
                </div>
                <!--  HD-Option-Grid  -->
                <div class="HD-Title">{{ activityComp.org_name }}</div>
                <!--  HD-Title  -->
              </div>
              <!--  HeddingGrid  -->
              <div
                class="HD-Info"
                *ngIf="
                  activityComp.org_competency.overall_average_rating == 'High'
                "
              >
                {{ getReplacedText(activityComp.rating_text) }}
              </div>
              <div
                class="HD-Info"
                *ngIf="
                  activityComp.org_competency.overall_average_rating == 'Medium'
                "
              >
                {{ getReplacedText(activityComp.rating_text) }}
              </div>
              <div
                class="HD-Info"
                *ngIf="
                  activityComp.org_competency.overall_average_rating == 'Low'
                "
              >
                {{ getReplacedText(activityComp.rating_text) }}
              </div>
            </div>
            <!--  CoreJobBox -->
          </div>
          <!--  CoreJob  -->
        </div>
      </div>

      <!-- Working Style Section to starts here -->
      <div
        class="ReportGrid"
        style="padding-top: 40px"
        *ngIf="viewReport?.report_data?.job_type == 'Customised Profile Fit'"
      >
        <div class="RTopLeftBox"></div>
        <div class="RTopRightBox"></div>
        <div class="clearfix"></div>
        <div class="ManagerialBox">
          <h2 class="ManagHedding">
            {{ viewReport?.report_data?.working_style.name }}
          </h2>
          <p class="ManagInfo">
            {{ viewReport?.report_data?.working_style.cover_page_text }}
          </p>
        </div>
        <!--  ManagerialBox -->
        <div class="clearfix"></div>
        <div
          class="DelegativeGrid"
          [ngClass]="
            viewReport?.report_data?.job_type != 'Organisational Match'
              ? 'marginTop60'
              : ''
          "
        >
          <ng-container
            *ngIf="viewReport?.report_data?.working_style['Key Driver']"
          >
            <h2>
              {{
                viewReport?.report_data?.working_style['Key Driver']
                  .main_heading
              }}
            </h2>
            <div class="DelegativeBox" style="margin-top: -8px">
              <p>
                {{
                  getReplacedText(
                    viewReport?.report_data?.working_style['Key Driver']
                      .main_sub_text
                  )
                }}
              </p>
              <p
                [innerHTML]="
                  safeHtml(
                    viewReport?.report_data?.working_style['Key Driver'][
                      'Motivation (social)'
                    ]?.sub_heading_description
                  )
                "
              ></p>
              <p
                [innerHTML]="
                  safeHtml(
                    viewReport?.report_data?.working_style['Key Driver'][
                      'Motivation (self growth)'
                    ]?.sub_heading_description
                  )
                "
              ></p>
              <p
                [innerHTML]="
                  safeHtml(
                    viewReport?.report_data?.working_style['Key Driver'][
                      'Motivation (novelty)'
                    ]?.sub_heading_description
                  )
                "
              ></p>
              <p
                [innerHTML]="
                  safeHtml(
                    viewReport?.report_data?.working_style['Key Driver'][
                      'Motivation (prestige)'
                    ]?.sub_heading_description
                  )
                "
              ></p>
              <p
                [innerHTML]="
                  safeHtml(
                    viewReport?.report_data?.working_style['Key Driver'][
                      'Motivation (freedom)'
                    ]?.sub_heading_description
                  )
                "
              ></p>
              <p
                [innerHTML]="
                  safeHtml(
                    viewReport?.report_data?.working_style['Key Driver'][
                      'Motivation (aesthetic)'
                    ]?.sub_heading_description
                  )
                "
              ></p>
              <p
                [innerHTML]="
                  safeHtml(
                    viewReport?.report_data?.working_style['Key Driver'][
                      'Motivation (goal-oriented)'
                    ]?.sub_heading_description
                  )
                "
              ></p>
              <p
                [innerHTML]="
                  safeHtml(
                    viewReport?.report_data?.working_style['Key Driver'][
                      'Motivation (reward)'
                    ]?.sub_heading_description
                  )
                "
              ></p>
            </div>
          </ng-container>
          <!-- DelegativeBox  -->
          <p></p>
          <p></p>
          <h2 style="padding-top: 20px">
            {{
              viewReport?.report_data?.working_style['Influencing Style']
                .main_heading
            }}
          </h2>
          <div class="DelegativeBox" style="margin-top: -8px">
            <p>
              {{
                getReplacedText(
                  viewReport?.report_data?.working_style['Influencing Style']
                    .main_sub_text
                )
              }}
            </p>
            <p
              [innerHTML]="
                safeHtml(
                  viewReport?.report_data?.working_style['Influencing Style'][
                    'Influencing Style (EPL)'
                  ]?.sub_heading_description
                )
              "
            ></p>
            <p
              [innerHTML]="
                safeHtml(
                  viewReport?.report_data?.working_style['Influencing Style'][
                    'Influencing Style (EP)'
                  ]?.sub_heading_description
                )
              "
            ></p>
            <p
              [innerHTML]="
                safeHtml(
                  viewReport?.report_data?.working_style['Influencing Style'][
                    'Influencing Style (PL)'
                  ]?.sub_heading_description
                )
              "
            ></p>
            <p
              [innerHTML]="
                safeHtml(
                  viewReport?.report_data?.working_style['Influencing Style'][
                    'Influencing Style (EL)'
                  ]?.sub_heading_description
                )
              "
            ></p>
            <p
              [innerHTML]="
                safeHtml(
                  viewReport?.report_data?.working_style['Influencing Style'][
                    'Influencing Style (E)'
                  ]?.sub_heading_description
                )
              "
            ></p>
            <p
              [innerHTML]="
                safeHtml(
                  viewReport?.report_data?.working_style['Influencing Style'][
                    'Influencing Style (P)'
                  ]?.sub_heading_description
                )
              "
            ></p>
            <p
              [innerHTML]="
                safeHtml(
                  viewReport?.report_data?.working_style['Influencing Style'][
                    'Influencing Style (L)'
                  ]?.sub_heading_description
                )
              "
            ></p>
          </div>
          <!-- DelegativeBox  -->

          <!-- <p></p>       <p></p>
        <h2 style="padding-top:20px;">{{viewReport?.report_data?.working_style['Approach to Work'].main_heading}}</h2>
        <div class="DelegativeBox" style="margin-top:-8px;">
            <p> {{ getReplacedText(viewReport?.report_data?.working_style['Approach to Work'].main_sub_text) }}</p>
            <p [innerHTML]="safeHtml(viewReport?.report_data?.working_style['Approach to Work']['scatter_data_first'].description, 'work_approach')"></p>
            <p [innerHTML]="safeHtml(viewReport?.report_data?.working_style['Approach to Work']['scatter_data_second'].description, 'work_approach')"></p>
        </div>  -->
          <!-- DelegativeBox  -->
        </div>
        <!--  DelegativeGrid  -->
      </div>
      <!-- Working Style Section to end here -->
    </div>
    <div class="container-image" *ngIf="companyData.isProctoring">
      <div class="image-grid">
        <div *ngFor="let image of images">
          <img src="{{ image }}" class="proctor-image" />
        </div>
      </div>
    </div>
    <!-- <ng-container *ngIf="aptitudeReport && aptitudeReport.gameStatus"
      [ngTemplateOutlet]="aptitudeReportTemplate">
    </ng-container> -->
    <div
      class="ReportGrid page2"
      style="padding-top: 20px; padding-bottom: 20px"
    >
      <div class="container-image" *ngIf="companyData.isProctoring">
        <div class="image-grid">
          <div *ngFor="let image of images">
            <img [src]="image.src" class="proctor-image" />
          </div>
        </div>
      </div>
      <div class="RTopLeftBox" style="height: auto"></div>
      <div class="RTopRightBox" style="height: auto"></div>
      <div class="DelegativeGrid">
        <div class="DelegativeBox">
          <h2 style="padding-top: 30px; text-align: center; margin-left: -20px">
            Disclaimer
          </h2>
          <p>
            This report is strictly confidential and contains the intellectual
            property of Stairwell Ventures. Stairwell Ventures permits the
            client to reproduce, distribute, amend and store this report for its
            internal and non-commercial use only. All other rights are reserved
            with Stairwell Ventures.
          </p>
          <p>
            While our research links psychometric and neurocognitive attributes
            measured by this assessment to important on-the-job behaviours,
            there are other factors that may impact on how well an individual
            performs at their job. These factors also need to be assessed (for
            eg. via an interview or any other selection and performance
            criteria) when appraising a candidate. A candidate's results may be
            influenced by external factors in the environment and his/her
            self-awareness, so the classification they achieved should be
            considered an indication or aproximation of the abilities measured
            by this assessment.
          </p>
          <p>
            Stairwell Ventures has made every effort to produce an informative
            report but, as with other psychometrics, it is not infallible. We
            cannot be held liable for actions taken from the interpretation of
            the information contained in this report. The report is likely to
            remain a good reflection of the individual’s potential for 12
            months, depending upon personal circumstances.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- 3 point ends -->

<!-- 5 point -->
<div *ngIf="!isThreePointReport" style="background: #ccc">
  <div class="responsiveReportGrid" style="margin-bottom: 20px">
    <div class="ReportGrid" style="position: relative; background-image: url('assets/images/tvs-report-img-bg.png');background-size: cover;">
      <div
        class="page1"
        style="position: relative; overflow: hidden"
        *ngIf="isPageOne"
      >
        <div class="RTopLeft"></div>
        <div class="RTopRight"></div>
        <div class="ReportHead">
          <div class="RLogoGrid">
            <div style="display: flex;align-items: center;justify-content: space-between;width:595px;padding:0 30px;"> 
              <img
              src="assets/images/TL-logo.png"
              height="150"
              style="margin: 0"
            />
            <img src="assets/images/tvs-logo.png" class="tvs-logo" />
            </div>
          </div>

          <!-- <img src="assets/images/tvs-report-img-bg.png" class="page-1-bg" /> -->
          <!--  RDateGrid  -->
          <div class="clearfix"></div>

          <div class="RCandidateBox" style="margin-top: 50px;">
            <div class="RCandidateInfo">
              <h2>
                <span>Name of Candidate</span
                >{{
                  viewReport?.report_data?.candidate_first_name ||
                    scoreData?.firstName
                }}
                {{
                  viewReport?.report_data?.candidate_last_name ||
                    scoreData?.lastName
                }}
              </h2>
            </div>

            <!--  RCandidateInfo  -->
            <div class="RCandidateInfo">
              <h2>
                <span>Assessment Title</span
                >{{
                  viewReport?.report_data?.job_title ||
                    assessmentDetail?.job_title
                }}
              </h2>
            </div>
            <!--  RCandidateInfo  -->
            <div class="RCandidateInfo">
              <h2>
                <span>Assessment Type</span
                >{{
                  getReportName(
                    viewReport?.report_data?.job_type ||
                      assessmentDetail?.job_type
                  )
                }}
              </h2>
            </div>
            <!--  RCandidateInfo  -->
          </div>
          <div class="clearfix"></div>
          <!-- SUMMARY START -->

          <div class="clearfix"></div>
          <!-- SUMMARY END -->

          <!--  RCandidateBox  -->

          <div class="clearfix"></div>
          <div class="ReportGrid">
            <div class="RTabBox"></div>

            <div class="clearfix"></div>
          </div>
          <!--  RBotBox  -->
        </div>

        <!--  ReportHead  -->
      </div>



      <div
      class="summary-container"
      [ngStyle]="{
        'grid-template-columns':
          scoreData  && this.assessmentDetail?.values && !!viewReport?.comp_framework?.overall_average_per && !assessmentDetail?.isBehavioralOff
            ? 'repeat(2,1fr)'
            : 'repeat(1,1fr)'
      }"
    >
      <!-- START -->
      <div
        [ngStyle]="{ width: scoreData && this.assessmentDetail?.values  ? '100%' : '50%' }"
        *ngIf="
          hideOrgComp && !!viewReport?.comp_framework?.overall_average_per && !assessmentDetail?.isBehavioralOff"
      >
        <div class="center">
          <!-- For Organizatinal competency in case of Customised Profile Fit -->
          <div
            class="RBoxGrid"
            *ngIf="hideOrgComp"
            [ngClass]="hideOrgComp ? 'floatLeft' : ''"
            style="height: 252px; width: 242px"
          >
            <h2>{{ viewReport?.comp_framework?.coverpage_name }}</h2>

            <table
              class="ratingTable2"
              style="position: relative; margin: 20px auto"
            >
              <tr class="ratingTR">
                <td class="ratetd" style="width: 70%; text-align: bottom">
                  <div class="StarBox starCenter">
                    <div
                      *ngIf="
                        viewReport?.comp_framework?.overall_average_rating ==
                        'Low'
                      "
                    >
                      <span class="fa fa-star checked"></span>
                      <span class="fa fa-star-o"></span>
                      <span class="fa fa-star-o"></span>
                      <span class="fa fa-star-o unchecked"></span>
                      <span class="fa fa-star-o unchecked"></span>
                      <div class="clearfix">Very Low</div>
                    </div>
                    <div
                      *ngIf="
                        viewReport?.comp_framework?.overall_average_rating ==
                        'Medium-Low'
                      "
                    >
                      <span class="fa fa-star checked"></span>
                      <span class="fa fa-star"></span>
                      <span class="fa fa-star-o"></span>
                      <span class="fa fa-star-o unchecked"></span>
                      <span class="fa fa-star-o unchecked"></span>
                      <div class="clearfix">Low</div>
                    </div>
                    <div
                      *ngIf="
                        viewReport?.comp_framework?.overall_average_rating ==
                        'Medium'
                      "
                    >
                      <span class="fa fa-star checked"></span>
                      <span class="fa fa-star"></span>
                      <span class="fa fa-star"></span>
                      <span class="fa fa-star-o unchecked"></span>
                      <span class="fa fa-star-o unchecked"></span>
                      <div class="clearfix">Medium</div>
                    </div>
                    <div
                      *ngIf="
                        viewReport?.comp_framework?.overall_average_rating ==
                        'Medium-High'
                      "
                    >
                      <span class="fa fa-star checked"></span>
                      <span class="fa fa-star"></span>
                      <span class="fa fa-star"></span>
                      <span class="fa fa-star unchecked"></span>
                      <span class="fa fa-star-o unchecked"></span>
                      <div class="clearfix">High</div>
                    </div>
                    <div
                      *ngIf="
                        viewReport?.comp_framework?.overall_average_rating ==
                        'High'
                      "
                    >
                      <span class="fa fa-star checked"></span>
                      <span class="fa fa-star"></span>
                      <span class="fa fa-star"></span>
                      <span class="fa fa-star unchecked"></span>
                      <span class="fa fa-star unchecked"></span>
                      <div class="clearfix">Very High</div>
                    </div>
                  </div>
                </td>
                <td class="rateText2 center">
                  {{ viewReport?.comp_framework?.overall_average_per }}
                </td>
              </tr>
            </table>

            <p>
              {{
                lcReportDiscriptions[
                  viewReport?.comp_framework?.overall_average_rating == 'Low'
                    ? 'Very Low'
                    : viewReport?.comp_framework?.overall_average_rating ==
                      'Medium-Low'
                    ? 'Low'
                    : viewReport?.comp_framework?.overall_average_rating ==
                      'Medium'
                    ? 'Medium'
                    : viewReport?.comp_framework?.overall_average_rating ==
                      'Medium-High'
                    ? 'High'
                    : 'Very High'
                ]
              }}
            </p>

            <!--  MBRight  -->
          </div>
          <!--  RBoxGrid  -->
        </div>
    
      </div>

      <div *ngIf="scoreData && this.assessmentDetail?.values">
        <div class="center">
          <!-- For Organizatinal competency in case of Customised Profile Fit -->
          <div class="RBoxGrid floatLeft" style="height: 252px; width: 242px">
            <h2>TVS VALUES</h2>

            <table
              class="ratingTable2"
              style="position: relative; margin: 20px auto"
            >
              <tr class="ratingTR">
                <td class="ratetd" style="width: 70%; text-align: bottom">
                  <div class="StarBox starCenter">
                    <div *ngIf="getLabel(valuesScoreAverage) == 'Very Low'">
                      <span class="fa fa-star checked"></span>
                      <span class="fa fa-star-o"></span>
                      <span class="fa fa-star-o"></span>
                      <span class="fa fa-star-o unchecked"></span>
                      <span class="fa fa-star-o unchecked"></span>
                      <div class="clearfix">Very Low</div>
                    </div>
                    <div *ngIf="getLabel(valuesScoreAverage) == 'Low'">
                      <span class="fa fa-star checked"></span>
                      <span class="fa fa-star"></span>
                      <span class="fa fa-star-o"></span>
                      <span class="fa fa-star-o unchecked"></span>
                      <span class="fa fa-star-o unchecked"></span>
                      <div class="clearfix">Low</div>
                    </div>
                    <div *ngIf="getLabel(valuesScoreAverage) == 'Medium'">
                      <span class="fa fa-star checked"></span>
                      <span class="fa fa-star"></span>
                      <span class="fa fa-star"></span>
                      <span class="fa fa-star-o unchecked"></span>
                      <span class="fa fa-star-o unchecked"></span>
                      <div class="clearfix">Medium</div>
                    </div>
                    <div *ngIf="getLabel(valuesScoreAverage) == 'High'">
                      <span class="fa fa-star checked"></span>
                      <span class="fa fa-star"></span>
                      <span class="fa fa-star"></span>
                      <span class="fa fa-star unchecked"></span>
                      <span class="fa fa-star-o unchecked"></span>
                      <div class="clearfix">High</div>
                    </div>
                    <div *ngIf="getLabel(valuesScoreAverage) == 'Very High'">
                      <span class="fa fa-star checked"></span>
                      <span class="fa fa-star"></span>
                      <span class="fa fa-star"></span>
                      <span class="fa fa-star unchecked"></span>
                      <span class="fa fa-star unchecked"></span>
                      <div class="clearfix">Very High</div>
                    </div>
                  </div>
                </td>
                <td class="rateText2 center">
                  {{ valuesScoreAverage }}
                </td>
              </tr>
            </table>

            <p style="height: fit-content">
              {{ reportDiscriptions[getLabel(valuesScoreAverage)] }}
            </p>
          </div>
          <div class="clearfix"></div>
        </div>
      
      </div>
      <!-- END -->
    </div>


      <div class="RBotBox" style="background: transparent;" *ngIf="(!scoreData || !assessmentDetail?.values) && !assessmentDetail?.isBehavioralOff && viewReport?.comp_framework?.overall_average_per">
        <a href="javascript: void(0);">
          <div
            class="RTabBox"
            [ngClass]="getOverallVerdict(viewReport, 'Unlikely to succeed')"
            style="border: 2px solid #e3e1e1;color:#474747;background:#f5f7f7"
          >
            Unlikely to succeed
          </div>
             <div class="full-width-output" style="width:fit-content">
            <output
              for="foo"
              class="black"
              *ngIf="getOverallVerdict(viewReport, 'Unlikely to succeed')"
              onforminput="value = 200;"
              style="margin-left: 0px; width: 136px"
              >Overall Rating : {{ getOverallRatingPer() }}</output
            >
          </div>
        </a>
        <a href="javascript: void(0);">
          <div
            class="RTabBox"
            [ngClass]="
              getOverallVerdict(viewReport, 'Restricted likelihood of success')
            "
             style="border: 2px solid #e3e1e1;color:#474747;background:#f5f7f7"
          >
    Restricted Likelihood of Success
          </div>
             <div class="full-width-output" style="width:fit-content">
            <output
              for="foo"
              class="black"
              *ngIf="
                getOverallVerdict(viewReport, 'Restricted likelihood of success')
              "
              onforminput="value = 200;"
              style="margin-left: 139px; width: 136px"
              >Overall Rating : {{ getOverallRatingPer() }}</output
            >
          </div>
        </a>
        <a href="javascript: void(0);">
          <div
            class="RTabBox"
            [ngClass]="getOverallVerdict(viewReport, 'Likely to succeed')"
            style="border: 2px solid #e3e1e1;color:#474747;background:#f5f7f7"
          >
        Likely to succeed
          </div>
             <div class="full-width-output" style="width:fit-content">
            <output
              for="foo"
              class="black"
              *ngIf="getOverallVerdict(viewReport, 'Likely to succeed')"
              onforminput="value = 200;"
              style="margin-left: 278px; width: 136px"
              >Overall Rating : {{ getOverallRatingPer() }}</output
            >
          </div>
        </a>
        <a href="javascript: void(0);">
          <div
            class="RTabBox"
            [ngClass]="getOverallVerdict(viewReport, 'Very Likely to succeed')"
            style="border: 2px solid #e3e1e1;color:#474747;background:#f5f7f7"
          >
         Very likely to succeed
          </div>
             <div class="full-width-output" style="width:fit-content">
            <output
              for="foo"
              class="black"
              *ngIf="getOverallVerdict(viewReport, 'Very Likely to succeed')"
              onforminput="value = 200;"
              style="margin-left: 417px; width: 136px"
              >Overall Rating : {{ getOverallRatingPer() }}</output
            >
          </div>
        </a>
        <div class="clearfix"></div>
        <div class="report-legend-div">
          <!-- <div class="legend-bar">
  
                  </div> -->
          <div class="legend-0-div">
            <div class="legend-0-box">
              <span class="legend-text-font">0</span>
            </div>
            <div class="legend-0-bar"></div>
          </div>
  
          <div class="legend-0-div">
            <div class="legend-0-box">
              <span class="legend-text-font"
                >{{ companyData.customVerditBoundaries[0].maxValue }}
              </span>
            </div>
            <div class="legend-0-bar"></div>
          </div>
  
          <div class="legend-0-div">
            <div class="legend-0-box">
              <span class="legend-text-font">{{
                companyData.customVerditBoundaries[1].maxValue
              }}</span>
            </div>
            <div class="legend-0-bar"></div>
          </div>
  
          <div class="legend-4-div">
            <div class="legend-0-box">
              <span class="legend-text-font">{{
                companyData.customVerditBoundaries[2].maxValue
              }}</span>
            </div>
            <div class="legend-4-bar"></div>
            <div class="legend-4-box">
              <span class="legend-text-font">100</span>
            </div>
          </div>
        </div>
      </div>
    

      <div class="RBotBox" style="background: transparent;" *ngIf="scoreData && assessmentDetail?.values && (!viewReport?.comp_framework?.overall_average_per || assessmentDetail?.isBehavioralOff)">
        <a href="javascript: void(0);">
          <div
            class="RTabBox"
            [ngClass]="getValuesOverallVerdict('Unlikely to succeed')"
            style="border: 2px solid #e3e1e1;color:#474747;background:#f5f7f7"
          >
            Unlikely to succeed
          </div>
             <div class="full-width-output" style="width:fit-content">
            <output
              for="foo"
              class="black"
              *ngIf="getValuesOverallVerdict('Unlikely to succeed')"
              onforminput="value = 200;"
              style="margin-left: 0px; width: 136px"
              >Overall Rating : {{ getValuesOverallRatingPer() }}</output
            >
          </div>
        </a>
        <a href="javascript: void(0);">
          <div
            class="RTabBox"
            [ngClass]="
              getValuesOverallVerdict('Restricted likelihood of success')
            "
             style="border: 2px solid #e3e1e1;color:#474747;background:#f5f7f7"
          >
    Restricted Likelihood of Success
          </div>
             <div class="full-width-output" style="width:fit-content">
            <output
              for="foo"
              class="black"
              *ngIf="
                getValuesOverallVerdict('Restricted likelihood of success')
              "
              onforminput="value = 200;"
              style="margin-left: 139px; width: 136px"
              >Overall Rating : {{ getValuesOverallRatingPer() }}</output
            >
          </div>
        </a>
        <a href="javascript: void(0);">
          <div
            class="RTabBox"
            [ngClass]="getValuesOverallVerdict('Likely to succeed')"
            style="border: 2px solid #e3e1e1;color:#474747;background:#f5f7f7"
          >
        Likely to succeed
          </div>
             <div class="full-width-output" style="width:fit-content">
            <output
              for="foo"
              class="black"
              *ngIf="getValuesOverallVerdict('Likely to succeed')"
              onforminput="value = 200;"
              style="margin-left: 278px; width: 136px"
              >Overall Rating : {{ getValuesOverallRatingPer() }}</output
            >
          </div>
        </a>
        <a href="javascript: void(0);">
          <div
            class="RTabBox"
            [ngClass]="getValuesOverallVerdict('Very Likely to succeed')"
            style="border: 2px solid #e3e1e1;color:#474747;background:#f5f7f7"
          >
         Very likely to succeed
          </div>
             <div class="full-width-output" style="width:fit-content">
            <output
              for="foo"
              class="black"
              *ngIf="getValuesOverallVerdict('Very Likely to succeed')"
              onforminput="value = 200;"
              style="margin-left: 417px; width: 136px"
              >Overall Rating : {{ getValuesOverallRatingPer() }}</output
            >
          </div>
        </a>
        <div class="clearfix"></div>
        <div class="report-legend-div">
          <!-- <div class="legend-bar">
  
                  </div> -->
          <div class="legend-0-div">
            <div class="legend-0-box">
              <span class="legend-text-font">0</span>
            </div>
            <div class="legend-0-bar"></div>
          </div>
  
          <div class="legend-0-div">
            <div class="legend-0-box">
              <span class="legend-text-font"
                >{{ viewReport?.comp_framework?.coverpage_name.includes('LC1') ? 56 : 61 }}
              </span>
            </div>
            <div class="legend-0-bar"></div>
          </div>
  
          <div class="legend-0-div">
            <div class="legend-0-box">
              <span class="legend-text-font">{{
                viewReport?.comp_framework?.coverpage_name.includes('LC1') ? 63 : 67
              }}</span>
            </div>
            <div class="legend-0-bar"></div>
          </div>
  
          <div class="legend-4-div">
            <div class="legend-0-box">
              <span class="legend-text-font">{{
                viewReport?.comp_framework?.coverpage_name.includes('LC1') ? 70 : 74
              }}</span>
            </div>
            <div class="legend-4-bar"></div>
            <div class="legend-4-box">
              <span class="legend-text-font">100</span>
            </div>
          </div>
        </div>
      </div>

      <div class="RBotBox" style="background: transparent;" *ngIf="scoreData && assessmentDetail?.values && viewReport?.comp_framework?.overall_average_per && !assessmentDetail?.isBehavioralOff">
        <a href="javascript: void(0);">
          <div
            class="RTabBox"
            [ngClass]="getBothOverallVerdict(viewReport, 'Unlikely to succeed')"
            style="border: 2px solid #e3e1e1;color:#474747;background:#f5f7f7"
          >
            Unlikely to succeed
          </div>
             <div class="full-width-output" style="width:fit-content">
            <output
              for="foo"
              class="black"
              *ngIf="getBothOverallVerdict(viewReport, 'Unlikely to succeed')"
              onforminput="value = 200;"
              style="margin-left: 0px; width: 136px"
              >Overall Rating : {{  getBothOverallRatingPer() }}</output
            >
          </div>
        </a>
        <a href="javascript: void(0);">
          <div
            class="RTabBox"
            [ngClass]="
              getBothOverallVerdict(viewReport, 'Restricted likelihood of success')
            "
             style="border: 2px solid #e3e1e1;color:#474747;background:#f5f7f7"
          >
    Restricted Likelihood of Success
          </div>
             <div class="full-width-output" style="width:fit-content">
            <output
              for="foo"
              class="black"
              *ngIf="
                getBothOverallVerdict(viewReport, 'Restricted likelihood of success')
              "
              onforminput="value = 200;"
              style="margin-left: 139px; width: 136px"
              >Overall Rating : {{  getBothOverallRatingPer() }}</output
            >
          </div>
        </a>
        <a href="javascript: void(0);">
          <div
            class="RTabBox"
            [ngClass]="getBothOverallVerdict(viewReport, 'Likely to succeed')"
            style="border: 2px solid #e3e1e1;color:#474747;background:#f5f7f7"
          >
        Likely to succeed
          </div>
             <div class="full-width-output" style="width:fit-content">
            <output
              for="foo"
              class="black"
              *ngIf="getBothOverallVerdict(viewReport, 'Likely to succeed')"
              onforminput="value = 200;"
              style="margin-left: 278px; width: 136px"
              >Overall Rating : {{  getBothOverallRatingPer() }}</output
            >
          </div>
        </a>
        <a href="javascript: void(0);">
          <div
            class="RTabBox"
            [ngClass]="getBothOverallVerdict(viewReport, 'Very Likely to succeed')"
            style="border: 2px solid #e3e1e1;color:#474747;background:#f5f7f7"
          >
         Very likely to succeed
          </div>
             <div class="full-width-output" style="width:fit-content">
            <output
              for="foo"
              class="black"
              *ngIf="getBothOverallVerdict(viewReport, 'Very Likely to succeed')"
              onforminput="value = 200;"
              style="margin-left: 417px; width: 136px"
              >Overall Rating : {{  getBothOverallRatingPer() }}</output
            >
          </div>
        </a>
        <div class="clearfix"></div>
        <div class="report-legend-div">
          <!-- <div class="legend-bar">
  
                  </div> -->
          <div class="legend-0-div">
            <div class="legend-0-box">
              <span class="legend-text-font">0</span>
            </div>
            <div class="legend-0-bar"></div>
          </div>
  
          <div class="legend-0-div">
            <div class="legend-0-box">
              <span class="legend-text-font"
                >{{ 
                  viewReport?.comp_framework?.coverpage_name.includes('LC1') ? 46 : 48
                }}
              </span>
            </div>
            <div class="legend-0-bar"></div>
          </div>
  
          <div class="legend-0-div">
            <div class="legend-0-box">
              <span class="legend-text-font">{{
                viewReport?.comp_framework?.coverpage_name.includes('LC1') ? 57 : 59
              }}</span>
            </div>
            <div class="legend-0-bar"></div>
          </div>
  
          <div class="legend-4-div">
            <div class="legend-0-box">
              <span class="legend-text-font">{{
                viewReport?.comp_framework?.coverpage_name.includes('LC1') ? 68 : 69
              }}</span>
            </div>
            <div class="legend-4-bar"></div>
            <div class="legend-4-box">
              <span class="legend-text-font">100</span>
            </div>
          </div>
        </div>
      </div>
      <!-- <img src="assets/images/tvs-report-img-bg.png" class="page-1-bg" /> -->
      
      <!-- page1 -->
    </div>


  </div>

  <div class="responsiveReportGrid" style="margin-bottom: 20px">
    <div
      class="ReportGrid"
      style="padding-top: 10px; padding-bottom: 140px"
      *ngIf="
        viewReport?.report_data?.job_type == 'Customised Profile Fit' ||
        (!!viewReport && assessmentDetail)
      "
    >
      <div class="RTopLeftBox" style="height: 0"></div>
      <div class="RTopRightBox" style="height: 0"></div>

      <div class="clearfix"></div>

      <p class="summary-title">SUMMARY</p>

      <div
        class="summary-container"
        [ngStyle]="{
          'grid-template-columns':
            scoreData && assessmentDetail?.values && !assessmentDetail?.isBehavioralOff
              ? 'repeat(2,1fr)'
              : 'repeat(1,1fr)'
        }"
      >
        <!-- START -->
        <div
          class="summary-box"
          [ngStyle]="{ width: scoreData && this.assessmentDetail?.values ? '100%' : '50%' }"
          *ngIf="
            hideOrgComp && !!viewReport?.comp_framework?.overall_average_per && !assessmentDetail?.isBehavioralOff
          "
        >
          <div class="center">
            <!-- For Organizatinal competency in case of Customised Profile Fit -->
            <div
              class="RBoxGrid"
              *ngIf="hideOrgComp"
              [ngClass]="hideOrgComp ? 'floatLeft' : ''"
              style="height: 252px; width: 242px"
            >
              <h2>{{ viewReport?.comp_framework?.coverpage_name }}</h2>

              <table
                class="ratingTable2"
                style="position: relative; margin: 20px auto"
              >
                <tr class="ratingTR">
                  <td class="ratetd" style="width: 70%; text-align: bottom">
                    <div class="StarBox starCenter">
                      <div
                        *ngIf="
                          viewReport?.comp_framework?.overall_average_rating ==
                          'Low'
                        "
                      >
                        <span class="fa fa-star checked"></span>
                        <span class="fa fa-star-o"></span>
                        <span class="fa fa-star-o"></span>
                        <span class="fa fa-star-o unchecked"></span>
                        <span class="fa fa-star-o unchecked"></span>
                        <div class="clearfix">Very Low</div>
                      </div>
                      <div
                        *ngIf="
                          viewReport?.comp_framework?.overall_average_rating ==
                          'Medium-Low'
                        "
                      >
                        <span class="fa fa-star checked"></span>
                        <span class="fa fa-star"></span>
                        <span class="fa fa-star-o"></span>
                        <span class="fa fa-star-o unchecked"></span>
                        <span class="fa fa-star-o unchecked"></span>
                        <div class="clearfix">Low</div>
                      </div>
                      <div
                        *ngIf="
                          viewReport?.comp_framework?.overall_average_rating ==
                          'Medium'
                        "
                      >
                        <span class="fa fa-star checked"></span>
                        <span class="fa fa-star"></span>
                        <span class="fa fa-star"></span>
                        <span class="fa fa-star-o unchecked"></span>
                        <span class="fa fa-star-o unchecked"></span>
                        <div class="clearfix">Medium</div>
                      </div>
                      <div
                        *ngIf="
                          viewReport?.comp_framework?.overall_average_rating ==
                          'Medium-High'
                        "
                      >
                        <span class="fa fa-star checked"></span>
                        <span class="fa fa-star"></span>
                        <span class="fa fa-star"></span>
                        <span class="fa fa-star unchecked"></span>
                        <span class="fa fa-star-o unchecked"></span>
                        <div class="clearfix">High</div>
                      </div>
                      <div
                        *ngIf="
                          viewReport?.comp_framework?.overall_average_rating ==
                          'High'
                        "
                      >
                        <span class="fa fa-star checked"></span>
                        <span class="fa fa-star"></span>
                        <span class="fa fa-star"></span>
                        <span class="fa fa-star unchecked"></span>
                        <span class="fa fa-star unchecked"></span>
                        <div class="clearfix">Very High</div>
                      </div>
                    </div>
                  </td>
                  <td class="rateText2 center">
                    {{ viewReport?.comp_framework?.overall_average_per }}
                  </td>
                </tr>
              </table>

              <p>
                {{
                  lcReportDiscriptions[
                    viewReport?.comp_framework?.overall_average_rating == 'Low'
                      ? 'Very Low'
                      : viewReport?.comp_framework?.overall_average_rating ==
                        'Medium-Low'
                      ? 'Low'
                      : viewReport?.comp_framework?.overall_average_rating ==
                        'Medium'
                      ? 'Medium'
                      : viewReport?.comp_framework?.overall_average_rating ==
                        'Medium-High'
                      ? 'High'
                      : 'Very High'
                  ]
                }}
              </p>

              <!--  MBRight  -->
            </div>
            <!--  RBoxGrid  -->
            <img
              src="assets/images/lc-stages.jpg"
              style="width: 100%; padding-bottom: 30px"
            />
          </div>
          <div
            *ngIf="
              viewReport?.comp_framework?.org_data &&
              viewReport?.comp_framework?.overall_average_per
            "
          >
            <div *ngFor="let activityComp of sortedLC">
              <div class="summary-main-card">
                <div
                  style="
                    background-color: white;
                    border-radius: 5px;
                    padding: 10px;
                    margin-right: 10px;
                  "
                >
                  <p style="margin-bottom: 0">
                    {{ activityComp.org_name }}
                  </p>
                </div>
                <div style="height: 100%">
                  <div
                    for="foo"
                    *ngIf="
                      activityComp.org_competency.overall_average_rating ==
                      'Low'
                    "
                    class="vlow"
                    style="
                      margin-left: 0;
                      height: 100%;
                      display: grid;
                      place-items: center;
                      border-radius: 5px;
                    "
                  >
                    <p onforminput="value = 200;" style="margin: 0">VERY LOW</p>
                  </div>

                  <div
                    for="foo"
                    *ngIf="
                      activityComp.org_competency.overall_average_rating ==
                      'Medium-Low'
                    "
                    class="low score-text-container"
                  >
                    <p onforminput="value = 200;" style="margin: 0">LOW</p>
                  </div>

                  <div
                    for="foo"
                    *ngIf="
                      activityComp.org_competency.overall_average_rating ==
                      'Medium'
                    "
                    class="medium score-text-container"
                  >
                    <p onforminput="value = 200;" style="margin: 0">MEDIUM</p>
                  </div>

                  <div
                    for="foo"
                    *ngIf="
                      activityComp.org_competency.overall_average_rating ==
                      'Medium-High'
                    "
                    class="high score-text-container"
                  >
                    <p onforminput="value = 200;" style="margin: 0">HIGH</p>
                  </div>

                  <div
                    class="vhigh score-text-container"
                    for="foo"
                    *ngIf="
                      activityComp.org_competency.overall_average_rating ==
                      'High'
                    "
                  >
                    <p onforminput="value = 200;" style="margin: 0">
                      VERY HIGH
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="scoreData && this.assessmentDetail?.values" class="summary-box">
          <div class="center">
            <!-- For Organizatinal competency in case of Customised Profile Fit -->
            <div class="RBoxGrid floatLeft" style="height: 252px; width: 242px">
              <h2>TVS VALUES</h2>

              <table
                class="ratingTable2"
                style="position: relative; margin: 20px auto"
              >
                <tr class="ratingTR">
                  <td class="ratetd" style="width: 70%; text-align: bottom">
                    <div class="StarBox starCenter">
                      <div *ngIf="getLabel(valuesScoreAverage) == 'Very Low'">
                        <span class="fa fa-star checked"></span>
                        <span class="fa fa-star-o"></span>
                        <span class="fa fa-star-o"></span>
                        <span class="fa fa-star-o unchecked"></span>
                        <span class="fa fa-star-o unchecked"></span>
                        <div class="clearfix">Very Low</div>
                      </div>
                      <div *ngIf="getLabel(valuesScoreAverage) == 'Low'">
                        <span class="fa fa-star checked"></span>
                        <span class="fa fa-star"></span>
                        <span class="fa fa-star-o"></span>
                        <span class="fa fa-star-o unchecked"></span>
                        <span class="fa fa-star-o unchecked"></span>
                        <div class="clearfix">Low</div>
                      </div>
                      <div *ngIf="getLabel(valuesScoreAverage) == 'Medium'">
                        <span class="fa fa-star checked"></span>
                        <span class="fa fa-star"></span>
                        <span class="fa fa-star"></span>
                        <span class="fa fa-star-o unchecked"></span>
                        <span class="fa fa-star-o unchecked"></span>
                        <div class="clearfix">Medium</div>
                      </div>
                      <div *ngIf="getLabel(valuesScoreAverage) == 'High'">
                        <span class="fa fa-star checked"></span>
                        <span class="fa fa-star"></span>
                        <span class="fa fa-star"></span>
                        <span class="fa fa-star unchecked"></span>
                        <span class="fa fa-star-o unchecked"></span>
                        <div class="clearfix">High</div>
                      </div>
                      <div *ngIf="getLabel(valuesScoreAverage) == 'Very High'">
                        <span class="fa fa-star checked"></span>
                        <span class="fa fa-star"></span>
                        <span class="fa fa-star"></span>
                        <span class="fa fa-star unchecked"></span>
                        <span class="fa fa-star unchecked"></span>
                        <div class="clearfix">Very High</div>
                      </div>
                    </div>
                  </td>
                  <td class="rateText2 center">
                    {{ valuesScoreAverage }}
                  </td>
                </tr>
              </table>

              <p style="height: fit-content">
                {{ reportDiscriptions[getLabel(valuesScoreAverage)] }}
              </p>
            </div>
            <div class="clearfix"></div>
            <div
              style="display: flex; justify-content: space-between"
              class="legend-line"
            >
              <div *ngFor="let item of verdictArray">
                <div>
                  <small style="opacity: 0"> {{ item.key }}</small>
                  <div
                    class="rectangle"
                    [attr.data-dynamic-content]="
                      item.key !== 'Extra' ? item.key : ''
                    "
                  >
                    <div class="value">
                      {{ item.value !== 'Extra' && item.value }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="clearfix"></div>
          </div>
          <div>
            <div *ngFor="let activityComp of scoreValuesArray">
              <div class="summary-main-card">
                <div
                  style="
                    background-color: white;
                    border-radius: 5px;
                    padding: 10px;
                    margin-right: 10px;
                  "
                >
                  <p style="margin-bottom: 0">{{ activityComp.key }}</p>
                </div>
                <div style="height: 100%">
                  <div style="height: 100%" *ngIf="scoreData">
                    <div
                      for="foo"
                      class="score-text-container"
                      [ngClass]="{
                        vhigh: getLabel(activityComp.value) === 'Very High',
                        high: getLabel(activityComp.value) === 'High',
                        medium: getLabel(activityComp.value) === 'Medium',
                        low: getLabel(activityComp.value) === 'Low',
                        vlow: getLabel(activityComp.value) === 'Very Low'
                      }"
                    >
                      <p onforminput="value = 200;">
                        {{ getLabel(activityComp.value) }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- END -->
      </div>
      <!-- <div class="ManagerialBox">
<h2 class="ManagHedding">
  LC Report
</h2>
</div> -->
      <div class="clearfix"></div>
    </div>
  </div>

  <div
    class="responsiveReportGrid"
    style="margin-bottom: 20px"
    *ngIf="!!viewReport?.comp_framework?.overall_average_per && !assessmentDetail?.isBehavioralOff"
  >
    <div
      class="ReportGrid"
      style="padding-top: 10px; padding-bottom: 140px"
      [ngClass]="isPageOne ? 'page2s' : ''"
    >
      <!--  ManagerialBox -->
      <!--  CoreJob  -->

      <!-- Job Type == Organsational Match -->
      <div *ngIf="viewReport?.report_data?.response?.org_data">
        <div
          class="ReportGrid"
          *ngFor="
            let orgdetail of viewReport?.report_data?.response?.org_data
              | orderBy: 'org_name';
            let i = index
          "
        >
          <div
            class="PeopleOriGrid"
            [ngClass]="i == 0 ? 'marginTop60' : 'marginTop20'"
          >
            <table>
              <tr>
                <td class="ratetd" style="width: 62%">
                  <h3 class="rateH3">{{ orgdetail.org_name }}</h3>
                  <hr
                    style="width: 75% !important; margin-left: 0 !important"
                  />
                </td>
                <td>
                  <table class="ratingTable">
                    <tr class="ratingTR">
                      <td
                        class="ratetd verticleTop"
                        style="width: 90%; text-align: bottom"
                      >
                        <div class="progress" style="">
                          <div
                            class="progress-bar"
                            role="progressbar"
                            style="
                              width: 20%;
                              background-color: #f2936d !important;
                            "
                          ></div>
                          <div
                            class="progress-bar"
                            role="progressbar"
                            style="
                              width: 20%;
                              background-color: #fcbc83 !important;
                            "
                          ></div>
                          <div
                            class="progress-bar"
                            role="progressbar"
                            style="
                              width: 20%;
                              background-color: #ffd98d !important;
                            "
                          ></div>
                          <div
                            class="progress-bar"
                            role="progressbar"
                            style="
                              width: 20%;
                              background-color: #eaeb86 !important;
                            "
                          ></div>
                          <div
                            class="progress-bar"
                            role="progressbar"
                            style="
                              width: 20%;
                              background-color: #cce080 !important;
                            "
                          ></div>
                        </div>
                           <div class="full-width-output" style="width:fit-content">
                          <output
                            for="foo"
                            *ngIf="
                              orgdetail.org_competency.overall_average_rating ==
                              'Low'
                            "
                            class="vlow"
                            onforminput="value = 200;"
                            style="min-width: 53px;"
                            >Very Low</output
                          >
                          <output
                            for="foo"
                            *ngIf="
                              orgdetail.org_competency.overall_average_rating ==
                              'Medium-Low'
                            "
                            class="low"
                            onforminput="value = 200;"
                            >Low</output
                          >
                          <output
                            for="foo"
                            *ngIf="
                              orgdetail.org_competency.overall_average_rating ==
                              'Medium'
                            "
                            class="medium"
                            onforminput="value = 200;"
                            >Medium</output
                          >
                          <output
                            for="foo"
                            *ngIf="
                              orgdetail.org_competency.overall_average_rating ==
                              'Medium-High'
                            "
                            class="high"
                            onforminput="value = 200;"
                            >High</output
                          >
                          <output
                            for="foo"
                            *ngIf="
                              orgdetail.org_competency.overall_average_rating ==
                              'High'
                            "
                            class="vhigh"
                            onforminput="value = 200;"
                            style="min-width: 53px;"
                            >Very High</output
                          >
                        </div>
                      </td>
                      <td class="rateText center ratetd ng-repeat-orgdetail">
                        {{ orgdetail.org_competency.overall_average_per }}
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
            <div class="MBLeft">
              <p class="ratingP orgRating" *ngIf="orgdetail.rating_text">
                {{ getReplacedText(orgdetail.rating_text) }}
              </p>
              <p class="ratingP orgrating" *ngIf="orgdetail.ratingtext">
                {{ getReplacedText(orgdetail.ratingtext) }}
              </p>
            </div>
            <div
              *ngFor="
                let subcompetency of orgdetail.org_competency.response
                  | orderBy: 'activity_name'
              "
            >
              <div
                style="
                  margin: 10px;
                  padding: 18px;
                  background-color: #f2f4f3;
                  margin-bottom: 5px;
                "
              >
                <table
                  style="
                    width: 100%;
                    background-color: #f2f4f3;
                    margin-top: -12px;
                  "
                >
                  <tr>
                    <td class="ratetd verticleTop" style="width: 90%">
                      <span class="tdLeftText">{{
                        subcompetency.activity_name
                      }}</span>
                    </td>
                    <td>
                      <table class="ratingTable">
                        <tr class="ratingTR">
                          <td
                            class="ratetd verticleTop"
                            style="width: 90%; text-align: bottom"
                          >
                            <div class="progress" style="">
                              <div
                                class="progress-bar"
                                role="progressbar"
                                style="
                                  width: 20%;
                                  background-color: #f2936d !important;
                                "
                              ></div>
                              <div
                                class="progress-bar"
                                role="progressbar"
                                style="
                                  width: 20%;
                                  background-color: #fcbc83 !important;
                                "
                              ></div>
                              <div
                                class="progress-bar"
                                role="progressbar"
                                style="
                                  width: 20%;
                                  background-color: #ffd98d !important;
                                "
                              ></div>
                              <div
                                class="progress-bar"
                                role="progressbar"
                                style="
                                  width: 20%;
                                  background-color: #eaeb86 !important;
                                "
                              ></div>
                              <div
                                class="progress-bar"
                                role="progressbar"
                                style="
                                  width: 20%;
                                  background-color: #cce080 !important;
                                "
                              ></div>
                            </div>
                               <div class="full-width-output" style="width:fit-content">
                              <output
                                for="foo"
                                *ngIf="subcompetency.rating == 'Low'"
                                class="vlow"
                                onforminput="value = 100;"
                                style="min-width: 53px;"
                                >Very Low</output
                              >
                              <output
                                for="foo"
                                *ngIf="subcompetency.rating == 'Medium-Low'"
                                class="low"
                                onforminput="value = 100;"
                                >Low</output
                              >
                              <output
                                for="foo"
                                *ngIf="subcompetency.rating == 'Medium'"
                                class="medium"
                                onforminput="value = 100;"
                                >Medium</output
                              >
                              <output
                                for="foo"
                                *ngIf="subcompetency.rating == 'Medium-High'"
                                class="high"
                                onforminput="value = 100;"
                                >High</output
                              >
                              <output
                                for="foo"
                                *ngIf="subcompetency.rating == 'High'"
                                class="vhigh"
                                style="margin-left: 80px;min-width: 53px;"
                                onforminput="value = 100;"
                                >Very High</output
                              >
                            </div>
                          </td>
                          <td class="rateText center ratetd subcompetencyPer">
                            {{ subcompetency.average_per }}
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </table>
                <hr />
                <div class="MBLeft">
                  <p
                    class="ratingP ng-for-subcompetency"
                    *ngIf="subcompetency.ratingtext"
                  >
                    {{ getReplacedText(subcompetency.ratingtext) }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--  Organsational Match  -->

      <!-- managerial style  -->
      <!-- managerial style end -->
      <!-- Organizatonl competencies in case of job fit and campus Recruitment-->
      <div class="ReportGrid page2s" *ngIf="hideOrgComp">
        <div class="RTopLeftBox"></div>
        <div class="RTopRightBox"></div>
        <div class="clearfix"></div>
        <div class="ManagerialBox" style="height: auto">
          <div class="MBLeft width100">
            <h2>{{ viewReport?.comp_framework?.coverpage_name }}</h2>
            <p
              *ngIf="
                viewReport?.comp_framework?.overall_average_rating == 'Low'
              "
            >
              {{
                getReplacedText(viewReport?.comp_framework?.section_subtext_low)
              }}
            </p>
            <p
              *ngIf="
                viewReport?.comp_framework?.overall_average_rating ==
                'Medium-Low'
              "
            >
              {{
                getReplacedText(
                  viewReport?.comp_framework?.section_subtext_medium_low
                )
              }}
            </p>
            <p
              *ngIf="
                viewReport?.comp_framework?.overall_average_rating == 'Medium'
              "
            >
              {{
                getReplacedText(
                  viewReport?.comp_framework?.section_subtext_medium
                )
              }}
            </p>
            <p
              *ngIf="
                viewReport?.comp_framework?.overall_average_rating ==
                'Medium-High'
              "
            >
              {{
                getReplacedText(
                  viewReport?.comp_framework?.section_subtext_high_medium
                )
              }}
            </p>
            <p
              *ngIf="
                viewReport?.comp_framework?.overall_average_rating == 'High'
              "
            >
              {{
                getReplacedText(
                  viewReport?.comp_framework?.section_subtext_high
                )
              }}
            </p>
          </div>
          <!--  MBLeft  -->
          <table class="FloatRightStar">
            <tr class="ratingTR">
              <td class="ratetd" style="width: 70%; text-align: bottom">
                <div class="StarBox starCenter floatrightFont">
                  <div
                    *ngIf="
                      viewReport?.comp_framework?.overall_average_rating ==
                      'Low'
                    "
                  >
                    <span class="fa fa-star checked"></span>
                    <span class="fa fa-star-o"></span>
                    <span class="fa fa-star-o"></span>
                    <span class="fa fa-star-o unchecked"></span>
                    <span class="fa fa-star-o unchecked"></span>
                    <div class="clearfix">Very Low</div>
                  </div>
                  <div
                    *ngIf="
                      viewReport?.comp_framework?.overall_average_rating ==
                      'Medium-Low'
                    "
                  >
                    <span class="fa fa-star checked"></span>
                    <span class="fa fa-star"></span>
                    <span class="fa fa-star-o"></span>
                    <span class="fa fa-star-o unchecked"></span>
                    <span class="fa fa-star-o unchecked"></span>
                    <div class="clearfix">Low</div>
                  </div>
                  <div
                    *ngIf="
                      viewReport?.comp_framework?.overall_average_rating ==
                      'Medium'
                    "
                  >
                    <span class="fa fa-star checked"></span>
                    <span class="fa fa-star"></span>
                    <span class="fa fa-star"></span>
                    <span class="fa fa-star-o unchecked"></span>
                    <span class="fa fa-star-o unchecked"></span>
                    <div class="clearfix">Medium</div>
                  </div>
                  <div
                    *ngIf="
                      viewReport?.comp_framework?.overall_average_rating ==
                      'Medium-High'
                    "
                  >
                    <span class="fa fa-star checked"></span>
                    <span class="fa fa-star"></span>
                    <span class="fa fa-star"></span>
                    <span class="fa fa-star unchecked"></span>
                    <span class="fa fa-star-o unchecked"></span>
                    <div class="clearfix">High</div>
                  </div>
                  <div
                    *ngIf="
                      viewReport?.comp_framework?.overall_average_rating ==
                      'High'
                    "
                  >
                    <span class="fa fa-star checked"></span>
                    <span class="fa fa-star"></span>
                    <span class="fa fa-star"></span>
                    <span class="fa fa-star unchecked"></span>
                    <span class="fa fa-star unchecked"></span>
                    <div class="clearfix">Very High</div>
                  </div>
                </div>
              </td>
              <td class="rateText2 center">
                {{ viewReport?.comp_framework?.overall_average_per }}
              </td>
            </tr>
          </table>
        </div>
        <!--  ManagerialBox -->
        <div class="clearfix"></div>

        <div class="CoreJob" style="margin-top: 60px">
          <!-- <h2> {{ candidateActivity.org_name }} </h2> -->
          <div *ngIf="viewReport?.comp_framework?.org_data">
            <div
              *ngFor="let activityComp of sortedLC"
              style="
                margin: 10px;
                padding: 18px;
                background-color: #f2f4f3;
                margin-bottom: 5px;
              "
            >
              <table
                style="
                  width: 100%;
                  background-color: #f2f4f3;
                  margin-top: -12px;
                "
              >
                <tr class="flex-center">
                  <td class="ratetd verticleTop" style="width: 90%">
                    <span class="tdLeftText">{{ activityComp.org_name }}</span>
                  </td>
                  <td>
                    <table class="ratingTable">
                      <tr class="ratingTR">
                        <td
                          class="ratetd"
                          style="width: 90%; text-align: bottom"
                        >
                          <div class="progress" style="">
                            <div
                              class="progress-bar"
                              role="progressbar"
                              style="
                                width: 20%;
                                background-color: #f2936d !important;
                              "
                            ></div>
                            <div
                              class="progress-bar"
                              role="progressbar"
                              style="
                                width: 20%;
                                background-color: #fcbc83 !important;
                              "
                            ></div>
                            <div
                              class="progress-bar"
                              role="progressbar"
                              style="
                                width: 20%;
                                background-color: #ffd98d !important;
                              "
                            ></div>
                            <div
                              class="progress-bar"
                              role="progressbar"
                              style="
                                width: 20%;
                                background-color: #eaeb86 !important;
                              "
                            ></div>
                            <div
                              class="progress-bar"
                              role="progressbar"
                              style="
                                width: 20%;
                                background-color: #cce080 !important;
                              "
                            ></div>
                          </div>
                             <div class="full-width-output" style="width:fit-content">
                            <output
                              for="foo"
                              *ngIf="
                                activityComp.org_competency
                                  .overall_average_rating == 'Low'
                              "
                              class="vlow"
                              onforminput="value = 200;"
                              style="min-width: 53px;"
                              >Very Low</output
                            >
                            <output
                              for="foo"
                              *ngIf="
                                activityComp.org_competency
                                  .overall_average_rating == 'Medium-Low'
                              "
                              class="low"
                              onforminput="value = 200;"
                              >Low</output
                            >
                            <output
                              for="foo"
                              *ngIf="
                                activityComp.org_competency
                                  .overall_average_rating == 'Medium'
                              "
                              class="medium"
                              onforminput="value = 200;"
                              >Medium</output
                            >
                            <output
                              for="foo"
                              *ngIf="
                                activityComp.org_competency
                                  .overall_average_rating == 'Medium-High'
                              "
                              class="high"
                              onforminput="value = 200;"
                              >High</output
                            >
                            <output
                              for="foo"
                              *ngIf="
                                activityComp.org_competency
                                  .overall_average_rating == 'High'
                              "
                              class="vhigh"
                              onforminput="value = 200;"
                              style="min-width: 53px;"
                              >Very High</output
                            >
                          </div>
                        </td>
                        <td class="rateText center ratetd">
                          {{ activityComp.org_competency.overall_average_per }}
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </table>
              <hr />
              <div class="MBLeft">
                <p class="ratingP" *ngIf="activityComp.ratingtext">
                  {{ getReplacedText(activityComp.ratingtext) }}
                </p>
                <p class="ratingP" *ngIf="activityComp.rating_text">
                  {{ getReplacedText(activityComp.rating_text) }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <!--  CoreJob  -->
      </div>
    </div>
  </div>

  <div class="responsiveReportGrid" style="margin-bottom: 20px">
    <div class="ReportGrid" style="padding-top: 10px" *ngIf="scoreData && assessmentDetail?.values">
      <div class="RTopLeftBox"></div>
      <div class="RTopRightBox"></div>
      <div class="clearfix"></div>
      <div class="ManagerialBox">
        <div class="MBLeft width100">
          <h2>TVS Values</h2>
          <p>Candidates likely alignment to TVS Values</p>
        </div>

        <table class="FloatRightStar">
          <tr class="ratingTR">
            <td class="ratetd" style="width: 70%; text-align: bottom">
              <div class="StarBox starCenter floatrightFont">
                <div *ngIf="getLabel(valuesScoreAverage) == 'Very Low'">
                  <span class="fa fa-star checked"></span>
                  <span class="fa fa-star-o"></span>
                  <span class="fa fa-star-o"></span>
                  <span class="fa fa-star-o unchecked"></span>
                  <span class="fa fa-star-o unchecked"></span>
                  <div class="clearfix">Very Low</div>
                </div>
                <div *ngIf="getLabel(valuesScoreAverage) == 'Low'">
                  <span class="fa fa-star checked"></span>
                  <span class="fa fa-star"></span>
                  <span class="fa fa-star-o"></span>
                  <span class="fa fa-star-o unchecked"></span>
                  <span class="fa fa-star-o unchecked"></span>
                  <div class="clearfix">Low</div>
                </div>
                <div *ngIf="getLabel(valuesScoreAverage) == 'Medium'">
                  <span class="fa fa-star checked"></span>
                  <span class="fa fa-star"></span>
                  <span class="fa fa-star"></span>
                  <span class="fa fa-star-o unchecked"></span>
                  <span class="fa fa-star-o unchecked"></span>
                  <div class="clearfix">Medium</div>
                </div>
                <div *ngIf="getLabel(valuesScoreAverage) == 'High'">
                  <span class="fa fa-star checked"></span>
                  <span class="fa fa-star"></span>
                  <span class="fa fa-star"></span>
                  <span class="fa fa-star unchecked"></span>
                  <span class="fa fa-star-o unchecked"></span>
                  <div class="clearfix">High</div>
                </div>
                <div *ngIf="getLabel(valuesScoreAverage) == 'Very High'">
                  <span class="fa fa-star checked"></span>
                  <span class="fa fa-star"></span>
                  <span class="fa fa-star"></span>
                  <span class="fa fa-star unchecked"></span>
                  <span class="fa fa-star unchecked"></span>
                  <div class="clearfix">Very High</div>
                </div>
              </div>
            </td>
            <td class="rateText2 center">
              {{ valuesScoreAverage }}
            </td>
          </tr>
        </table>
        <!-- <h2 class="ManagHedding">
    TVS Values
  </h2> -->
      </div>
      <!--  ManagerialBox -->
      <div class="clearfix"></div>

      <!-- values report spec start -->
      <div
        class="CoreJob"
        style="margin-top: 70px; padding-bottom: 140px"
        *ngIf="scoreData"
      >
        <div>
          <div
            *ngFor="let value of valuesReportSpec"
            style="
              margin: 10px;
              padding: 18px;
              background-color: #f2f4f3;
              margin-bottom: 5px;
            "
          >
            <table
              style="width: 100%; background-color: #f2f4f3; margin-top: -12px"
            >
              <tr class="flex-center">
                <td class="ratetd verticleTop" style="width: 90%">
                  <span class="tdLeftText">{{ value.value }}</span>
                </td>
                <td>
                  <table class="ratingTable">
                    <tr class="ratingTR">
                      <td class="ratetd" style="width: 90%; text-align: bottom">
                        <div class="progress" style="">
                          <div
                            class="progress-bar"
                            role="progressbar"
                            style="
                              width: 20%;
                              background-color: #f2936d !important;
                            "
                          ></div>
                          <div
                            class="progress-bar"
                            role="progressbar"
                            style="
                              width: 20%;
                              background-color: #fcbc83 !important;
                            "
                          ></div>
                          <div
                            class="progress-bar"
                            role="progressbar"
                            style="
                              width: 20%;
                              background-color: #ffd98d !important;
                            "
                          ></div>
                          <div
                            class="progress-bar"
                            role="progressbar"
                            style="
                              width: 20%;
                              background-color: #eaeb86 !important;
                            "
                          ></div>
                          <div
                            class="progress-bar"
                            role="progressbar"
                            style="
                              width: 20%;
                              background-color: #cce080 !important;
                            "
                          ></div>
                        </div>
                        <div class="full-width-output" *ngIf="scoreData" style="width:fit-content">
                          <output
                            for="foo"
                            [ngClass]="{
                              vhigh:
                                getLabel(
                                  scoreData.processedValuesScore[value.value]
                                ) === 'Very High',
                              high:
                                getLabel(
                                  scoreData.processedValuesScore[value.value]
                                ) === 'High',
                              medium:
                                getLabel(
                                  scoreData.processedValuesScore[value.value]
                                ) === 'Medium',
                              low:
                                getLabel(
                                  scoreData.processedValuesScore[value.value]
                                ) === 'Low',
                              vlow:
                                getLabel(
                                  scoreData.processedValuesScore[value.value]
                                ) === 'Very Low'
                            }"
                            class="low"
                            onforminput="value = 200;"
                            style="min-width: 53px;"
                            >{{
                              getLabel(
                                scoreData.processedValuesScore[value.value]
                              )
                            }}</output
                          >
                        </div>
                      </td>
                      <td class="rateText center ratetd">
                        {{ scoreData?.processedValuesScore[value.value] }}
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
            <hr />
            <div class="MBLeft">
              <p class="ratingP">
                {{
                  getReplacedText(
                    value.text[
                      getLabel(scoreData?.processedValuesScore[value.value])
                    ]
                  )
                }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    class="responsiveReportGrid"
    style="margin-bottom: 20px"
    *ngIf="hideOrgComp && !!viewReport?.comp_framework?.overall_average_per && !assessmentDetail?.isBehavioralOff"
  >
    <img
      *ngIf="viewReport?.comp_framework?.coverpage_name.includes('LC1')"
      src="assets/images/lc-guide-1.jpg"
      style="width: 100%; height: fit-content"
    />
    <img
      *ngIf="viewReport?.comp_framework?.coverpage_name.includes('LC2')"
      src="assets/images/lc-guide-2-1.jpg"
      style="width: 100%; height: fit-content"
    />
    <img
      *ngIf="viewReport?.comp_framework?.coverpage_name.includes('LC2')"
      src="assets/images/lc-guide-2-2.jpg"
      style="width: 100%; height: fit-content"
    />
    <img
      *ngIf="viewReport?.comp_framework?.coverpage_name.includes('LC3')"
      src="assets/images/lc-guide-3-1.jpg"
      style="width: 100%; height: fit-content"
    />
    <img
      *ngIf="viewReport?.comp_framework?.coverpage_name.includes('LC3')"
      src="assets/images/lc-guide-3-2.jpg"
      style="width: 100%; height: fit-content"
    />
    <img
      *ngIf="viewReport?.comp_framework?.coverpage_name.includes('LC4')"
      src="assets/images/lc-guide-4-1.jpg"
      style="width: 100%; height: fit-content"
    />
    <img
      *ngIf="viewReport?.comp_framework?.coverpage_name.includes('LC4')"
      src="assets/images/lc-guide-4-2.jpg"
      style="width: 100%; height: fit-content"
    />
  </div>

  <div
    class="responsiveReportGrid"
    style="margin-bottom: 20px"
    *ngIf="scoreData && assessmentDetail?.values"
  >
    <img
      src="assets/images/tvs-interview-guide.jpg"
      style="width: 100%; height: fit-content"
    />
  </div>

  <div
    class="responsiveReportGrid"
    style="margin-bottom: 20px"
    *ngIf="hideOrgComp && !!viewReport?.comp_framework?.overall_average_per && !assessmentDetail?.isBehavioralOff"
  >
    <img
      *ngIf="viewReport?.comp_framework?.coverpage_name.includes('LC1')"
      src="assets/images/lc-def-1.jpg"
      style="width: 100%; height: fit-content"
    />
    <img
      *ngIf="viewReport?.comp_framework?.coverpage_name.includes('LC2')"
      src="assets/images/lc-def-2-1.jpg"
      style="width: 100%; height: fit-content"
    />
    <img
      *ngIf="viewReport?.comp_framework?.coverpage_name.includes('LC2')"
      src="assets/images/lc-def-2-2.jpg"
      style="width: 100%; height: fit-content"
    />
    <img
      *ngIf="viewReport?.comp_framework?.coverpage_name.includes('LC3')"
      src="assets/images/lc-def-3-1.jpg"
      style="width: 100%; height: fit-content"
    />
    <img
      *ngIf="viewReport?.comp_framework?.coverpage_name.includes('LC3')"
      src="assets/images/lc-def-3-2.jpg"
      style="width: 100%; height: fit-content"
    />
    <img
      *ngIf="viewReport?.comp_framework?.coverpage_name.includes('LC4')"
      src="assets/images/lc-def-4-1.jpg"
      style="width: 100%; height: fit-content"
    />
    <img
      *ngIf="viewReport?.comp_framework?.coverpage_name.includes('LC4')"
      src="assets/images/lc-def-4-2.jpg"
      style="width: 100%; height: fit-content"
    />
  </div>

  <div
    class="responsiveReportGrid"
    style="margin-bottom: 20px"
    *ngIf="scoreData && assessmentDetail?.values"
  >
    <img
      src="assets/images/tvs-guide.jpg"
      style="width: 100%; height: fit-content"
    />
  </div>
  <div class="responsiveReportGrid">
    <!--  ReportGrid  -->

    <div class="clearfix"></div>

    <!-- Working Style Section to starts here -->

    <!-- values report spec end -->

    <!-- Working Style Section to end here -->
    <!-- <ng-container *ngIf="aptitudeReport" [ngTemplateOutlet]="aptitudeReportTemplate">
    </ng-container> -->
    <div
      class="ReportGrid page2s"
      style="padding-top: 20px; padding-bottom: 20px"
    >
      <div class="container-image" *ngIf="companyData.isProctoring">
        <div class="image-grid">
          <div *ngFor="let image of images">
            <img [src]="image.src" class="proctor-image" />
          </div>
        </div>
      </div>
      <div class="RTopLeftBox" style="height: auto"></div>
      <div class="RTopRightBox" style="height: auto"></div>
      <div class="DelegativeGrid">
        <div class="DelegativeBox">
          <h2 style="padding-top: 30px; text-align: center; margin-left: -20px">
            Disclaimer
          </h2>
          <p>
            This report is strictly confidential and contains the intellectual
            property of Stairwell Ventures. Stairwell Ventures permits the
            client to reproduce, distribute, amend and store this report for its
            internal and non-commercial use only. All other rights are reserved
            with Stairwell Ventures.
          </p>
          <p>
            While our research links psychometric and neurocognitive attributes
            measured by this assessment to important on-the-job behaviours,
            there are other factors that may impact on how well an individual
            performs at their job. These factors also need to be assessed (for
            eg. via an interview or any other selection and performance
            criteria) when appraising a candidate. A candidate's results may be
            influenced by external factors in the environment and his/her
            self-awareness, so the classification they achieved should be
            considered an indication or aproximation of the abilities measured
            by this assessment.
          </p>
          <p>
            Stairwell Ventures has made every effort to produce an informative
            report but, as with other psychometrics, it is not infallible. We
            cannot be held liable for actions taken from the interpretation of
            the information contained in this report. The report is likely to
            remain a good reflection of the individual’s potential for 12
            months, depending upon personal circumstances.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- 5 point ends -->
