import { Component, OnInit, Output, EventEmitter  } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

import { ApiService } from 'src/app/shared-services/api/api/api.service';
import { AuthService, IUser } from 'src/app/shared-services/auth/auth.service';
import { ToasterService } from 'src/app/shared-services/toaster/toaster.service';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
})
export class SearchBarComponent implements OnInit {
  user: IUser;
  searchTerm = new UntypedFormControl('');
  searchResults: TSearchResult[] = [];
  // isLoadingResult = false;
  @Output() onCandidateSelected = new EventEmitter<TSearchResult>();
  @Output() onLoading = new EventEmitter<boolean>();
  constructor(private _api: ApiService, private _auth: AuthService, private _toastr: ToasterService, ) {}

  ngOnInit(): void {
    this._auth.currentUser.subscribe((user) => {
      this.user = user;
    });
  }

  handleSearch(event) {
    event.preventDefault();


    if (!this.searchTerm.value){
      return this.searchResults = [];
    }

    // this.isLoadingResult = true;
    this.onLoading.emit(true);

    this._api.candidateSearch(this.searchTerm.value, this.user.user.company_id).subscribe((result) => {
      console.log('Search Result', result);
      this.searchResults = result;
      if (result.length === 0){
        this._toastr.showSuccess('No Candidate Data found');
      }
      this.onLoading.emit(false);
    }, err => {
      console.log(err);
      this._toastr.showError('Error: ' + err.message);
    });
  }

  handleCandidateSelect(data: TSearchResult){
      this.onCandidateSelected.emit(data);
  }
}

export interface TSearchResult {
  _id: string;
  assessment_id: string;
  assessment_name: string;
  assessment_type: string;
  email: string;
  name: string;
  status: string;
}
