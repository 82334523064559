import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { TCompanyAssessmentResponse } from '../aptitude-assessments/aptitude.interface';

export interface IAssessment {
  job_type: string;
  job_title: string;
  profile_name: string;
  competency_id: string;
  competency_name: string;
  domain_name: string;
  job_requirment: {};
  start_time?: any;
  start_datetime?: any;
  end_time?: any;
  end_datetime?: any;
  isCloned?: any;
  assessment_typeCode?: any;
  assessment_type?: any;
  aptitudeGames?: any[];
  techAssessments?: any[];
  values?: string;
  company_id?: string;
  department?: string;
  isBehavioralOff?: boolean;
}

export interface TNewCohort {
  name: string;
  divisionId: string;
  companyId: string;
  startDate: string;
  endDate: string;
  createdBy: string;
  _id?: string;
  // createdAt?: string;
  // isDeleted?: string;
  // updatedAt?: string;
  // __v?: number;
}

@Injectable({
  providedIn: 'root',
})
export class AssessmentService {
  constructor() {
    this.AssessmentSharedData = new BehaviorSubject<any>(
      this.getLocalAssessmentData(),
    );
  }

  public get getAssessmentValue(): IAssessment {
    return (
      (this.AssessmentSharedData &&
        JSON.parse(JSON.stringify(this.AssessmentSharedData.value))) ||
      this.getLocalAssessmentData()
    );
  }
  AssessmentSharedData: BehaviorSubject<any>;
  assessmentsData: IAssessment = this.getLocalAssessmentData();

  companyAptitudeAssessmentData: TCompanyAssessmentResponse;
  selectedAssessment = JSON.parse(JSON.stringify(this.getAssessmentValue));
  assessments = {
    assessment_type: [
      {
        type: 'Aptitude & Language Assessment',
        typeCode: 'apti_assessment',
        description: '',
      },
      {
        type: 'Psychometric Assessment',
        typeCode: 'psycho_assessment',
        description: '',
      },
    ],
    aptitude_type: [
      {
        type: 'Logical,Numerical & Neurocognitive Aptitude',
        typeCode: 'logical_numberical',
        description: '',
      },
      {
        type: 'Verbal Comprehension and Reasoning',
        typeCode: 'verbal_reasoning',
        description: '',
      },
    ],
    jobtype: [
      {
        job_type: 'Customised Profile Fit',
        job_type_description:
          'Evaluate the candidate to understand likelihood of success in a particular role in your organization.',
      },
      {
        job_type: 'Campus Recruitment',
        job_type_description:
          // tslint:disable-next-line: quotemark
          "Evaluate a candidate's potential to grow in and contribute to your organisation",
      },
      {
        job_type: 'Organisational Match',
        job_type_description:
          // tslint:disable-next-line: quotemark
          "Assess every candidate for compatibility with your organisation's unique competency framework.",
      },
      {
        job_type: 'Learning & Development',
        job_type_description:
          'Understand the  potential, drivers and derailers of your people to enhance their professional growth.',
      },
      {
        job_type: 'Aptitude Assessments',
        job_type_description:
          'Assess for Quantitative, Verbal and Cognitive Aptitude',
      },
    ] /** End Purpose of Assessment */,

    profileDetails: [
      {
        profile_name: 'Individual Contributor',
        profile_description: 'Roles that do not involve managing a team.',
      },
      {
        profile_name: 'First-time or Junior Manager',
        profile_description:
          'Roles that need foundational managerial competencies.',
      },
      {
        profile_name: 'Senior Manager or Manager of Managers',
        profile_description:
          'Roles involving managing large teams or other managers.',
      },
    ] /** End Profile  */,

    domain: [
      {
        domain_name: 'Sales',
      },
      {
        domain_name: 'Marketing',
      },
      {
        domain_name: 'Operations',
      },
      {
        domain_name: 'Finance',
      },
      {
        domain_name: 'Research and Development',
      },
      {
        domain_name: 'Quality Assessment',
      },
      {
        domain_name: 'Other',
      },
    ],
    department: null,
  };
  // Cohorts data
  newCohortData: TNewCohort = {
    name: '',
    divisionId: '',
    companyId: '',
    startDate: '',
    endDate: '',
    createdBy: '',
  };

  private lastAddedCohortData: TGetCohortByIdResponse = {
    companyId: null,
    createdAt: '',
    divisionId: null,
    endDate: '',
    isDeleted: null,
    name: '',
    startDate: '',
    updatedAt: '',
    __v: 0,
    _id: '',
    candidates: [],
  };

  resetAllStateData() {
    localStorage.removeItem('assessmentService');
    this.AssessmentSharedData = new BehaviorSubject<any>(null);
    this.companyAptitudeAssessmentData = null;
  }

  getLocalAssessmentData() {
    const storedAssessmentData =
      localStorage.getItem('assessmentService') || null;
    // let assessmentData = {};
    if (storedAssessmentData && JSON.parse(storedAssessmentData)) {
      return JSON.parse(storedAssessmentData);

      // assessmentData = {
      //   job_type: assessmentDataJSON.job_type,
      //   job_title: assessmentDataJSON.job_title,
      //   profile_name: assessmentDataJSON.profile_name,
      //   competency_id: assessmentDataJSON.competency_id,
      //   competency_name: assessmentDataJSON.competency_name,
      //   domain_name: assessmentDataJSON.domain_name,
      //   job_requirment: assessmentDataJSON.job_requirment,
      //   start_time: new Date(),
      //   start_datetime: assessmentDataJSON.start_datetime,
      //   end_time: new Date(),
      //   end_datetime: assessmentDataJSON.end_datetime,
      // TODO check with satpreet if we do need vwerbal and aptitude based old tests
      // needs to remove following 3 for apti assessment
      // assessment_type: assessmentDataJSON.assessment_type,
      // assessment_typeCode: assessmentDataJSON.assessment_typeCode,
      // isCloned: assessmentDataJSON.isCloned,
      // };
    } else {
      return {
        job_type: '',
        job_title: '',
        profile_name: 'Individual Contributor',
        competency_id: '',
        competency_name: '',
        domain_name: 'Sales',
        job_requirment: {},
        start_time: new Date(),
        start_datetime: this.setDesiredStartDate(),
        end_time: new Date(),
        end_datetime: this.setDesiredEndDate(),

        // TODO check with satpreet if we do need vwerbal and aptitude based old tests
        // needs to remove following 3 for apti assessment
        assessment_type: 'Psychometric Assessment',
        assessment_typeCode: 'psycho_assessment',
        //  'psycho_assessment',
        // apti_assessment
        isCloned: '',
        aptitudeGames: [],
        techAssessments: [],
        department: null,
      };
    }
  }

  setDesiredStartDate() {
    const StartTime = new Date();
    const startDateUpdated = new Date(
      StartTime.getFullYear(),
      StartTime.getMonth(),
      StartTime.getDate(),
    );
    return startDateUpdated;
  }
  setDesiredEndDate() {
    const dateValue = new Date();
    const endDateUpdated = new Date(
      dateValue.getFullYear() + 1,
      dateValue.getMonth(),
      dateValue.getDate(),
    );
    endDateUpdated.setHours(8, 0, 0, 0);
    return endDateUpdated;
  }

  storeToLocal(Assessment) {
    localStorage.setItem('assessmentService', JSON.stringify(Assessment));
  }

  // Assessment data for the candidate-list component

  setAssessmentData(data) {
    this.AssessmentSharedData.next(data);
    this.storeToLocal(data);
    return this.assessmentsData;
  }

  getAssessmentData(): Observable<IAssessment> {
    return this.AssessmentSharedData;
    // return JSON.parse(sessionStorage.getItem('dataShared'));
  }

  clearAssessmentData() {
    console.log('cleared');
    this.AssessmentSharedData.next(null);
    localStorage.setItem('assessmentService', null);
  }

  setAssessmentField(name, value) {
    const val = this.getAssessmentValue;
    val[name] = value;
    this.AssessmentSharedData.next(val);
    return this.AssessmentSharedData;
    // return this.storeToLocal(data);
  }

  setCompetencyData(id, name) {
    const assessment = this.getAssessmentValue;
    assessment.competency_id = id;
    assessment.competency_name = name;
    this.setAssessmentData(assessment);
    return this.AssessmentSharedData;
  }

  setCompetencyJobDomainData(name, jobRequirment) {
    const assessment = this.getAssessmentValue;
    assessment.domain_name = '';
    assessment.job_requirment = {};
    this.setAssessmentData(assessment);
    return this.AssessmentSharedData;
  }

  setLastAddedCohortData(cohortData: TGetCohortByIdResponse) {
    this.lastAddedCohortData = cohortData;
  }

  getLastAddedCohortData(): any {
    return this.lastAddedCohortData;
  }

  resetNewCohortData() {
    return (this.newCohortData = {
      ...this.newCohortData,
      companyId: '',
    });
  }
}

interface TCohortBaseData {
  createdAt: string;

  endDate: string;
  isDeleted: any;
  name: string;
  startDate: string;
  updatedAt: string;
  __v: number;
  _id: string;
}

export interface TAddedCohort extends TCohortBaseData {
  createdAt: string;
  companyId: string;
  divisionId: string;
  endDate: string;
  isDeleted: any;
  name: string;
  startDate: string;
  updatedAt: string;
  __v: number;
  _id: string;
}
export interface TGetCohortByIdResponse extends TCohortBaseData {
  companyId: {
    isDeleted: boolean;
    sections: string[];
    _id: string;
    name: string;
  };

  divisionId: {
    isDeleted: boolean;
    _id: string;
    name: string;
    companyId: string;
  };

  candidates: {
    currentBlock: {
      tileNumber: 2;
      completed: boolean;
    };
    isDeleted: boolean;
    gameStatus: string;
    starred: boolean;
    loginCount: number;
    _id: string;
    email: string;
    token: string;
    name: string;
    companyId: string;
    cohortId: string;
    orientationScores: string;
    createdAt: string;
  }[];
}

export interface SelectedCohortType extends TCohortBaseData {
  companyId: {
    isDeleted: boolean;
    sections: string[];
    _id: string;
    name: string;
  };
  candidates: {
    email: string;
    gameStatus: string;
    name: string;
    token: string;
    _id: string;
  }[];
}
