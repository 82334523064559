<div class="HeadStrip">
  <div class="container">
    <div class="row">
      <div class="col-md-2 position-relative">
        <div class="Selectnumber">
          <div class="Sub"><a routerLink="../job-requirements"><i class="fa fa-caret-left"
                aria-hidden="true"></i>Back</a></div> 06
        </div>
      </div>
      <div class="col-md-10">
        <div class="ProfileHedding profilepage-heading">
          <h2 class="marginTop-8">Add values assessment.</h2>
          <div class="in-info" style="margin: 0;">Select an option below only if you want to assess candidates on TVS
            values.</div>
        </div><!--  Numbering  -->
      </div>
    </div> <!--  row  -->
  </div><!--  container  -->
</div><!--  HeadStrip -->


<!--  Start Section  -->
<div class="container pt-5">
  <div class="row">
    <div class="col-md-2">
      <span class="WAProfileIco"></span>
    </div>
    <div class="col-md-10">
      <div class="row box-grid">
        <div class="col-md-3 BoxP" *ngFor="let value of values">
          <div [ngClass]="setActive(value.type_name)" class="ProcessBox" (click)="valueSelect(value)">
            <h2>{{ this.valuesText[value.type_name] }}</h2>
            <!-- <p>{{ profile.profile_description }}</p> -->
          </div><!-- ProcessBox  -->
        </div>
        <div class="col-md-3 BoxP"></div>
      </div><!--  row  -->
      <div class="new-btn-grid-box">
        <button type="button" class="new-btn" (click)="saveValueDetail()">Next</button>
      </div>
    </div>
  </div><!-- row  -->
</div>

<!--  End Section  -->