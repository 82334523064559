import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../shared-services/api/api/api.service';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { ReportService } from '../report.service';

@Component({
  selector: 'app-aptitude-report',
  templateUrl: './aptitude-report.component.html',
  styleUrls: ['./aptitude-report.component.scss'],
  // encapsulation: ViewEncapsulation.ShadowDom
})
export class AptitudeReportComponent implements OnInit {
  reportContent = this.reportService.aptiReportContent;
  gameNameToAttributes = this.reportService.gameNameToAttributes;
  urlHalfCircleImg = this.reportService.urlHalfCircleImg;
  images = [];
  reportDisplayData = {
    candidateName: '',
    assessmentTitle: '',
    assessmentType: 'Aptitude assessment',
    totalScore: 0,
    sectionCount: 0,
    sectionScoresTotal: {
      quant: undefined,
      language: undefined,
      aptitude: undefined,
    },
    sectionDetailedScores: {
      quant: [],
      language: [],
      aptitude: [],
    },
    sectionDetailTotalScore: {
      quant: 0,
      language: 0,
      aptitude: 0,
    },
  };

  constructor(
    private _api: ApiService,
    private _route: ActivatedRoute,
    protected sanitizer: DomSanitizer,
    private reportService: ReportService,
  ) {}
  ngOnInit() {
    this.getReport();
  }
  getReport() {
    const assessmentId = this._route.snapshot.params.id;
    const email = this._route.snapshot.params.email;
    this._api.viewAptitudeReport(email, assessmentId).subscribe((res) => {
      this.images = res.proctoringImages
      this.reportDisplayData.candidateName = res.name;
      this.reportDisplayData.assessmentTitle = res.assessmentId.name;
      this.reportDisplayData.sectionCount = res.processedScoreAvg.length;
      this.reportDisplayData.totalScore =
        res.processedScoreAvg.reduce((acc, current) => acc + current.score, 0) /
        res.processedScoreAvg.length;
      this.reportDisplayData.sectionScoresTotal.quant = res.processedScoreAvg.filter(
        (el) => el.gameName === 'quant',
      )[0];
      this.reportDisplayData.sectionScoresTotal.language = res.processedScoreAvg.filter(
        (el) => el.gameName === 'language',
      )[0];
      this.reportDisplayData.sectionScoresTotal.aptitude = res.processedScoreAvg.filter(
        (el) => el.gameName === 'aptitude',
      )[0];

      const quantScores = res.processedScores.filter(
        (el) => el.gameType === 'quant',
      );
      const aptitudeScores = res.processedScores.filter(
        (el) => el.gameType === 'aptitude',
      );
      const languageScores = res.processedScores.filter(
        (el) => el.gameType === 'language',
      );

      this.reportDisplayData.sectionDetailedScores.quant = quantScores;
      this.reportDisplayData.sectionDetailedScores.aptitude = aptitudeScores;
      this.reportDisplayData.sectionDetailedScores.language = languageScores;

      this.reportDisplayData.sectionDetailTotalScore.quant =
        quantScores.reduce((acc, current) => acc + current.rating, 0) /
        quantScores.length;
      this.reportDisplayData.sectionDetailTotalScore.aptitude =
        aptitudeScores.reduce((acc, current) => acc + current.rating, 0) /
        aptitudeScores.length;
      this.reportDisplayData.sectionDetailTotalScore.language =
        languageScores.reduce((acc, current) => acc + current.rating, 0) /
        languageScores.length;
      // debugger;

      // sync both score for now
      // this.reportDisplayData.sectionScoresTotal.quant.score = this.reportDisplayData.sectionDetailTotalScore.quant;
      // this.reportDisplayData.sectionScoresTotal.aptitude.score = this.reportDisplayData.sectionDetailTotalScore.aptitude;
      // this.reportDisplayData.sectionScoresTotal.language.score = this.reportDisplayData.sectionDetailTotalScore.language;
    });
  }

  getFullCircleImage(score: number): string {
    return this.reportService.getFullCircleImage(score);
  }
  getOgGameName(gameName: string): string {
    return this.reportService.games.find(game => gameName.includes(game));
  }
}
