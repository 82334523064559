import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TvsReportsRoutingModule } from './tvs-reports-routing.module';
import { TvsReportsComponent } from './tvs-reports.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedPipesModule } from '../pipe/shared-pipes/shared-pipes.module';
import { TvsReportService } from './tvs-report.service';

@NgModule({
  declarations: [
    TvsReportsComponent,
  ],
  imports: [CommonModule, TvsReportsRoutingModule, NgbModule, SharedPipesModule],
  providers: [TvsReportService],
})
export class TvsReportsModule {}
