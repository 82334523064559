<div class="report-body">
  <div class="report-container">
    <div class="page">
      <div class="topStrip">
        <div class="topStrip-left"></div>
        <div class="topStrip-right"></div>
      </div>
      <div class="logoContainer">
        <img
          src="../../../assets/images/apti-report/TL-logo.png"
          alt="Talent Litmus Logo"
        />
      </div>

      <section class="score-summary-section">
        <div class="score-summary-container">
          <div class="score-summary--title">Assessment Report</div>
          <div class="data-display-container">
            <div class="data-display">
              <div class="data-display-title">Name of the Candidate</div>
              <div class="data-display--content">
                {{ reportDisplayData.candidateName }}
              </div>
            </div>
            <div class="data-display">
              <div class="data-display-title">Assessment Title</div>
              <div class="data-display--content">
                {{ reportDisplayData.assessmentTitle }}
              </div>
            </div>
            <div class="data-display">
              <div class="data-display-title">Assessment Type</div>
              <div class="data-display--content">
                {{ reportDisplayData.assessmentType }}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="pie-charts-section">
        <div
          class="pie-charts-container"
          [ngStyle]="{
            'justify-content':
              reportDisplayData.sectionCount < 3
                ? 'space-around'
                : 'space-between'
          }"
        >
          <div
            class="pie-chart"
            *ngIf="reportDisplayData.sectionScoresTotal.aptitude"
          >
            <div class="pie-chart--title">Cognitive Abilities</div>
            <div
              class="pie-chart--image-container"
              [ngStyle]="{
                'background-image': getFullCircleImage(
                  reportDisplayData.sectionDetailTotalScore.aptitude * 10
                )
              }"
            >
              <span class="pie-chart--score">
                {{
                  reportDisplayData.sectionDetailTotalScore.aptitude
                    | number: '1.0-1'
                }}/10</span
              >
            </div>
          </div>
          <div
            class="pie-chart"
            *ngIf="reportDisplayData.sectionScoresTotal.quant"
          >
            <div class="pie-chart--title">Numerical Reasoning</div>
            <div
              class="pie-chart--image-container"
              [ngStyle]="{
                'background-image': getFullCircleImage(
                  reportDisplayData.sectionDetailTotalScore.quant * 10
                )
              }"
            >
              <span class="pie-chart--score"
                >{{
                  reportDisplayData.sectionDetailTotalScore.quant
                    | number: '1.0-1'
                }}/10</span
              >
            </div>
          </div>
          <div
            class="pie-chart"
            *ngIf="reportDisplayData.sectionScoresTotal.language"
          >
            <div class="pie-chart--title">Verbal Reasoning</div>
            <div
              class="pie-chart--image-container"
              [ngStyle]="{
                'background-image': getFullCircleImage(
                  reportDisplayData.sectionDetailTotalScore.language * 10
                )
              }"
            >
              <span class="pie-chart--score"
                >{{
                  reportDisplayData.sectionDetailTotalScore.language
                    | number: '1.0-1'
                }}/10</span
              >
            </div>
          </div>
        </div>
      </section>

      <section class="score-bar-section">
        <div class="score-bar-container">
          <h2 class="score-summary--title">Overall Score</h2>
          <div class="scorebar-image">
            <img
              src="../../../assets/images/apti-report/scorebar.png"
              alt="Scorebar image"
            />
            <div class="progress-circle-container">
              <div
                class="progress-circle"
                [ngStyle]="{ left: reportDisplayData.totalScore * 10 + '%' }"
              >
                <span
                  >{{ reportDisplayData.totalScore | number: '1.0-1' }}/10</span
                >
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div class="page">
      <div class="topStrip marginBtm-9">
        <div class="topStrip-left"></div>
        <div class="topStrip-right"></div>
      </div>

      <section class="detailedScore-section">
        <div
          class="detailedScore-container"
          [ngStyle]="{
            'justify-content':
              reportDisplayData.sectionCount < 3
                ? 'space-around'
                : 'space-between'
          }"
        >
          <!-- Aptitude Section -->
          <div
            class="detailedScore-Card"
            *ngIf="reportDisplayData.sectionDetailedScores.aptitude.length > 0"
          >
            <div class="detailedScore-Card-title">
              <span> Cognitive Abilities </span>
            </div>
            <div class="score-meters">
              <div
                class="score-meter-container"
                *ngFor="
                  let sectionData of reportDisplayData.sectionDetailedScores
                    .aptitude
                "
              >
                <h4 class="score-meter-title">
                  {{ gameNameToAttributes[getOgGameName(sectionData.gameName)] }}
                </h4>
                <div class="score-meter-text" [innerHTML]="reportContent[getOgGameName(sectionData.gameName)]">

                </div>
                <img
                  class="scoreMeter-image"
                  [src]="
                    urlHalfCircleImg +
                    (sectionData.rating <= 0 ? 1 : sectionData.rating) +
                    '.png'
                  "
                  alt="rating meter"
                />
                <div class="scoreMeter-score">
                  <span> {{ sectionData.rating }}/10 </span>
                </div>
              </div>
              <!-- <div class="score-meter-container">
                <h4 class="score-meter-title">Planning and Prioritization</h4>
                <div class="score-meter-text">
                  ndlkdnlkdnlkdnlkdnExecutive Functioning wkjdhfjw
                  scdscsdcsdcsdcsdcwdjbdwjkedbwjkebdwjewkdb
                  Dnakjdkjdajdhjdhajdhajdhajdhjdhaldhasjldhaldhljad
                </div>
                <img
                  class="scoreMeter-image"
                  src="../../../assets/images/apti-report/score3.png"
                  alt=""
                />
                <div class="scoreMeter-score">
                  <span> 4/10 </span>
                </div>
              </div>
              <div class="score-meter-container">
                <h4 class="score-meter-title">Planning and Prioritization</h4>
                <div class="score-meter-text">
                  ndlkdnlkdnlkdnlkdnExecutive Functioning wkjdhfjw
                  scdscsdcsdcsdcsdcwdjbdwjkedbwjkebdwjewkdb
                  Dnakjdkjdajdhjdhajdhajdhajdhjdhaldhasjldhaldhljad
                </div>
                <img
                  class="scoreMeter-image"
                  src="../../../assets/images/apti-report/score3.png"
                  alt=""
                />
                <div class="scoreMeter-score">
                  <span> 4/10 </span>
                </div>
              </div> -->
            </div>
            <div class="pie-chart total-pie-chart">
              <div class="pie-chart--title">Average Score</div>
              <div
                class="pie-chart--image-container"
                [ngStyle]="{
                  'background-image': getFullCircleImage(
                    reportDisplayData.sectionDetailTotalScore.aptitude * 10
                  )
                }"
              >
                <span class="pie-chart--score"
                  >{{
                    reportDisplayData.sectionDetailTotalScore.aptitude
                      | number: '1.0-1'
                  }}/10</span
                >
              </div>
            </div>
          </div>
          <!-- Quant Section -->
          <div
            class="detailedScore-Card"
            *ngIf="reportDisplayData.sectionDetailedScores.quant.length > 0"
          >
            <div class="detailedScore-Card-title">
              <span> Numerical Reasoning </span>
            </div>
            <div class="score-meters">
              <div
                class="score-meter-container"
                *ngFor="
                  let sectionData of reportDisplayData.sectionDetailedScores
                    .quant
                "
              >
                <h4 class="score-meter-title">
                  {{ gameNameToAttributes[getOgGameName(sectionData.gameName)] }}
                </h4>
                <div class="score-meter-text" [innerHTML]="reportContent[getOgGameName(sectionData.gameName)]">

                </div>
                <img
                  class="scoreMeter-image"
                  [src]="
                    urlHalfCircleImg +
                    (sectionData.rating <= 0 ? 1 : sectionData.rating) +
                    '.png'
                  "
                  alt="rating meter"
                />
                <div class="scoreMeter-score">
                  <span> {{ sectionData.rating }}/10 </span>
                </div>
              </div>
              <!-- <div class="score-meter-container">
                <h4 class="score-meter-title">Planning and Prioritization</h4>
                <div class="score-meter-text">
                  ndlkdnlkdnlkdnlkdnExecutive Functioning wkjdhfjw
                  scdscsdcsdcsdcsdcwdjbdwjkedbwjkebdwjewkdb
                  Dnakjdkjdajdhjdhajdhajdhajdhjdhaldhasjldhaldhljad
                </div>
                <img
                  class="scoreMeter-image"
                  src="../../../assets/images/apti-report/score3.png"
                  alt=""
                />
                <div class="scoreMeter-score">
                  <span> 4/10 </span>
                </div>
              </div>
              <div class="score-meter-container">
                <h4 class="score-meter-title">Planning and Prioritization</h4>
                <div class="score-meter-text">
                  ndlkdnlkdnlkdnlkdnExecutive Functioning wkjdhfjw
                  scdscsdcsdcsdcsdcwdjbdwjkedbwjkebdwjewkdb
                  Dnakjdkjdajdhjdhajdhajdhajdhjdhaldhasjldhaldhljad
                </div>
                <img
                  class="scoreMeter-image"
                  src="../../../assets/images/apti-report/score3.png"
                  alt=""
                />
                <div class="scoreMeter-score">
                  <span> 4/10 </span>
                </div>
              </div> -->
            </div>
            <div class="pie-chart total-pie-chart">
              <div class="pie-chart--title">Average Score</div>
              <div
                class="pie-chart--image-container"
                [ngStyle]="{
                  'background-image': getFullCircleImage(
                    reportDisplayData.sectionDetailTotalScore.quant * 10
                  )
                }"
              >
                <span class="pie-chart--score"
                  >{{
                    reportDisplayData.sectionDetailTotalScore.quant
                      | number: '1.0-1'
                  }}/10</span
                >
              </div>
            </div>
          </div>
          <!-- Language Section -->
          <div
            class="detailedScore-Card"
            *ngIf="reportDisplayData.sectionDetailedScores.language.length > 0"
          >
            <div class="detailedScore-Card-title">
              <span> Verbal Reasoning </span>
            </div>
            <div class="score-meters">
              <div
                class="score-meter-container"
                *ngFor="
                  let sectionData of reportDisplayData.sectionDetailedScores
                    .language
                "
              >
                <h4 class="score-meter-title">
                  {{ gameNameToAttributes[getOgGameName(sectionData.gameName)] }}
                </h4>
                <div
                  class="score-meter-text"
                  [innerHTML]="reportContent[getOgGameName(sectionData.gameName)]"
                ></div>
                <img
                  class="scoreMeter-image"
                  [src]="
                    urlHalfCircleImg +
                    (sectionData.rating <= 0 ? 1 : sectionData.rating) +
                    '.png'
                  "
                  alt="rating meter"
                />
                <div class="scoreMeter-score">
                  <span> {{ sectionData.rating }}/10 </span>
                </div>
              </div>
            </div>
            <div class="pie-chart total-pie-chart">
              <div class="pie-chart--title">Average Score</div>
              <div
                class="pie-chart--image-container"
                [ngStyle]="{
                  'background-image': getFullCircleImage(
                    reportDisplayData.sectionDetailTotalScore.language * 10
                  )
                }"
              >
                <span class="pie-chart--score"
                  >{{
                    reportDisplayData.sectionDetailTotalScore.language
                      | number: '1.0-1'
                  }}/10</span
                >
              </div>
            </div>
          </div>
        </div>
 
      </section>

<div class="container-image">
  <div class="image-grid">
    <div  *ngFor="let image of images" >
      
      <img src='{{image}}' class="proctor-image"/>
    </div>
   </div>
</div>
      <div class="ReportGrid" style="padding-top:20px;padding-bottom: 20px;">
        <div class="RTopLeftBox" style="height: auto;"></div>
        <div class="RTopRightBox" style="height: auto;"></div>
        <div class="DelegativeGrid">
          <div class="DelegativeBox">
            <h2 style="font-size:1.4rem;padding-top: 30px;text-align: center;margin-left: -20px;">Disclaimer</h2>
            <p style="font-size:1.2rem"> This report is strictly confidential and contains the intellectual property of Stairwell Ventures.
              Stairwell Ventures
              permits the client to reproduce, distribute, amend and store this report for its internal and non-commercial
              use only. All other rights are reserved with Stairwell Ventures.</p>
            <p style="font-size:1.2rem"> While our research links psychometric and neurocognitive attributes measured by this assessment to
              important on-the-job
              behaviours, there are other factors that may impact on how well an individual performs at their job. These
              factors
              also need to be assessed (for eg. via an interview or any other selection and performance criteria) when
              appraising
              a candidate. A candidate's results may be influenced by external factors in the environment and his/her
              self-awareness,
              so the classification they achieved should be considered an indication or aproximation of the abilities
              measured
              by this assessment.</p>
            <p style="font-size:1.2rem">Stairwell Ventures has made every effort to produce an informative report but, as with other psychometrics,
              it
              is not infallible. We cannot be held liable for actions taken from the interpretation of the information
              contained
              in this report. The report is likely to remain a good reflection of the individual’s potential for 12
              months,
              depending upon personal circumstances.</p>
          </div>
        </div>
      </div>

    </div>
   
  </div>
</div>
