import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

const headers = { 'Api-key': environment.apiKeyV2 };

@Injectable({
  providedIn: 'root',
})
export class ApiBaseService {
  url = environment.apiPath;
  apiKeyV2 = environment.apiKeyV2;
  urlV2 = environment.apiPathV2;
  constructor(public http: HttpClient) {}

  get(endpoint: string, params?: any, reqOpts?: any): Observable<any> {
    reqOpts = processParams(params, reqOpts);
    return this.http.get(this.url + '/' + endpoint, reqOpts);
  }

  // for fetching from v2 api
  getV2<T>(endpoint: string, params?: any, reqOpts?: any): Observable<T> {
    reqOpts = processParams(params, reqOpts);

    return this.http.get<T>(this.urlV2 + '/' + endpoint, {
      headers,
      params: reqOpts.params,
    });
  }

  postV2<T>(endpoint: string, body: any, reqOpts?: any) {
    return this.http.post<T>(this.urlV2 + '/' + endpoint, body, {
      ...reqOpts,
      headers,
    });
  }

  putV2(endpoint: string, body: any, reqOpts?: any) {
    return this.http.put(this.urlV2 + '/' + endpoint, body, {
      ...reqOpts,
      headers,
    });
  }
  deleteV2(endpoint: string, body: Object, reqOpts?: any) {
    const options = { headers, body };
    return this.http.delete(this.urlV2 + '/' + endpoint, options);
  }

  post(endpoint: string, body: any, reqOpts?: any): Observable<any> {
    return this.http.post(this.url + '/' + endpoint, body, reqOpts);
  }

  put(endpoint: string, body: any, reqOpts?: any) {
    return this.http.put(this.url + '/' + endpoint, body, reqOpts);
  }

  delete(endpoint: string, reqOpts?: any) {
    return this.http.delete(this.url + '/' + endpoint, reqOpts);
  }

  patch(endpoint: string, body: any, reqOpts?: any) {
    return this.http.patch(this.url + '/' + endpoint, body, reqOpts);
  }
}

function processParams(params?: any, reqOpts?: any) {
  if (!reqOpts) {
    reqOpts = {
      params: new HttpParams(),
    };
  }

  // Support easy query params for GET requests
  if (params) {
    reqOpts.params = new HttpParams();
    for (const k in params) {
      if (params.hasOwnProperty(k)) {
        reqOpts.params = reqOpts.params.set(k, params[k]);
      }
    }
  }

  return reqOpts;
}
