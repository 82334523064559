<div class="HeadStrip">
  <div class="container">
    <div class="row">
      <div class="col-md-2">
        <div class="Selectnumber">
          <!-- <div *ngIf="selectedAssessment?.assessment_typeCode=='psycho_assessment' || !selectedAssessment?.assessment_typeCode" class="Sub"><a routerLink="../purpose-assessment"><i class="fa fa-caret-left" aria-hidden="true"></i>Back</a></div> -->
          <!-- <div *ngIf="!(selectedAssessment?.assessment_typeCode=='psycho_assessment' || !selectedAssessment?.assessment_typeCode)" class="Sub"><a routerLink="../assessment-type"><i class="fa fa-caret-left" aria-hidden="true"></i>Back</a></div> -->
          01
        </div><!--  Numbering  -->
      </div>
      <div class="col-md-10">
        <div class="ProfileHedding">
          <h2>Group Name<div class="in-info">Give the group of employees a name</div>
          </h2>
        </div><!--  Numbering  -->
      </div>
    </div> <!--  row  -->
  </div><!--  container  -->
</div><!--  HeadStrip -->
<!--  Start Section  -->

<div class="container">
  <div class="row JobTitelGrid">
    <div class="col-md-3">
      <span class="JobTiIco"></span>
    </div>
    <div class="col-md-9">
      <div class="col-md-12">
        <h3>Set up an Onboarding/Orientation group </h3>
        <p class="welcomeInfo">
          We recommend that you add individuals undergoing an onboarding/ orientation program together or around the
          same time in a single group
        </p>
      </div>
      <div class="col-md-12">
        <form [formGroup]='jobTitleForm' class="margin-zero">
          <div class="jobbox-grid">
            <!-- <div><span ng-show="assessmentVM.error">{{assessmentVM.errorMsg}}<span></div> -->
            <div class="BmInput margin-top-80">
              <input type="text" class="form-control fc-BmInput" formControlName='jobTitle'
                placeholder=" Group Name eg: March 2021 New Joinees" required>
            </div>
            <div class="jobtitel-btn-grid">
              <button type="" class="new-btn" (click)='setJobTitle()'
                [ngClass]="{ 'disabled-button': jobTitleForm.invalid }" value="Next">Next</button>
            </div>
          </div><!--  row  -->
        </form>
      </div>
    </div>
  </div><!-- row  -->
</div>
