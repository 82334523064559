import { Component, OnInit } from '@angular/core';
import { AssessmentService } from '../assessment-service/assessment.service';
import { SharedDataService } from 'src/app/shared-services/shared-data/shared-data.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-congratulations',
  templateUrl: './congratulations.component.html',
  styleUrls: ['./congratulations.component.scss'],
})
export class CongratulationsComponent implements OnInit {
  constructor(
    private as: AssessmentService,
    private shared: SharedDataService,
    private _router: Router,
    private _route: ActivatedRoute,
  ) {}

  displayTitle = 'You have successfully set up the assessment!';
  displayMessage = 'You can now invite candidates to take the assessment.';



  queryParams: any = {};
  ngOnInit(): void {
    this.queryParams = this._route.snapshot.queryParams;

    if (this.queryParams.cohortId) {
      this.shared.setActiveTab('Orientation');
      this.displayTitle = 'You have successfully set up the group!';
      this.displayMessage = 'You can now invite participants to this group.';
    }
  }
  redirectInviteCandidate(redirectpath) {
    if (this.queryParams.cohortId) {
      this.shared.setActiveTab('Orientation');
      this.handleCohortCase(redirectpath);
      return;
    }

    this.shared.setActiveTab('Assessments');

    const res = this.as.getAssessmentValue;
    this.shared.setCandidatesData(res).subscribe((_1) => {
      if (redirectpath === 'invite_now') {
        this._router.navigate(['candidate/add-candidates']);
      } else if (redirectpath === 'invite_later') {

        if (res.job_type === 'Aptitude Assessments'){
          this._router.navigate(['candidate/list-candidates-aptitude']);
        }else{

          this._router.navigate(['candidate/list-candidates']);
        }


        // $location.path('/dashboard');list-candidate
      }
    });
  }

  handleCohortCase(redirectpath) {
    const cohortId = this.queryParams.cohortId;
    if (redirectpath === 'invite_now') {
      this._router.navigateByUrl(`candidate/add-candidates-learning?cohortId=${cohortId}`);
    } else if (redirectpath === 'invite_later') {
      this._router.navigateByUrl(`candidate/list-candidates-learning?cohortId=${cohortId}`);
      // $location.path('/dashboard');list-candidate
    }
  }
}
