<!--  container  -->
<div class="HeadStrip">
    <div class="container">
            <div class="row">
                    <div class="col-md-2">
                            <div class="ProfileHedding">
                                    <h5>Purpose <span> of Assessment</span></h5>
                            </div>
                            <!--  Numbering  -->
                    </div>
                    <div class="col-md-10">
                            <div class="ProfileInfo">
                                    <h3>What is the purpose of assessing your candidates? <span> Select any one</span></h3>
                            </div>
                            <!--  Numbering  -->
                    </div>
            </div>
            <!--  row  -->
    </div>
    <!--  container  -->
</div>
<!--  HeadStrip -->

<!--  Start Section  -->
<div class="container">
    <div class="row">
            <div class="col-md-2">
                    <span class="WAProcess"></span>
            </div>
            <div class="col-md-10">
                    <div class="box-grid">
                            <div *ngFor="let purpose of jobtype" [ngClass]="setActive(purpose.job_type)" class="ProcessBox-1" (click)="purposeSelected(purpose)">
                                    <h2>{{purpose?.job_type}}</h2>
                                    <p>{{purpose?.job_type_description}}</p>
                            </div>
                    </div>
                    <div class="clearfix"></div>
                    <div class="new-btn-grid-box">
                            <button type="button" [disabled]='!selectedJobtype' class="new-btn" (click)="saveAssessmentPurpose()">Next</button>
                    </div>
            </div>
    </div>
    <!-- row  -->
</div>
<!--  End Section  -->
