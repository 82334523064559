import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  AssessmentService,
  IAssessment,
} from '../assessment-service/assessment.service';
import { Router } from '@angular/router';
import { SharedDataService } from 'src/app/shared-services/shared-data/shared-data.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { COMPANY_ID } from 'src/app/shared-services/constants/globalEnums.enums';

@Component({
  selector: 'app-values',
  templateUrl: './values.component.html',
})
export class ValuesComponent implements OnInit, OnDestroy {
  private _unsubscribe$ = new Subject<void>();
  currentCompany = JSON.parse(localStorage.getItem('user')).user;

  values = [
    {
      type_name: 'ic',
    },
    // {
    //   type_name: 'sm',
    // },
  ];

  selectedValue = '';
  assessment: IAssessment;
  valuesText = {
    ic: 'Values - individual contributor',
    sm: 'Values - Manager',
  };
  constructor(
    private as: AssessmentService,
    private _router: Router,
    private shareService: SharedDataService,
  ) {}

  ngOnInit(): void {
    this.shareService.setActiveTab('Assessments');
    this.Init();

    // This is to remove managerial from one TVS company
    if (
      this.currentCompany &&
     [COMPANY_ID.TVS_MOTOR , COMPANY_ID.TVS_MOTOR_PRIVATE].includes(this.currentCompany.company_id)
    ) {
      this.values = [
        {
          type_name: 'ic',
        },
        {
          type_name: 'sm',
        },
      ];
    }
  }

  // TODO clear up this mess
  setActive(value: string): string {
    if (this.selectedValue === value) {
      return 'active';
    }
  }

  valueSelect(value: { type_name: string }): void {
    if (this.selectedValue === value.type_name) {
      this.selectedValue = null;
    } else {
      this.selectedValue = value.type_name;
    }
  }

  saveValueDetail(): void {
    this.assessment.values = this.selectedValue;
    this.as.setAssessmentData(this.assessment);
    this._router.navigate(['summary']);
    // this.as.storeToLocal(this.as.assessmentsData);
  }

  Init(): void {
    this.as
      .getAssessmentData()
      .pipe(takeUntil(this._unsubscribe$))
      .subscribe((res) => {
        this.assessment = res;
        this.selectedValue = res?.values || '';
      });
  }
  ngOnDestroy(): void {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }
}
