
<owl-date-time #dt1></owl-date-time>
<div class="HeadStrip">
    <div class="container">
      <div class="row">
           <div class="col-md-12">
           <div class="WASummaryHed"> Group Details</div>
           <div class="WASummarySubHed1"> Review and confirm the group details </div>
         </div>
       </div> <!--  row  -->
     </div><!--  container  -->
  </div><!--  HeadStrip -->
  <!--  Start Section  -->

  <div class="container">
      <div class="row">
      <div class="col-md-12">
          <div class="row">
          <div class="col-md-3"><span class="WASummaryIco"></span></div>
          <div class="col-md-9 row">
            <div class="col-md-6">
              <div class="WAppSGrid">
                  <div class="WAppSBox">
                  <div class="WASNo">1</div>
                  <div class="WASInfo">
                  <h2>Group Name</h2>
                  <p>{{ selectedAssessment.name }}</p>
                  </div><!--  WASNo  -->
                </div>
              </div><!--  WAppSGrid  -->
              <a  (click)="handleNameEdit()"><div class="WAppSCircle"></div></a>


            </div>
            <div class="col-md-6" >


              <div class="WAppSGrid">
                <!-- <div class="WAppSBox">
                  <div class="WASNo">2</div>
                  <div class="WASInfo">
                    <h2>Division</h2>
                    <select disabled [(ngModel)]="selectedDivision" class="form-select divisionSelect" aria-label="Default select example">
                      <option *ngFor="let item of companyDivisionData" [value]="item._id">{{item.name}}</option>
                    </select>
                  </div>
                </div> -->

              </div><!--  WAppSGrid  -->
            </div>

            <form [formGroup]='summaryForm'  novalidate ng-submit="summaryForm.$valid && postAssessment()">
              <div class="col-md-12 mt-5">
                <h2 class="oGrid-hed">Access time <small>(Note: These time limits will be applicable to all individuals added to this group)</small></h2>
                <div class="styled-select dropdown oGrid-1" style="width: fit-content !important;">

                    <input  formControlName="startDatetime"   style="width: 100%;border-bottom: 0.5px solid #9e9d9d;cursor: pointer;" [owlDateTimeTrigger]="dt6" [owlDateTime]="dt6">
                    <owl-date-time [hour12Timer]=true  #dt6></owl-date-time>

                </div>
                  <div flex-gt-xs>

                  </div>
                </div>
                <div class="oGrid-3">to</div>
                <div class="styled-select  oGrid-2">
                  <div class="styled-select  dropdown oGrid-1" style="width: fit-content !important;margin-right: 100px;">

                    <input  formControlName="endDatetime" style="width: 100%;border-bottom: 0.5px solid #9e9d9d;cursor: pointer;" [owlDateTimeTrigger]="dt5" [owlDateTime]="dt5">
                    <owl-date-time [hour12Timer]=true #dt5></owl-date-time>

                </div>
                <div class="styled-select">

                </div>
              </div>
              <div class="col-md-12" style="margin-top: 80px;">
                <div class="WAppS-btn-grid">
                  <button (click)='postAssessment()' type="submit" class="new-btn" [ngClass]="{ 'disabled-button' :   summaryForm.invalid }" >Confirm</button>
                </div>
              </div>
            </form>
          </div>
        </div><!--  row  -->
      </div>
    </div><!-- row  -->
  </div>
  <!--  End Section  -->
