<!--  Start Section  -->
<div class="JobGrid">
	<div class="JobBox">
		<div class="row">
	    <div class="col-md-4">
	    	<span class="CongraIco">
				<img src="assets/images/Congratulations.gif">
	    	</span>
	    </div>

	    <div class="col-md-8">
	    	<h3>{{displayTitle}}</h3>
	    	<dd>{{displayMessage}}</dd>
	    	<div class="succ-btn-grid">
	    		<button type="button" class="new-btn" (click)="redirectInviteCandidate('invite_now')"> Invite Now </button>
	    	</div>
	    	<div class="succ-btn-grid">
			    <button type="button" class="new-btn" (click)="redirectInviteCandidate('invite_later')"> Invite Later </button>
	    	</div>
	    </div>
	  </div><!-- row  -->
	</div>
</div>
<!--  End Section  -->
