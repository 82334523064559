<section><!--  Start Section  -->
  <div class="loading" *ngIf="isLoading">Loading&#8230;</div>
	<div class="SignupGrid" >
	   <div class="STopLine"></div>
	   <div class="SCircle"><h2>Talent<br /><span>Litmus</span></h2></div><!--  SCircle  -->
      <div class="SignUpBox">
  			<form class="margin-zero" [formGroup]='forgotPasswordForm' name="forgotPassword" >
        	<div class="FormGrid">
  					<div><span *ngIf="forgotPasswordForm?.errors">{{forgotPasswordForm?.errors}}</span></div>
            <p style="color: #777">Please enter your email id below and we will send you a new password on email</p>
          	<div class="SF forgot-password-input">
            	<input type="email" class="form-control Sup" formControlName="email" placeholder="EMAIL" required>
            </div>
            <button type="submit" class="forgot-button" (click)="onForgotPassword()"
            [ngClass]="forgotPasswordForm.invalid ? 'SupBut center-block disabled-button' : 'SupBut center-block'"
            >Reset Password</button>
          </div>
        </form>
      </div><!--  SignUpBox -->
	</div><!--  SignupGrid  -->
</section><!--  End Section  -->
