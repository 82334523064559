import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { ApiService } from '../shared-services/api/api/api.service';
import { ToasterService } from '../shared-services/toaster/toaster.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
  forgotPasswordForm: FormGroup;
  isLoading = false;
  constructor(
    private _formBuilder: FormBuilder,
    private _api: ApiService,
    private _toastr: ToasterService,
    private _router: Router,
  ) {}

  ngOnInit(): void {
    this.forgotPasswordForm = this._formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  onForgotPassword() {
    this.isLoading = true;
    this._api.forgotPassword(this.forgotPasswordForm.value.email).subscribe(
      (res) => {
        console.log(res);
        this.isLoading = false;
        this._toastr.showSuccess(res.res_msg);
        this._router.navigate(['login']);
      },
      (err) => {
        this._toastr.showError('Oops! ' + err.message);
        this.isLoading = false;
      },
    );
  }
}
