<div class="HeadStrip" id="headStrip">
  <div class="container">
    <div class="row">
      <div class="col-md-2 position-relative">
        <div class="Selectnumber">
          <div class="Sub">
            <a *ngIf="company?.company?.departments?.length" routerLink="../departments"
              ><i class="fa fa-caret-left" aria-hidden="true"></i>Back</a
            >
            <a *ngIf="!company?.company?.departments?.length" routerLink="../domain"
              ><i class="fa fa-caret-left" aria-hidden="true"></i>Back</a
            >
          </div>
          {{company?.company?.departments?.length > 0 ? '06' : '05' }}
        </div>
        <!--  Numbering  -->
      </div>
      <div class="col-md-8">
        <div class="WebAppDomain">
          <h4>
            Job Responsibilities (Non-managerial only)
            <span>Select upto 15 important role requirements </span>
          </h4>
        </div>
      </div>
      <div class="col-md-2">
        <div class="SelectedNu">
          Selected <span> {{ jobRequirmentCount }}</span>
        </div>
      </div>
    </div>
    <!--  row  -->
  </div>
  <!--  container  -->
  <div class="container" style="margin-top: 40px;">
    <div class="row">
      <div class="col-md-12 position-relative" >
        <div class="new-btn-grid-top">
          <button type="button"  class="new-btn" (click)="saveJobRequirment()">
            Next
          </button>
        </div>
      </div>
    </div>
    <!--  row  -->
  </div>
</div>
<!--  HeadStrip -->
<!--  Start Section  -->
<div class="container" style="margin-top: 20px;">
  <div class="row">
    <div class="col-md-2"><span class="WAReqIco"></span></div>
    <div class="col-md-10">
      <div class="HedTitle">
        <h1>
          Instructions
          <span
            ><a
              href="javascript: void(0);"
              (click)="toggleInstruction = !toggleInstruction"
            >
              {{
                toggleInstruction ? "Hide Instructions" : "Show Instructions"
              }}</a
            ></span
          >
        </h1>
        <div *ngIf="toggleInstruction">
          <p>
            <span>Step 1</span> Read the full list of job responsibilities once
          </p>
          <p>
            <span>Step 2</span> Select a maximum of 15 responsibilities that are
            most important to the role
          </p>
          <p>
            <span>Step 3</span> Mark each selected job responsibility as either
            primary (very important and must-have) or secondary (good-to-have
            and comparatively less important)
          </p>
        </div>
      </div>

      <div class="TableBox">
        <h2>Working With People</h2>
        <div class="table-responsive">
          <table class="table table-condensed">
            <thead>
              <tr>
                <th></th>
                <th width="74%"></th>
                <th width="8%">Primary</th>
                <th width="8%">Secondary</th>
                <th width="10%">Not Required</th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="
                  let acitivities of activityData
                    | filterByGenre: 'Working with People';
                  let $index = index
                "
              >
                <!-- | filter:{ genre: 'Working with People'} -->
                <td><div class="ok-ico"></div></td>
                <td class="ok-text">
                  {{ acitivities.name }}
                  <div class="InfoRico">
                    <div class="tooltip1">
                      <div class="tooltipspan">
                        <span [innerHtml]="acitivities.explaination"></span>
                      </div>
                    </div>
                  </div>

                  <!-- <div
                    class="InfoRico"
                    
                    (init)="spanStyle+`{{$index}}`='tooltipspan'"
                    (mouseover)="spanStyle='tooltipspanOut'"
                    (mouseleave)="spanStyle='tooltipspan'"
                    placement="right" [ngbTooltip]="tipContent"
                  > -->
                  <!-- <ng-template #tipContent [innerHtml]="(acitivities.explaination)"></ng-template> -->

                  <!-- <span
                      ngClass="{{'spanStyle' + $index}}"
                      [innerHtml]="(acitivities.explaination)"
                    >
                    </span> -->
                  <!-- </div> -->
                </td>
                <td>
                  <div class="radio radio-primary">
                    <input
                      type="radio"
                      name="{{ 'activities_people' + $index }}&quot;"
                      (click)="
                        setSelectedRequirment(
                          $event,
                          acitivities._id,
                          'primary',
                          acitivities
                        )
                      "
                      [checked]="
                        acitivities.requirment_type == 'primary' ? true : false
                      "
                    />
                  </div>
                </td>
                <td>
                  <div class="radio radio-primary">
                    <input
                      type="radio"
                      name="{{ 'activities_people' + $index }}&quot;"
                      (click)="
                        setSelectedRequirment(
                          $event,
                          acitivities._id,
                          'secondary',
                          acitivities
                        )
                      "
                      [checked]="
                        acitivities.requirment_type == 'secondary'
                          ? true
                          : false
                      "
                    />
                  </div>
                </td>
                <td>
                  <div class="radio radio-primary">
                    <input
                      type="radio"
                      name="{{ 'activities_people' + $index }}&quot;"
                      (change)="
                        setSelectedRequirment(
                          $event,
                          acitivities._id,
                          'not_required',
                          acitivities
                        )
                      "
                      [checked]="
                        acitivities.requirment_type == 'not_required'
                          ? true
                          : false
                      "
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!--  TableBox  -->

      <div class="TableBox">
        <h2>Planning and Strategising</h2>
        <div class="table-responsive">
          <table class="table table-condensed">
            <thead>
              <tr>
                <th></th>
                <th width="74%"></th>
                <th width="8%">Primary</th>
                <th width="8%">Secondary</th>
                <th width="10%">Not Required</th>
              </tr>
            </thead>
            <tbody>
              <div class="tooltip">
                Hover over me
                <span class="tooltiptext">Tooltip text</span>
              </div>
              <tr
                *ngFor="
                  let acitivities of activityData
                    | filterByGenre: 'Planning and Strategising';
                  let $index = index
                "
              >
                <!-- | filter:{ genre: 'Planning and Strategising'  -->
                <td><div class="ok-ico"></div></td>
                <td class="ok-text">
                  {{ acitivities.name }}

                  <div class="InfoRico">
                    <div class="tooltip1">
                      <div class="tooltipspan">
                        <span [innerHtml]="acitivities.explaination"></span>
                      </div>
                    </div>
                  </div>
                  <!-- <div
                    class="InfoRico"
                    ng-init="spanStyle='tooltipspan'"
                    ng-mouseover="spanStyle='tooltipspanOut'"
                    ng-mouseleave="spanStyle='tooltipspan'"
                  >
                    <span
                      ng-class="spanStyle"
                      ng-bind-html="assessmentVM.safeHtml(acitivities.explaination)"
                    >
                    </span>
                  </div> -->
                </td>
                <td>
                  <div class="radio radio-primary">
                    <input
                      type="radio"
                      name="{{ 'activities_planning' + $index }}&quot;"
                      (click)="
                        setSelectedRequirment(
                          $event,
                          acitivities._id,
                          'primary',
                          acitivities
                        )
                      "
                      [checked]="
                        acitivities.requirment_type == 'primary' ? true : false
                      "
                    />
                  </div>
                </td>
                <td>
                  <div class="radio radio-primary">
                    <input
                      type="radio"
                      name="{{ 'activities_planning' + $index }}&quot;"
                      (click)="
                        setSelectedRequirment(
                          $event,
                          acitivities._id,
                          'secondary',
                          acitivities
                        )
                      "
                      [checked]="
                        acitivities.requirment_type == 'secondary'
                          ? true
                          : false
                      "
                    />
                  </div>
                </td>
                <td>
                  <div class="radio radio-primary">
                    <input
                      type="radio"
                      name="{{ 'activities_planning' + $index }}&quot;"
                      (click)="
                        setSelectedRequirment(
                          $event,
                          acitivities._id,
                          'not_required',
                          acitivities
                        )
                      "
                      [checked]="
                        acitivities.requirment_type == 'not_required'
                          ? true
                          : false
                      "
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!--  TableBox  -->

      <div class="TableBox">
        <h2>Implementation and Achieving Results</h2>
        <div class="table-responsive">
          <table class="table table-condensed">
            <thead>
              <tr>
                <th></th>
                <th width="74%"></th>
                <th width="8%">Primary</th>
                <th width="8%">Secondary</th>
                <th width="10%">Not Required</th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="
                  let acitivities of activityData
                    | filterByGenre: 'Implemention and Achieving Results';
                  let $index = index
                "
              >
                <!-- | filter:{genre: 'Implemention and Achieving Results'} -->
                <td><div class="ok-ico"></div></td>
                <td class="ok-text">
                  {{ acitivities.name }}
                  <!-- <div
                    class="InfoRico"
                    ng-init="spanStyle='tooltipspan'"
                    ng-mouseover="spanStyle='tooltipspanOut'"
                    ng-mouseleave="spanStyle='tooltipspan'"
                  >
                    <span
                      ng-class="spanStyle"
                      ng-bind-html="assessmentVM.safeHtml(acitivities.explaination)"
                    >
                    </span>
                  </div> -->
                  <div class="InfoRico">
                    <div class="tooltip1">
                      <div class="tooltipspan">
                        <span [innerHtml]="acitivities.explaination"></span>
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  <div class="radio radio-primary">
                    <input
                      type="radio"
                      name="{{ 'activities_implementation' + $index }}&quot;"
                      (click)="
                        setSelectedRequirment(
                          $event,
                          acitivities._id,
                          'primary',
                          acitivities
                        )
                      "
                      [checked]="
                        acitivities.requirment_type == 'primary' ? true : false
                      "
                    />
                  </div>
                </td>
                <td>
                  <div class="radio radio-primary">
                    <input
                      type="radio"
                      name="{{ 'activities_implementation' + $index }}&quot;"
                      (click)="
                        setSelectedRequirment(
                          $event,
                          acitivities._id,
                          'secondary',
                          acitivities
                        )
                      "
                      [checked]="
                        acitivities.requirment_type == 'secondary'
                          ? true
                          : false
                      "
                    />
                  </div>
                </td>
                <td>
                  <div class="radio radio-primary">
                    <input
                      type="radio"
                      name="{{ 'activities_implementation' + $index }}&quot;"
                      (click)="
                        setSelectedRequirment(
                          $event,
                          acitivities._id,
                          'not_required',
                          acitivities
                        )
                      "
                      [checked]="
                        acitivities.requirment_type == 'not_required'
                          ? true
                          : false
                      "
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!--  TableBox  -->
    </div>
    <!--  row  -->
  </div>
  <!-- row  -->
</div>
<!--  End Section  -->

