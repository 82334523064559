import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class ToasterService {
  constructor(private _toastr: ToastrService) {}
  showSuccess(message) {
    this._toastr.success('', message, {
      progressBar: true,
    });
  }
  showProgress(message) {
    this._toastr.info('', message, {
      progressBar: true,
    });
  }
  showError(message) {
    this._toastr.error('', message, {
      progressBar: true,
    });
  }
}
