import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiBaseService } from '../api/api-base/api-base.service';
import { ToasterService } from '../toaster/toaster.service';
import { SharedDataService } from '../shared-data/shared-data.service';

export interface IUser {
  company?: any;
  user?: any;
}
export interface IToken {
  token?: string;
}

@Injectable()
export class AuthService {
  token: string;
  private currentUserSubject: BehaviorSubject<IUser>;
  public currentUserTokenSubject: BehaviorSubject<IToken>;
  currentCompanyImgSubject: BehaviorSubject<any>;

  public currentUser: Observable<IUser>;
  public currentUserToken: Observable<IToken>;
  public currentCompanyImg: Observable<any>;

  constructor(
    private _api: ApiBaseService,
    private _router: Router,
    private _toast: ToasterService,
    private ss: SharedDataService
  ) {
    this.currentUserSubject = new BehaviorSubject<IUser>(
      JSON.parse(localStorage.getItem('user')),
    );
    this.currentUserTokenSubject = new BehaviorSubject<IToken>(
      JSON.parse(localStorage.getItem('currentUser')),
    );
    this.currentCompanyImgSubject = new BehaviorSubject<any>(
      localStorage.getItem('company_logo'),
    );
    this.currentUser = this.currentUserSubject.asObservable();
    this.currentUserToken = this.currentUserTokenSubject.asObservable();
    this.currentCompanyImg = this.currentCompanyImgSubject.asObservable();
  }
  public get currentUserValue(): IUser {
    return this.currentUserSubject.value;
  }

  public get currentUserTokenValue(): IToken {
    return this.currentUserTokenSubject.value;
  }
  signupUser(_1: string, _2: string) {
    // your code for signing up the new user
  }

  signinUser(loginForm: any) {
    this._api
      .post('auth/local/', {
        email: loginForm.email,
        password: loginForm.password,
      })
      .subscribe(
        (user) => {
          if (user) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('currentUser', JSON.stringify(user));
            this.currentUserTokenSubject = new BehaviorSubject<IToken>(user);
            this.getUserInfo();
            return user;
          } else {
            return 'error';
          }
        },
        (err) => {
          this._toast.showError(err?.error?.message);
        },
      );

    // your code for checking credentials and getting tokens for for signing in user
  }

  logout() {
    this.ss.setLandingData(null);
    localStorage.removeItem('currentUser');
    localStorage.removeItem('user');
    localStorage.removeItem('company_logo');
    this.currentUserSubject.next(null);
    this.currentUserTokenSubject.next(null);
    this.token = null;
    this._router.navigate(['login']);
  }

  getToken() {
    return this.token;
  }

  isAuthenticated() {

    //   // here you can check if user is authenticated or not through his token
    //   if(this.currentUser.){
    //   return true;
    // }else{
    //   return false;
    // }
  }

  // TODO Darwinbox login-pending
  getUserInfo() {
    this._api.get('api/users/companyLogin/', {}).subscribe(
      (user) => {
        if (user?.user) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          this.currentUserSubject.next(user);
          localStorage.setItem('user', JSON.stringify(user));
          localStorage.setItem('latestCompanyData', JSON.stringify(user.company))
          this.DownloadImg(user);
          this._router.navigate(['dashboard']);
          this._toast.showSuccess('Login Successful');
          return user;
        } else {
          this.logout();
          this._toast.showError(user.errorMsg);
        }
      },
      (err) => {
        console.log(err);
        this._toast.showError(err.error.error);
        return err;
      },
    );
  }

  DownloadImg(user) {
    if (user.company.company_logo !== '') {
      this._api
        .get('api/company/fileDownload/' + user?.company?.company_logo, {})
        .subscribe((ImgData) => {
          localStorage.setItem('company_logo', ImgData.image);
          this.currentCompanyImgSubject.next(ImgData.image);

          this._router.navigate(['']);
          // TODO make a direct innject to dashboard
        });
    } else {
      localStorage.setItem('company_logo', './../assets/images/9.png');
      this._router.navigate(['']);
    }
  }
}
