import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/shared-services/api/api/api.service';
import { ToasterService } from 'src/app/shared-services/toaster/toaster.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-view-analytic',
  templateUrl: './view-analytic.component.html',
  styleUrls: ['./view-analytic.component.scss'],
})
export class ViewAnalyticComponent implements OnInit {
  isLoading = false;
  reportData: any;
  personaKey = 2;
  assessmentId = this._route.snapshot.params.id;
  SERVER_URL = environment.apiPath;
  constructor(
    private _route: ActivatedRoute,
    private _api: ApiService,
    private _toastr: ToasterService,
    private _router: Router,
  ) {}

  ngOnInit(): void {
    // const assessmentId = this._route.snapshot.params.id;

    if (this.assessmentId) {
      this.isLoading = true;
      this._api.getAnalyticReport(this.assessmentId).subscribe((res) => {
        this.reportData = res;
        this.isLoading = false;
      });
    } else {
      this._router.navigate(['candidate/list-candidates']);
    }
  }

  changeLabelOfPersonaData(index) {
    this.personaKey = index;
  }

  getColorClass(percentage) {
    if (percentage < 33) {
      return 'red';
    }
    if (percentage < 66 && percentage >= 33) {
      return 'orange';
    }
    if (percentage <= 100 && percentage >= 66) {
      return 'green';
    }
  }

  // downloadAnalyticExcel() {
  //   this._api
  //     .downloadAnalyticExcel(this._route.snapshot.params.id)
  //     .subscribe((_1) => {
  //       this._toastr.showSuccess('Download Started ...');
  //     });
  // }

  getCircle(score) {
    if (score < 34) {
      return 'circle-red';
    } else {
      if (score >= 34 && score < 67) {
        return 'circle-orange';
      } else {
        return 'circle-green';
      }
    }
  }
}
