<div class="HeadStrip">
  <div class="container">
    <div class="row">
      <div class="col-md-2">
        <div class="Selectnumber">
          <div class="Sub">
            <a routerLink="../job-title"><i class="fa fa-caret-left" aria-hidden="true"></i>Back</a>
          </div>
          01
        </div>
        <!--  Numbering  -->
      </div>
      <div class="col-md-10">
        <div class="WebAppHedding">Tech Assessment</div>
        <div class="WebAppSubHedding">
          Select technical assessment from the list
        </div>
      </div>
    </div>
    <!--  row  -->
  </div>
  <!--  container  -->
</div>
<!--  HeadStrip -->

<!--  Start Section  -->

<section>
  <div class="container WebAppC">
    <div class="row">
      <div class="col-md-4">
        <span class="CompetencyIco"></span>
      </div>
      <div class="col-md-8">
        <div class="row box-grid">
          <div class="col-md-3 BoxP" *ngFor="let question of questionGamesList; let i= index">
            <div [ngClass]="setActive(question._id)" class="WebAppBox" (click)="
                organizationalDNASelected(question._id, i)
              ">
              <h2>{{ question.name }}</h2>
            </div>
          </div>
        </div>
        <!--  row  -->
        <div class="WebAppBut">
          <button type="button" class="new-btn" (click)="saveCompetencyDetail()">
            Next
          </button>
        </div>
      </div>
    </div>
    <!-- row  -->
  </div>
</section>

<!--  End Section  -->
