import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/shared-services/api/api/api.service';
import { AssessmentService } from '../assessment-service/assessment.service';
import { AuthService } from 'src/app/shared-services/auth/auth.service';
import { Router } from '@angular/router';
import { TCompanyAssessmentResponse } from './aptitude.interface';

@Component({
  selector: 'app-aptitude-assessments',
  templateUrl: './aptitude-assessments.component.html',
  styleUrls: ['./aptitude-assessments.component.scss'],
})
export class AptitudeAssessmentsComponent implements OnInit {
  aptitudeData: TCompanyAssessmentResponse['aptitudeGames'];
  totalAptitudes = 0;
  assessment = this.aservice.getAssessmentValue;
  companyDetail = JSON.parse(localStorage.getItem('user'));

  constructor(
    private _api: ApiService,
    private aservice: AssessmentService,
    private _auth: AuthService,
    private _router: Router,
  ) {
    this.getCompetency();
    if (!this.assessment.aptitudeGames || !this.assessment.techAssessments) {
      this.assessment.aptitudeGames = [];
      this.assessment.techAssessments = [];
      this.aservice.setAssessmentData(this.assessment);
    }
  }

  ngOnInit(): void {}
  getCompetency() {
    const companyId = this.companyDetail?.user?.company_id;
    this._api.getCompanyAptitudeGames(companyId).subscribe((data) => {
      this.aservice.companyAptitudeAssessmentData = data;

      this.aptitudeData = data.aptitudeGames;
      this.totalAptitudes = data.aptitudeGames.length;
    });
  }

  setActive(id) {
    const aptiIndex = this.assessment.aptitudeGames.findIndex(
      (el) => el.gameId === id,
    );
    if (aptiIndex !== -1) {
      return 'active';
    }
  }

  organizationalDNASelected(id: string, index: number) {
    const aptiIndex = this.assessment.aptitudeGames.findIndex((el) => el.gameId === id);
    // id not present in the assessments
    if (aptiIndex === -1) {
      this.assessment.aptitudeGames.push({ gameId: id, order: index });
    } else {
      // if the element is already present in the array then we will remove it from the array
      this.assessment.aptitudeGames = this.assessment.aptitudeGames.filter(
        (el) => el.gameId !== id,
      );
    }
  }

  saveCompetencyDetail() {
    this.assessment.aptitudeGames.sort(
      (first, second) => first.order - second.order,
    );
    this.aservice.setAssessmentData(this.assessment);
    this._router.navigate(['techAssessmentsList']);

    // if (this.assessment.job_type === 'Customised Profile Fit') {
    //   this._router.navigate(['domain']);
    // } else {
    //   this.aservice.setCompetencyJobDomainData(1, 1).subscribe((_1) => {
    //     this._router.navigate(['summary']);
    //   });
    // }
  }
}
