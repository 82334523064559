<div class="HeadStrip">
    <div class="container">
      <div class="row">
        <div class="col-md-2 position-relative">
          <div class="Selectnumber">
            <div class="Sub"><a routerLink="../competency"><i class="fa fa-caret-left" aria-hidden="true"></i>Back</a></div> 04
          </div><!--  Numbering  -->
        </div>
        <div class="col-md-10">
          <div class="ProfileHedding">
            <h2>Domain<div class="in-info">State any one</div></h2>
          </div>
        </div>
      </div> <!--  row  -->
    </div><!--  container  -->
  </div><!--  HeadStrip -->
  
  
  
  <!--  Start Section  -->
  
  <div class="container WebAppD">
    <div class="row">
      <div class="col-md-2">
        <span class="WADomainIco"></span>
      </div>
      <div class="col-md-10">
        <div class="row">
          <div class="col-md-12">
            <div class="WADLink">
              <ul>
                <li *ngFor="let domain of domains">
                  <a href="javascript: void(0);" [ngClass]="setActive(domain.domain_name)" (click)="domainSelected(domain)">{{domain.domain_name}}</a>
                </li>
              </ul>
            </div><!-- ProcessBox  -->
          </div>
          <div class="col-md-12">
            <div class="WebAppBut">
              <button type="button" class="new-btn" [disabled]='!selectedAssessment?.domain_name' (click)="saveAssessmentData()">Next</button>
            </div>
          </div>
        </div><!--  row  -->
      </div>
    </div><!-- row  -->
  </div><!--  End Section  -->
  