import { Component, OnInit, OnDestroy } from '@angular/core';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import {
  AssessmentService,
  IAssessment,
} from '../assessment-service/assessment.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { SharedDataService } from 'src/app/shared-services/shared-data/shared-data.service';

@Component({
  selector: 'app-job-title',
  templateUrl: './job-title.component.html',
  styleUrls: ['./job-title.component.scss'],
})
export class JobTitleComponent implements OnInit, OnDestroy {
  jobTitleForm: FormGroup;
  selectedAssessment: IAssessment;
  subscriptions: Subscription[] = [];
  constructor(
    private _fb: FormBuilder,
    private as: AssessmentService,
    private _router: Router,
    private shareService: SharedDataService,
  ) {}

  ngOnInit(): void {
    this.shareService.setActiveTab('Assessments');

    this.jobTitleForm = this._fb.group({
      jobTitle: ['', [Validators.required]],
    });
    this.Init();
  }
  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  setJobTitle() {
    this.selectedAssessment.job_title = this.jobTitleForm.controls.jobTitle.value;
    const dataAssessment = this.selectedAssessment;

    if (dataAssessment.job_type === 'Customised Profile Fit') {
      this.subscriptions.push(
        this.as
          .setAssessmentField('job_title', this.selectedAssessment.job_title)
          .subscribe((res) => {
            if (res?.isCloned === 'duplicate') {
              this.subscriptions.push(
                this.as.setAssessmentField('isCloned', '').subscribe((_1) => {
                  this._router.navigate(['summary']);
                }),
              );
            } else {
              if (res?.assessment_typeCode === 'apti_assessment') {
                this._router.navigate(['assessment-type']);
              } else {
                this._router.navigate(['profile']);
              }
            }
          }),
      );
    } else {
      if (dataAssessment?.isCloned === 'duplicate') {
        this.subscriptions.push(
          this.as.setAssessmentField('isCloned', '').subscribe((_1) => {
            this._router.navigate(['summary']);
          }),
        );
      } else {
        this.subscriptions.push(
          this.as
            .setAssessmentField('job_title', this.selectedAssessment.job_title)
            .subscribe((res) => {
              if (res.job_type === 'Aptitude Assessments') {
                this._router.navigate(['techAssessmentsList']);
              } else if (res?.assessment_typeCode === 'apti_assessment') {
                this._router.navigate(['assessment-type']);
              } else {
                this._router.navigate(['competency']);
              }
            }),
        );
      }
    }
  }

  Init() {
    this.as.getAssessmentData().subscribe((res) => {
      if (res) {
        if (res?.job_type !== '') {
          this.selectedAssessment = res;
        } else {
          this.selectedAssessment = {
            job_type: '',
            job_title: '',
            profile_name: 'Individual Contributor',
            competency_id: 'competency Set 1',
            competency_name: 'competency Set 1',
            domain_name: 'Sales',
            job_requirment: {},
            start_time: new Date(),
            start_datetime: new Date(),
            end_time: new Date(),
            end_datetime: new Date(),
            department:''
            // assessment_type: '',
            // isCloned: undefined,
          };
        }

        this.jobTitleForm.setValue({
          jobTitle: this.selectedAssessment.job_title || '',
        });
      }
    });
  }
}
