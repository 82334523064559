import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from './shared-services/auth/auth.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { SharedDataService } from './shared-services/shared-data/shared-data.service';
import { AssessmentService } from './assessment/assessment-service/assessment.service';
import { forkJoin, of, Subject } from 'rxjs';
import { ApiService } from './shared-services/api/api/api.service';
import { catchError, switchMap, takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ApiBaseService } from './shared-services/api/api-base/api-base.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  private _unsubscribe$ = new Subject<void>();

  title = 'tl-dashboardv2';
  user: any;
  companyLogo: any;
  showSettingOption = true;
  excludedRoute = false;
  downloadLink = '';
  selectedTab: 'Assessments' | 'Orientation' | 'Settings' = 'Assessments';
  serverUrl: any = environment.apiPath;
  constructor(
    private _auth: AuthService,
    private domSanitizer: DomSanitizer,
    private _router: Router,
    private _route: ActivatedRoute,
    public sharedService: SharedDataService,
    private _cdr: ChangeDetectorRef,
    private assessmentService: AssessmentService,
    private _api: ApiService,
    private _apiBase: ApiBaseService,
  ) {
    this.checkRoute();
    this._auth.currentUser
      .pipe(
        takeUntil(this._unsubscribe$),
        switchMap((user) => {
          this.user = user;
          // Assuming this._auth.currentCompanyImg emits a SafeUrl
          return this._auth.currentCompanyImg.pipe(
            catchError((err) => {
              console.error('Error getting company image:', err);
              return of('');
            }),
          );
        }),
      )
      .subscribe(
        (img: string) => {
          this.companyLogo = img
            ? this.domSanitizer.bypassSecurityTrustUrl(img)
            : '';
          this.showSettingOption = this.user?.user?.role !== 'admin';
        },
        (err) => {
          console.error('Error getting current user:', err);
        },
      );
  }
  ngOnDestroy(): void {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  ngOnInit() {
    this.getUserInfo();

    if (!this.user) {
      this.getReport();
    }
    this.onLogoutFromOtherTabs();

    this.sharedService.selectedTab
      .pipe(takeUntil(this._unsubscribe$))
      .subscribe((value) => {
        this.selectedTab = value;
        console.log('Selected tab', value);
        setTimeout(() => {
          this._cdr.detectChanges();
        }, 0);
      });
  }

  getAssessmentId(url = window.location.href): string {
    // Regular expression to match an email address
    const emailRegex = /[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}/;

    // Find the email in the URL using the regular expression
    const emailMatch = url.match(emailRegex);

    if (emailMatch) {
      // Extract the substring after the email
      const stringAfterEmail = url.substring(
        url.indexOf(emailMatch[0]) + emailMatch[0].length,
      );

      console.log(stringAfterEmail.split('/')[1].split('?')[0]);

      return stringAfterEmail.split('/')[1].split('?')[0];
    }
  }

  setDownloadLink(res?: Record<string, any>): void {
    if (
      res.behavioural.report_data.job_type == 'Learning & Development' ||
      res.behavioural.report_data.job_type == 'Learning & Development'
    ) {
      this.downloadLink = `${
        this.serverUrl
      }/api/candidates/downloadAssessmentReportofCandidates/L&D/${this.getAssessmentId()}`;
    } else if (
      res.behavioural.report_data.job_type == 'Customised Profile Fit' ||
      res.behavioural.report_data.job_title != 'Keva Values'
    ) {
      this.downloadLink = `${
        this.serverUrl
      }/api/candidates/generateJobfitReport/jobfit/${this.extractEmails()}/${this.getAssessmentId()}`;
    } else if (
      res.behavioural.report_data.job_type == 'Campus Recruitment' ||
      res.behavioural.report_data.job_title != 'Keva Values'
    ) {
      this.downloadLink = `${
        this.serverUrl
      }/api/candidates/generateReport/${this.extractEmails()}/${this.getAssessmentId()}`;
    } else if (
      res.behavioural.report_data.job_type == 'Organisational Match' ||
      res.behavioural.report_data.job_title != 'Keva Values'
    ) {
      this.downloadLink = `${
        this.serverUrl
      }/api/candidates/generateCandidateReport/orgreport/${this.extractEmails()}/${this.getAssessmentId()}`;
    }
  }

  extractEmails(): string | string[] {
    const inputString = window.location.href;
    // Define the regular expression pattern to match email addresses
    const emailPattern = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}\b/g;

    // Find all matches of the pattern in the input string
    const emailsFound = inputString.match(emailPattern);
    return emailsFound?.[0] || [];
  }

  getReport(): void {
    const assessmentId = this.getAssessmentId();
    const email = this.extractEmails();
    const report$ = {
      behavioural: this._api.viewReport(email, assessmentId),
    };
    if (window.location.href.includes('view-report-apti')) {
      this.downloadLink = `${environment.apiPathV2}/aptitude/invites/generatePDFreport/${email}/${assessmentId}`;
    }

    forkJoin(report$)
      .pipe(takeUntil(this._unsubscribe$))
      .subscribe((res: any) => {
        this.setDownloadLink(res);
      });
  }

  // onLogoutFromOtherTabs() {
  //   window.addEventListener('storage', (event) => {
  //     if (event.storageArea === localStorage) {
  //       const user = localStorage.getItem('currentUser');
  //       console.log('user: ', user);

  //       if (!user) {
  //         this.logout();
  //       } else {
  //         this._router.navigateByUrl('/');
  //       }
  //     }
  //   });
  // }

  onLogoutFromOtherTabs() {
    let previousUser = localStorage.getItem('currentUser');

    window.addEventListener('storage', (event) => {

      if (event.storageArea === localStorage && event.key === 'currentUser') {

        const currentUser = localStorage.getItem('currentUser');
        
        if (currentUser !== previousUser) {
          console.log('user: ', currentUser , event.key);
          if (!currentUser) {
            this.logout();
          } else {
            this._router.navigateByUrl('/');
          }

          previousUser = currentUser;
        }
      }
    });
  }

  logout() {
    this._auth.logout();
    this.sharedService.resetStateData();
    this.assessmentService.resetAllStateData();
  }

  checkRoute() {
    this._router.events.pipe(takeUntil(this._unsubscribe$)).subscribe((res) => {
      if (res instanceof NavigationEnd) {
        if (
          res.url.startsWith('/report/view-raw-report') ||
          res.url.startsWith('/report/view-lnd-report') ||
          res.url.startsWith('/login') ||
          res.url.startsWith('/forgot-password') ||
          res.url.includes('/tvs-report')
        ) {
          this.excludedRoute = true;
        } else {
          this.excludedRoute = false;
        }
      }
    });
  }

  getUserInfo() {
    if (localStorage.user) {
      this._apiBase
        .get(`api/company/${JSON.parse(localStorage.user).company._id}`, {})
        .subscribe((data) => {
          localStorage.setItem('latestCompanyData', JSON.stringify(data));
        });
    }
  }
}
