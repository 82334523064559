import { Injectable } from '@angular/core';

@Injectable()
export class ReportService {
  urlFullCircleImg = 'https://assets.talentlitmus.com/assets/fullCircleMeter/';
  urlHalfCircleImg =
    'https://assets.talentlitmus.com/assets/halfCircleMeter/score';
  games = [
    'Jail Escape',
    'Square Numbers',
    'Visualization',
    'Tower of Hanoi',
    'Train Sequence',
    'Diction',
    'Discounting',
    'Critical Thinking',
    'Bomb Maze',
  ];
  gameNameToAttributes = {
    'Jail Escape': 'Problem Solving',
    'Square Numbers': 'Arithmetic',
    Visualization: 'Vocabulary',
    'Tower of Hanoi': 'Planning and Prioritisation',
    'Train Sequence': 'Pattern Recognition',
    Diction: 'Diction',
    Discounting: 'Ratios and Proportions',
    'Critical Thinking': 'Critical Thinking',
    'Bomb Maze': 'Working Memory',
  };

  aptiReportContent = {
    'Square Numbers':
      'Ability to make arithmetic-based calculations like addition and subtraction',
    Visualization:
      'Understanding of different words in English language <br><br>',
    Diction:
      'Correct usage of terms and grammar in language used in every-day life <br> &nbsp;',
    'Jail Escape':
      'Ability to use available means to find solutions to clearly defined problem statements',
    'Tower of Hanoi':
      'Ability to plan and organise smaller steps to complete a larger task <br><br>',
    Discounting:
      'Ability to compute and compare percentages and ratios <br><br>',
    'Critical Thinking':
      'Ability to read information and derive the right conclusions <br> &nbsp;',
    'Bomb Maze':
      'Ability to perceive,retain and apply multiple pieces of information <br><br>',
    'Train Sequence':
      'Ability to find patterns in numbers and predict missing parts of a sequences of numbers',
  };

  aptiReportContentCombined = {
    'Square Numbers':
      'Ability to make arithmetic-based calculations like addition and subtraction',
    Visualization:
      'Understanding of different words in English language <br><br><br>',
    Diction:
      'Correct usage of terms and grammar in language used in every-day life <br> &nbsp;',
    'Jail Escape':
      'Ability to use available means to find solutions to clearly defined problem statements <br> <br>',
    'Tower of Hanoi':
      'Ability to plan and organise smaller steps to complete a larger task <br>',
    Discounting:
      'Ability to compute and compare percentages and ratios <br><br>',
    'Critical Thinking':
      'Ability to read information and derive the right conclusions <br> <br>',
    'Bomb Maze':
      'Ability to perceive,retain and apply multiple pieces of information <br>',
    'Train Sequence':
      'Ability to find patterns in numbers and predict missing parts of a sequences of numbers',
  };

  constructor() {}

  getFullCircleImage(score: number): string {
    let scoreValue = 'score-10';

    if (score < 16.66) {
      scoreValue = 'score-10';
    } else if (score >= 16.66 && score < 25) {
      scoreValue = 'score-17';
    } else if (score >= 25 && score < 33.33) {
      scoreValue = 'score-25';
    } else if (score >= 33.33 && score < 41.66) {
      scoreValue = 'score-33';
    } else if (score >= 41.66 && score < 50) {
      scoreValue = 'score-42';
    } else if (score >= 50 && score < 58.33) {
      scoreValue = 'score-50';
    } else if (score >= 58.33 && score < 66.67) {
      scoreValue = 'score-58';
    } else if (score >= 66.67 && score < 75) {
      scoreValue = 'score-67';
    } else if (score >= 75 && score < 83.33) {
      scoreValue = 'score-75';
    } else if (score >= 83.33 && score < 91.66) {
      scoreValue = 'score-92';
    } else if (score >= 91.66 && score <= 100) {
      scoreValue = 'score-100';
    }

    return `url('${this.urlFullCircleImg}${scoreValue}.png')`;
  }
}
