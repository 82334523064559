<div class="HeadStrip">
    <div class="container">
      <div class="row">
          <div class="col-md-2 position-relative">
          <div class="Selectnumber">
            <div class="Sub">
              <a routerLink="../job-title"><i class="fa fa-caret-left" aria-hidden="true"></i>Back</a>
            </div> 
            02
          </div>
        </div>
          <div class="col-md-10">
          <div class="ProfileHedding profilepage-heading">
               <h2 class="marginTop-8">Profile<div class="in-info">Identify the kind of role a candidate is <br />expected to play in an organization.</div></h2>
          </div><!--  Numbering  -->
        </div>
      </div> <!--  row  -->
    </div><!--  container  -->
  </div><!--  HeadStrip -->
  
  
  <!--  Start Section  -->
  <div class="container pt-5">
      <div class="row">
      <div class="col-md-2">
        <span class="WAProfileIco"></span>
      </div>
      <div class="col-md-10">
          <div class="row box-grid">
          <div class="col-md-3 BoxP" *ngFor="let profile of profiles">
            <div [ngClass]="setActive(profile.profile_name, 'profile_name')" class="ProcessBox" (click)="profileSelect(profile)">
              <h2>{{ profile.profile_name }}</h2>
              <p>{{ profile.profile_description }}</p>
            </div><!-- ProcessBox  -->
          </div>
          <div class="col-md-3 BoxP"></div>
        </div><!--  row  -->
        <div class="new-btn-grid-box">
          <button type="button" class="new-btn" (click)="saveProfileDetail()">Next</button>
        </div>
      </div>
    </div><!-- row  -->
  </div>
  
  <!--  End Section  -->
  