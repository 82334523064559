import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ReportsComponent } from './reports.component';
import { LndReportsComponent } from './lnd-reports/lnd-reports.component';
import { ViewAnalyticComponent } from './view-analytic/view-analytic.component';
import { AptitudeReportComponent } from './aptitude-report/aptitude-report.component';
import { GroupReportIpvComponent } from './group-report-ipv/group-report-ipv.component';

const routes: Routes = [
  { path: 'view-analytic/:id', component: ViewAnalyticComponent },
  { path: 'group-report/:id', component: GroupReportIpvComponent },
  { path: ':email/:id', component: ReportsComponent },
  { path: 'view-report/:email/:id', component: ReportsComponent },
  { path: 'view-report-apti/:email/:id', component: AptitudeReportComponent },
  { path: 'view-lnd-report/:email/:id', component: LndReportsComponent },
  { path: 'view-lnd-report/:email/:id/:page', component: LndReportsComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ReportsRoutingModule {}
