import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AuthService } from '../shared-services/auth/auth.service';
import { ApiService } from '../shared-services/api/api/api.service';
import {
  trigger,
  transition,
  animate,
  style,
  stagger,
  query,
} from '@angular/animations';
import { AssessmentService } from '../assessment/assessment-service/assessment.service';
import { Router, ActivatedRoute } from '@angular/router';
import { SharedDataService } from '../shared-services/shared-data/shared-data.service';
import { ToasterService } from '../shared-services/toaster/toaster.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject, forkJoin } from 'rxjs';
import { TSearchResult } from './search-bar/search-bar.component';
import { COMPANY_ID } from '../shared-services/constants/globalEnums.enums';
import { takeUntil } from 'rxjs/operators';

const jobTypeToVariable = {
  'Campus Recruitment': 'Campus Recruitment',
  'Customised Profile Fit': 'Customised Profile Fit',
  'Learning & Development': 'Learning & Development',
  'Organisational Match': 'Organisational Match',
  'Aptitude Assessment': 'aptiAssessmentData',
};

export interface IdashData {
  'Campus Recruitment': any;
  'Customised Profile Fit': any;
  'Learning & Development': any;
  'Organisational Match': any;
  aptiAssessmentData: any;
}
enum dashboardEnum {
  'campus-assesment-view' = 'Campus Recruitment',
  'job-fit-analysis-view' = 'Customised Profile Fit',
  'competency-assesment-view' = 'Organisational Match',
  'lnd-view-all' = 'Learning & Development',
  'aptitude-view' = 'aptiAssessmentData',
}

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  // TODO -ask or remove
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [
        query('td', [
          style({ opacity: 0, transform: 'translateY(-100px)' }),
          stagger(-30, [
            animate(
              '500ms cubic-bezier(0.35, 0, 0.25, 1)',
              style({ opacity: 1, transform: 'none' }),
            ),
          ]),
        ]),
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('0.1s ease-in', style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class DashboardComponent implements OnInit, OnDestroy {
  private _unsubscribe$ = new Subject<void>();

  @ViewChild('deleteModal') private deleteModal;

  user: any;

  types = [
    'job-fit-analysis-view',
    'campus-assesment-view',
    'competency-assesment-view',
    'lnd-view-all',
    'aptitude-view',
  ];

  dashboardData: IdashData = {
    'Campus Recruitment': [],
    'Customised Profile Fit': [],
    'Learning & Development': [],
    'Organisational Match': [],
    aptiAssessmentData: null,
  };

  showSettingOption = false;
  toggle: any;
  Json: any = JSON;
  showMoreType: any;
  showMoreFlags = {
    CampusRecruitment: false,
    CustomisedProfileFit: false,
    LearningDevelopment: false,
    OrganisationalMatch: false,
    aptiAssessmentData: false,
  };
  companyDetail = localStorage.latestCompanyData
  ? {
      ...JSON.parse(localStorage.user),
      company: JSON.parse(localStorage.latestCompanyData),
    } : JSON.parse(localStorage.getItem('user'));
  totalCounts = { totalAssessment: 0, totalAttempted: 0, totalCandidates: 0 };
  isLoading = false;
  constructor(
    private _auth: AuthService,
    private _api: ApiService,
    private sharedService: SharedDataService,
    private _router: Router,
    private assessmentService: AssessmentService,
    private _toastr: ToasterService,
    private _modalService: NgbModal,
    private _route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.sharedService.setActiveTab('Assessments');

    if (this._route.snapshot.params.type) {
      if (this.types.includes(this._route.snapshot.params.type)) {
        this.ShowType();
      } else {
        this._router.navigate(['dashboard']);
      }
    }
    this._auth.currentUser
      .pipe(takeUntil(this._unsubscribe$))
      .subscribe((user) => {
        if (user && user.user) {
          this.user = user;
          this.getAssessmentData(user);
        } else {
          // this._auth.logout();
          this._router.navigate(['login']);
        }
      });
  }

  getAssessmentData(user) {
    let loggedUserAssessment = '';
    if (user?.user?.role === 'user') {
      loggedUserAssessment = user?.user?._id;
      this.showSettingOption = true;
    } else {
      loggedUserAssessment = user?.user?.company_id;
      this.showSettingOption = false;
    }
    const res = this.sharedService.getLandingValue();
    if (res) {
      this.dashboardData = res.data;
      if (!this.showMoreType) {
        this.initAllCount(res);
      } else {
        this.initTypeCounts();
      }
    } else {
      this.getAssessments(loggedUserAssessment);
    }
  }

  getEvaluatedCandidates(candidates) {
    return candidates.reduce(
      (acc, curr) => (curr.gameStatus === 'completed' ? acc + 1 : acc),
      0,
    );
  }

  getAssessments(loggedUserAssessment) {
    this.isLoading = true;

    forkJoin([
      this._api.getAssessments(loggedUserAssessment),
      this._api.getAddedAptitudeAssessments(loggedUserAssessment),
    ])
      .pipe(takeUntil(this._unsubscribe$))
      .subscribe(
        (responseArr) => {
          this.isLoading = false;
          const res = responseArr[0];
          // job type is not present in the response data
          // I have adding job type manually here so that we can show data in summery component
          const aptiAssessmentList = responseArr[1].list.map((el) => {
            return {
              ...el,
              job_type: 'Aptitude Assessments',
              job_title: el.name,
            };
          });

          res.data.aptiAssessmentData = aptiAssessmentList || [];
          this.sharedService.setLandingData(res);
          this.dashboardData = res.data;
          if (!this.showMoreType) {
            this.initAllCount(res);
          } else {
            this.initTypeCounts();
          }
        },
        (err) => {
          this.isLoading = false;

          console.log(err);
        },
      );
  }

  daysLeft(date) {
    const dayDiff: any =
      (new Date(date).getTime() - new Date().getTime()) / (24 * 60 * 60 * 1000);
    // const result = dayDiff.toFixed(0);
    //   if(item && result==="-0"){
    //     console.log(item, '  ', result);
    //   }
    return dayDiff.toFixed(0);
  }

  listCandidate(item) {
    console.log({ item }, this.companyDetail);
    this.sharedService.setCandidatesData(item);
    if (item.job_type === 'Aptitude Assessments') {
      this._router.navigate(['candidate/list-candidates-aptitude']);
      return;
    }
    if (
      (this.companyDetail.user.company_id === COMPANY_ID.TVS_MOTOR ||
        this.companyDetail.user.company_id === COMPANY_ID.TVS_MOTOR_PRIVATE) &&
      item.isBehavioralOff
    ) {
      this._router.navigate(['candidate/list-candidates-sjt']);
    } else {
      this._router.navigate(['candidate/list-candidates']);
    }
  }

  // TODO - try material dropdown instead
  // tried using ngb-dropdowns but it breaks due to multiple instances and async operation
  hideToggle(e) {
    if (e.target.className === 'ShowIfo') {
      return false;
    } else {
      if (this.toggle) {
        this.toggle = {};
      }
    }
  }

  toggleOption(event, togglestatus) {
    togglestatus.toggle = !togglestatus.toggle;
    this.toggle = {};
    this.toggle = togglestatus;

    event.stopPropagation();
  }

  editAssessment(item) {
    this.assessmentService.setAssessmentData(
      JSON.parse(
        JSON.stringify({
          ...item,
          department:
            item?.department?._id &&
            this.companyDetail.company.departments?.length > 0
              ? item.department._id
              : null,
        }),
      ),
    );
    this._router.navigate(['summary']);
  }

  duplicateAssessment(item) {
    let duplicate;

    duplicate = {
      competency_id: item.competency_id,
      competency_name: item.competency_name,
      domain_name: item.domain_name,
      job_requirment: item.job_requirment,
      job_title: '',
      job_type: item.job_type,
      profile_name: item.profile_name,
      start_time: item.start_time,
      start_datetime: item.start_datetime,
      end_time: item.start_time,
      end_datetime: item.end_datetime,
      created_by: this.user.user._id,
      company_id: this.user.user.company_id,
      assessment_type: item.assessment_type,
      assessment_typeCode: item.assessment_typeCode,
      isCloned: 'duplicate',
      values: item.values,
      department:
        item?.department?._id &&
        this.companyDetail.company.departments?.length > 0
          ? item.department._id
          : null,
    };

    if (item.job_type === 'Aptitude Assessments') {
      duplicate = {
        ...item,
        job_title: '',
        isCloned: 'duplicate',
        createdBy: this.user.user._id,
        companyId: this.user.user.company_id,
        _id: '',
        start_datetime: item.startDate,
        end_datetime: item.endDate,
      };
      // duplicate.job_title = '';
      // duplicate.isCloned = 'duplicate';
      // duplicate.createdBy = this.user.user._id;
      // duplicate.companyId = this.user.user.company_id;
      // duplicate._id = '';
      // duplicate.start_datetime = duplicate.startDate;
      // duplicate.end_datetime
    }

    this.assessmentService.setAssessmentData(
      JSON.parse(JSON.stringify(duplicate)),
    );
    this._router.navigate(['job-title']);
  }

  showConfirm(e, item) {
    const onDeleteSuccess = () => {
      this.sharedService.clearLandingData();
      this.getAssessmentData(this.user);
      this._toastr.showSuccess('Assessment deleted Successfully');
    };

    this._modalService
      .open(this.deleteModal, {
        ariaLabelledBy: 'modal-basic-title',
        centered: true,
      })
      .result.then(
        (result) => {
          const closeResult = `${result}`;
          if (closeResult === 'delete') {
            if (item.job_type === 'Aptitude Assessments') {
              this._api
                .deleteAptitudeAssessment({ _id: item._id })
                .pipe(takeUntil(this._unsubscribe$))
                .subscribe((res) => {
                  console.log(res);
                  onDeleteSuccess();
                });
            } else {
              this._api
                .deleteCAssessment(item._id)
                .pipe(takeUntil(this._unsubscribe$))
                .subscribe((_1) => {
                  // this.sharedService.clearCandidatesData();
                  onDeleteSuccess();
                });
            }
          }
        },
        (reason) => {
          console.log(reason);
        },
      );
  }

  redirectInviteCandidate() {
    this.assessmentService.clearAssessmentData();
    this._router.navigate(['purpose-assessment']);
  }

  addCandidates(item) {
    if (this.daysLeft(item.end_datetime) < 0) {
      this._toastr.showError(
        "This job is expired, you can't add more candidates.",
      );
    } else {
      this.sharedService.setCandidatesData(item);

      this._router.navigate(['candidate/add-candidates']);
    }
  }

  logout() {
    this._auth.logout();
  }
  ShowType() {
    this.showMoreType = this._route.snapshot.params.type;
  }
  initTypeCounts() {
    const typeDetails = this.dashboardData[dashboardEnum[this.showMoreType]];
    this.totalCounts.totalAssessment = typeDetails.length;
    this.totalCounts.totalAttempted = this.getCandidateCounts(
      dashboardEnum[this.showMoreType],
      'ONLYATTEMPTED',
    );
    this.totalCounts.totalCandidates = this.getCandidateCounts(
      dashboardEnum[this.showMoreType],
      'All',
    );
    // this.scrollTop();
  }
  switchViewAll(type) {
    this._router.navigate(['dashboard/' + type]);
  }

  getCandidateCounts(index, option = 'All') {
    let totalUsers = 0;
    let totalAttempted = 0;
    // for handling apti Assessment case
    // as for both cases there is a return condition there is not need to handle else case separately
    /*
    Fixed issue where candidates count is less than completed candidates
       curr.candidates.reduce((candidatedInvited, candidate) => {
              if (candidate.gameStatus === 'invited') {
                return candidatedInvited + 1;
              } else {
                return candidatedInvited;
              }
            }, 0)
    */

    if (index === 'aptiAssessmentData') {
      if (option === 'All') {
        return this.dashboardData[index].reduce((acc, curr) => {
          return acc + curr.candidates.length;
        }, 0);
      } else if (option === 'ONLYATTEMPTED') {
        return this.dashboardData[index].reduce((acc, curr) => {
          return (
            acc +
            curr.candidates.reduce((candidatedInvited, candidate) => {
              if (candidate.gameStatus === 'completed') {
                return candidatedInvited + 1;
              } else {
                return candidatedInvited;
              }
            }, 0)
          );
        }, 0);
      }
    }

    this.dashboardData[index].forEach((assessment) => {
      console.log('assessment: ', assessment);
      
      totalUsers += assessment.totalCandidates;
      totalAttempted += assessment.totalAttempted;
    });
    if (option !== 'All') {
      return totalAttempted;
    } else {
      return totalUsers;
    }
  }

  initAllCount(data) {
    this.totalCounts.totalAssessment =
      data.totalAssessment + this.dashboardData.aptiAssessmentData.length;
    this.totalCounts.totalAttempted =
      data.totalAttempted +
      (this.dashboardData.aptiAssessmentData
        ? this.dashboardData.aptiAssessmentData.reduce((acc, curr) => {
            return (
              acc +
              curr.candidates.reduce((candidatedInvited, candidate) => {
                if (candidate.gameStatus === 'completed') {
                  return candidatedInvited + 1;
                } else {
                  return candidatedInvited;
                }
              }, 0)
            );
          }, 0)
        : 0);
    this.totalCounts.totalCandidates =
      data.totalCandidates +
      this.getCandidateCounts('aptiAssessmentData', 'All');
  }

  scrollTop() {
    const scrollToTop = window.setInterval(() => {
      const pos = window.pageYOffset;
      if (pos > 0) {
        window.scrollTo(0, 0); // how far to scroll on each step
      } else {
        window.clearInterval(scrollToTop);
      }
    }, 16);
  }

  handleSearchCandidate(candidateData: TSearchResult) {
    const allCandidateOfType = this.dashboardData[
      jobTypeToVariable[candidateData.assessment_type]
    ];
    console.log(allCandidateOfType);
    const assessmentData = allCandidateOfType.find(
      (el) => el._id === candidateData.assessment_id,
    );
    this.listCandidate(assessmentData);
  }

  handleSearchLoading(loading: boolean) {
    this.isLoading = loading;
  }
  ngOnDestroy(): void {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }
}
