import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/shared-services/api/api/api.service';
import { AssessmentService } from '../assessment-service/assessment.service';
import { AuthService } from 'src/app/shared-services/auth/auth.service';
import { Router } from '@angular/router';
import { SharedDataService } from 'src/app/shared-services/shared-data/shared-data.service';

@Component({
  selector: 'app-competency',
  templateUrl: './competency.component.html',
  styleUrls: ['./competency.component.scss'],
})
export class CompetencyComponent implements OnInit {
  competencySet: any;
  assessment = this.aservice.getAssessmentValue;
  company = localStorage.latestCompanyData
    ? {
        ...JSON.parse(localStorage.user),
        company: JSON.parse(localStorage.latestCompanyData),
      }
    : JSON.parse(localStorage.user);
  constructor(
    private _api: ApiService,
    private aservice: AssessmentService,
    private _auth: AuthService,
    private _router: Router,
    private shareService: SharedDataService,
  ) {
    this.getCompetency();
  }

  ngOnInit(): void {
    this.shareService.setActiveTab('Assessments');
  }
  getCompetency() {
    this._api
      .getCompetencies(this._auth.currentUserValue?.company?._id)
      .subscribe((data) => {
        this.competencySet = data;
      });
  }

  setActive(val) {
    if (this.assessment.competency_id === val) {
      return 'active';
    }
  }

  organizationalDNASelected(id, name) {
    if (this.assessment.competency_id === id) {
      this.assessment.competency_id = '';
      this.assessment.competency_name = '';
    } else {
      this.assessment.competency_id = id;
      this.assessment.competency_name = name;
    }
  }

  saveCompetencyDetail() {
    this.aservice.setAssessmentData(this.assessment);
    if (this.assessment.job_type === 'Customised Profile Fit') {
      this._router.navigate(['domain']);
    } else {
      this.aservice.setCompetencyJobDomainData(1, 1);
      if (
        this.company?.company?.departments?.length > 0 &&
        this.assessment.job_type === 'Campus Recruitment'
      ) {
        this._router.navigate(['departments']);
      } else {
        this._router.navigate(['summary']);
      }
    }
  }
}
