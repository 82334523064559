/* eslint-disable @typescript-eslint/naming-convention */

export enum COMPANY_ID {
  MEDTRONIC = '5d3a48634920e049cffc6771', // tech assesment
  MEDTRONIC_INDIA = '6034980d776e4c0011e4c792',
  INDIA_MEDTRONIC_PVT_LTD = '619606e40c60510012714a70',
  CARL_ZEISS_INDIA = '61b9d3175fd13700121b6c54',
  TVS_MOTOR = '641c0a74bc13fe001122dffc',
  TVS_MOTOR_PRIVATE = '5f6325c4fd451b19e243df7e',
  HDFC_BANK = '63ca686defc2b80012eed1b3',
  HDFC = '5d917ef24e455e13512dbcde',
  THERMAX = '618ced405429e900117ba635'
}
