<div class="loading" *ngIf="isLoading"></div>
<div class="l-d" *ngIf="!isLoading">
  <div class="header-top">
    <div class="container">
      <h3>Team Report - {{ job_title }}</h3>

      <div class="d-flex justify-content-between position-relative" style="height: 44px">
        <a href="{{ SERVER_URL }}/api/candidates/downloadEntrepreneursReport/{{
            assessmentId
          }}">
          <!-- <span class="pdfIcon"> </span> -->
          <div class="icon-container"><img class="pdfIcon" src="../../../assets/images/pdf-icon-1.png" alt=""></div>
        </a>
      </div>
    </div>
  </div>
  <div class="container analytics-container">
    <div class="d-flex help-icons">
      <div class="d-flex guide-wrapper">
        <div class="veryLow-bg guide-border-left-radius">
          <p class="text-white guide-text">0 - 19</p>
        </div>
        <div class="bg-white guide-border-right-radius">
          <p class="text-gray-brown guide-text guide-white-patch">Very Low</p>
        </div>
      </div>
      <div class="d-flex guide-wrapper">
        <div class="low-bg guide-border-left-radius">
          <p class="text-white guide-text">20 - 39</p>
        </div>
        <div class="bg-white guide-border-right-radius">
          <p class="text-gray-brown guide-text guide-white-patch">Low</p>
        </div>
      </div>
      <div class="d-flex guide-wrapper">
        <div class="medium-bg guide-border-left-radius">
          <p class="text-white guide-text">40 - 59</p>
        </div>
        <div class="bg-white guide-border-right-radius">
          <p class="text-gray-brown guide-text guide-white-patch">Medium</p>
        </div>
      </div>

      <div class="d-flex guide-wrapper">
        <div class="high-bg guide-border-left-radius">
          <p class="text-white guide-text">60 - 79</p>
        </div>
        <div class="bg-white guide-border-right-radius">
          <p class="text-gray-brown guide-text guide-white-patch">High</p>
        </div>
      </div>

      <div class="d-flex guide-wrapper">
        <div class="veryHigh-bg guide-border-left-radius">
          <p class="text-white guide-text">80 - 100</p>
        </div>
        <div class="bg-white guide-border-right-radius">
          <p class="text-gray-brown guide-text guide-white-patch">Very High</p>
        </div>
      </div>
    </div>

    <div class="table-responsive" style="overflow-x: initial">

      <table class="table table-striped table-bordered">
        <thead>
          <tr class="table-header">
            <th class="text-center vertical-center" style="width: 190px;white-space: nowrap;">Compentencies</th>
            <ng-container *ngFor="let data of reportData.orgComp.data">
              <th class="text-center vertical-center" style="white-space: nowrap;" *ngIf="data?._name">{{ data?._name }}
              </th>
            </ng-container>
            <th class="text-center vertical-center" style="width: 190px;white-space: nowrap;">Team Score</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let leadershiphead of reportData.orgComp.header">
            <ng-container *ngIf=" leadershiphead.key != '_'  && leadershiphead.key != '_email'">
              <td style="width: 100px" class="text-center table-header-blue"
                [ngStyle]="{'font-weight':leadershiphead.key === '_average' ? 'bold' : '' }">{{ leadershiphead.key ===
                '_average'?
                'Overall': leadershiphead.key }}
              </td>
              <ng-container *ngFor="let data of reportData.orgComp.data">
                <td class="text-center vertical-center" style="white-space: nowrap;"
                  [ngStyle]="{'font-weight':leadershiphead.key === '_average' ? 'bold' : '' }"
                  [ngClass]="getClassNameForScore(data[leadershiphead.key])" *ngIf="data && data[leadershiphead.key]">{{
                  getNameFromScore(data[leadershiphead.key]) }}</td>
              </ng-container>
              <td class="text-center vertical-center" style="white-space: nowrap;"
                [ngClass]="getClassNameForScore(leadershiphead['_average'] )">
                <b>{{getNameFromScore(leadershiphead['_average']) }}</b>
              </td>
            </ng-container>

          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>