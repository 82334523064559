<div class="WAPDTableBox">
  <div class="table-responsive">
    <table class="table table-border">
      <thead>
        <tr>
          <th colspan="5">
            <div class="container-primary">
              <div class="container-secondary">
                <div class="search-text TableInfo">
                  <h1>Candidate Email</h1>
                </div>

                <input
                  type="email"
                  class="search-input"
                  [formControl]="searchTerm"
                  (keyup.enter)="handleSearch($event)"
                />
              </div>

              <div class="submit-btn">
                <button (click)="handleSearch($event)" class="btn">
                  Search
                </button>
              </div>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <div class="searchResults" [ngClass]="{showScroll:searchResults.length > 4}">
          <tr class="tableRow" *ngFor="let result of searchResults"  role="button" (click)="handleCandidateSelect(result)">
            <td class="tableCell">
              <div class="TableInfo">
                <h2>
                  <span>Name</span>
                  <div>{{ result.name }}</div>
                </h2>
              </div>
            </td>

            <td class="tableCell">
              <div class="TableInfo">
                <h2>
                  <span>Email</span>
                  <div>{{ result.email }}</div>
                </h2>
              </div>
            </td>
            <td class="tableCell">
              <div class="TableInfo">
                <h2>
                  <span>Job Title</span>
                  <div>{{ result.assessment_name }}</div>
                </h2>
              </div>
            </td>
            <td class="tableCell">
              <div class="TableInfo">
                <h2>
                  <span>Type</span>
                  <div>{{ result.assessment_type }}</div>
                </h2>
              </div>
            </td>
            <td class="tableCell">
              <div class="TableInfo">
                <h2>
                  <span>Status</span>
                  <div>{{ result.status }}</div>
                </h2>
              </div>
            </td>
          </tr>
        </div>
      </tbody>
    </table>
  </div>
</div>
