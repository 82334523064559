import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AuthService } from '../shared-services/auth/auth.service';
import { ApiService } from '../shared-services/api/api/api.service';
import {
  trigger,
  transition,
  animate,
  style,
  stagger,
  query,
} from '@angular/animations';
import {
  AssessmentService,
  SelectedCohortType,
  TGetCohortByIdResponse,
} from '../assessment/assessment-service/assessment.service';
import { Router, ActivatedRoute } from '@angular/router';
import { SharedDataService } from '../shared-services/shared-data/shared-data.service';
import { ToasterService } from '../shared-services/toaster/toaster.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TRequestCohorts } from './cohort.interface';
import { forkJoin, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export interface IdashData {
  'Campus Recruitment': any;
  'Customised Profile Fit': any;
  'Learning & Development': any;
  'Organisational Match': any;
}
enum dashboardEnum {
  'campus-assesment-view' = 'Campus Recruitment',
  'job-fit-analysis-view' = 'Customised Profile Fit',
  'competency-assesment-view' = 'Learning & Development',
  'lnd-view-all' = 'Organisational Match',
}

@Component({
  selector: 'app-learning',
  templateUrl: './learning.component.html',
  styleUrls: ['./learning.component.scss'],
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [
        query('td', [
          style({ opacity: 0, transform: 'translateY(-100px)' }),
          stagger(-30, [
            animate(
              '500ms cubic-bezier(0.35, 0, 0.25, 1)',
              style({ opacity: 1, transform: 'none' }),
            ),
          ]),
        ]),
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('0.1s ease-in', style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class LearningComponent implements OnInit, OnDestroy {
  @ViewChild('deleteModal') private deleteModal;

  user: any;

  types = [
    'job-fit-analysis-view',
    'campus-assesment-view',
    'competency-assesment-view',
    'lnd-view-all',
  ];

  dashboardData: TRequestCohorts = { list: [], totalCount: 0 };

  showSettingOption = false;
  toggle: any;
  Json: any = JSON;
  showMoreType: any;
  showMoreFlags = {
    CampusRecruitment: false,
    CustomisedProfileFit: false,
    LearningDevelopment: false,
    OrganisationalMatch: false,
  };
  private _unsubscribe$ = new Subject<void>();
  totalCounts = { totalAssessment: 0, totalAttempted: 0, totalCandidates: 0 };
  isLoading = false;
  constructor(
    private _auth: AuthService,
    private _api: ApiService,
    private sharedService: SharedDataService,
    private _router: Router,
    private assessmentService: AssessmentService,
    private _toastr: ToasterService,
    private _modalService: NgbModal,
    private _route: ActivatedRoute,
  ) { }

  ngOnInit(): void {

    this.sharedService.setActiveTab('Orientation');

    if (this._route.snapshot.params.type) {
      if (this.types.includes(this._route.snapshot.params.type)) {
        this.ShowType();
      } else {
        this._router.navigate(['learning']);
      }
    }
    this._auth.currentUser.pipe(takeUntil(this._unsubscribe$)).subscribe((user) => {
      if (user && user.user) {
        this.user = user;
        this.getCohortData(user);
      } else {
        // this._auth.logout();
        this._router.navigate(['login']);
      }
    });
  }

  ngOnDestroy(): void {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  getCohortData(user, useCache = true) {
    // this should exists in all cases otherwise we will get error
    const companyId = user.company._id;
    let loggedUserAssessment = '';
    if (user?.user?.role === 'user') {
      loggedUserAssessment = user?.user?._id;

    } else {
      loggedUserAssessment = user?.user?.company_id;

    }

    // setting company id for creating new cohorts
    this.assessmentService.newCohortData.companyId = loggedUserAssessment;
    this.sharedService.companyId = loggedUserAssessment;
    const cachedData = this.sharedService.getCohortList();
    if (useCache && cachedData) {
      this.dashboardData = cachedData;
      this.initAllCount(cachedData);
    } else {
      this.getCohorts(loggedUserAssessment, companyId);
    }
  }

  getCohorts(loggedUserAssessment: string, companyId: string) {
    this.isLoading = true;

    // also fetching the company division data at the same time
    // TODO this might not be the best approach to fetch both data at the same time but will work for now
    forkJoin([
      this._api.getCohortsList(loggedUserAssessment),
      this._api.getDivisionForCompany(companyId),
    ]).pipe(takeUntil(this._unsubscribe$)).subscribe(
      (res) => {
        this.isLoading = false;
        const cohortData = res[0];
        const divisionData = res[1];

        this.sharedService.setCohortList(cohortData);
        this.sharedService.setCompanyDivisionData(divisionData);
        // this.assessmentService.useCachedCohorts = true;
        this.dashboardData = cohortData;
        this.initAllCount(cohortData);

      },
      (err) => {
        this.isLoading = false;

        console.log(err);
      },
    );
  }

  daysLeft(date) {
    const dayDiff: any =
      (new Date(date).getTime() - new Date().getTime()) / (24 * 60 * 60 * 1000);
    return dayDiff.toFixed(0);
  }

  listCandidate(item: SelectedCohortType) {
    // this create the issue to stale data, so commenting for now
    this.sharedService.setSelectedCohort(item);

    this._router.navigate(['candidate/list-candidates-learning'], {
      queryParams: { cohortId: item._id },
    });
  }

  // TODO - try material dropdown instead
  // tried using ngb-dropdowns but it breaks due to multiple instances and async operation
  hideToggle(e) {
    if (e.target.className === 'ShowIfo') {
      return false;
    } else {
      if (this.toggle) {
        this.toggle = {};
      }
    }
  }

  toggleOption(event, togglestatus) {
    togglestatus.toggle = !togglestatus.toggle;
    this.toggle = {};
    this.toggle = togglestatus;

    event.stopPropagation();
  }

  editAssessment(item) {
    this.assessmentService.setLastAddedCohortData(
      JSON.parse(JSON.stringify(item)),
    );
    this._router.navigate(['summary-learning'], { queryParams: { edit: true } });
  }

  duplicateAssessment(item) {
    // const duplicate = {
    //   competency_id: item.competency_id,
    //   competency_name: item.competency_name,
    //   domain_name: item.domain_name,
    //   job_requirment: item.job_requirment,
    //   job_title: '',
    //   job_type: item.job_type,
    //   profile_name: item.profile_name,
    //   start_time: item.start_time,
    //   start_datetime: item.start_datetime,
    //   end_time: item.start_time,
    //   end_datetime: item.end_datetime,
    //   created_by: this.user.user._id,
    //   company_id: this.user.user.company_id,
    //   assessment_type: item.assessment_type,
    //   assessment_typeCode: item.assessment_typeCode,
    //   isCloned: 'duplicate',
    // };

    const duplicate = JSON.parse(JSON.stringify(item));
    duplicate._id = '';
    duplicate.job_title = '';
    duplicate.companyId = this.user.user.company_id;

    duplicate.isCloned = 'duplicated';

    this.assessmentService.setLastAddedCohortData(duplicate);
    this._router.navigate(['job-title-learning'], {
      queryParams: { duplicate: true },
    });
  }

  showConfirm(e, item) {
    this._modalService
      .open(this.deleteModal, {
        ariaLabelledBy: 'modal-basic-title',
        centered: true,
      })
      .result.then(
        (result) => {
          const closeResult = `${result}`;
          if (closeResult === 'delete') {
            this._api.deleteCohort({ _id: item._id }).pipe(takeUntil(this._unsubscribe$)).subscribe((_1) => {
              // this.sharedService.clearCandidatesData();
              this.sharedService.clearLandingData();
              this.getCohortData(this.user, false);

              this._toastr.showSuccess('Group deleted Successfully');
            });
          }
        },
        (reason) => {
          console.log(reason);
        },
      );
  }

  redirectInviteCandidate() {
    this.assessmentService.clearAssessmentData();
    this._router.navigate(['job-title-learning']);
  }

  getCandidateCompletedCount(item) {
    // cohortData.list.reduce((acc, cohort) => acc + cohort.candidates.reduce((total, cand) => {if(cand.gameStatus==="in-progress"){return total + 1}return total}, 0), 0)
    return item.candidates.reduce((total, cand) => {
      if (cand.gameStatus === 'completed') {
        return total + 1;
      }
      return total;
    }, 0);
  }

  addCandidates(item: TGetCohortByIdResponse) {
    if (this.daysLeft(item.endDate) < 0) {
      this._toastr.showError(
        'This job is expired, you can\'t add more candidates.',
      );
    } else {
      this.sharedService.setSelectedCohort(item);
      this.assessmentService.setLastAddedCohortData(item);

      this._router.navigate(['candidate/add-candidates-learning'], {
        queryParams: { cohortId: item._id },
      });
    }
  }

  logout() {
    this._auth.logout();
  }
  ShowType() {
    this.showMoreType = this._route.snapshot.params.type;
  }

  switchViewAll(type) {
    this._router.navigate(['dashboard/' + type]);
  }

  getCandidateCounts(index, option = 'All') {
    let totalUsers = 0;
    let totalAttempted = 0;
    this.dashboardData[index].forEach((assessment) => {
      totalUsers += assessment.totalCandidates;
      totalAttempted += assessment.totalAttempted;
    });
    if (option !== 'All') {
      return totalAttempted;
    } else {
      return totalUsers;
    }
  }

  initAllCount(data: TRequestCohorts) {
    this.totalCounts.totalAssessment = data.totalCount;
    this.totalCounts.totalAttempted = data.list.reduce(
      (accOuter, cohort) =>
        accOuter +
        cohort.candidates.reduce(
          (accInner, candidate) =>
            accInner + ((candidate.gameStatus === 'completed' || candidate.gameStatus === 'in-progress') ? 1 : 0),
          0,
        ),
      0,
    );
    this.totalCounts.totalCandidates = data.list.reduce((acc, cohort) => {
      return acc + cohort.candidates.length;
    }, 0);
  }

  scrollTop() {
    const scrollToTop = window.setInterval(() => {
      const pos = window.pageYOffset;
      if (pos > 0) {
        window.scrollTo(0, 0); // how far to scroll on each step
      } else {
        window.clearInterval(scrollToTop);
      }
    }, 16);
  }
}
