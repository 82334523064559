import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { AuthService, IToken } from '../shared-services/auth/auth.service';
import { BehaviorSubject } from 'rxjs';
import { ApiBaseService } from '../shared-services/api/api-base/api-base.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup = this._formBuilder.group({
    email: ['', [Validators.required, Validators.email]],
    password: ['', [Validators.required, Validators.minLength(6)]],
  });

  isLoading = false;
  errors = '';
  constructor(
    private _formBuilder: FormBuilder,
    private _auth: AuthService,
    private _api: ApiBaseService,
  ) {}

  ngOnInit(): void {}
  onSubmit() {
    this.isLoading = true;
    // this._auth.signinUser(this.loginForm.value);
    this._api
      .post('auth/local/', {
        email: this.loginForm.value.email,
        password: this.loginForm.value.password,
      })
      .subscribe(
        (user) => {
          this.isLoading = false;
          if (user?.token) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('currentUser', JSON.stringify(user));
            this._auth.currentUserTokenSubject = new BehaviorSubject<IToken>(
              user,
            );
            this._auth.getUserInfo();
            return user;
          }
        },
        (err) => {
          console.log(err);
          this.errors = 'invalid username or password';
          this.isLoading = false;
          // this._toast.showError(err?.error?.message);
        },
      );
  }
}
