<div class="loading" *ngIf="isLoading">Loading&#8230;</div>
<div (click)="hideToggle($event)" role="none">
  <div>
    <div class="HeadStrip">
      <div class="container">
        <div class="WAA-Ca-Hed">
          <div class="row">
            <div class="col-md-2 hidden-xs hidden-sm">
              <div class="WebAppHedding1">Home</div>
            </div>
            <div class="col-md-10 col-lg-10 col-sm-12 col-xs-12">
              <div class="DashboardNum">
                <div class="DNumbering">{{ totalCounts?.totalAssessment }}</div>
                <!--  DNumbering  -->
                <div class="DNInfo DNInfo1">Assessments</div>
                <!-- DInfo -->
              </div>
              <div class="DashboardNum">
                <div class="DNumbering">{{ totalCounts?.totalCandidates }}</div>
                <!--  DNumbering  -->
                <div class="DNInfo DNInfo2">
                  Candidates <br />
                  invited
                </div>
                <!-- DInfo -->
              </div>
              <div class="DashboardNum Last">
                <div class="DNumbering">{{ totalCounts?.totalAttempted }} </div>
                <!--  DNumbering  -->
                <div class="DNInfo DNInfo3">
                  Candidates <br />
                  Evaluated
                </div>
                <!-- DInfo -->
              </div>
            </div>
          </div>
          <!--  row  -->
        </div>
        <div class="row"></div>
      </div>
      <!--  container  -->
    </div>
    <!--  HeadStrip -->

    <!--  Start Section  -->
    <section>
      <div class="container">
        <div class="row">
          <div class="col-md-12" style="z-index: 99">
            <div class="TableAd" (click)="redirectInviteCandidate()">
              <div class="AddButtone">Add New Assessment</div>
            </div>
          </div>

          <div class="col-md-12">
            <!-- Search bar starts -->

          <app-search-bar (onLoading)="handleSearchLoading($event)" (onCandidateSelected)="handleSearchCandidate($event)"></app-search-bar>

          <!-- Search bar ends -->

            <div
              *ngIf="
                (showMoreType && showMoreType === 'job-fit-analysis-view') ||
                !showMoreType
              "
              class="WAPDTableBox"
            >
              <div class="table-responsive">
                <table class="table table-border">
                  <thead>
                    <tr>
                      <th colspan="2">
                        <div class="TableInfo">
                          <h1>Customised Profile Fit</h1>
                        </div>
                      </th>
                      <th colspan="2" class="text-right">
                        <div class="TotalNo">
                          Total no. of assessments
                          <span>{{
                            dashboardData['Customised Profile Fit']?.length ==
                            null
                              ? 0
                              : dashboardData['Customised Profile Fit']?.length
                          }}</span>
                        </div>
                      </th>
                      <th width="15%" class="text-center">
                        <div class="TableInfo">
                          <span
                            *ngIf="
                              dashboardData['Customised Profile Fit']?.length >
                                3 && !showMoreType
                            "
                            ><a
                              href="javascript: void(0);"
                              (click)="switchViewAll('job-fit-analysis-view')"
                              >View All</a
                            ></span
                          >
                        </div>
                        <!-- <div class="TableInfo">
                          <span
                            *ngIf="
                              dashboardData['Customised Profile Fit']?.length >
                                3 && showMoreFlags.CustomisedProfileFit
                            "
                            ><a
                              href="javascript: void(0);"
                              (click)="switchViewAll('CustomisedProfileFit')"
                              >View Less</a
                            ></span
                          >
                        </div> -->
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      [@inOutAnimation]
                      *ngFor="
                        let item of showMoreType
                          ? (dashboardData['Customised Profile Fit']
                            | orderBy: 'created_at':'desc')
                          : (dashboardData['Customised Profile Fit']
                            | orderBy: 'created_at':'desc'
                            | slice: 0:3);
                        let i = index
                      "
                    >
                      <td width="18%">
                        <div class="TableInfo">
                          <h2>
                            <span>Job Title</span>
                            <div role="button" (click)="listCandidate(item)">
                              {{ item?.job_title }}
                            </div>
                          </h2>
                        </div>
                      </td>
                      <td width="38%">
                        <div class="TableInfo">
                          <h2>
                            <span>No. of candidates evaluated </span
                            >{{ item?.totalAttempted }}
                          </h2>
                        </div>
                      </td>
                      <td width="13%">
                        <div class="TableInfo">
                          <p>Created on</p>
                          <p>{{ item.created_at | date: 'dd-MM-yyyy' }}</p>
                        </div>
                      </td>
                      <td width="16%">
                        <div class="TableInfo">
                          <p *ngIf="daysLeft(item.end_datetime) < 0">
                            <span> Expired </span>
                          </p>
                          <p *ngIf="daysLeft(item.end_datetime) >= 0">
                            <span>
                              {{ daysLeft(item.end_datetime) }} days left</span
                            >
                          </p>
                          <p>Valid Till</p>
                          <p>{{ item.end_datetime | date: 'dd-MM-yyyy' }}</p>
                        </div>
                      </td>
                      <td>
                        <div class="TableInfo">
                          <a
                            href="javascript: void(0);"
                            (click)="addCandidates(item)"
                            title="Invite Candidates"
                            ><div class="AdUser"></div
                          ></a>
                          <a
                            href="javascript: void(0);"
                            (click)="toggleOption($event, item)"
                            id="toggle-sh-bu"
                            title="Options"
                            ><div class="ShowIfo"></div
                          ></a>
                          <a title="View details"
                            ><div
                              class="RightA"
                              (click)="listCandidate(item)"
                            ></div
                          ></a>
                        </div>

                        <div
                          *ngIf="
                            item.toggle &&
                            Json.stringify(item) == Json.stringify(toggle) &&
                            Json.stringify(item) == Json.stringify(toggle)
                          "
                          id="toggle-hs"
                          class="DetailBox exclude"
                        >
                          <div class="box-arrow"></div>
                          <ul>
                            <li (click)="editAssessment(item)">
                              <a href="javascript: void(0);">Edit</a>
                            </li>
                            <li (click)="duplicateAssessment(item)">
                              <a href="javascript: void(0);">Duplicate</a>
                            </li>
                            <li (click)="showConfirm($event, item)">
                              <a href="javascript: void(0);">Delete</a>
                            </li>
                          </ul>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div
              *ngIf="
                (showMoreType && showMoreType === 'campus-assesment-view') ||
                !showMoreType
              "
              class="WAPDTableBox"
            >
              <div class="table-responsive">
                <table class="table table-border">
                  <thead>
                    <tr>
                      <th colspan="2">
                        <div class="TableInfo"><h1>Campus Recruitment</h1></div>
                      </th>
                      <th colspan="2" class="text-right">
                        <div class="TotalNo">
                          Total no. of assessments
                          <span>{{
                            dashboardData['Campus Recruitment']?.length == null
                              ? 0
                              : dashboardData['Campus Recruitment']?.length
                          }}</span>
                        </div>
                      </th>
                      <th width="15%" class="text-center">
                        <div class="TableInfo">
                          <span
                            *ngIf="
                              dashboardData['Campus Recruitment']?.length > 3 &&
                              !showMoreType
                            "
                            ><a
                              href="javascript: void(0);"
                              (click)="switchViewAll('campus-assesment-view')"
                              >View All</a
                            ></span
                          >
                        </div>
                        <!-- <div class="TableInfo">
                          <span
                            *ngIf="
                              dashboardData['Campus Recruitment']?.length > 3 &&
                              showMoreFlags.CampusRecruitment
                            "
                            ><a
                              href="javascript: void(0);"
                              (click)="switchViewAll('CampusRecruitment')"
                              >View Less</a
                            ></span
                          >
                        </div> -->
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      [@inOutAnimation]
                      *ngFor="
                        let item of showMoreType
                          ? (dashboardData['Campus Recruitment']
                            | orderBy: 'created_at')
                          : (dashboardData['Campus Recruitment']
                            | orderBy: 'created_at'
                            | slice: 0:3);
                        let i = index
                      "
                    >
                      <td width="18%">
                        <div class="TableInfo">
                          <h2>
                            <span>Job Title</span>
                            <div role="button" (click)="listCandidate(item)">
                              {{ item?.job_title }}
                            </div>
                          </h2>
                        </div>
                      </td>
                      <td width="38%">
                        <div class="TableInfo">
                          <h2>
                            <span>No. of candidates evaluated </span
                            >{{ item?.totalAttempted }}
                          </h2>
                        </div>
                      </td>
                      <td width="13%">
                        <div class="TableInfo">
                          <p>Created on</p>
                          <p>{{ item.created_at | date: 'dd-MM-yyyy' }}</p>
                        </div>
                      </td>
                      <td width="16%">
                        <div class="TableInfo">
                          <p *ngIf="daysLeft(item.end_datetime) < 0">
                            <span> Expired </span>
                          </p>
                          <p *ngIf="daysLeft(item.end_datetime) >= 0">
                            <span>
                              {{ daysLeft(item.end_datetime) }} days left</span
                            >
                          </p>
                          <p>Valid Till</p>
                          <p>{{ item.end_datetime | date: 'dd-MM-yyyy' }}</p>
                        </div>
                      </td>
                      <td>
                        <div class="TableInfo">
                          <a
                            href="javascript: void(0);"
                            (click)="addCandidates(item)"
                            title="Invite Candidates"
                            ><div class="AdUser"></div
                          ></a>
                          <a
                            href="javascript: void(0);"
                            (click)="toggleOption($event, item)"
                            title="Options"
                          >
                            <div class="ShowIfo"></div
                          ></a>
                          <a title="View details"
                            ><div
                              class="RightA"
                              (click)="listCandidate(item)"
                            ></div
                          ></a>
                        </div>

                        <div
                          *ngIf="
                            item.toggle &&
                            Json.stringify(item) == Json.stringify(toggle)
                          "
                          id="toggle-hs"
                          class="DetailBox exclude"
                        >
                          <div class="box-arrow"></div>
                          <ul>
                            <li (click)="editAssessment(item)">
                              <a href="javascript: void(0);">Edit</a>
                            </li>
                            <li (click)="duplicateAssessment(item)">
                              <a href="javascript: void(0);">Duplicate</a>
                            </li>
                            <li (click)="showConfirm($event, item)">
                              <a href="javascript: void(0);">Delete</a>
                            </li>
                          </ul>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div
              *ngIf="
                (showMoreType &&
                  showMoreType === 'competency-assesment-view') ||
                !showMoreType
              "
              class="WAPDTableBox"
            >
              <div class="table-responsive">
                <table class="table table-border">
                  <thead>
                    <tr>
                      <th colspan="2">
                        <div class="TableInfo">
                          <h1>Organisational Match</h1>
                        </div>
                      </th>
                      <th colspan="2" class="text-right">
                        <div class="TotalNo">
                          Total no. of assessments
                          <span>{{
                            dashboardData['Organisational Match']?.length ==
                            null
                              ? 0
                              : dashboardData['Organisational Match']?.length
                          }}</span>
                        </div>
                      </th>
                      <th width="15%" class="text-center">
                        <div class="TableInfo">
                          <span
                            *ngIf="
                              dashboardData['Organisational Match']?.length >
                                3 && !showMoreType
                            "
                            ><a
                              href="javascript: void(0);"
                              (click)="
                                switchViewAll('competency-assesment-view')
                              "
                              >View All</a
                            ></span
                          >
                        </div>
                        <!-- <div class="TableInfo">
                          <span
                            *ngIf="
                              dashboardData['Organisational Match']?.length >
                                3 && showMoreFlags.OrganisationalMatch
                            "
                            ><a
                              href="javascript: void(0);"
                              (click)="switchViewAll('OrganisationalMatch')"
                              >View Less</a
                            ></span
                          >
                        </div> -->
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      [@inOutAnimation]
                      *ngFor="
                        let item of showMoreType
                          ? (dashboardData['Organisational Match']
                            | orderBy: 'created_at')
                          : (dashboardData['Organisational Match']
                            | orderBy: 'created_at'
                            | slice: 0:3)
                      "
                    >
                      <td width="18%">
                        <div class="TableInfo">
                          <h2>
                            <span>Job Title</span>
                            <div role="button" (click)="listCandidate(item)">
                              {{ item?.job_title }}
                            </div>
                          </h2>
                        </div>
                      </td>
                      <td width="38%">
                        <div class="TableInfo">
                          <h2>
                            <span>No. of candidates evaluated </span
                            >{{ item?.totalAttempted }}
                          </h2>
                        </div>
                      </td>
                      <td width="13%">
                        <div class="TableInfo">
                          <p>Created on</p>
                          <p>{{ item.created_at | date: 'dd-MM-yyyy' }}</p>
                        </div>
                      </td>
                      <td width="16%">
                        <div class="TableInfo">
                          <p *ngIf="daysLeft(item.end_datetime) < 0">
                            <span> Expired </span>
                          </p>
                          <p *ngIf="daysLeft(item.end_datetime) >= 0">
                            <span>
                              {{ daysLeft(item.end_datetime) }} days left</span
                            >
                          </p>
                          <p>Valid Till</p>
                          <p>{{ item.end_datetime | date: 'dd-MM-yyyy' }}</p>
                        </div>
                      </td>
                      <td>
                        <div class="TableInfo">
                          <a
                            href="javascript: void(0);"
                            (click)="addCandidates(item)"
                            title="Invite Candidates"
                            ><div class="AdUser"></div
                          ></a>
                          <a
                            href="javascript: void(0);"
                            (click)="toggleOption($event, item)"
                            id="toggle-sh-bu"
                            title="Options"
                            ><div class="ShowIfo"></div
                          ></a>
                          <a title="View details"
                            ><div
                              class="RightA"
                              (click)="listCandidate(item)"
                            ></div
                          ></a>
                        </div>

                        <div
                          *ngIf="
                            item.toggle &&
                            Json.stringify(item) == Json.stringify(toggle)
                          "
                          id="toggle-hs"
                          class="DetailBox exclude"
                        >
                          <div class="box-arrow"></div>
                          <ul>
                            <li (click)="editAssessment(item)">
                              <a href="javascript: void(0);">Edit</a>
                            </li>
                            <li (click)="duplicateAssessment(item)">
                              <a href="javascript: void(0);">Duplicate</a>
                            </li>
                            <li (click)="showConfirm($event, item)">
                              <a href="javascript: void(0);">Delete</a>
                            </li>
                          </ul>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div
              *ngIf="
                (showMoreType && showMoreType === 'lnd-view-all') ||
                !showMoreType
              "
              class="WAPDTableBox"
            >
              <div class="table-responsive">
                <table class="table table-border">
                  <thead>
                    <tr>
                      <th colspan="2">
                        <div class="TableInfo">
                          <h1>Learning & Development</h1>
                        </div>
                      </th>
                      <th colspan="2" class="text-right">
                        <div class="TotalNo">
                          Total no. of assessments
                          <span>{{
                            dashboardData['Learning & Development']?.length ==
                            null
                              ? 0
                              : dashboardData['Learning & Development']?.length
                          }}</span>
                        </div>
                      </th>
                      <th width="15%" class="text-center">
                        <div class="TableInfo">
                          <span
                            *ngIf="
                              dashboardData['Learning & Development']?.length >
                                3 && !showMoreType
                            "
                            ><a
                              href="javascript: void(0);"
                              (click)="switchViewAll('lnd-view-all')"
                              >View All</a
                            ></span
                          >
                        </div>
                        <!-- <div class="TableInfo">
                        <span
                          *ngIf="
                            dashboardData['Learning & Development']?.length >
                              3 && showMoreFlags.LearningDevelopment
                          "
                          ><a
                            href="javascript: void(0);"
                            (click)="switchViewAll('LearningDevelopment')"
                            >View Less</a
                          ></span
                        >
                      </div> -->
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      [@inOutAnimation]
                      *ngFor="
                        let item of showMoreType
                          ? (dashboardData['Learning & Development']
                            | orderBy: 'created_at')
                          : (dashboardData['Learning & Development']
                            | orderBy: 'created_at'
                            | slice: 0:3)
                      "
                    >
                      <td width="18%">
                        <div class="TableInfo">
                          <h2>
                            <span>Job Title</span>
                            <div role="button" (click)="listCandidate(item)">
                              {{ item?.job_title }}
                            </div>
                          </h2>
                        </div>
                      </td>
                      <td width="38%">
                        <div class="TableInfo">
                          <h2>
                            <span>No. of candidates evaluated </span
                            >{{ item?.totalAttempted }}
                          </h2>
                        </div>
                      </td>
                      <td width="13%">
                        <div class="TableInfo">
                          <p>Created on</p>
                          <p>{{ item.created_at | date: 'dd-MM-yyyy' }}</p>
                        </div>
                      </td>
                      <td width="16%">
                        <div class="TableInfo">
                          <p *ngIf="daysLeft(item.end_datetime) < 0">
                            <span> Expired </span>
                          </p>
                          <p *ngIf="daysLeft(item.end_datetime) >= 0">
                            <span>
                              {{ daysLeft(item.end_datetime) }} days left</span
                            >
                          </p>
                          <p>Valid Till</p>
                          <p>{{ item.end_datetime | date: 'dd-MM-yyyy' }}</p>
                        </div>
                      </td>
                      <td>
                        <div class="TableInfo">
                          <a
                            href="javascript: void(0);"
                            (click)="addCandidates(item)"
                            title="Invite Candidates"
                            ><div class="AdUser"></div
                          ></a>
                          <a
                            href="javascript: void(0);"
                            (click)="toggleOption($event, item)"
                            id="toggle-sh-bu"
                            title="Options"
                            ><div class="ShowIfo"></div
                          ></a>
                          <a title="View details"
                            ><div
                              class="RightA"
                              (click)="listCandidate(item)"
                            ></div
                          ></a>
                        </div>

                        <div
                          *ngIf="
                            item.toggle &&
                            Json.stringify(item) == Json.stringify(toggle)
                          "
                          id="toggle-hs"
                          class="DetailBox exclude"
                        >
                          <div class="box-arrow"></div>
                          <ul>
                            <li (click)="editAssessment(item)">
                              <a href="javascript: void(0);">Edit</a>
                            </li>
                            <li (click)="duplicateAssessment(item)">
                              <a href="javascript: void(0);">Duplicate</a>
                            </li>
                            <li (click)="showConfirm($event, item)">
                              <a href="javascript: void(0);">Delete</a>
                            </li>
                          </ul>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div
            *ngIf="
              (showMoreType && showMoreType === 'aptitude-view') ||
              !showMoreType
            "
            class="WAPDTableBox"
          >
            <div class="table-responsive">
              <table class="table table-border">
                <thead>
                  <tr>
                    <th colspan="2">
                      <div class="TableInfo">
                        <h1>Aptitude Assessments</h1>
                      </div>
                    </th>
                    <th colspan="2" class="text-right">
                      <div class="TotalNo">
                        Total no. of assessments
                        <span>{{
                          dashboardData['aptiAssessmentData']?.length ==
                          null
                            ? 0
                            : dashboardData['aptiAssessmentData']?.length
                        }}</span>
                      </div>
                    </th>
                    <th width="15%" class="text-center">
                      <div class="TableInfo">
                        <span
                          *ngIf="
                            dashboardData['aptiAssessmentData']?.length >
                              3 && !showMoreType
                          "
                          ><a
                            href="javascript: void(0);"
                            (click)="switchViewAll('aptitude-view')"
                            >View All</a
                          ></span
                        >
                      </div>
                      <!-- <div class="TableInfo">
                      <span
                        *ngIf="
                          dashboardData['aptiAssessmentData']?.length >
                            3 && showMoreFlags.LearningDevelopment
                        "
                        ><a
                          href="javascript: void(0);"
                          (click)="switchViewAll('LearningDevelopment')"
                          >View Less</a
                        ></span
                      >
                    </div> -->
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    [@inOutAnimation]
                    *ngFor="
                      let item of showMoreType
                        ? (dashboardData['aptiAssessmentData']
                          | orderBy: 'createdAt')
                        : (dashboardData['aptiAssessmentData']
                          | orderBy: 'createdAt'
                          | slice: 0:3)
                    "
                  >
                    <td width="18%">
                      <div class="TableInfo">
                        <h2>
                          <span>Job Title</span>
                          <div role="button" (click)="listCandidate(item)">
                            {{ item?.name }}
                          </div>
                        </h2>
                      </div>
                    </td>
                    <td width="38%">
                      <div class="TableInfo">
                        <h2>
                          <span>No. of candidates evaluated</span
                          >{{ getEvaluatedCandidates(item.candidates) }}
                        </h2>
                      </div>
                    </td>
                    <td width="13%">
                      <div class="TableInfo">
                        <p>Created on</p>
                        <p>{{ item.createdAt | date: 'dd-MM-yyyy' }}</p>
                      </div>
                    </td>
                    <td width="16%">
                      <div class="TableInfo">
                        <p *ngIf="daysLeft(item.endDate) < 0">
                          <span> Expired </span>
                        </p>
                        <p *ngIf="daysLeft(item.endDate) >= 0">
                          <span>
                            {{ daysLeft(item.endDate) }} days left</span
                          >
                        </p>
                        <p>Valid Till</p>
                        <p>{{ item.endDate | date: 'dd-MM-yyyy' }}</p>
                      </div>
                    </td>
                    <td>
                      <div class="TableInfo">
                        <a
                          href="javascript: void(0);"
                          (click)="addCandidates(item)"
                          title="Invite Candidates"
                          ><div class="AdUser"></div
                        ></a>
                        <a
                          href="javascript: void(0);"
                          (click)="toggleOption($event, item)"
                          id="toggle-sh-bu"
                          title="Options"
                          ><div class="ShowIfo"></div
                        ></a>
                        <a title="View details"
                          ><div
                            class="RightA"
                            (click)="listCandidate(item)"
                          ></div
                        ></a>
                      </div>

                      <div
                        *ngIf="
                          item.toggle &&
                          Json.stringify(item) == Json.stringify(toggle)
                        "
                        id="toggle-hs"
                        class="DetailBox exclude"
                      >
                        <div class="box-arrow"></div>
                        <ul>
                          <li (click)="editAssessment(item)">
                            <a href="javascript: void(0);">Edit</a>
                          </li>
                          <li (click)="duplicateAssessment(item)">
                            <a href="javascript: void(0);">Duplicate</a>
                          </li>
                          <li (click)="showConfirm($event, item)">
                            <a href="javascript: void(0);">Delete</a>
                          </li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

            <!-- <div class="WAPDTableBox">
                <div class="table-responsive">
                  <table class="table table-border">
                    <thead>
                      <tr>
                        <th colspan="2"><div class="TableInfo"><h1>Campus Recruitment</h1></div></th>
                        <th colspan="2" class="text-right">
                          <div class="TotalNo"> Total no. of assessments <span>{{ dashboardData['Campus Recruitment'].length==null ? 0 :  dashboardData['Campus Recruitment'].length}}</span> </div>
                        </th>
                        <th width="15%" class="text-center">
                          <div class="TableInfo"><span *ngIf="dashboardData['Campus Recruitment'].length > 3"><a href="javascript: void(0);" (click)="
                          campusAssesmentView()">
                          View all</a></span></div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr ng-repeat="item in dashboardData['Campus Recruitment'] | orderBy:'created_at' | limitTo: 3"  >
                        <td width="18%">
                          <div class="TableInfo">
                            <h2>
                              <span>Job Title</span>
                              <div (click)="listCandidate(item)"> {{ item?.job_title }} </div>
                            </h2>
                          </div>
                        </td>
                        <td width="38%">
                          <div class="TableInfo"><h2><span>No. of candidates evaluated</span>{{ item.__v }}</h2></div>
                        </td>
                        <td width="13%">
                          <div class="TableInfo">
                            <p> Created on </p>
                            <p>{{ convertDate(item.created_at) }}</p>
                          </div>
                        </td>
                        <td width="16%">
                          <div class="TableInfo">
                            <p *ngIf="daysLeft( item.end_datetime ) < 0"><span> Expired </span></p>
                            <p *ngIf="daysLeft( item.end_datetime ) >= 0"><span> {{ daysLeft( item.end_datetime ) }} days left </span></p>
                            <p> Valid Till </p>
                            <p>{{ convertDate(item.end_datetime) }}</p>
                          </div>
                        </td>
                        <td >
                          <div class="TableInfo" ng-init="item.toggle && Json.stringify(item) == Json.stringify(toggle)=false">
                            <a href="javascript: void(0);" (click)="addCandidates(item)"  title="Invite Candidates"><div class="AdUser"></div></a>
                            <a href="javascript: void(0);" (click)="toggleOption($event,item)" id="toggle-sh-bu"  title="Options">
                              <div class="ShowIfo"></div>
                            </a>
                            <a  href="javascript: void(0);" (click)="listCandidate(item)" title="View details"><div class="RightA" ></div></a>
                          </div>
                          <div  *ngIf="item.toggle && Json.stringify(item) == Json.stringify(toggle)" id="toggle-hs" class="DetailBox">
                            <ul>
                              <li (click)="editAssessment(item)"><a href="javascript: void(0);" >Edit</a></li>
                              <li (click)="duplicateAssessment(item)">
                                <a href="javascript: void(0);">Duplicate</a>
                              </li>
                              <li (click)="showConfirm($event, item)"><a href="javascript: void(0);">Delete</a></li>
                            </ul>
                          </div>

                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div> -->

            <!-- <div class="WAPDTableBox">
                <div class="table-responsive">
                  <table class="table table-border">
                    <thead>
                      <tr>
                        <th colspan="2"><div class="TableInfo"><h1>Organisational Match</h1></div></th>
                        <th colspan="2" class="text-right">
                          <div class="TotalNo"> Total no. of assessments <span>{{ dashboardData['Organisational Match'].length == null ? 0 : dashboardData['Organisational Match'].length }}</span> </div>
                        </th>
                        <th width="15%" class="text-center">
                          <div class="TableInfo"><span *ngIf="dashboardData['Organisational Match'].length > 3"><a href="javascript: void();" (click)="competencyView('Organisational Match')">View all</a></span></div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr ng-repeat="item in dashboardData['Organisational Match'] | orderBy:'created_at' | limitTo: 3">
                        <td width="18%">
                          <div class="TableInfo">
                            <h2>
                              <span>Job Title</span>
                              <div (click)="listCandidate(item)"> {{ item?.job_title }} </div>
                            </h2>
                          </div>
                        </td>
                        <td width="38%">
                          <div class="TableInfo">
                            <h2><span>No. of candidates evaluated</span>{{ item.__v }}</h2>
                          </div>
                        </td>
                        <td width="13%">
                          <div class="TableInfo">
                            <p>Created on</p>
                            <p>{{ convertDate(item.created_at) }}</p>
                          </div>
                        </td>
                        <td width="16%">
                          <div class="TableInfo">
                            <p *ngIf="daysLeft( item.end_datetime ) < 0"><span> Expired </span></p>
                            <p *ngIf="daysLeft( item.end_datetime ) >= 0"><span> {{ daysLeft( item.end_datetime ) }}  days left</span></p>
                            <p>Valid Till</p>
                            <p>{{ convertDate(item.end_datetime) }}</p>
                          </div>
                        </td>
                        <td>
                          <div class="TableInfo" ng-init="item.toggle && Json.stringify(item) == Json.stringify(toggle)=false">
                            <a href="javascript: void(0);" (click)="addCandidates(item)"  title="Invite Candidates"><div class="AdUser"></div></a>
                            <a href="javascript: void(0);" (click)="toggleOption($event,item)" id="toggle-sh-bu"  title="Options"><div class="ShowIfo"></div></a>
                            <a  href="javascript: void(0);" (click)="listCandidate(item)" title="View details"><div class="RightA" ></div></a>
                          </div>
                          <div  *ngIf="item.toggle && Json.stringify(item) == Json.stringify(toggle)" id="toggle-hs" class="DetailBox">
                            <ul>
                              <li (click)="editAssessment(item)"><a href="javascript: void(0);" >Edit</a></li>
                              <li (click)="duplicateAssessment(item)">
                                <a href="javascript: void(0);">Duplicate</a>
                              </li>
                              <li (click)="showConfirm($event, item)"><a href="javascript: void(0);">Delete</a></li>
                            </ul>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div> -->
            <!--learning & development -->
            <!-- <div class="WAPDTableBox">
                <div class="table-responsive">
                  <table class="table table-border">
                    <thead>
                      <tr>
                        <th colspan="2"><div class="TableInfo"><h1>Learning & Development</h1></div></th>
                        <th colspan="2" class="text-right">
                          <div class="TotalNo"> Total no. of assessments <span>{{ dashboardData['Learning & Development'].length == null ? 0 : dashboardData['Learning & Development'].length }}</span> </div>
                        </th>
                        <th width="15%" class="text-center">
                          <div class="TableInfo"><span *ngIf="dashboardData['Learning & Development'].length > 3"><a href="javascript: void();" (click)="viewLNDAssessments('Learning & Development')">View all</a></span></div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr ng-repeat="item in dashboardData['Learning & Development'] | orderBy:'created_at' | limitTo: 3">
                        <td width="18%">
                          <div class="TableInfo">
                            <h2>
                              <span>Job Title</span>
                              <div (click)="listCandidate(item)"> {{ item?.job_title }} </div>
                            </h2>
                          </div>
                        </td>
                        <td width="38%">
                          <div class="TableInfo">
                            <h2><span>No. of candidates evaluated</span>{{ item.__v }}</h2>
                          </div>
                        </td>
                        <td width="13%">
                          <div class="TableInfo">
                            <p>Created on</p>
                            <p>{{ convertDate(item.created_at) }}</p>
                          </div>
                        </td>
                        <td width="16%">
                          <div class="TableInfo">
                            <p *ngIf="daysLeft( item.end_datetime ) < 0"><span> Expired </span></p>
                            <p *ngIf="daysLeft( item.end_datetime ) >= 0"><span> {{ daysLeft( item.end_datetime ) }}  days left</span></p>
                            <p>Valid Till</p>
                            <p>{{ convertDate(item.end_datetime) }}</p>
                          </div>
                        </td>
                        <td>
                          <div class="TableInfo" ng-init="item.toggle && Json.stringify(item) == Json.stringify(toggle)=false">
                            <a href="javascript: void(0);" (click)="addCandidates(item)"  title="Invite Candidates"><div class="AdUser"></div></a>
                            <a href="javascript: void(0);" (click)="toggleOption($event,item)" id="toggle-sh-bu"  title="Options"><div class="ShowIfo"></div></a>
                            <a  href="javascript: void(0);" (click)="listCandidate(item)" title="View details"><div class="RightA" ></div></a>
                          </div>
                          <div  *ngIf="item.toggle && Json.stringify(item) == Json.stringify(toggle)" id="toggle-hs" class="DetailBox">
                            <ul>
                              <li (click)="editAssessment(item)"><a href="javascript: void(0);" >Edit</a></li>
                              <li (click)="duplicateAssessment(item)">
                                <a href="javascript: void(0);">Duplicate</a>
                              </li>
                              <li (click)="showConfirm($event, item)"><a href="javascript: void(0);">Delete</a></li>
                            </ul>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div> -->

            <!--assessmen type row end here-->
          </div>
        </div>
        <!-- row  -->
      </div>

      <ng-template #deleteModal let-modal>
        <div class="modal-body">
          <h6 class="pop-title">Delete Assessment</h6>
          <p style="text-align: left">
            Are you sure you want to delete the assessment?
          </p>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-light btn-blue"
            (click)="modal.close('Close click')"
            tabindex='-1'
          >
            CANCEL
          </button>
          <button
            type="button"
            class="btn btn-light btn-blue"
            (click)="modal.close('delete')"
            tabindex='-1'
          >
            DELETE
          </button>
        </div>
        <!-- <div style="text-align: left;" class="modal-body">
          <h4>Delete Assessment</h4>
          <p>Are you sure you want to delete the assessment?</p>
        </div>
        <div class="modal-footer">
          <button
          type="button"
          class="btn btn-link"
          (click)="modal.close('delete')"
        >
          Delete
        </button>
          <button
          type="button"
          class="btn btn-link"
          (click)="modal.close('Close click')"
        >
          Close
        </button>
        </div> -->
      </ng-template>

      <!--   oops Popup  -->
      <!-- <div class="modal fade delet-popup" id="oops" role="dialog">
              <div class="modal-dialog">
                   <div class="modal-content">
                        <div class="modal-header"><div class="oops-ico"></div></div>
                        <div class="modal-body">
                             <h2>Oops! </h2>
                             <p>You cannot proceed without <br />selecting 15 categories</p>
                             <div class="row Link">
                                  <div class="col-md-8 col-md-offset-2"><a href="#" class="active">Ok</a></div>
                             </div>
                        </div>
                   </div>
              </div>
         </div> -->
    </section>
  </div>
</div>
