<div class="loading" *ngIf="isLoading">Loading&#8230;</div>
<div (click)="hideToggle($event)" role="none">
  <div>
    <div class="HeadStrip">
      <div class="container">
        <div class="WAA-Ca-Hed">
          <div class="row">
            <div class="col-md-2 hidden-xs hidden-sm">
              <div class="WebAppHedding1">Home</div>
            </div>
            <div class="col-md-10 col-lg-10 col-sm-12 col-xs-12">
              <div class="DashboardNum">
                <div class="DNumbering">{{ totalCounts?.totalAssessment }}</div>
                <!--  DNumbering  -->
                <div class="DNInfo DNInfo1">Groups</div>
                <!-- DInfo -->
              </div>
              <div class="DashboardNum">
                <div class="DNumbering">{{ totalCounts?.totalCandidates }}</div>
                <!--  DNumbering  -->
                <div class="DNInfo DNInfo2">
                  Individuals <br />
                  Invited
                </div>
                <!-- DInfo -->
              </div>
              <div class="DashboardNum Last">
                <div class="DNumbering">{{ totalCounts?.totalAttempted }}</div>
                <!--  DNumbering  -->
                <div class="DNInfo DNInfo3">
                  Individuals <br />
                  Played
                </div>
                <!-- DInfo -->
              </div>
            </div>
          </div>
          <!--  row  -->
        </div>
        <div class="row"></div>
      </div>
      <!--  container  -->
    </div>
    <!--  HeadStrip -->

    <!--  Start Section  -->
    <section>
      <div class="container">
        <div class="row">
          <div class="col-md-12" style="z-index: 99">
            <div class="TableAd" (click)="redirectInviteCandidate()">
              <div class="AddButtone">Add New Group</div>
            </div>
          </div>

          <div class="col-md-12">
            <div *ngIf="
                (showMoreType && showMoreType === 'job-fit-analysis-view') ||
                !showMoreType
              " class="WAPDTableBox">
              <div class="table-responsive">
                <table class="table table-border">
                  <thead>
                    <tr>
                      <th colspan="2">
                        <div class="TableInfo">
                          <h1>

                            Orientation Groups

                          </h1>

                        </div>
                      </th>
                      <th colspan="2" class="text-right">
                        <div class="TotalNo">
                          Total no. of Groups
                          <span>{{

                            dashboardData.list.length

                            }}</span>
                        </div>
                      </th>
                      <!-- <th width="15%" class="text-center">
                        <div class="TableInfo">
                          <span
                            *ngIf="
                              dashboardData.list.length >
                                3 && !showMoreType
                            "
                            ><a
                              href="javascript: void(0);"
                              (click)="switchViewAll('job-fit-analysis-view')"
                              >View All</a
                            ></span
                          >
                        </div> -->
                      <!-- <div class="TableInfo">
                          <span
                            *ngIf="
                              dashboardData['Customised Profile Fit']?.length >
                                3 && showMoreFlags.CustomisedProfileFit
                            "
                            ><a
                              href="javascript: void(0);"
                              (click)="switchViewAll('CustomisedProfileFit')"
                              >View Less</a
                            ></span
                          >
                        </div> -->
                      <!-- </th> -->
                    </tr>
                  </thead>
                  <tbody>

                    <!-- <tr>
                      <td colspan="2" class="noGroupData" *ngIf="dashboardData.list.length===0 && isLoading===false"
                        style="text-align: center;">No Group
                        data found</td>
                    </tr> -->
                    <tr *ngIf="dashboardData.list.length===0 && isLoading===false">
                      <td class="noGroupData" width="48%">
                        No Group Data Found
                      </td>
                      <td width="18%">

                      </td>
                      <td width="13%">

                      </td>
                      <td width="6%">

                      </td>
                      <td>
                        <div class="TableInfo">

                        </div>


                      </td>
                    </tr>


                    <!--  *ngFor="
                        let item of showMoreType
                          ? (dashboardData['Customised Profile Fit']
                            | orderBy: 'created_at':'desc')
                          : (dashboardData['Customised Profile Fit']
                            | orderBy: 'created_at':'desc'
                            | slice: 0:3);
                        let i = index
                      " -->
                    <tr [@inOutAnimation] *ngFor="
                        let item of dashboardData.list
                        let i = index
                      ">
                      <td width="18%">
                        <div class="TableInfo">
                          <h2>
                            <span>Group Name</span>
                            <div role="button" (click)="listCandidate(item)">
                              {{ item?.name }}
                            </div>
                          </h2>
                        </div>
                      </td>
                      <td width="38%">
                        <div class="TableInfo">
                          <h2>
                            <span>Journeys Completed</span>{{ getCandidateCompletedCount(item) }}
                          </h2>
                        </div>
                      </td>
                      <td width="13%">
                        <div class="TableInfo">
                          <p>Created on</p>
                          <p>{{ item.createdAt | date: 'dd-MM-yyyy' }}</p>
                        </div>
                      </td>
                      <td width="16%">
                        <div class="TableInfo">
                          <p *ngIf="daysLeft(item.endDate) < 0">
                            <span> Expired </span>
                          </p>
                          <p *ngIf="daysLeft(item.endDate) >= 0">
                            <span>
                              {{ daysLeft(item.endDate) }} days left</span>
                          </p>
                          <p>Valid Till</p>
                          <p>{{ item.endDate | date: 'dd-MM-yyyy' }}</p>
                        </div>
                      </td>
                      <td>
                        <div class="TableInfo">
                          <a href="javascript: void(0);" (click)="addCandidates(item)" title="Invite Candidates">
                            <div class="AdUser"></div>
                          </a>
                          <a href="javascript: void(0);" (click)="toggleOption($event, item)" id="toggle-sh-bu"
                            title="Options">
                            <div class="ShowIfo"></div>
                          </a>
                          <a title="View details">
                            <div class="RightA" (click)="listCandidate(item)"></div>
                          </a>
                        </div>
                        <!--  item &&  item?.toggle &&
                            Json.stringify(item) == Json.stringify(toggle) &&
                            Json.stringify(item) == Json.stringify(toggle) -->
                        <div *ngIf="
                          item &&
                          Json.stringify(item) == Json.stringify(toggle) &&
                          Json.stringify(item) == Json.stringify(toggle)
                          " id="toggle-hs" class="DetailBox exclude">
                          <div class="box-arrow"></div>
                          <ul>
                            <li (click)="editAssessment(item)">
                              <a href="javascript: void(0);">Edit</a>
                            </li>
                            <li (click)="duplicateAssessment(item)">
                              <a href="javascript: void(0);">Duplicate</a>
                            </li>
                            <li (click)="showConfirm($event, item)">
                              <a href="javascript: void(0);">Delete</a>
                            </li>
                          </ul>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>






          </div>
        </div>

      </div>

      <ng-template #deleteModal let-modal>
        <div class="modal-body">
          <h6 class="pop-title">Delete Assessment</h6>
          <p style="text-align: left">
            Are you sure you want to delete this group?
          </p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-light btn-blue" (click)="modal.close('Close click')" tabindex='-1'>
            CANCEL
          </button>
          <button type="button" class="btn btn-light btn-blue" (click)="modal.close('delete')" tabindex='-1'>
            DELETE
          </button>
        </div>


      </ng-template>


    </section>
  </div>
</div>
