<div class="HeadStrip">
  <div class="container">
    <div class="row">
      <div class="col-md-2 position-relative">
        <div class="Selectnumber" *ngIf="assessment.job_type === 'Customised Profile Fit'">
          <div class="Sub">
            <a routerLink="../profile"
              ><i class="fa fa-caret-left" aria-hidden="true"></i>Back</a
            >
          </div>
          03
        </div>
        <div class="Selectnumber" *ngIf="assessment.job_type !== 'Customised Profile Fit'" >
          <div class="Sub">
            <a routerLink="../job-title"
              ><i class="fa fa-caret-left" aria-hidden="true"></i>Back</a
            >
          </div>
          02
        </div>
        <!--  Numbering  -->
      </div>
      <div class="col-md-10">
        <div class="WebAppHedding">Organisational Competencies</div>
        <div class="WebAppSubHedding">
          Select any one from a list of core competencies
        </div>
      </div>
    </div>
    <!--  row  -->
  </div>
  <!--  container  -->
</div>
<!--  HeadStrip -->

<!--  Start Section  -->

<section>
  <div class="container WebAppC">
    <div class="row">
      <div class="col-md-4">
        <span class="CompetencyIco"></span>
      </div>
      <div class="col-md-8">
        <div class="row box-grid">
          <div class="col-md-3 BoxP" *ngFor="let competency of competencySet">
            <div
              [ngClass]="setActive(competency._id)"
              class="WebAppBox"
              (click)="
                organizationalDNASelected(competency._id, competency.name)
              "
            >
              <h2>{{ competency.name }}</h2>
            </div>
          </div>
        </div>
        <!--  row  -->
        <div class="WebAppBut">
          <button
            type="button"
            class="new-btn"
            (click)="saveCompetencyDetail()"
          >
            Next
          </button>
        </div>
      </div>
    </div>
    <!-- row  -->
  </div>
</section>

<!--  End Section  -->
