<div class="ldHtml l-d " *ngIf="showReport">
  <header>
    <div *ngIf="ldInitForIndex" class="l-d">
      <div class="nav-wrapper d-flex justify-content-between">
        <nav class="navbar navbar-main-wrapper">
          <a class="navbar-brand logo-stairwell" [routerLink]='["/candidate/list-candidates"]'>
            <img src="./assets/ld/images/TL_logo.svg" width="160" height="190" alt="stairwell">
          </a>
          <div style="position: absolute;" class="selected-nav-wrapper">
            <button type="button" id="toogleButton" class="btn background-of-button"
              [ngClass]="isCollapsed ? 'collapsed': ''" (click)="collapseIt()">
              Go to another section
              <i id="toogleIcon" [ngClass]="!isCollapsed ?'fa fa-angle-up':'fa fa-angle-down'"></i>

            </button>
            <div class="collapse bg-white border-0 open-dropdown header-dropdown" [ngbCollapse]="isCollapsed"
              id="collapseMenu">
              <!-- [@expandCollapse]="isCollapsed ? 'closed' : 'open'" -->
              <div class="header-dropdown-container">
                <ul class="d-flex justify-content-between px-80 pt-60">
                  <li [ngClass]="visiblePageIndex==0?'active-page':''" (click)="goToPersona(0)">
                    <div class=" left-swipe cpointer">
                      <!--removed class  dropdown-item -->
                      <div class="driver-menu "></div>
                      <p class="menu-text"> Home </p>
                    </div>
                  </li>
                  <!-- <li *ngIf="viewReport.response.persona" [ngClass]="visiblePageIndex==1?'active-page':''"  (click)="goToPersona(1)">
													<a class=" left-swipe">
														<div class="persona-menu"></div>
                                                        <p class="menu-text">{{viewReport.response.persona.title}}</p>
													</a>
												</li> -->
                  <li *ngIf="viewReport.response.keyDriver" [ngClass]="visiblePageIndex==2?'active-page':''"
                    (click)="goToPersona(2)">

                    <div class=" left-swipe cpointer">
                      <div class="key-drivers-menu"></div>
                      <p class="menu-text">{{viewReport.response.keyDriver.title}}</p>
                    </div>
                  </li>
                  <!-- <li *ngIf="viewReport.response.derailer" [ngClass]="visiblePageIndex==3?'active-page':''" (click)="goToPersona(3)">
													<div class=" left-swipe cpointer"  >
														<div class="derailers-menu"></div>
                                                        <p class="menu-text">{{viewReport.response.derailer.title}}</p>
                                                    </div>
												</li> -->
                  <li *ngIf="viewReport.response.highComp" [ngClass]="visiblePageIndex==5?'active-page':''"
                    (click)="goToPersona(5)">
                    <div class=" left-swipe cpointer">
                      <div class="high-competencies-menu"></div>
                      <p class="menu-text">{{viewReport.response.highComp.title}}</p>
                    </div>
                  </li>
                  <li *ngIf="viewReport.response.leadership" [ngClass]="visiblePageIndex==4?'active-page':''"
                    (click)="goToPersona(4)">
                    <div class=" left-swipe cpointer">
                      <div class="leadership-menu"></div>
                      <p class="menu-text">{{viewReport.response.leadership.title}}</p>
                    </div>
                  </li>
                  <li *ngIf="viewReport.response.orgComp" [ngClass]="visiblePageIndex==6?'active-page':''"
                    (click)="goToPersona(6)">
                    <div class=" left-swipe last-dropdown cpointer">
                      <div class="organizational-competencies-menu"></div>
                      <p class="menu-text">{{viewReport.response.orgComp.title}}</p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </div>
  </header>
  <!-- Landing Page Division -->
  <div class="landing-page-background" *ngIf="visiblePageIndex === 0">
    <main class=" landing-page-background">
      <div style="height: 100vh" class="row">
        <div class="col-lg-5 logo1">
          <a routerLink="/candidate/list-candidates">
            <img src="assets/ld\images\TL_logo.svg" alt="logo" height="150">
          </a>

        </div>
        <div class="container col-lg-7 padding-right-80 logo2 ">
          <div class="text-wrapper">
            <h2 style="margin-top: 20px" class="report-main-description-heading"> {{viewReport?.job_title}}</h2>
            <p class="">
              Name:
              <span class="candidate-name"> {{viewReport.candidate_first_name}}
                {{viewReport.candidate_last_name}}</span>
            </p>
            <p>
              {{viewReport.report_desc}}
            </p>
            <div class="pages w-100">

              <!-- <div *ngIf="viewReport.response.persona" (click)="goToPersona(1)" class="d-flex align-item-center justify-content-between margin-top-bottom-10">
                                <span> {{viewReport.response.persona.title}} </span>
                                <div class="ml-10">
                                    <img src="assets/ld/images/Opening page button.svg" alt="logo-line" />
                                </div>
                            </div> -->

              <div *ngIf="viewReport.response.keyDriver" (click)="goToPersona(2)"
                class="d-flex align-item-center justify-content-between margin-top-bottom-10 cpointer">
                <span>{{viewReport.response.keyDriver.title}}</span>
                <div class="ml-10">
                  <img src="assets/ld/images/Opening page button.svg" alt="logo-line" />
                </div>
              </div>

              <!-- Derailers main page -->
              <!-- <div *ngIf="viewReport.response.derailer" (click)="goToPersona(3)" class="d-flex align-item-center justify-content-between margin-top-bottom-10 cpointer">
                                <span>{{viewReport.response.derailer.title}} </span>
                                <div class="ml-10">
                                    <img src="assets/ld/images/Opening page button.svg" alt="logo-line" />
                                </div>
                            </div> -->

              <div *ngIf="viewReport.response.leadership" (click)="goToPersona(4)"
                class="d-flex align-item-center justify-content-between margin-top-bottom-10 cpointer">
                <span>{{viewReport.response.leadership.title}} </span>
                <div class="ml-10">
                  <img src="assets/ld/images/Opening page button.svg" alt="logo-line" />
                </div>
              </div>

              <div *ngIf="viewReport.response.highComp" (click)="goToPersona(5)"
                class="d-flex align-item-center justify-content-between margin-top-bottom-10 cpointer">
                <span>{{viewReport.response.highComp.title}} </span>
                <div class="ml-10">
                  <img src="assets/ld/images/Opening page button.svg" alt="logo-line" />
                </div>
              </div>

              <div *ngIf="viewReport.response.orgComp" (click)="goToPersona(6)"
                class="d-flex align-item-center justify-content-between margin-top-bottom-10 cpointer">
                <span>{{viewReport.response.orgComp.title}}</span>
                <div class="ml-10">
                  <img src="assets/ld/images/Opening page button.svg" alt="logo-line" />
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </main>

  </div>


  <!-- persona div -->
  <div *ngIf="visiblePageIndex === 1">


    <main style="height: 100%!important;" class="persona-report">
      <div class="container-fluid padding-left-right-zero">
        <div id="overlay" class="overlay"></div>
        <div class="position-relative d-flex justify-content-center">
          <div class="container w-100 d-flex justify-content-center align-item-center h-100"
            style="position: relative; height: 1000px;">
            <div class="section-line-circle"></div>
            <div class="col-lg-4 col-md-4 report-summary-wrapper">
              <h2 class="page-heading"> {{viewReport.response.persona.title}} </h2>
              <p class="page-desc">
                {{viewReport.response.persona.description}}
              </p>
            </div>
          </div>
          <div class="container aligning-content">
            <div class="row">
              <ul class="nav nav-tabs tabs-background">
                <!-- <span > -->
                <li style="cursor: pointer" [ngClass]="personaKey== i ?'active':''"
                  *ngFor=" let value of viewReport.response.persona.data; let i=index">
                  <a (click)="changeLabelOfPersonaData(i)">
                    {{value.key}}
                  </a>
                </li>
                <!-- </span> -->

              </ul>
              <div class="container persona-mid-section" style="margin-bottom: 30px;">
                <div class="row ">
                  <div class="bg-white data-sec-wrapper tab-content">
                    <!-- COPYING AND PERFORMING -->
                    <div>
                      <div class="content-wrapper tab-pane fade in active">
                        <div class="d-flex justify-content-center mptb-25">
                          <div class="col-lg-12">
                            <h2 class="data-heading">
                              {{personaDataForCurrentIndex.key}} </h2>
                            <p class="page-desc pb-5">
                              {{personaDataForCurrentIndex.desc}}
                            </p>
                          </div>
                        </div>
                        <div class="d-flex justify-content-center mptb-25"
                          *ngFor="let value2 of personaDataForCurrentIndex.data">
                          <div class="col-lg-4 persona-text-padding">
                            <p class="mt-32" [innerHtml]="value2.low_text | safe :'html'"></p>
                          </div>
                          <div class="col-lg-4">
                            <h2 class="Perseverance-heading text-center mt-neagtive">{{value2.name}}</h2>
                            <div class="mt-25 d-flex ">
                              <span class="mt-3">Low</span>
                              <div class=" flex-direction-row graph-spacing">
                                <!-- ,="" -->
                                <div class="ldBar d-flex progress-bar-container"
                                  data-stroke="data:ldbar/res,stripe(#a2dbe1,#a2dbe1,10)"
                                  data-path="M10 20Q20 15 30 20Q40 25 50 20Q60 15 70 20Q80 25 90 20" data-value="30"
                                  style="width: 200px !important; height: 14px !important;">
                                  <svg xmlns:xlink="http://www.w3.org/1999/xlink" preserveAspectRatio="xMidYMid"
                                    width="100%" height="100%" viewBox="5.5 13 89 14">
                                    <defs>
                                      <filter id="ldBar-12ada8642562f-filter" x="-1" y="-1" width="3" height="3">
                                        <feMorphology operator="dilate" radius="3"></feMorphology>
                                        <feColorMatrix values="0 0 0 0 1    0 0 0 0 1    0 0 0 0 1    0 0 0 1 0"
                                          result="cm"></feColorMatrix>
                                      </filter>
                                      <mask id="ldBar-12ada8642562f-mask">
                                        <image xlink:href="" filter="url(#ldBar-12ada8642562f-filter)" x="0" y="0"
                                          width="100" height="100" preserveAspectRatio="xMidYMid"></image>
                                      </mask>
                                      <g>
                                        <mask id="ldBar-12ada8642562f-mask-path">
                                          <path d="M10 20Q20 15 30 20Q40 25 50 20Q60 15 70 20Q80 25 90 20" fill="#fff"
                                            stroke="#fff" filter="url(#ldBar-12ada8642562f-filter)">
                                          </path>
                                        </mask>
                                      </g>
                                      <clipPath id="ldBar-12ada8642562f-clip">
                                        <rect class="mask" fill="#000"></rect>
                                      </clipPath>
                                      <pattern id="ldBar-12ada8642562f-pattern" patternUnits="userSpaceOnUse" x="0"
                                        y="0" width="150" height="150">
                                        <image x="0" y="0" width="150" height="150"
                                          xlink:href="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KICAgICAgICA8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgdmlld0JveD0iMCAwIDEwMCAxMDAiPiw8cmVjdCBmaWxsPSIjYTJkYmUxIiB3aWR0aD0iMTAwIiBoZWlnaHQ9IjEwMCIvPjxnPjxnPjxwb2x5Z29uIGZpbGw9IiNhMmRiZTEiIHBvaW50cz0iLTkwLDEwMCAtMTAwLDEwMCAtNjAsMCAtNTAsMCAiLz48cG9seWdvbiBmaWxsPSIjYTJkYmUxIiBwb2ludHM9Ii03MCwxMDAgLTgwLDEwMCAtNDAsMCAtMzAsMCAiLz48cG9seWdvbiBmaWxsPSIjYTJkYmUxIiBwb2ludHM9Ii01MCwxMDAgLTYwLDEwMCAtMjAsMCAtMTAsMCAiLz48cG9seWdvbiBmaWxsPSIjYTJkYmUxIiBwb2ludHM9Ii0zMCwxMDAgLTQwLDEwMCAwLDAgMTAsMCAiLz48cG9seWdvbiBmaWxsPSIjYTJkYmUxIiBwb2ludHM9Ii0xMCwxMDAgLTIwLDEwMCAyMCwwIDMwLDAgIi8+PHBvbHlnb24gZmlsbD0iI2EyZGJlMSIgcG9pbnRzPSIxMCwxMDAgMCwxMDAgNDAsMCA1MCwwICIvPjxwb2x5Z29uIGZpbGw9IiNhMmRiZTEiIHBvaW50cz0iMzAsMTAwIDIwLDEwMCA2MCwwIDcwLDAgIi8+PHBvbHlnb24gZmlsbD0iI2EyZGJlMSIgcG9pbnRzPSI1MCwxMDAgNDAsMTAwIDgwLDAgOTAsMCAiLz48cG9seWdvbiBmaWxsPSIjYTJkYmUxIiBwb2ludHM9IjcwLDEwMCA2MCwxMDAgMTAwLDAgMTEwLDAgIi8+PHBvbHlnb24gZmlsbD0iI2EyZGJlMSIgcG9pbnRzPSI5MCwxMDAgODAsMTAwIDEyMCwwIDEzMCwwICIvPjxwb2x5Z29uIGZpbGw9IiNhMmRiZTEiIHBvaW50cz0iMTEwLDEwMCAxMDAsMTAwIDE0MCwwIDE1MCwwICIvPjxwb2x5Z29uIGZpbGw9IiNhMmRiZTEiIHBvaW50cz0iMTMwLDEwMCAxMjAsMTAwIDE2MCwwIDE3MCwwICIvPjxwb2x5Z29uIGZpbGw9IiNhMmRiZTEiIHBvaW50cz0iMTUwLDEwMCAxNDAsMTAwIDE4MCwwIDE5MCwwICIvPjwvZz48YW5pbWF0ZVRyYW5zZm9ybSBhdHRyaWJ1dGVOYW1lPSJ0cmFuc2Zvcm0iIHR5cGU9InRyYW5zbGF0ZSIgZnJvbT0iMCwwIiB0bz0iMjAsMCIgZHVyPSIxMHMiIHJlcGVhdENvdW50PSJpbmRlZmluaXRlIi8+PC9nPjwvc3ZnPg==">
                                        </image>
                                      </pattern>
                                    </defs>
                                    <g>
                                      <path d="M10 20Q20 15 30 20Q40 25 50 20Q60 15 70 20Q80 25 90 20" fill="none"
                                        class="baseline" stroke="#ddd" stroke-width="0.5"></path>
                                    </g>
                                    <!-- TODO -->
                                    <g>
                                      <path elem-ready [attr.value]="value2.score" title="persona"
                                        d="M10 20Q20 15 30 20Q40 25 50 20Q60 15 70 20Q80 25 90 20" class="mainline"
                                        clip-path="" fill="none" stroke-width="3"
                                        attr.stroke="url(#ldBar-12ada8642562f-pattern)"
                                        attr.stroke-dasharray="{{value2.score}} 80.25284729003907"></path>
                                    </g>
                                  </svg>
                                </div>

                              </div>
                              <span class="mt-3">High</span>
                            </div>
                            <h4 class="data-sub-heading text-center Percentile">
                              Percentile: {{value2.score}} </h4>
                          </div>
                          <div class="col-lg-4 persona-border-top">
                            <p class="mt-32" [innerHtml]="(value2.high_text) | safe :'html'">

                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>

  </div>


  <div *ngIf="visiblePageIndex === 2">
    <main class="driver-report" >


      <div class="container-fluid padding-left-right-zero">
        <div id="overlay" class="overlay"></div>
        <div class="position-relative d-flex justify-content-center">
          <div class="container w-100 d-flex h-100">
            <!-- style="position: relative;" -->
            <div class="section-left-circle"></div>
            <div class="col-lg-4 col-md-4 driver-summary-wrapper">
              <h2 class="page-heading"> {{viewReport.response.keyDriver.title}} </h2>
              <p class="page-desc">
                {{viewReport.response.keyDriver.description}}
              </p>
            </div>
          </div>

          <div class="container driver-content">
            <div style="margin-bottom: 30px;" class="row">
              <div class="col-lg-6 col-md-6" *ngFor="let kd of viewReport.response.keyDriver.data | keyvalue">
                <div class="bg-white data-section-wrapper p-0">
                  <div class="p-4">
                    <div class="d-flex align-item-center">
                      <h3 class="data-heading mb-4"> {{kd.value.title}}</h3>
                    </div>
                    <div class="padding-gradient mb-3">
                      <p>
                        {{kd.value.description}}
                      </p>
                    </div>
                  </div>
                </div>
              </div>


            </div>
          </div>
        </div>
      </div>
    </main>

  </div>

  <!-- <div *ngIf="visiblePageIndex === 3">
        <main class=" derailers-report">

            <div class="container-fluid padding-left-right-zero">
                <div id="overlay" class="overlay"></div>
                <div class="position-relative d-flex justify-content-center">
                    <div class="container w-100 d-flex justify-content-center align-item-center h-100" style="position: relative;">
                        <div class="section-line-circle"></div>
                        <div class="col-lg-4 col-md-4 report-summary-wrapper">
                            <h2 class="page-heading"> {{viewReport.response.derailer.title}} </h2>
                            <p  class="page-desc derailer-desc">
                                {{viewReport.response.derailer.description}}
                            </p>
                        </div>
                    </div>
                    <div class="container derailer-content" style="margin-bottom: 30px;margin-top: 30px;">
                        <div class="row">
                            <div class="col-lg-6 col-md-6" *ngFor="let derailer of viewReport.response.derailer.data | keyvalue">
                                <div class="bg-white data-section-wrapper p-0">
                                    <div class="p-4">
                                        <div class="d-flex align-item-center">
                                            <h3 class="data-heading mb-4"> {{derailer.value.derailers_name}} </h3>
                                            <button [ngStyle]="{ 'background' : (derailer.value.average_per < 33) ? 'green' : (derailer.value.average_per < 67 && derailer.value.average_per > 33) ? '#e3c323' : (derailer.value.average_per < 100 && derailer.value.average_per > 66) ? '#ff7c46' : '#ff7c46' }"
                                                class="btn bg-pinkish-orange text-white btn-height">
                                                Risk: {{derailer.value.rating}}
                                            </button>
                                        </div>
                                        <div class="padding-gradient">
                                            <p [innerHtml]=" derailer.value.behavioural_implications | safe :'html'">

                                            </p>
                                        </div>
                                    </div>
                                    <div class="padding-gradient mb-3 bg-ice-blue">
                                        <div class="p-4">
                                            <div>
                                                <h3> Mitigation Tips </h3>
                                            </div>
                                            <div>
                                                <p [innerHtml]="derailer.value.mitigation_tips | safe :'html'">

                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </main>

    </div> -->

  <div *ngIf="visiblePageIndex === 4">

    <main class="leadership-report" >
      <div class="container-fluid padding-left-right-zero">
        <div id="overlay" class="overlay"></div>
        <div class="position-relative d-flex justify-content-center">
          <div class="container w-100 d-flex justify-content-center align-item-center h-100"
            style="position: relative;">
            <div class="section-line-circle"></div>
            <div class="col-lg-4 col-md-4 report-summary-wrapper">
              <h2 class="page-heading"> {{viewReport.response.leadership.title}} </h2>
              <p class="page-desc">
                {{viewReport.response.leadership.description}}
              </p>
            </div>
          </div>
          <div class="container driver-content" style="margin-bottom: 30px;">
            <div class="row">

              <div class="col-lg-4 col-md-4" *ngFor="let value of viewReport.response.leadership.data">
                <div class="bg-white data-section-wrapper">
                  <h3 class="data-heading font-size-22 mb-4 mt-0"> {{value.key}} </h3>


                  <div *ngFor="let subvalue of value.data" ngClass="{{getColorBox(subvalue.average_per)}}-gradient"
                    class="d-flex padding-gradient items-highlight mb-3 {{getColorBox(subvalue.average_per)}}-gradient"
                    data-toggle="modal" data-target="#myModal" (click)="openModal(subvalue,'leadership')">
                    <div class="bg-white progress-bar-section">
                      <h5 class="data-sub-heading">
                        {{subvalue.factor}}
                      </h5>

                      <div class=" flex-direction-row graph-spacing">
                        <!-- ,=""  -->
                        <div class="ldBar d-flex progress-bar-container"
                          data-stroke="data:ldbar/res,stripe(#a2dbe1,#a2dbe1,10)"
                          data-path="M10 20Q20 15 30 20Q40 25 50 20Q60 15 70 20Q80 25 90 20" data-value="30"
                          style="width: 100% !important; height: 14px !important;">
                          <svg xmlns:xlink="http://www.w3.org/1999/xlink" preserveAspectRatio="xMidYMid" width="100%"
                            height="100%" viewBox="5.5 13 89 14">
                            <defs>
                              <filter id="ldBar-12ada8642562f-filter" x="-1" y="-1" width="3" height="3">
                                <feMorphology operator="dilate" radius="3"></feMorphology>
                                <feColorMatrix values="0 0 0 0 1    0 0 0 0 1    0 0 0 0 1    0 0 0 1 0" result="cm">
                                </feColorMatrix>
                              </filter>
                              <mask id="ldBar-12ada8642562f-mask">
                                <image xlink:href="" filter="url(#ldBar-12ada8642562f-filter)" x="0" y="0" width="100"
                                  height="100" preserveAspectRatio="xMidYMid"></image>
                              </mask>
                              <g>
                                <mask id="ldBar-12ada8642562f-mask-path">
                                  <path d="M10 20Q20 15 30 20Q40 25 50 20Q60 15 70 20Q80 25 90 20" fill="#fff"
                                    stroke="#fff" filter="url(#ldBar-12ada8642562f-filter)">
                                  </path>
                                </mask>
                              </g>
                              <clipPath id="ldBar-12ada8642562f-clip">
                                <rect class="mask" fill="#000"></rect>
                              </clipPath>
                              <pattern id="ldBar-12ada8642562f-pattern" patternUnits="userSpaceOnUse" x="0" y="0"
                                width="150" height="150">
                                <image x="0" y="0" width="150" height="150"
                                  xlink:href="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KICAgICAgICA8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgdmlld0JveD0iMCAwIDEwMCAxMDAiPiw8cmVjdCBmaWxsPSIjYTJkYmUxIiB3aWR0aD0iMTAwIiBoZWlnaHQ9IjEwMCIvPjxnPjxnPjxwb2x5Z29uIGZpbGw9IiNhMmRiZTEiIHBvaW50cz0iLTkwLDEwMCAtMTAwLDEwMCAtNjAsMCAtNTAsMCAiLz48cG9seWdvbiBmaWxsPSIjYTJkYmUxIiBwb2ludHM9Ii03MCwxMDAgLTgwLDEwMCAtNDAsMCAtMzAsMCAiLz48cG9seWdvbiBmaWxsPSIjYTJkYmUxIiBwb2ludHM9Ii01MCwxMDAgLTYwLDEwMCAtMjAsMCAtMTAsMCAiLz48cG9seWdvbiBmaWxsPSIjYTJkYmUxIiBwb2ludHM9Ii0zMCwxMDAgLTQwLDEwMCAwLDAgMTAsMCAiLz48cG9seWdvbiBmaWxsPSIjYTJkYmUxIiBwb2ludHM9Ii0xMCwxMDAgLTIwLDEwMCAyMCwwIDMwLDAgIi8+PHBvbHlnb24gZmlsbD0iI2EyZGJlMSIgcG9pbnRzPSIxMCwxMDAgMCwxMDAgNDAsMCA1MCwwICIvPjxwb2x5Z29uIGZpbGw9IiNhMmRiZTEiIHBvaW50cz0iMzAsMTAwIDIwLDEwMCA2MCwwIDcwLDAgIi8+PHBvbHlnb24gZmlsbD0iI2EyZGJlMSIgcG9pbnRzPSI1MCwxMDAgNDAsMTAwIDgwLDAgOTAsMCAiLz48cG9seWdvbiBmaWxsPSIjYTJkYmUxIiBwb2ludHM9IjcwLDEwMCA2MCwxMDAgMTAwLDAgMTEwLDAgIi8+PHBvbHlnb24gZmlsbD0iI2EyZGJlMSIgcG9pbnRzPSI5MCwxMDAgODAsMTAwIDEyMCwwIDEzMCwwICIvPjxwb2x5Z29uIGZpbGw9IiNhMmRiZTEiIHBvaW50cz0iMTEwLDEwMCAxMDAsMTAwIDE0MCwwIDE1MCwwICIvPjxwb2x5Z29uIGZpbGw9IiNhMmRiZTEiIHBvaW50cz0iMTMwLDEwMCAxMjAsMTAwIDE2MCwwIDE3MCwwICIvPjxwb2x5Z29uIGZpbGw9IiNhMmRiZTEiIHBvaW50cz0iMTUwLDEwMCAxNDAsMTAwIDE4MCwwIDE5MCwwICIvPjwvZz48YW5pbWF0ZVRyYW5zZm9ybSBhdHRyaWJ1dGVOYW1lPSJ0cmFuc2Zvcm0iIHR5cGU9InRyYW5zbGF0ZSIgZnJvbT0iMCwwIiB0bz0iMjAsMCIgZHVyPSIxMHMiIHJlcGVhdENvdW50PSJpbmRlZmluaXRlIi8+PC9nPjwvc3ZnPg==">
                                </image>
                              </pattern>
                            </defs>
                            <g>
                              <path d="M10 20Q20 15 30 20Q40 25 50 20Q60 15 70 20Q80 25 90 20" fill="none"
                                class="baseline" stroke="#ddd" stroke-width="0.5"></path>
                            </g>
                            <g>
                              <path elem-ready [attr.value]="subvalue.average_per"
                                d="M10 20Q20 15 30 20Q40 25 50 20Q60 15 70 20Q80 25 90 20" class="mainline" clip-path=""
                                fill="none" stroke-width="3" stroke="url(#ldBar-12ada8642562f-pattern)"
                                attr.stroke-dasharray="{{subvalue.average_per}} 80.25284729003907"></path>
                            </g>
                          </svg>
                          <div class="ldBar-label">{{subvalue.average_per}}</div>
                        </div>

                      </div>
                    </div>
                    <span class="d-flex justify-content-center align-items-center chevron-icon-right">
                      <i class="fa fa-angle-right" aria-hidden="true"></i>
                    </span>
                  </div>

                </div>
              </div>
            </div>

              <div class="container mt-3">
                <div class="d-flex">
                  <div class="d-flex guide-wrapper">
                    <div class="red-gradient guide-border-left-radius">
                      <p class="text-white guide-text"> 0 - 33 </p>
                    </div>
                    <div class="bg-white guide-border-right-radius">
                      <p class="text-gray-brown guide-text guide-white-patch">
                        Development Area
                      </p>
                    </div>
                  </div>

                  <div class="d-flex guide-wrapper">
                    <div class="orange-gradient guide-border-left-radius">
                      <p class="text-white guide-text"> 34 - 66 </p>
                    </div>
                    <div class="bg-white guide-border-right-radius">
                      <p class="text-gray-brown guide-text guide-white-patch">
                        Potential Strength
                      </p>
                    </div>
                  </div>

                  <div class="d-flex guide-wrapper">
                    <div class="green-gradient guide-border-left-radius">
                      <p class="text-white guide-text"> 67 - 100 </p>
                    </div>
                    <div class="bg-white guide-border-right-radius">
                      <p class="text-gray-brown guide-text guide-white-patch">
                        Strength
                      </p>
                    </div>
                  </div>
                </div>
              </div>

            <div class="col-lg-12 col-md-12">
              <div class="report-main-description-wrapper bg-white" style="margin-bottom: 20px;">
                <h2 class="report-main-description-heading"> Leadership Style </h2>
                <!-- <ul class="ordered-list" [innerHtml]="safeHtml(viewReport.response.leadership.leadership_style)">

                                        </ul> -->
                <p style="position: relative;"
                  [innerHtml]="(viewReport.response.leadership.leadership_style) | safe :'html'"></p>
                <ul class="ordered-list">
                  <li class="report-main-description-desc"
                    *ngFor="let leadership of viewReport.response.leadership.leadershipDesc | keyvalue">
                    {{leadership?.key}} : {{leadership?.value}}</li>
                </ul>
              </div>
            </div>
          </div>

        </div>
      </div>
    </main>


  </div>

  <div class="high-compentency-report" *ngIf="visiblePageIndex === 5">

    <main class="high-compentency-report" [ngStyle]="{height: getReportHeight('high-compentency-report')}">
      <div class="container-fluid padding-left-right-zero">
        <div id="overlay" class="overlay"></div>
        <div class="position-relative d-flex justify-content-center">
          <div class="container w-100 d-flex justify-content-center align-item-center h-100">
            <div class="section-line-circle high-performance-line"></div>
            <div class="col-lg-4 col-md-4 report-summary-wrapper performance-summary-wrapper">
              <h2 class="page-heading"> {{viewReport.response.highComp.title}} </h2>
              <p class="page-desc">
                {{viewReport.response.highComp.description}}
              </p>
            </div>
          </div>



          <div class="container mb-5 driver-content">
            <div class="container mt-3"  style="position: absolute; bottom: 102%;">
              <div class="d-flex">
                <div class="d-flex guide-wrapper">
                  <div class="red-gradient guide-border-left-radius">
                    <p class="text-white guide-text"> 0 - 32 </p>
                  </div>
                  <div class="bg-white guide-border-right-radius">
                    <p class="text-gray-brown guide-text guide-white-patch">
                      Development Area
                    </p>
                  </div>
                </div>

                <div class="d-flex guide-wrapper">
                  <div class="orange-gradient guide-border-left-radius">
                    <p class="text-white guide-text"> 33 - 66 </p>
                  </div>
                  <div class="bg-white guide-border-right-radius">
                    <p class="text-gray-brown guide-text guide-white-patch">
                      Potential Strength
                    </p>
                  </div>
                </div>

                <div class="d-flex guide-wrapper">
                  <div class="green-gradient guide-border-left-radius">
                    <p class="text-white guide-text"> 67 - 100 </p>
                  </div>
                  <div class="bg-white guide-border-right-radius">
                    <p class="text-gray-brown guide-text guide-white-patch">
                      Strength
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div style="margin-bottom: 30px;" class="row">
              <div class="col-lg-4 col-md-4">
                <div class="bg-white data-section-wrapper">
                  <h3 class="data-heading mb-4 font-size-22"> Strengths </h3>
                  <div *ngFor="let value of viewReport.response.highComp.data.High"
                    class="d-flex  green-gradient padding-gradient items-highlight mb-3" data-toggle="modal"
                    data-target="#myModal" (click)="openModal(value,'highComp')">
                    <div class="bg-white progress-bar-section open-modal">
                      <h5 class="data-sub-heading">
                        {{value.success_factor}}
                      </h5>
                      <div class=" flex-direction-row graph-spacing">
                        <!-- ,="" -->
                        <div class="ldBar d-flex progress-bar-container"
                          data-stroke="data:ldbar/res,stripe(#a2dbe1,#a2dbe1,10)"
                          data-path="M10 20Q20 15 30 20Q40 25 50 20Q60 15 70 20Q80 25 90 20" data-value="30"
                          style="width: 100% !important; height: 14px !important;">
                          <svg xmlns:xlink="http://www.w3.org/1999/xlink" preserveAspectRatio="xMidYMid" width="100%"
                            height="100%" viewBox="5.5 13 89 14">
                            <defs>
                              <filter id="ldBar-12ada8642562f-filter" x="-1" y="-1" width="3" height="3">
                                <feMorphology operator="dilate" radius="3"></feMorphology>
                                <feColorMatrix values="0 0 0 0 1    0 0 0 0 1    0 0 0 0 1    0 0 0 1 0" result="cm">
                                </feColorMatrix>
                              </filter>
                              <mask id="ldBar-12ada8642562f-mask">
                                <image xlink:href="" filter="url(#ldBar-12ada8642562f-filter)" x="0" y="0" width="100"
                                  height="100" preserveAspectRatio="xMidYMid"></image>
                              </mask>
                              <g>
                                <mask id="ldBar-12ada8642562f-mask-path">
                                  <path d="M10 20Q20 15 30 20Q40 25 50 20Q60 15 70 20Q80 25 90 20" fill="#fff"
                                    stroke="#fff" filter="url(#ldBar-12ada8642562f-filter)">
                                  </path>
                                </mask>
                              </g>
                              <clipPath id="ldBar-12ada8642562f-clip">
                                <rect class="mask" fill="#000"></rect>
                              </clipPath>
                              <pattern id="ldBar-12ada8642562f-pattern" patternUnits="userSpaceOnUse" x="0" y="0"
                                width="150" height="150">
                                <image x="0" y="0" width="150" height="150"
                                  xlink:href="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KICAgICAgICA8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgdmlld0JveD0iMCAwIDEwMCAxMDAiPiw8cmVjdCBmaWxsPSIjYTJkYmUxIiB3aWR0aD0iMTAwIiBoZWlnaHQ9IjEwMCIvPjxnPjxnPjxwb2x5Z29uIGZpbGw9IiNhMmRiZTEiIHBvaW50cz0iLTkwLDEwMCAtMTAwLDEwMCAtNjAsMCAtNTAsMCAiLz48cG9seWdvbiBmaWxsPSIjYTJkYmUxIiBwb2ludHM9Ii03MCwxMDAgLTgwLDEwMCAtNDAsMCAtMzAsMCAiLz48cG9seWdvbiBmaWxsPSIjYTJkYmUxIiBwb2ludHM9Ii01MCwxMDAgLTYwLDEwMCAtMjAsMCAtMTAsMCAiLz48cG9seWdvbiBmaWxsPSIjYTJkYmUxIiBwb2ludHM9Ii0zMCwxMDAgLTQwLDEwMCAwLDAgMTAsMCAiLz48cG9seWdvbiBmaWxsPSIjYTJkYmUxIiBwb2ludHM9Ii0xMCwxMDAgLTIwLDEwMCAyMCwwIDMwLDAgIi8+PHBvbHlnb24gZmlsbD0iI2EyZGJlMSIgcG9pbnRzPSIxMCwxMDAgMCwxMDAgNDAsMCA1MCwwICIvPjxwb2x5Z29uIGZpbGw9IiNhMmRiZTEiIHBvaW50cz0iMzAsMTAwIDIwLDEwMCA2MCwwIDcwLDAgIi8+PHBvbHlnb24gZmlsbD0iI2EyZGJlMSIgcG9pbnRzPSI1MCwxMDAgNDAsMTAwIDgwLDAgOTAsMCAiLz48cG9seWdvbiBmaWxsPSIjYTJkYmUxIiBwb2ludHM9IjcwLDEwMCA2MCwxMDAgMTAwLDAgMTEwLDAgIi8+PHBvbHlnb24gZmlsbD0iI2EyZGJlMSIgcG9pbnRzPSI5MCwxMDAgODAsMTAwIDEyMCwwIDEzMCwwICIvPjxwb2x5Z29uIGZpbGw9IiNhMmRiZTEiIHBvaW50cz0iMTEwLDEwMCAxMDAsMTAwIDE0MCwwIDE1MCwwICIvPjxwb2x5Z29uIGZpbGw9IiNhMmRiZTEiIHBvaW50cz0iMTMwLDEwMCAxMjAsMTAwIDE2MCwwIDE3MCwwICIvPjxwb2x5Z29uIGZpbGw9IiNhMmRiZTEiIHBvaW50cz0iMTUwLDEwMCAxNDAsMTAwIDE4MCwwIDE5MCwwICIvPjwvZz48YW5pbWF0ZVRyYW5zZm9ybSBhdHRyaWJ1dGVOYW1lPSJ0cmFuc2Zvcm0iIHR5cGU9InRyYW5zbGF0ZSIgZnJvbT0iMCwwIiB0bz0iMjAsMCIgZHVyPSIxMHMiIHJlcGVhdENvdW50PSJpbmRlZmluaXRlIi8+PC9nPjwvc3ZnPg==">
                                </image>
                              </pattern>
                            </defs>
                            <g>
                              <path d="M10 20Q20 15 30 20Q40 25 50 20Q60 15 70 20Q80 25 90 20" fill="none"
                                class="baseline" stroke="#ddd" stroke-width="0.5"></path>
                            </g>
                            <g>
                              <path elem-ready [attr.value]="value.average_per"
                                d="M10 20Q20 15 30 20Q40 25 50 20Q60 15 70 20Q80 25 90 20" class="mainline" clip-path=""
                                fill="none" stroke-width="3" stroke="url(#ldBar-12ada8642562f-pattern)"
                                attr.stroke-dasharray="{{value.average_per}} 80.25284729003907"></path>
                            </g>
                          </svg>
                          <div class="ldBar-label">{{value.average_per}}</div>
                        </div>

                      </div>
                    </div>
                    <span class="d-flex justify-content-center align-items-center chevron-icon-right">
                      <i class="fa fa-angle-right" aria-hidden="true"></i>
                    </span>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-4">
                <div class="bg-white data-section-wrapper">
                  <h3 class="data-heading mb-4 font-size-22"> Potential Strengths </h3>

                  <div *ngFor="let value of viewReport.response.highComp.data.Medium"
                    class="d-flex orange-gradient padding-gradient items-highlight mb-3" data-toggle="modal"
                    data-target="#myModal" (click)="openModal(value,'highComp')">
                    <div class="bg-white progress-bar-section">
                      <h5 class="data-sub-heading"> {{value.success_factor}} </h5>
                      <div class=" flex-direction-row graph-spacing">
                        <!-- ,="" -->
                        <div class="ldBar d-flex progress-bar-container"
                          data-stroke="data:ldbar/res,stripe(#a2dbe1,#a2dbe1,10)"
                          data-path="M10 20Q20 15 30 20Q40 25 50 20Q60 15 70 20Q80 25 90 20" data-value="30"
                          style="width: 100% !important; height: 14px !important;">
                          <svg xmlns:xlink="http://www.w3.org/1999/xlink" preserveAspectRatio="xMidYMid" width="100%"
                            height="100%" viewBox="5.5 13 89 14">
                            <defs>
                              <filter id="ldBar-12ada8642562f-filter" x="-1" y="-1" width="3" height="3">
                                <feMorphology operator="dilate" radius="3"></feMorphology>
                                <feColorMatrix values="0 0 0 0 1    0 0 0 0 1    0 0 0 0 1    0 0 0 1 0" result="cm">
                                </feColorMatrix>
                              </filter>
                              <mask id="ldBar-12ada8642562f-mask">
                                <image xlink:href="" filter="url(#ldBar-12ada8642562f-filter)" x="0" y="0" width="100"
                                  height="100" preserveAspectRatio="xMidYMid"></image>
                              </mask>
                              <g>
                                <mask id="ldBar-12ada8642562f-mask-path">
                                  <path d="M10 20Q20 15 30 20Q40 25 50 20Q60 15 70 20Q80 25 90 20" fill="#fff"
                                    stroke="#fff" filter="url(#ldBar-12ada8642562f-filter)">
                                  </path>
                                </mask>
                              </g>
                              <clipPath id="ldBar-12ada8642562f-clip">
                                <rect class="mask" fill="#000"></rect>
                              </clipPath>
                              <pattern id="ldBar-12ada8642562f-pattern" patternUnits="userSpaceOnUse" x="0" y="0"
                                width="150" height="150">
                                <image x="0" y="0" width="150" height="150"
                                  xlink:href="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KICAgICAgICA8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgdmlld0JveD0iMCAwIDEwMCAxMDAiPiw8cmVjdCBmaWxsPSIjYTJkYmUxIiB3aWR0aD0iMTAwIiBoZWlnaHQ9IjEwMCIvPjxnPjxnPjxwb2x5Z29uIGZpbGw9IiNhMmRiZTEiIHBvaW50cz0iLTkwLDEwMCAtMTAwLDEwMCAtNjAsMCAtNTAsMCAiLz48cG9seWdvbiBmaWxsPSIjYTJkYmUxIiBwb2ludHM9Ii03MCwxMDAgLTgwLDEwMCAtNDAsMCAtMzAsMCAiLz48cG9seWdvbiBmaWxsPSIjYTJkYmUxIiBwb2ludHM9Ii01MCwxMDAgLTYwLDEwMCAtMjAsMCAtMTAsMCAiLz48cG9seWdvbiBmaWxsPSIjYTJkYmUxIiBwb2ludHM9Ii0zMCwxMDAgLTQwLDEwMCAwLDAgMTAsMCAiLz48cG9seWdvbiBmaWxsPSIjYTJkYmUxIiBwb2ludHM9Ii0xMCwxMDAgLTIwLDEwMCAyMCwwIDMwLDAgIi8+PHBvbHlnb24gZmlsbD0iI2EyZGJlMSIgcG9pbnRzPSIxMCwxMDAgMCwxMDAgNDAsMCA1MCwwICIvPjxwb2x5Z29uIGZpbGw9IiNhMmRiZTEiIHBvaW50cz0iMzAsMTAwIDIwLDEwMCA2MCwwIDcwLDAgIi8+PHBvbHlnb24gZmlsbD0iI2EyZGJlMSIgcG9pbnRzPSI1MCwxMDAgNDAsMTAwIDgwLDAgOTAsMCAiLz48cG9seWdvbiBmaWxsPSIjYTJkYmUxIiBwb2ludHM9IjcwLDEwMCA2MCwxMDAgMTAwLDAgMTEwLDAgIi8+PHBvbHlnb24gZmlsbD0iI2EyZGJlMSIgcG9pbnRzPSI5MCwxMDAgODAsMTAwIDEyMCwwIDEzMCwwICIvPjxwb2x5Z29uIGZpbGw9IiNhMmRiZTEiIHBvaW50cz0iMTEwLDEwMCAxMDAsMTAwIDE0MCwwIDE1MCwwICIvPjxwb2x5Z29uIGZpbGw9IiNhMmRiZTEiIHBvaW50cz0iMTMwLDEwMCAxMjAsMTAwIDE2MCwwIDE3MCwwICIvPjxwb2x5Z29uIGZpbGw9IiNhMmRiZTEiIHBvaW50cz0iMTUwLDEwMCAxNDAsMTAwIDE4MCwwIDE5MCwwICIvPjwvZz48YW5pbWF0ZVRyYW5zZm9ybSBhdHRyaWJ1dGVOYW1lPSJ0cmFuc2Zvcm0iIHR5cGU9InRyYW5zbGF0ZSIgZnJvbT0iMCwwIiB0bz0iMjAsMCIgZHVyPSIxMHMiIHJlcGVhdENvdW50PSJpbmRlZmluaXRlIi8+PC9nPjwvc3ZnPg==">
                                </image>
                              </pattern>
                            </defs>
                            <g>
                              <path d="M10 20Q20 15 30 20Q40 25 50 20Q60 15 70 20Q80 25 90 20" fill="none"
                                class="baseline" stroke="#ddd" stroke-width="0.5"></path>
                            </g>
                            <g>
                              <path elem-ready [attr.value]="value.average_per"
                                d="M10 20Q20 15 30 20Q40 25 50 20Q60 15 70 20Q80 25 90 20" class="mainline" clip-path=""
                                fill="none" stroke-width="3" stroke="url(#ldBar-12ada8642562f-pattern)"
                                attr.stroke-dasharray="{{value.average_per}} 80.25284729003907"></path>
                            </g>
                          </svg>
                          <div class="ldBar-label">{{value.average_per}}</div>
                        </div>

                      </div>
                    </div>
                    <span class="d-flex justify-content-center align-items-center chevron-icon-right">
                      <i class="fa fa-angle-right" aria-hidden="true"></i>
                    </span>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-4">
                <div class="bg-white data-section-wrapper">
                  <h3 class="data-heading mb-4 font-size-22"> Development Areas </h3>
                  <div *ngFor="let value of viewReport.response.highComp.data.Low"
                    class="d-flex red-gradient  padding-gradient items-highlight mb-3" data-toggle="modal"
                    data-target="#myModal" (click)="openModal(value,'highComp')">
                    <div class="bg-white progress-bar-section">
                      <h5 class="data-sub-heading">
                        {{value.success_factor}}
                      </h5>
                      <div class=" flex-direction-row graph-spacing">
                        <!-- ,="" -->
                        <div class="ldBar d-flex progress-bar-container"
                          data-stroke="data:ldbar/res,stripe(#a2dbe1,#a2dbe1,10)"
                          data-path="M10 20Q20 15 30 20Q40 25 50 20Q60 15 70 20Q80 25 90 20" data-value="30"
                          style="width: 100% !important; height: 14px !important;">
                          <svg xmlns:xlink="http://www.w3.org/1999/xlink" preserveAspectRatio="xMidYMid" width="100%"
                            height="100%" viewBox="5.5 13 89 14">
                            <defs>
                              <filter id="ldBar-12ada8642562f-filter" x="-1" y="-1" width="3" height="3">
                                <feMorphology operator="dilate" radius="3"></feMorphology>
                                <feColorMatrix values="0 0 0 0 1    0 0 0 0 1    0 0 0 0 1    0 0 0 1 0" result="cm">
                                </feColorMatrix>
                              </filter>
                              <mask id="ldBar-12ada8642562f-mask">
                                <image xlink:href="" filter="url(#ldBar-12ada8642562f-filter)" x="0" y="0" width="100"
                                  height="100" preserveAspectRatio="xMidYMid"></image>
                              </mask>
                              <g>
                                <mask id="ldBar-12ada8642562f-mask-path">
                                  <path d="M10 20Q20 15 30 20Q40 25 50 20Q60 15 70 20Q80 25 90 20" fill="#fff"
                                    stroke="#fff" filter="url(#ldBar-12ada8642562f-filter)">
                                  </path>
                                </mask>
                              </g>
                              <clipPath id="ldBar-12ada8642562f-clip">
                                <rect class="mask" fill="#000"></rect>
                              </clipPath>
                              <pattern id="ldBar-12ada8642562f-pattern" patternUnits="userSpaceOnUse" x="0" y="0"
                                width="150" height="150">
                                <image x="0" y="0" width="150" height="150"
                                  xlink:href="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KICAgICAgICA8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgdmlld0JveD0iMCAwIDEwMCAxMDAiPiw8cmVjdCBmaWxsPSIjYTJkYmUxIiB3aWR0aD0iMTAwIiBoZWlnaHQ9IjEwMCIvPjxnPjxnPjxwb2x5Z29uIGZpbGw9IiNhMmRiZTEiIHBvaW50cz0iLTkwLDEwMCAtMTAwLDEwMCAtNjAsMCAtNTAsMCAiLz48cG9seWdvbiBmaWxsPSIjYTJkYmUxIiBwb2ludHM9Ii03MCwxMDAgLTgwLDEwMCAtNDAsMCAtMzAsMCAiLz48cG9seWdvbiBmaWxsPSIjYTJkYmUxIiBwb2ludHM9Ii01MCwxMDAgLTYwLDEwMCAtMjAsMCAtMTAsMCAiLz48cG9seWdvbiBmaWxsPSIjYTJkYmUxIiBwb2ludHM9Ii0zMCwxMDAgLTQwLDEwMCAwLDAgMTAsMCAiLz48cG9seWdvbiBmaWxsPSIjYTJkYmUxIiBwb2ludHM9Ii0xMCwxMDAgLTIwLDEwMCAyMCwwIDMwLDAgIi8+PHBvbHlnb24gZmlsbD0iI2EyZGJlMSIgcG9pbnRzPSIxMCwxMDAgMCwxMDAgNDAsMCA1MCwwICIvPjxwb2x5Z29uIGZpbGw9IiNhMmRiZTEiIHBvaW50cz0iMzAsMTAwIDIwLDEwMCA2MCwwIDcwLDAgIi8+PHBvbHlnb24gZmlsbD0iI2EyZGJlMSIgcG9pbnRzPSI1MCwxMDAgNDAsMTAwIDgwLDAgOTAsMCAiLz48cG9seWdvbiBmaWxsPSIjYTJkYmUxIiBwb2ludHM9IjcwLDEwMCA2MCwxMDAgMTAwLDAgMTEwLDAgIi8+PHBvbHlnb24gZmlsbD0iI2EyZGJlMSIgcG9pbnRzPSI5MCwxMDAgODAsMTAwIDEyMCwwIDEzMCwwICIvPjxwb2x5Z29uIGZpbGw9IiNhMmRiZTEiIHBvaW50cz0iMTEwLDEwMCAxMDAsMTAwIDE0MCwwIDE1MCwwICIvPjxwb2x5Z29uIGZpbGw9IiNhMmRiZTEiIHBvaW50cz0iMTMwLDEwMCAxMjAsMTAwIDE2MCwwIDE3MCwwICIvPjxwb2x5Z29uIGZpbGw9IiNhMmRiZTEiIHBvaW50cz0iMTUwLDEwMCAxNDAsMTAwIDE4MCwwIDE5MCwwICIvPjwvZz48YW5pbWF0ZVRyYW5zZm9ybSBhdHRyaWJ1dGVOYW1lPSJ0cmFuc2Zvcm0iIHR5cGU9InRyYW5zbGF0ZSIgZnJvbT0iMCwwIiB0bz0iMjAsMCIgZHVyPSIxMHMiIHJlcGVhdENvdW50PSJpbmRlZmluaXRlIi8+PC9nPjwvc3ZnPg==">
                                </image>
                              </pattern>
                            </defs>
                            <g>
                              <path d="M10 20Q20 15 30 20Q40 25 50 20Q60 15 70 20Q80 25 90 20" fill="none"
                                class="baseline" stroke="#ddd" stroke-width="0.5"></path>
                            </g>
                            <g>
                              <path elem-ready [attr.value]="value.average_per"
                                d="M10 20Q20 15 30 20Q40 25 50 20Q60 15 70 20Q80 25 90 20" class="mainline" clip-path=""
                                fill="none" stroke-width="3" stroke="url(#ldBar-12ada8642562f-pattern)"
                                attr.stroke-dasharray="{{value.average_per}} 80.25284729003907"></path>
                            </g>
                          </svg>
                          <div class="ldBar-label">{{value.average_per}}</div>
                        </div>

                      </div>
                    </div>
                    <span class="d-flex justify-content-center align-items-center chevron-icon-right">
                      <i class="fa fa-angle-right" aria-hidden="true"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


      </div>
    </main>


  </div>

  <div *ngIf="visiblePageIndex === 6">
    <main class=" organisational-report">
      <div class="container-fluid padding-left-right-zero">
        <div id="overlay" class="overlay"></div>
        <div class="position-relative d-flex justify-content-center">
          <div class="container w-100 d-flex justify-content-center align-item-center h-100">
            <div class="section-line-circle high-performance-line"></div>
            <div class="col-lg-4 col-md-4 report-summary-wrapper performance-summary-wrapper">
              <h2 class="page-heading">{{viewReport.response.orgComp.title}} </h2>
              <p class="page-desc">
                {{viewReport.response.orgComp.description}}
              </p>
            </div>
          </div>

          <div class="container mb-5 driver-content">
            <div style="margin-bottom: 30px;" class="row">
              <div class="col-lg-4 col-md-4">
                <div class="bg-white data-section-wrapper">
                  <h3 class="data-heading mb-4 font-size-22"> Strengths </h3>
                  <div *ngFor="let value of viewReport.response.orgComp.data.High"
                    class="d-flex green-gradient  padding-gradient items-highlight mb-3" data-toggle="modal"
                    data-target="#myModal" (click)="openModal(value,'orgComp')">
                    <div class="bg-white progress-bar-section open-modal">
                      <h5 class="data-sub-heading">{{value.org_name}}</h5>
                      <div class=" flex-direction-row graph-spacing">
                        <!-- ,=""  -->
                        <div class="ldBar d-flex progress-bar-container"
                          data-stroke="data:ldbar/res,stripe(#a2dbe1,#a2dbe1,10)"
                          data-path="M10 20Q20 15 30 20Q40 25 50 20Q60 15 70 20Q80 25 90 20" data-value="30"
                          style="width: 100% !important; height: 14px !important;">
                          <svg xmlns:xlink="http://www.w3.org/1999/xlink" preserveAspectRatio="xMidYMid" width="100%"
                            height="100%" viewBox="5.5 13 89 14">
                            <defs>
                              <filter id="ldBar-12ada8642562f-filter" x="-1" y="-1" width="3" height="3">
                                <feMorphology operator="dilate" radius="3"></feMorphology>
                                <feColorMatrix values="0 0 0 0 1    0 0 0 0 1    0 0 0 0 1    0 0 0 1 0" result="cm">
                                </feColorMatrix>
                              </filter>
                              <mask id="ldBar-12ada8642562f-mask">
                                <image xlink:href="" filter="url(#ldBar-12ada8642562f-filter)" x="0" y="0" width="100"
                                  height="100" preserveAspectRatio="xMidYMid"></image>
                              </mask>
                              <g>
                                <mask id="ldBar-12ada8642562f-mask-path">
                                  <path d="M10 20Q20 15 30 20Q40 25 50 20Q60 15 70 20Q80 25 90 20" fill="#fff"
                                    stroke="#fff" filter="url(#ldBar-12ada8642562f-filter)">
                                  </path>
                                </mask>
                              </g>
                              <clipPath id="ldBar-12ada8642562f-clip">
                                <rect class="mask" fill="#000"></rect>
                              </clipPath>
                              <pattern id="ldBar-12ada8642562f-pattern" patternUnits="userSpaceOnUse" x="0" y="0"
                                width="150" height="150">
                                <image x="0" y="0" width="150" height="150"
                                  xlink:href="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KICAgICAgICA8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgdmlld0JveD0iMCAwIDEwMCAxMDAiPiw8cmVjdCBmaWxsPSIjYTJkYmUxIiB3aWR0aD0iMTAwIiBoZWlnaHQ9IjEwMCIvPjxnPjxnPjxwb2x5Z29uIGZpbGw9IiNhMmRiZTEiIHBvaW50cz0iLTkwLDEwMCAtMTAwLDEwMCAtNjAsMCAtNTAsMCAiLz48cG9seWdvbiBmaWxsPSIjYTJkYmUxIiBwb2ludHM9Ii03MCwxMDAgLTgwLDEwMCAtNDAsMCAtMzAsMCAiLz48cG9seWdvbiBmaWxsPSIjYTJkYmUxIiBwb2ludHM9Ii01MCwxMDAgLTYwLDEwMCAtMjAsMCAtMTAsMCAiLz48cG9seWdvbiBmaWxsPSIjYTJkYmUxIiBwb2ludHM9Ii0zMCwxMDAgLTQwLDEwMCAwLDAgMTAsMCAiLz48cG9seWdvbiBmaWxsPSIjYTJkYmUxIiBwb2ludHM9Ii0xMCwxMDAgLTIwLDEwMCAyMCwwIDMwLDAgIi8+PHBvbHlnb24gZmlsbD0iI2EyZGJlMSIgcG9pbnRzPSIxMCwxMDAgMCwxMDAgNDAsMCA1MCwwICIvPjxwb2x5Z29uIGZpbGw9IiNhMmRiZTEiIHBvaW50cz0iMzAsMTAwIDIwLDEwMCA2MCwwIDcwLDAgIi8+PHBvbHlnb24gZmlsbD0iI2EyZGJlMSIgcG9pbnRzPSI1MCwxMDAgNDAsMTAwIDgwLDAgOTAsMCAiLz48cG9seWdvbiBmaWxsPSIjYTJkYmUxIiBwb2ludHM9IjcwLDEwMCA2MCwxMDAgMTAwLDAgMTEwLDAgIi8+PHBvbHlnb24gZmlsbD0iI2EyZGJlMSIgcG9pbnRzPSI5MCwxMDAgODAsMTAwIDEyMCwwIDEzMCwwICIvPjxwb2x5Z29uIGZpbGw9IiNhMmRiZTEiIHBvaW50cz0iMTEwLDEwMCAxMDAsMTAwIDE0MCwwIDE1MCwwICIvPjxwb2x5Z29uIGZpbGw9IiNhMmRiZTEiIHBvaW50cz0iMTMwLDEwMCAxMjAsMTAwIDE2MCwwIDE3MCwwICIvPjxwb2x5Z29uIGZpbGw9IiNhMmRiZTEiIHBvaW50cz0iMTUwLDEwMCAxNDAsMTAwIDE4MCwwIDE5MCwwICIvPjwvZz48YW5pbWF0ZVRyYW5zZm9ybSBhdHRyaWJ1dGVOYW1lPSJ0cmFuc2Zvcm0iIHR5cGU9InRyYW5zbGF0ZSIgZnJvbT0iMCwwIiB0bz0iMjAsMCIgZHVyPSIxMHMiIHJlcGVhdENvdW50PSJpbmRlZmluaXRlIi8+PC9nPjwvc3ZnPg==">
                                </image>
                              </pattern>
                            </defs>
                            <g>
                              <path d="M10 20Q20 15 30 20Q40 25 50 20Q60 15 70 20Q80 25 90 20" fill="none"
                                class="baseline" stroke="#ddd" stroke-width="0.5"></path>
                            </g>
                            <g>
                              <path elem-ready [attr.value]="value.org_competency.overall_average_per"
                                d="M10 20Q20 15 30 20Q40 25 50 20Q60 15 70 20Q80 25 90 20" class="mainline" clip-path=""
                                fill="none" stroke-width="3" stroke="url(#ldBar-12ada8642562f-pattern)"
                                attr.stroke-dasharray="{{value.org_competency.overall_average_per}} 80.25284729003907">
                              </path>
                            </g>
                          </svg>
                          <div class="ldBar-label">{{value.org_competency.overall_average_per}}</div>
                        </div>

                      </div>
                    </div>
                    <span class="d-flex justify-content-center align-items-center chevron-icon-right">
                      <i class="fa fa-angle-right" aria-hidden="true"></i>
                    </span>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-4">
                <div class="bg-white data-section-wrapper">
                  <h3 class="data-heading mb-4 font-size-22"> Potential Strengths </h3>

                  <div *ngFor="let value of viewReport.response.orgComp.data.Medium"
                    class="d-flex orange-gradient  padding-gradient items-highlight mb-3" data-toggle="modal"
                    data-target="#myModal" (click)="openModal(value,'orgComp')">
                    <div class="bg-white progress-bar-section">
                      <h5 class="data-sub-heading"> {{value.org_name}}</h5>
                      <div class=" flex-direction-row graph-spacing">
                        <!--  ,="" -->
                        <div class="ldBar d-flex progress-bar-container"
                          data-stroke="data:ldbar/res,stripe(#a2dbe1,#a2dbe1,10)"
                          data-path="M10 20Q20 15 30 20Q40 25 50 20Q60 15 70 20Q80 25 90 20" data-value="30"
                          style="width: 100%!important; height: 14px !important;">
                          <svg xmlns:xlink="http://www.w3.org/1999/xlink" preserveAspectRatio="xMidYMid" width="100%"
                            height="100%" viewBox="5.5 13 89 14">
                            <defs>
                              <filter id="ldBar-12ada8642562f-filter" x="-1" y="-1" width="3" height="3">
                                <feMorphology operator="dilate" radius="3"></feMorphology>
                                <feColorMatrix values="0 0 0 0 1    0 0 0 0 1    0 0 0 0 1    0 0 0 1 0" result="cm">
                                </feColorMatrix>
                              </filter>
                              <mask id="ldBar-12ada8642562f-mask">
                                <image xlink:href="" filter="url(#ldBar-12ada8642562f-filter)" x="0" y="0" width="100"
                                  height="100" preserveAspectRatio="xMidYMid"></image>
                              </mask>
                              <g>
                                <mask id="ldBar-12ada8642562f-mask-path">
                                  <path d="M10 20Q20 15 30 20Q40 25 50 20Q60 15 70 20Q80 25 90 20" fill="#fff"
                                    stroke="#fff" filter="url(#ldBar-12ada8642562f-filter)">
                                  </path>
                                </mask>
                              </g>
                              <clipPath id="ldBar-12ada8642562f-clip">
                                <rect class="mask" fill="#000"></rect>
                              </clipPath>
                              <pattern id="ldBar-12ada8642562f-pattern" patternUnits="userSpaceOnUse" x="0" y="0"
                                width="150" height="150">
                                <image x="0" y="0" width="150" height="150"
                                  xlink:href="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KICAgICAgICA8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgdmlld0JveD0iMCAwIDEwMCAxMDAiPiw8cmVjdCBmaWxsPSIjYTJkYmUxIiB3aWR0aD0iMTAwIiBoZWlnaHQ9IjEwMCIvPjxnPjxnPjxwb2x5Z29uIGZpbGw9IiNhMmRiZTEiIHBvaW50cz0iLTkwLDEwMCAtMTAwLDEwMCAtNjAsMCAtNTAsMCAiLz48cG9seWdvbiBmaWxsPSIjYTJkYmUxIiBwb2ludHM9Ii03MCwxMDAgLTgwLDEwMCAtNDAsMCAtMzAsMCAiLz48cG9seWdvbiBmaWxsPSIjYTJkYmUxIiBwb2ludHM9Ii01MCwxMDAgLTYwLDEwMCAtMjAsMCAtMTAsMCAiLz48cG9seWdvbiBmaWxsPSIjYTJkYmUxIiBwb2ludHM9Ii0zMCwxMDAgLTQwLDEwMCAwLDAgMTAsMCAiLz48cG9seWdvbiBmaWxsPSIjYTJkYmUxIiBwb2ludHM9Ii0xMCwxMDAgLTIwLDEwMCAyMCwwIDMwLDAgIi8+PHBvbHlnb24gZmlsbD0iI2EyZGJlMSIgcG9pbnRzPSIxMCwxMDAgMCwxMDAgNDAsMCA1MCwwICIvPjxwb2x5Z29uIGZpbGw9IiNhMmRiZTEiIHBvaW50cz0iMzAsMTAwIDIwLDEwMCA2MCwwIDcwLDAgIi8+PHBvbHlnb24gZmlsbD0iI2EyZGJlMSIgcG9pbnRzPSI1MCwxMDAgNDAsMTAwIDgwLDAgOTAsMCAiLz48cG9seWdvbiBmaWxsPSIjYTJkYmUxIiBwb2ludHM9IjcwLDEwMCA2MCwxMDAgMTAwLDAgMTEwLDAgIi8+PHBvbHlnb24gZmlsbD0iI2EyZGJlMSIgcG9pbnRzPSI5MCwxMDAgODAsMTAwIDEyMCwwIDEzMCwwICIvPjxwb2x5Z29uIGZpbGw9IiNhMmRiZTEiIHBvaW50cz0iMTEwLDEwMCAxMDAsMTAwIDE0MCwwIDE1MCwwICIvPjxwb2x5Z29uIGZpbGw9IiNhMmRiZTEiIHBvaW50cz0iMTMwLDEwMCAxMjAsMTAwIDE2MCwwIDE3MCwwICIvPjxwb2x5Z29uIGZpbGw9IiNhMmRiZTEiIHBvaW50cz0iMTUwLDEwMCAxNDAsMTAwIDE4MCwwIDE5MCwwICIvPjwvZz48YW5pbWF0ZVRyYW5zZm9ybSBhdHRyaWJ1dGVOYW1lPSJ0cmFuc2Zvcm0iIHR5cGU9InRyYW5zbGF0ZSIgZnJvbT0iMCwwIiB0bz0iMjAsMCIgZHVyPSIxMHMiIHJlcGVhdENvdW50PSJpbmRlZmluaXRlIi8+PC9nPjwvc3ZnPg==">
                                </image>
                              </pattern>
                            </defs>
                            <g>
                              <path d="M10 20Q20 15 30 20Q40 25 50 20Q60 15 70 20Q80 25 90 20" fill="none"
                                class="baseline" stroke="#ddd" stroke-width="0.5"></path>
                            </g>
                            <g>
                              <path elem-ready [attr.value]="value.org_competency.overall_average_per"
                                d="M10 20Q20 15 30 20Q40 25 50 20Q60 15 70 20Q80 25 90 20" class="mainline" clip-path=""
                                fill="none" stroke-width="3" stroke="url(#ldBar-12ada8642562f-pattern)"
                                attr.stroke-dasharray="{{value.org_competency.overall_average_per}} 80.25284729003907">
                              </path>
                            </g>
                          </svg>
                          <div class="ldBar-label">{{value.org_competency.overall_average_per}}</div>
                        </div>
                      </div>
                    </div>
                    <span class="d-flex justify-content-center align-items-center chevron-icon-right">
                      <i class="fa fa-angle-right" aria-hidden="true"></i>
                    </span>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-4">
                <div class="bg-white data-section-wrapper">
                  <h3 class="data-heading mb-4 font-size-22"> Development Areas </h3>
                  <div *ngFor="let value of viewReport.response.orgComp.data.Low"
                    class="d-flex red-gradient padding-gradient items-highlight mb-3" data-toggle="modal"
                    data-target="#myModal" (click)="openModal(value,'orgComp')">
                    <div class="bg-white progress-bar-section">
                      <h5 class="data-sub-heading">{{value.org_name}}</h5>
                      <div class=" flex-direction-row graph-spacing">
                        <!-- ,="" -->
                        <div class="ldBar d-flex progress-bar-container"
                          data-stroke="data:ldbar/res,stripe(#a2dbe1,#a2dbe1,10)"
                          data-path="M10 20Q20 15 30 20Q40 25 50 20Q60 15 70 20Q80 25 90 20" data-value="30"
                          style="width: 100% !important; height: 14px !important;">
                          <svg xmlns:xlink="http://www.w3.org/1999/xlink" preserveAspectRatio="xMidYMid" width="100%"
                            height="100%" viewBox="5.5 13 89 14">
                            <defs>
                              <filter id="ldBar-12ada8642562f-filter" x="-1" y="-1" width="3" height="3">
                                <feMorphology operator="dilate" radius="3"></feMorphology>
                                <feColorMatrix values="0 0 0 0 1    0 0 0 0 1    0 0 0 0 1    0 0 0 1 0" result="cm">
                                </feColorMatrix>
                              </filter>
                              <mask id="ldBar-12ada8642562f-mask">
                                <image xlink:href="" filter="url(#ldBar-12ada8642562f-filter)" x="0" y="0" width="100"
                                  height="100" preserveAspectRatio="xMidYMid"></image>
                              </mask>
                              <g>
                                <mask id="ldBar-12ada8642562f-mask-path">
                                  <path d="M10 20Q20 15 30 20Q40 25 50 20Q60 15 70 20Q80 25 90 20" fill="#fff"
                                    stroke="#fff" filter="url(#ldBar-12ada8642562f-filter)">
                                  </path>
                                </mask>
                              </g>
                              <clipPath id="ldBar-12ada8642562f-clip">
                                <rect class="mask" fill="#000"></rect>
                              </clipPath>
                              <pattern id="ldBar-12ada8642562f-pattern" patternUnits="userSpaceOnUse" x="0" y="0"
                                width="150" height="150">
                                <image x="0" y="0" width="150" height="150"
                                  xlink:href="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KICAgICAgICA8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgdmlld0JveD0iMCAwIDEwMCAxMDAiPiw8cmVjdCBmaWxsPSIjYTJkYmUxIiB3aWR0aD0iMTAwIiBoZWlnaHQ9IjEwMCIvPjxnPjxnPjxwb2x5Z29uIGZpbGw9IiNhMmRiZTEiIHBvaW50cz0iLTkwLDEwMCAtMTAwLDEwMCAtNjAsMCAtNTAsMCAiLz48cG9seWdvbiBmaWxsPSIjYTJkYmUxIiBwb2ludHM9Ii03MCwxMDAgLTgwLDEwMCAtNDAsMCAtMzAsMCAiLz48cG9seWdvbiBmaWxsPSIjYTJkYmUxIiBwb2ludHM9Ii01MCwxMDAgLTYwLDEwMCAtMjAsMCAtMTAsMCAiLz48cG9seWdvbiBmaWxsPSIjYTJkYmUxIiBwb2ludHM9Ii0zMCwxMDAgLTQwLDEwMCAwLDAgMTAsMCAiLz48cG9seWdvbiBmaWxsPSIjYTJkYmUxIiBwb2ludHM9Ii0xMCwxMDAgLTIwLDEwMCAyMCwwIDMwLDAgIi8+PHBvbHlnb24gZmlsbD0iI2EyZGJlMSIgcG9pbnRzPSIxMCwxMDAgMCwxMDAgNDAsMCA1MCwwICIvPjxwb2x5Z29uIGZpbGw9IiNhMmRiZTEiIHBvaW50cz0iMzAsMTAwIDIwLDEwMCA2MCwwIDcwLDAgIi8+PHBvbHlnb24gZmlsbD0iI2EyZGJlMSIgcG9pbnRzPSI1MCwxMDAgNDAsMTAwIDgwLDAgOTAsMCAiLz48cG9seWdvbiBmaWxsPSIjYTJkYmUxIiBwb2ludHM9IjcwLDEwMCA2MCwxMDAgMTAwLDAgMTEwLDAgIi8+PHBvbHlnb24gZmlsbD0iI2EyZGJlMSIgcG9pbnRzPSI5MCwxMDAgODAsMTAwIDEyMCwwIDEzMCwwICIvPjxwb2x5Z29uIGZpbGw9IiNhMmRiZTEiIHBvaW50cz0iMTEwLDEwMCAxMDAsMTAwIDE0MCwwIDE1MCwwICIvPjxwb2x5Z29uIGZpbGw9IiNhMmRiZTEiIHBvaW50cz0iMTMwLDEwMCAxMjAsMTAwIDE2MCwwIDE3MCwwICIvPjxwb2x5Z29uIGZpbGw9IiNhMmRiZTEiIHBvaW50cz0iMTUwLDEwMCAxNDAsMTAwIDE4MCwwIDE5MCwwICIvPjwvZz48YW5pbWF0ZVRyYW5zZm9ybSBhdHRyaWJ1dGVOYW1lPSJ0cmFuc2Zvcm0iIHR5cGU9InRyYW5zbGF0ZSIgZnJvbT0iMCwwIiB0bz0iMjAsMCIgZHVyPSIxMHMiIHJlcGVhdENvdW50PSJpbmRlZmluaXRlIi8+PC9nPjwvc3ZnPg==">
                                </image>
                              </pattern>
                            </defs>
                            <g>
                              <path d="M10 20Q20 15 30 20Q40 25 50 20Q60 15 70 20Q80 25 90 20" fill="none"
                                class="baseline" stroke="#ddd" stroke-width="0.5"></path>
                            </g>
                            <g>
                              <path elem-ready [attr.value]="value.org_competency.overall_average_per"
                                d="M10 20Q20 15 30 20Q40 25 50 20Q60 15 70 20Q80 25 90 20" class="mainline" clip-path=""
                                fill="none" stroke-width="3" stroke="url(#ldBar-12ada8642562f-pattern)"
                                attr.stroke-dasharray="{{value.org_competency.overall_average_per}} 80.25284729003907">
                              </path>
                            </g>
                          </svg>
                          <div class="ldBar-label">{{value.org_competency.overall_average_per}}</div>
                        </div>
                      </div>
                    </div>
                    <span class="d-flex justify-content-center align-items-center chevron-icon-right">
                      <i class="fa fa-angle-right" aria-hidden="true"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>



      </div>
    </main>


  </div>



  <!-- MODAL -->
  <ng-template style="max-width: 960px;width: 100% !important;" #Modal let-modal>
    <!-- <div class="modal-header">
          <h4 class="modal-title" id="modal-basic-title">Profile update</h4>
          <button style="float: right; height: 0; margin-top: -20px;margin-right: 16px; cursor: pointer;" type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
          </button>
        </div> -->
    <div class="l-d">
      <div class="high-competency-modal">
        <div class="modal-content">
          <div class="d-flex flex-column modal-header modal-header-style">
            <button (click)="modal.dismiss('Cross click')" id="ModalCloseButton" type="button" class="close"
              data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true" class="cross-icon">&times;</span>
            </button>
            <h5 class="modal-title" id="exampleModalLabel">{{ModalData.popHeader}}</h5>
            <p class="report-desc">
              {{ModalData.description}}
            </p>
            <div class="modal-score-container">
              <div class="bg-white col-lg-5 d-flex modal-score-wrapper">
                <div class="score-container"> {{ModalData.popAveragePer}} / 100 </div>
                <div class=" flex-direction-row graph-spacing width-70">

                  <div class="ldBar d-flex progress-bar-container"
                    data-stroke="data:ldbar/res,stripe(#a2dbe1,#a2dbe1,10)"
                    data-path="M10 20Q20 15 30 20Q40 25 50 20Q60 15 70 20Q80 25 90 20" data-value="30"
                    style="width: 100%!important;height: 60px!important;padding-top: 16px;">
                    <svg xmlns:xlink="http://www.w3.org/1999/xlink" preserveAspectRatio="xMidYMid" width="100%"
                      height="100%" viewBox="5.5 13 89 14">
                      <defs>
                        <filter id="ldBar-12ada8642562f-filter" x="-1" y="-1" width="3" height="3">
                          <feMorphology operator="dilate" radius="3"></feMorphology>
                          <feColorMatrix values="0 0 0 0 1    0 0 0 0 1    0 0 0 0 1    0 0 0 1 0" result="cm">
                          </feColorMatrix>
                        </filter>
                        <mask id="ldBar-12ada8642562f-mask">
                          <image xlink:href="" filter="url(#ldBar-12ada8642562f-filter)" x="0" y="0" width="100"
                            height="100" preserveAspectRatio="xMidYMid"></image>
                        </mask>
                        <g>
                          <mask id="ldBar-12ada8642562f-mask-path">
                            <path d="M10 20Q20 15 30 20Q40 25 50 20Q60 15 70 20Q80 25 90 20" fill="#fff" stroke="#fff"
                              filter="url(#ldBar-12ada8642562f-filter)">
                            </path>
                          </mask>
                        </g>
                        <clipPath id="ldBar-12ada8642562f-clip">
                          <rect class="mask" fill="#000"></rect>
                        </clipPath>
                        <pattern id="ldBar-12ada8642562f-pattern" patternUnits="userSpaceOnUse" x="0" y="0" width="150"
                          height="150">
                          <image x="0" y="0" width="150" height="150"
                            xlink:href="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KICAgICAgICA8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgdmlld0JveD0iMCAwIDEwMCAxMDAiPiw8cmVjdCBmaWxsPSIjYTJkYmUxIiB3aWR0aD0iMTAwIiBoZWlnaHQ9IjEwMCIvPjxnPjxnPjxwb2x5Z29uIGZpbGw9IiNhMmRiZTEiIHBvaW50cz0iLTkwLDEwMCAtMTAwLDEwMCAtNjAsMCAtNTAsMCAiLz48cG9seWdvbiBmaWxsPSIjYTJkYmUxIiBwb2ludHM9Ii03MCwxMDAgLTgwLDEwMCAtNDAsMCAtMzAsMCAiLz48cG9seWdvbiBmaWxsPSIjYTJkYmUxIiBwb2ludHM9Ii01MCwxMDAgLTYwLDEwMCAtMjAsMCAtMTAsMCAiLz48cG9seWdvbiBmaWxsPSIjYTJkYmUxIiBwb2ludHM9Ii0zMCwxMDAgLTQwLDEwMCAwLDAgMTAsMCAiLz48cG9seWdvbiBmaWxsPSIjYTJkYmUxIiBwb2ludHM9Ii0xMCwxMDAgLTIwLDEwMCAyMCwwIDMwLDAgIi8+PHBvbHlnb24gZmlsbD0iI2EyZGJlMSIgcG9pbnRzPSIxMCwxMDAgMCwxMDAgNDAsMCA1MCwwICIvPjxwb2x5Z29uIGZpbGw9IiNhMmRiZTEiIHBvaW50cz0iMzAsMTAwIDIwLDEwMCA2MCwwIDcwLDAgIi8+PHBvbHlnb24gZmlsbD0iI2EyZGJlMSIgcG9pbnRzPSI1MCwxMDAgNDAsMTAwIDgwLDAgOTAsMCAiLz48cG9seWdvbiBmaWxsPSIjYTJkYmUxIiBwb2ludHM9IjcwLDEwMCA2MCwxMDAgMTAwLDAgMTEwLDAgIi8+PHBvbHlnb24gZmlsbD0iI2EyZGJlMSIgcG9pbnRzPSI5MCwxMDAgODAsMTAwIDEyMCwwIDEzMCwwICIvPjxwb2x5Z29uIGZpbGw9IiNhMmRiZTEiIHBvaW50cz0iMTEwLDEwMCAxMDAsMTAwIDE0MCwwIDE1MCwwICIvPjxwb2x5Z29uIGZpbGw9IiNhMmRiZTEiIHBvaW50cz0iMTMwLDEwMCAxMjAsMTAwIDE2MCwwIDE3MCwwICIvPjxwb2x5Z29uIGZpbGw9IiNhMmRiZTEiIHBvaW50cz0iMTUwLDEwMCAxNDAsMTAwIDE4MCwwIDE5MCwwICIvPjwvZz48YW5pbWF0ZVRyYW5zZm9ybSBhdHRyaWJ1dGVOYW1lPSJ0cmFuc2Zvcm0iIHR5cGU9InRyYW5zbGF0ZSIgZnJvbT0iMCwwIiB0bz0iMjAsMCIgZHVyPSIxMHMiIHJlcGVhdENvdW50PSJpbmRlZmluaXRlIi8+PC9nPjwvc3ZnPg==">
                          </image>
                        </pattern>
                      </defs>
                      <g>
                        <path d="M10 20Q20 15 30 20Q40 25 50 20Q60 15 70 20Q80 25 90 20" fill="none" class="baseline"
                          stroke="#ddd" stroke-width="0.5"></path>
                      </g>
                      <g>
                        <path id="overlayScore" elem-ready [attr.value]="ModalData.popAveragePer"
                          d="M10 20Q20 15 30 20Q40 25 50 20Q60 15 70 20Q80 25 90 20" class="mainline" clip-path=""
                          fill="none" stroke-width="3" stroke="url(#ldBar-12ada8642562f-pattern)"
                          attr.stroke-dasharray="{{ModalData.popAveragePer}} 80.25284729003907"></path>
                      </g>
                    </svg>
                  </div>

                </div>
              </div>
            </div>
          </div>
          <div class="modal-body">
            <div class="content-padding">
              <h3 style="text-align:left;" class="report-result">Behavioral Indicators</h3>
              <p style="text-align:left;" class="report-result-desc">
                {{ModalData.popHeadDesc}}
              </p>
            </div>
            <div *ngIf="tips" class="development-tips-wrapper content-padding">
              <h4 style="text-align:left;"> Development Tips </h4>
              <div class="d-flex">
                <p style="text-align:left;" [innerHTML]="ModalData.tips | safe :'html'"></p>
              </div>
            </div>
            <div class="video-gallery content-padding" *ngIf="popType=='highComp'">

              <div *ngIf="popVideos.length > 0">
                <div class="" *ngIf="popType=='highComp'">

                  <h6> Video gallery </h6>
                </div>
              </div>


              <div class="row">
                <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2" *ngIf="popVideos.length>0">
                  <div *ngFor="let video of popVideos">
                    <div *ngIf="popVideos.length>0">
                      <a href="{{getVideoURLSafe(video)}}" target='__blank'>
                        <div>
                          <iframe width="120" height="80" *ngIf="popVideos.length>0" [src]="getVideoURLSafe(video)"
                            frameborder="0"></iframe>
                        </div>
                      </a>
                      <p class="video-name">
                        {{video.title}}
                      </p>
                    </div>
                  </div>


                </div>
              </div>

            </div>
          </div>

        </div>
      </div>
    </div>
    <!-- <div class="modal-footer">
          <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Save</button>
        </div> -->
  </ng-template>

  <!-- <div class=" modal fade high-competency-modal" id="Modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div class="modal-dialog" role="document" style="max-width: 960px;width: 100% !important;">
            <span style="float: right; height: 0; margin-top: -20px;margin-right: 16px; cursor: pointer;" data-dismiss="modal"
                aria-label="Close"> Close </span>
            <div class="modal-content">
                <div class="d-flex flex-column modal-header modal-header-style">
                    <button (click)="modalClosingCall()" id="ModalCloseButton" type="button" class="close"
                        data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true" class="cross-icon">&times;</span>
                    </button>
                    <h5 class="modal-title" id="exampleModalLabel">{{ModalData.popHeader}}</h5>
                    <p class="report-desc">
                        {{ModalData.description}}
                    </p>
                    <div class="modal-score-container">
                        <div class="bg-white col-lg-5 d-flex modal-score-wrapper">
                            <div class="score-container"> {{ModalData.popAveragePer}} / 100 </div>
                            <div class=" flex-direction-row graph-spacing width-70">

                                    <div class="ldBar d-flex progress-bar-container"  data-stroke="data:ldbar/res,stripe(#a2dbe1,#a2dbe1,10)" data-path="M10 20Q20 15 30 20Q40 25 50 20Q60 15 70 20Q80 25 90 20" data-value="30" style="width: 100%!important;height: 60px!important;padding-top: 16px;">
                                        <svg xmlns:xlink="http://www.w3.org/1999/xlink" preserveAspectRatio="xMidYMid" width="100%" height="100%" viewBox="5.5 13 89 14">
                                            <defs>
                                                <filter id="ldBar-12ada8642562f-filter" x="-1" y="-1" width="3" height="3">
                                                    <feMorphology operator="dilate" radius="3"></feMorphology>
                                                    <feColorMatrix values="0 0 0 0 1    0 0 0 0 1    0 0 0 0 1    0 0 0 1 0" result="cm"></feColorMatrix>
                                                </filter>
                                                <mask id="ldBar-12ada8642562f-mask">
                                                    <image xlink:href="" filter="url(#ldBar-12ada8642562f-filter)" x="0" y="0" width="100" height="100" preserveAspectRatio="xMidYMid"></image>
                                                </mask>
                                                <g>
                                                    <mask id="ldBar-12ada8642562f-mask-path">
                                                        <path d="M10 20Q20 15 30 20Q40 25 50 20Q60 15 70 20Q80 25 90 20" fill="#fff" stroke="#fff" filter="url(#ldBar-12ada8642562f-filter)">
                                                        </path>
                                                    </mask>
                                                </g>
                                                <clipPath id="ldBar-12ada8642562f-clip">
                                                    <rect class="mask" fill="#000"></rect>
                                                </clipPath>
                                                <pattern id="ldBar-12ada8642562f-pattern" patternUnits="userSpaceOnUse" x="0" y="0" width="150" height="150">
                                                    <image x="0" y="0" width="150" height="150" xlink:href="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KICAgICAgICA8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgdmlld0JveD0iMCAwIDEwMCAxMDAiPiw8cmVjdCBmaWxsPSIjYTJkYmUxIiB3aWR0aD0iMTAwIiBoZWlnaHQ9IjEwMCIvPjxnPjxnPjxwb2x5Z29uIGZpbGw9IiNhMmRiZTEiIHBvaW50cz0iLTkwLDEwMCAtMTAwLDEwMCAtNjAsMCAtNTAsMCAiLz48cG9seWdvbiBmaWxsPSIjYTJkYmUxIiBwb2ludHM9Ii03MCwxMDAgLTgwLDEwMCAtNDAsMCAtMzAsMCAiLz48cG9seWdvbiBmaWxsPSIjYTJkYmUxIiBwb2ludHM9Ii01MCwxMDAgLTYwLDEwMCAtMjAsMCAtMTAsMCAiLz48cG9seWdvbiBmaWxsPSIjYTJkYmUxIiBwb2ludHM9Ii0zMCwxMDAgLTQwLDEwMCAwLDAgMTAsMCAiLz48cG9seWdvbiBmaWxsPSIjYTJkYmUxIiBwb2ludHM9Ii0xMCwxMDAgLTIwLDEwMCAyMCwwIDMwLDAgIi8+PHBvbHlnb24gZmlsbD0iI2EyZGJlMSIgcG9pbnRzPSIxMCwxMDAgMCwxMDAgNDAsMCA1MCwwICIvPjxwb2x5Z29uIGZpbGw9IiNhMmRiZTEiIHBvaW50cz0iMzAsMTAwIDIwLDEwMCA2MCwwIDcwLDAgIi8+PHBvbHlnb24gZmlsbD0iI2EyZGJlMSIgcG9pbnRzPSI1MCwxMDAgNDAsMTAwIDgwLDAgOTAsMCAiLz48cG9seWdvbiBmaWxsPSIjYTJkYmUxIiBwb2ludHM9IjcwLDEwMCA2MCwxMDAgMTAwLDAgMTEwLDAgIi8+PHBvbHlnb24gZmlsbD0iI2EyZGJlMSIgcG9pbnRzPSI5MCwxMDAgODAsMTAwIDEyMCwwIDEzMCwwICIvPjxwb2x5Z29uIGZpbGw9IiNhMmRiZTEiIHBvaW50cz0iMTEwLDEwMCAxMDAsMTAwIDE0MCwwIDE1MCwwICIvPjxwb2x5Z29uIGZpbGw9IiNhMmRiZTEiIHBvaW50cz0iMTMwLDEwMCAxMjAsMTAwIDE2MCwwIDE3MCwwICIvPjxwb2x5Z29uIGZpbGw9IiNhMmRiZTEiIHBvaW50cz0iMTUwLDEwMCAxNDAsMTAwIDE4MCwwIDE5MCwwICIvPjwvZz48YW5pbWF0ZVRyYW5zZm9ybSBhdHRyaWJ1dGVOYW1lPSJ0cmFuc2Zvcm0iIHR5cGU9InRyYW5zbGF0ZSIgZnJvbT0iMCwwIiB0bz0iMjAsMCIgZHVyPSIxMHMiIHJlcGVhdENvdW50PSJpbmRlZmluaXRlIi8+PC9nPjwvc3ZnPg=="></image>
                                                </pattern>
                                            </defs>
                                            <g>
                                                <path d="M10 20Q20 15 30 20Q40 25 50 20Q60 15 70 20Q80 25 90 20" fill="none" class="baseline" stroke="#ddd" stroke-width="0.5"></path>
                                            </g>
                                            <g>
                                                <path id="overlayScore" elem-ready [attr.value]="popAveragePer" d="M10 20Q20 15 30 20Q40 25 50 20Q60 15 70 20Q80 25 90 20" class="mainline" clip-path="" fill="none" stroke-width="3" stroke="url(#ldBar-12ada8642562f-pattern)" attr.stroke-dasharray=""></path>
                                            </g>
                                        </svg>
                                        </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-body">
                    <div class="content-padding">
                        <h3 style="text-align:left;" class="report-result">Behavioral Indicators</h3>
                        <p style="text-align:left;" class="report-result-desc">
                            {{ModalData.popHeadDesc}}
                        </p>
                    </div>
                    <div *ngIf="tips" class="development-tips-wrapper content-padding">
                        <h4 style="text-align:left;" > Development Tips </h4>
                        <div class="d-flex">
                            <p style="text-align:left;" [innerHtml]="safeHtml(tips)"></p>
                        </div>
                    </div>
                    <div class="video-gallery content-padding" *ngIf="popType=='highComp'">

                        <div *ngIf="popVideos.length > 0">
                            <div class="" *ngIf="popType=='highComp'">

                                <h6> Video gallery </h6>
                            </div>
                        </div>


                        <div class="row">
                            <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2" *ngIf="popVideos.length>0" >
                                <div *ngFor="let video of popVideos">
                                <div *ngIf="popVideos.length>0">
                                    <a href="{{getVideoURLSafe(video)}}" target='__blank'>
                                       <div>
                                        <iframe width="120" height="80" *ngIf="popVideos.length>0" [src]="getVideoURLSafe(video)"
                                            frameborder="0"></iframe>
                                       </div>
                                    </a>
                                    <p class="video-name">
                                        {{video.title}}
                                    </p>
                                </div>
                                </div>


                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </div>
    </div> -->



</div>
