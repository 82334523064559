<div class="HeadStrip">
  <div class="container">
    <div class="row">
      <div class="col-md-2 position-relative">
        <div
          class="Selectnumber"
          *ngIf="selectedAssessment.job_type === 'Customised Profile Fit'"
        >
          <div class="Sub">
            <a routerLink="../domain"
              ><i class="fa fa-caret-left" aria-hidden="true"></i>Back</a
            >
          </div>
          05
        </div>
        <div
          class="Selectnumber"
          *ngIf="selectedAssessment.job_type !== 'Customised Profile Fit'"
        >
          <div class="Sub">
            <a routerLink="../competency"
              ><i class="fa fa-caret-left" aria-hidden="true"></i>Back</a
            >
          </div>
          03
        </div>
      </div>
      <div class="col-md-10">
        <div class="ProfileHedding">
          <h2>Add Technical Assessment</h2>
        </div>
      </div>
    </div>
    <!--  row  -->
  </div>
  <!--  container  -->
</div>
<!--  HeadStrip -->

<!--  Start Section  -->

<div class="container WebAppD">
  <div class="row">
    <div class="col-md-2">
      <span class="WADomainIco"></span>
    </div>
    <div class="col-md-10">
      <div class="row">
        <div
          class="col-md-3"
          *ngFor="let dept of company.company.departments; let i = index"
        >
          <div
            class="WebAppBox"
            [ngClass]="setActive(dept._id)"
            (click)="departmentSelected(dept)"
          >
            <h2>{{ dept.name }}</h2>
          </div>

          <a href="javascript: void(0);"></a>
        </div>
        <div class="col-md-12">
          <div class="WebAppBut">
            <button
              type="button"
              class="new-btn"
              (click)="saveAssessmentData()"
            >
              Next
            </button>
          </div>
        </div>
      </div>
      <!--  row  -->
    </div>
  </div>
  <!-- row  -->
</div>
<!--  End Section  -->
