import { Component, OnInit } from '@angular/core';
import {
  AssessmentService,
  IAssessment,
} from '../assessment-service/assessment.service';
import { Router } from '@angular/router';
import { SharedDataService } from 'src/app/shared-services/shared-data/shared-data.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  profiles = this.as.assessments.profileDetails;
  selectedProfile = this.profiles[this.profiles.length - 1].profile_name;
  assessment: IAssessment;
  constructor(private as: AssessmentService, private _router: Router, private shareService: SharedDataService) {}

  ngOnInit(): void {

    this.shareService.setActiveTab('Assessments');

    this.Init();
  }

  // TODO clear up this mess
  setActive(value, name) {
    if (this.selectedProfile === value) {
      return 'active';
    }
  }

  profileSelect(profile) {
    this.selectedProfile = profile.profile_name;
  }

  saveProfileDetail() {
    this.assessment.profile_name = this.selectedProfile;
    this.as.setAssessmentData(this.assessment);
    this._router.navigate(['competency']);
    // this.as.storeToLocal(this.as.assessmentsData);
  }

  Init() {
    this.as.getAssessmentData().subscribe((res) => {
      this.assessment = res;
      this.selectedProfile =
        res?.profile_name ||
        this.profiles[this.profiles.length - 1].profile_name;
    });
  }
}
