import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AuthService } from './shared-services/auth/auth.service';
import { ToasterService } from './shared-services/toaster/toaster.service';
import { AuthGuard } from './shared-services/auth/auth-guard.service';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { JwtInterceptor } from './shared-services/auth/jwt.interceptor';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PurposeAssessmentComponent } from './assessment/purpose-assessment/purpose-assessment.component';
import { JobTitleComponent } from './assessment/job-title/job-title.component';
import { CompetencyComponent } from './assessment/competency/competency.component';
import { ProfileComponent } from './assessment/profile/profile.component';
import { SummaryComponent } from './assessment/summary/summary.component';
import { CongratulationsComponent } from './assessment/congratulations/congratulations.component';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import {
  OwlDateTimeModule,
  OwlNativeDateTimeModule,
  OWL_DATE_TIME_LOCALE,
} from '@danielmoncada/angular-datetime-picker';
import { ReportViewComponent } from './report/report-view/report-view.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DomainComponent } from './assessment/domain/domain.component';
import { JobRequrementComponent } from './assessment/job-requrement/job-requrement.component';
import { AssessmentTypeComponent } from './assessment/assessment-type/assessment-type.component';
import { SharedPipesModule } from './pipe/shared-pipes/shared-pipes.module';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { LearningComponent } from './learning/learning.component';
import { JobTitleLearningComponent } from './assessment/job-title-learning/job-title-learning.component';
import { SummaryLearningComponent } from './assessment/summary-learning/summary-learning.component';

import { AptitudeAssessmentsComponent } from './assessment/aptitude-assessments/aptitude-assessments.component';
import { TechAssessmentsComponent } from './assessment/tech-assessments/tech-assessments.component';
import { SearchBarComponent } from './dashboard/search-bar/search-bar.component';
import { ValuesComponent } from './assessment/values/values.component';
import { DepartmentComponent } from './assessment/department/department.component';
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ForgotPasswordComponent,
    DashboardComponent,
    PurposeAssessmentComponent,
    JobTitleComponent,
    CompetencyComponent,
    ProfileComponent,
    SummaryComponent,
    CongratulationsComponent,
    ReportViewComponent,
    DomainComponent,
    DepartmentComponent,
    JobRequrementComponent,
    AssessmentTypeComponent,
    LearningComponent,
    JobTitleLearningComponent,
    SummaryLearningComponent,
    ValuesComponent,

    AptitudeAssessmentsComponent,
    TechAssessmentsComponent,
    SearchBarComponent,
    // SafePipe,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NgxMaterialTimepickerModule,
    ToastrModule.forRoot(),
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    NgbModule,
    SharedPipesModule,
  ],
  providers: [
    ToasterService,
    AuthService,
    AuthGuard,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: OWL_DATE_TIME_LOCALE, useValue: 'en-IN' },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
