<!--  container  -->
<div class="HeadStrip">
    <div class="container">
            <div class="row">
                    <div class="col-md-2">
                            <div class="ProfileHedding">
                                    <h5>Type <span> of Assessment</span></h5>
                            </div>
                            <!--  Numbering  -->
                    </div>
                    <div class="col-md-10">
                            <div class="ProfileInfo">
                                    <h3>Which type of assessment is this? <span> Select any one</span></h3>
                            </div>
                            <!--  Numbering  -->
                    </div>
            </div>
            <!--  row  -->
    </div>
    <!--  container  -->
</div>
<!--  HeadStrip -->

<!--  Start Section  -->
<div class="container">
    <div class="row">
            <div class="col-md-2">
                    <span class="WAProcess"></span>
            </div>
            <div class="col-md-10">
                    <div class="box-grid">
                            <div *ngFor="let assessmenttype of assessmentTypes" [ngClass]="setActive(assessmenttype.type)" class="ProcessBox-1" (click)="assessmentSelected(assessmenttype)" style="height: 160px;">
                                    <h2>{{assessmenttype.type}}</h2>
                                    <p>{{assessmenttype.description}}</p>
                            </div>
                    </div>
                    <div class="clearfix"></div>
                    <div class="new-btn-grid-box">
                            <button type="button" class="new-btn" [ngClass]="selectedAssessment?.assessment_type==''?'disabled-button':''" [disabled]="selectedAssessment?.assessment_type==''" (click)="saveAssessmentType()">Next</button>
                    </div>
            </div>
    </div>
    <!-- row  -->
</div>
<!--  End Section  -->