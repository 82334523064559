import { Pipe, PipeTransform } from '@angular/core';
import { orderBy, get } from 'lodash';

@Pipe({ name: 'orderBy' })
export class OrderPipe implements PipeTransform {
  transform(value: any[], column: string = '', order = 'desc'): any[] {
    if (!value || order === '' || !order) {
      return value;
    } // no array
    if (value.length <= 1) {
      return value;
    } // array with only one item
    if (!column || column === '') {
      if (order === 'asc') {
        return value.sort();
      } else {
        return value.sort().reverse();
      }
    }
    return orderBy(value, [(item) => (get(item, column) || '').toLowerCase()], [order]);
  }
}
