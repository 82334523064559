import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/shared-services/api/api/api.service';
import { AssessmentService } from '../assessment-service/assessment.service';
import { AuthService } from 'src/app/shared-services/auth/auth.service';
import { Router } from '@angular/router';

import { TCompanyAssessmentResponse } from '../aptitude-assessments/aptitude.interface';

@Component({
  selector: 'app-tech-assessments',
  templateUrl: './tech-assessments.component.html',
  styleUrls: ['./tech-assessments.component.scss'],
})
export class TechAssessmentsComponent implements OnInit {
  questionGamesList: TCompanyAssessmentResponse['techAssessments'];
  totalAptitudes = 0;
  assessment = this.aservice.getAssessmentValue;
  companyDetail = JSON.parse(localStorage.getItem('user'));

  constructor(
    private _api: ApiService,
    private aservice: AssessmentService,
    private _auth: AuthService,
    private _router: Router,
  ) {
    this.getCompetency();
    if (!this.assessment.aptitudeGames || !this.assessment.techAssessments) {
      this.assessment.aptitudeGames = [];
      this.assessment.techAssessments = [];
      this.aservice.setAssessmentData(this.assessment);
    }
  }

  ngOnInit(): void {}
  getCompetency() {
    // if companyAptitudeAssessment data exist then we don't need to call api again
    if (this.aservice.companyAptitudeAssessmentData) {
      this.questionGamesList = this.aservice.companyAptitudeAssessmentData.techAssessments;
    } else {
      const companyId = this.companyDetail?.user?.company_id;
      this._api.getCompanyAptitudeGames(companyId).subscribe((data) => {
        this.aservice.companyAptitudeAssessmentData = data;
        this.questionGamesList = data.techAssessments;
        this.totalAptitudes = data.techAssessments.length;
      });
    }
  }

  setActive(id) {
    const aptiIndex = this.assessment.techAssessments.indexOf(id);
    if (aptiIndex !== -1) {
      return 'active';
    }
  }

  organizationalDNASelected(id: string, index: number) {
    const aptiIndex = this.assessment.techAssessments.indexOf(id);

    // id not present in the assessments
    if (aptiIndex === -1) {
      this.assessment.techAssessments.push(id);
    } else {
      // if the element is already present in the array then we will remove it from the array
      this.assessment.techAssessments = this.assessment.techAssessments.filter(
        (currId) => currId !== id,
      );
    }
  }

  saveCompetencyDetail() {
    this.aservice.setAssessmentData(this.assessment);
    this._router.navigate(['summary']);
  }
}
