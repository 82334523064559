import { Component, OnDestroy, OnInit } from '@angular/core';
import { ApiService } from '../shared-services/api/api/api.service';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { forkJoin, Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { TvsReportService } from './tvs-report.service';
import { SharedDataService } from '../shared-services/shared-data/shared-data.service';
import { environment } from 'src/environments/environment';
import { IAssessment } from '../assessment/assessment-service/assessment.service';

@Component({
  selector: 'app-tvs-report',
  templateUrl: './tvs-reports.component.html',
  styleUrls: ['./tvs-reports.component.scss'],
})
export class TvsReportsComponent implements OnInit, OnDestroy {
  private _unsubscribe$ = new Subject<void>();

  companyData = {
    isProctoring: false,
    _id: '',
    name: '',
    customVerditBoundaries: [
      { title: 'Unlikely to succeed', maxValue: 35 },
      { title: 'Restricted likelihood of success', maxValue: 50 },
      { title: 'Likely to succeed', maxValue: 65 },
      { title: 'Very Likely to succeed', maxValue: 100 },
    ],
  };
  candidateId = '';
  images = [];

  constructor(
    private _api: ApiService,
    private _route: ActivatedRoute,
    protected sanitizer: DomSanitizer,
    private _reportService: TvsReportService,
    private _sharedService: SharedDataService,
  ) {}
  user = localStorage.user;
  isThreePointReport = false;
  isPageOne = true;
  downloadLink = '';
  viewReport: {
    report_data: any;
    isThreePointReport: any;
    comp_framework?: any;
  } = {
    report_data: null,
    isThreePointReport: null,
  };
  assessmentDetail: IAssessment = null;
  // aptitudeReport = null;
  sortedLC = this.viewReport.comp_framework;
  valuesReportSpec = null;
  valuesVerdict = null;
  // TODO Check here
  hideOrgComp = false;
  scoreData = null;
  valuesScoreAverage = null;
  circles: { label: string; position: number }[] = [];
  verdictArray: { key: string; value: number }[] = [];
  scoreValuesArray: { key: string; value: number }[] = [];
  reportDiscriptions = {
    High: 'This individual has a high degree of alignment to TVS values. It is highly likely that their actions and choices at the workplace are guided by these values.',
    Medium:
      'This individual has moderate alignment to TVS values. While they are likely to demonstrate behaviours that are associated with these values, they may sometimes make choices that are not coherent with these values.',
    Low: 'This individual has a low alignment to TVS values. Their actions or choices at the workplace may not be aligned to important behaviours that are associated with these values.',
    'Very High':
      'This individual has a high degree of alignment to TVS values. It is highly likely that their actions and choices at the workplace are guided by these values.',
    'Very Low':
      'This individual has a low alignment to TVS values. Their actions or choices at the workplace may not be aligned to important behaviours that are associated with these values.',
  };

  lcReportDiscriptions = {
    High: 'This individual is likely to be highly proficient in the application of TVS leadership competencies at the workplace and demonstrating related behaviors.',
    Medium:
      'This individual should be adequately proficient in the application of TVS leadership competencies, demonstrating the relevant behaviors more often than not.',
    Low: 'This individual may not be able to adequately match the proficiency level expected with regards to TVS Leadership competencies and related behaviors.',
    'Very High':
      'This individual is likely to be highly proficient in the application of TVS leadership competencies at the workplace and demonstrating related behaviors.',
    'Very Low':
      'This individual may not be able to adequately match the proficiency level expected with regards to TVS Leadership competencies and related behaviors.',
  };

  ngOnInit(): void {
    this.getReport();
    this.getTvsSjtScore();

    const assessmentId = this._route.snapshot.params.id;
    const email = this._route.snapshot.params.email;
    this.downloadLink = `${environment.apiPath}/api/candidates/downloadTvsPDF/${email}/${assessmentId}`;
    const res = this._sharedService.getCandidatesValue();
    if (res) {
      this.getCompanyDetail(res?.company_id);
    }
  }

  convertObjectToArray(obj: { [key: string]: number }): void {
    this.verdictArray = Object.keys({ 'Very Low': 0, ...obj })
      .map((key) => ({ key, value: obj[key] || 0 }))
      .sort((a, b) => a.value - b.value);
    this.verdictArray.push({ key: 'Extra', value: 100 });
  }

  getLabel(score: number): string {
    const cutoffs = this.valuesVerdict || {};
    // Convert object to array of key-value pairs
    let dataArray = Object.entries(cutoffs) as any;
    // Sort the array based on the numeric value (second element of each pair), descending
    dataArray.sort((a, b) => b[1] - a[1]);
    // Convert sorted array back to object (if needed)
    let sortedObject = {};
    dataArray.forEach((item) => {
      sortedObject[item[0]] = item[1];
    });
    for (const label in sortedObject) {
      if (sortedObject.hasOwnProperty(label)) {
        const cutoff = sortedObject[label];
        if (score >= cutoff) {
          return label;
        }
      }
    }

    return 'Very Low';
  }

  ngOnDestroy(): void {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  getOgGameName(gameName: string): string {
    return this._reportService.games.find((game) => gameName.includes(game));
  }

  accessQueryParams(): void {
    this._route.queryParams
      .pipe(takeUntil(this._unsubscribe$))
      .subscribe((params) => {
        this.candidateId = params.candidateId;
      });
  }
  downloadCandidateImages(images): void {
    images.forEach((element) => {
      this._api
        .downloadCandidateImages(element.file_name)
        .pipe(takeUntil(this._unsubscribe$))
        .subscribe(
          (res) => {
            this.images.push({
              src: this.sanitizer.bypassSecurityTrustUrl(res?.image),
              caption: '',
            });
            this.images = this.images;
          },
          (_1) => {
            // this._toastr.showError('something went wrong');
          },
        );
    });
  }

  getCandidateData(): void {
    this.accessQueryParams();
    this._api
      .getCandidateData(this.candidateId)
      .pipe(takeUntil(this._unsubscribe$))
      .subscribe((res) => {
        //  this.images = res.images;
        // this.selectedCandidateData = res;
        if (res && res.images) {
          this.downloadCandidateImages(res.images);
        }
      });
  }

  getTvsSjtScore() {
    const assessmentId = this._route.snapshot.params.id;
    const email = this._route.snapshot.params.email;
    this._api
      .getTvsSjtScore(email, assessmentId)
      .pipe(takeUntil(this._unsubscribe$))
      .subscribe((res) => {
        this.scoreData = res;
        if (this.scoreData) {
          const scores = Object.values(this.scoreData.processedValuesScore);
          this.scoreValuesArray = Object.entries(
            this.scoreData.processedValuesScore,
          )
            .map(([key, value]) => ({ key, value }))
            .sort((a, b) => Number(b.value) - Number(a.value)) as {
            key: string;
            value: number;
          }[];
          this.valuesScoreAverage = Math.round(
            (scores as number[]).reduce((acc, value) => acc + value, 0) /
              scores.length,
          );
        }
      });
  }

  getReport(): void {
    const assessmentId = this._route.snapshot.params.id;
    const email = this._route.snapshot.params.email;
    this._api
      .getAssessmentDetail(assessmentId)
      .pipe(
        switchMap((value) => {
          this.getCompanyDetail(value.company_id);
          this.assessmentDetail = value;
          sessionStorage.setItem('Candidate', JSON.stringify(value));
          return value;
        }),
        takeUntil(this._unsubscribe$),
      )
      .subscribe((res) => {
        return res;
      });

    const report$ = {
      behavioural: this._api.viewReport(email, assessmentId),
    };

    forkJoin(report$)
      .pipe(takeUntil(this._unsubscribe$))
      .subscribe((res: any) => {
        this.viewReport = res.behavioural;
        this.sortedLC = this.viewReport?.comp_framework?.org_data.sort(
          (a, b) =>
            b.org_competency.overall_average_per -
            a.org_competency.overall_average_per,
        );

        if (typeof res.behavioural.comp_framework !== 'undefined') {
          this.hideOrgComp = true;
        }
        this.isThreePointReport = res.behavioural.isThreePointReport;
      });
  }

  // TODO -test #5 --uncomment after test
  getReplacedText(text) {
    let strText = '';
    if (!text) {
      return;
    }
    if (
      this.viewReport?.report_data?.gender === 'Male' ||
      this.scoreData?.gender === 'Male'
    ) {
      strText = text
        .replace(/{@pronoun}/g, 'He')
        .replace(/{@spronoun}/g, 'he')
        .replace(/{@ppronoun}/g, 'His')
        .replace(/{@sppronoun}/g, 'his');
    } else if (
      this.viewReport?.report_data?.gender === 'Female' ||
      this.scoreData?.gender === 'Female'
    ) {
      strText = text
        .replace(/{@pronoun}/g, 'She')
        .replace(/{@spronoun}/g, 'she')
        .replace(/{@ppronoun}/g, 'Her')
        .replace(/{@sppronoun}/g, 'her');
    } else {
      strText = text
        .replace(
          /{@pronoun}/g,
          this.viewReport?.report_data?.candidate_first_name ||
            this.scoreData?.firstName,
        )
        .replace(
          /{@spronoun}/g,
          this.viewReport?.report_data?.candidate_first_name ||
            this.scoreData?.firstName,
        )
        .replace(/{@ppronoun}/g, 'Their')
        .replace(/{@sppronoun}/g, 'their');
    }
    strText = strText.replace(
      /{@name}/g,
      this.viewReport?.report_data?.candidate_first_name ||
        this.scoreData?.firstName,
    );
    return strText;
  }

  getVerdictImage(averageScore, verdictName) {
    let totalSum = 0;
    let totalCount = 0;
    if (averageScore) {
      if (averageScore?.report_data) {
        // TODO looks similar cases --check this out
        if (
          averageScore?.report_data?.response &&
          averageScore?.report_data?.job_type !== 'Campus Recruitment'
        ) {
          totalSum += parseFloat(
            averageScore?.report_data?.response?.overall_average,
          );
          totalCount++;
        } else if (
          averageScore?.report_data?.job_type === 'Campus Recruitment' &&
          averageScore?.report_data?.response
        ) {
          totalSum += parseFloat(
            averageScore.report_data.response.overall_average,
          );
          totalCount++;
        }

        if (averageScore?.report_data?.mparam_response) {
          totalSum += parseFloat(
            averageScore.report_data.mparam_response.overall_average,
          );
          totalCount++;
        }
      }

      if (averageScore?.comp_framework) {
        totalSum += parseFloat(averageScore?.comp_framework?.overall_average);
        totalCount++;
      }

      // totalSum = totalSum.toFixed(2);

      const totalAverage = (totalSum * 100) / totalCount;
      const verdict = this.calculateVerdict(totalAverage);

      switch (verdictName) {
        case 'Unlikely to succeed':
          if (verdict === 'Unlikely to succeed') {
            return true;
          } else {
            return false;
          }
          break;

        case 'Restricted likelihood of success':
          if (verdict === 'Restricted likelihood of success') {
            return true;
          } else {
            return false;
          }
          break;

        case 'Likely to succeed':
          if (verdict === 'Likely to succeed') {
            return true;
          } else {
            return false;
          }
          break;

        case 'Very Likely to succeed':
          if (verdict === 'Very Likely to succeed') {
            return true;
          } else {
            return false;
          }
          break;
      }
    }
  }

  getReportName(profileName) {
    if (profileName === 'Customised Profile Fit') {
      return 'Profile Fit Report';
    }
    if (profileName === 'Campus Recruitment') {
      return 'Professional Efficacy Report';
    }
    if (profileName === 'Organisational Match') {
      return 'Organisational Compatibility Report';
    }
  }

  getOverallRatingPer() {
    let total = 0;
    let totalCount = 0;

    // if (this.viewReport.report_data.response !== undefined) {
    //   if (
    //     this.viewReport?.report_data?.response?.overall_average_per &&
    //     !isNaN(this.viewReport.report_data.response.overall_average_per)
    //   ) {
    //     total += parseFloat(
    //       this.viewReport.report_data.response.overall_average_per,
    //     );
    //     totalCount++;
    //   }
    // }
    if (
      this.viewReport.report_data.response !== undefined &&
      this.viewReport.report_data.job_type !== 'Campus Recruitment'
    ) {
      if (
        this.viewReport?.report_data?.response?.overall_average_per &&
        !isNaN(this.viewReport.report_data.response.overall_average_per)
      ) {
        total += parseFloat(
          this.viewReport.report_data.response.overall_average_per,
        );
        totalCount++;
      }
    }
    if (this.viewReport.report_data.mparam_response) {
      if (
        this.viewReport.report_data.mparam_response.overall_average_per &&
        !isNaN(this.viewReport.report_data.mparam_response.overall_average_per)
      ) {
        total += parseFloat(
          this.viewReport.report_data.mparam_response.overall_average_per,
        );
        totalCount++;
      }
    }
    if (this.viewReport.comp_framework) {
      if (
        this.viewReport.comp_framework.overall_average_per &&
        !isNaN(this.viewReport.comp_framework.overall_average_per)
      ) {
        total += parseFloat(this.viewReport.comp_framework.overall_average_per);
        totalCount++;
      }
    }

    if (totalCount !== 0) {
      // total = total.toFixed(2);
      const averageScore = total / totalCount;
      // TODO -we hopefully dont require this
      // if (parseInt(averageScore) !== averageScore) {
      //   averageScore = averageScore.toFixed(1);
      // } else {
      //   averageScore = parseInt(averageScore);
      // }
      return averageScore % 1 !== 0 ? averageScore.toFixed(1) : averageScore;
    } else {
      return total;
    }
  }

  getBothOverallRatingPer() {
    let total = 0;
    let totalCount = 0;

    const scoreDataAvg = this.scoreData?.processedAvgScore || 0;
    const divideValue = this.scoreData ? 2 : 1;
    if (this.viewReport.report_data?.response !== undefined) {
      if (
        this.viewReport?.report_data?.response?.overall_average_per &&
        !isNaN(this.viewReport.report_data.response.overall_average_per)
      ) {
        total +=
          (parseFloat(
            this.viewReport.report_data.response.overall_average_per,
          ) +
            scoreDataAvg) /
          divideValue;
        totalCount++;
      }
    }
    if (this.viewReport.report_data?.mparam_response) {
      if (
        this.viewReport.report_data?.mparam_response.overall_average_per &&
        !isNaN(this.viewReport.report_data.mparam_response.overall_average_per)
      ) {
        total +=
          (parseFloat(
            this.viewReport.report_data?.mparam_response.overall_average_per,
          ) +
            scoreDataAvg) /
          divideValue;
        totalCount++;
      }
    }
    if (this.viewReport.comp_framework) {
      if (
        this.viewReport.comp_framework.overall_average_per &&
        !isNaN(this.viewReport.comp_framework.overall_average_per)
      ) {
        total +=
          (parseFloat(this.viewReport.comp_framework.overall_average_per) +
            scoreDataAvg) /
          divideValue;
        totalCount++;
      }
    }

    if (totalCount !== 0) {
      // total = total.toFixed(2);
      const averageScore = total / totalCount;
      // TODO -we hopefully dont require this
      // if (parseInt(averageScore) !== averageScore) {
      //   averageScore = averageScore.toFixed(1);
      // } else {
      //   averageScore = parseInt(averageScore);
      // }
      return averageScore % 1 !== 0 ? averageScore.toFixed(1) : averageScore;
    } else {
      return total;
    }
  }

  getValuesOverallRatingPer() {
    let total = 0;
    let totalCount = 0;
    //
    // const scoreDataAvg =  this.scoreData?.processedAvgScore || 0

    if (this.scoreData) {
      total += this.scoreData?.processedAvgScore;
      totalCount++;
    }

    if (totalCount !== 0) {
      // total = total.toFixed(2);
      const averageScore = total / totalCount;

      return averageScore % 1 !== 0 ? averageScore.toFixed(1) : averageScore;
    } else {
      return total;
    }
  }

  getOverallVerdict(averageScore, verdictName) {
    let totalSum = 0;
    let totalCount = 0;

    if (averageScore) {
      if (
        averageScore?.report_data?.response &&
        averageScore?.report_data?.job_type !== 'Campus Recruitment'
      ) {
        totalSum += parseFloat(
          averageScore?.report_data?.response?.overall_average,
        );
        totalCount++;
      } else if (
        averageScore?.report_data?.job_type === 'Campus Recruitment' &&
        averageScore?.report_data?.response
      ) {
        // totalSum += parseFloat(
        //   averageScore?.report_data?.response?.overall_average,
        // );
        totalSum += parseFloat(averageScore?.comp_framework?.overall_average);
        totalCount++;
      }

      if (averageScore?.report_data?.mparam_response) {
        totalSum += parseFloat(
          averageScore?.report_data?.mparam_response?.overall_average,
        );
        totalCount++;
      }

      if (averageScore.comp_framework) {
        totalSum += parseFloat(averageScore?.comp_framework?.overall_average);
        totalCount++;
      }
      // TODO - check if calculations break
      // totalSum = totalSum.toFixed(2);
      const totalAverage = (totalSum * 100) / totalCount;
      const verdict = this.calculateVerdict(totalAverage);
      switch (verdictName) {
        case 'Unlikely to succeed':
          if (verdict === 'Unlikely to succeed') {
            return 'RSelect RSelectNew';
          }
          break;

        case 'Restricted likelihood of success':
          if (verdict === 'Restricted likelihood of success') {
            return 'RSelect RSelectNew';
          }
          break;

        case 'Likely to succeed':
          if (verdict === 'Likely to succeed') {
            return 'RSelect RSelectNew';
          }
          break;

        case 'Very Likely to succeed':
          if (verdict === 'Very Likely to succeed') {
            return 'RSelect RSelectNew';
          }
          break;
      }
    } else {
      return;
    }
  }

  getValuesOverallVerdict(verdictName) {
    if (this.scoreData) {
      // TODO - check if calculations break
      // totalSum = totalSum.toFixed(2);
      const totalAverage = this.scoreData.processedAvgScore;
      const verdict = this.calculateValuesVerdict(
        totalAverage,
        this.assessmentDetail?.competency_id,
      );

      switch (verdictName) {
        case 'Unlikely to succeed':
          if (verdict === 'Unlikely to succeed') {
            return 'RSelect RSelectNew';
          }
          break;

        case 'Restricted likelihood of success':
          if (verdict === 'Restricted likelihood of success') {
            return 'RSelect RSelectNew';
          }
          break;

        case 'Likely to succeed':
          if (verdict === 'Likely to succeed') {
            return 'RSelect RSelectNew';
          }
          break;

        case 'Very Likely to succeed':
          if (verdict === 'Very Likely to succeed') {
            return 'RSelect RSelectNew';
          }
          break;
      }
    } else {
      return;
    }
  }

  getBothOverallVerdict(averageScore, verdictName) {
    let totalSum = 0;
    let totalCount = 0;
    if (averageScore && this.scoreData) {
      if (
        averageScore?.report_data?.response &&
        averageScore?.report_data?.job_type !== 'Campus Recruitment'
      ) {
        totalSum += parseFloat(
          averageScore?.report_data?.response?.overall_average,
        );
        totalCount++;
      } else if (
        averageScore?.report_data?.job_type === 'Campus Recruitment' &&
        averageScore?.report_data?.response
      ) {
        totalSum += parseFloat(
          averageScore?.report_data?.response?.overall_average,
        );
        totalCount++;
      }
      if (averageScore?.report_data?.mparam_response) {
        totalSum += parseFloat(
          averageScore?.report_data?.mparam_response?.overall_average,
        );
        totalCount++;
      }
      if (averageScore.comp_framework) {
        totalSum += parseFloat(averageScore?.comp_framework?.overall_average);
        totalCount++;
      }
      // TODO - check if calculations break
      // totalSum = totalSum.toFixed(2);
      const totalAverage = averageScore.report_data
        ? ((totalSum * 100) / totalCount + this.scoreData.processedAvgScore) / 2
        : (totalSum * 100) / totalCount;
      const verdict = this.calculateBothVerdict(
        totalAverage,
        this.assessmentDetail?.competency_id,
      );
      switch (verdictName) {
        case 'Unlikely to succeed':
          if (verdict === 'Unlikely to succeed') {
            return 'RSelect RSelectNew';
          }
          break;
        case 'Restricted likelihood of success':
          if (verdict === 'Restricted likelihood of success') {
            return 'RSelect RSelectNew';
          }
          break;
        case 'Likely to succeed':
          if (verdict === 'Likely to succeed') {
            return 'RSelect RSelectNew';
          }
          break;
        case 'Very Likely to succeed':
          if (verdict === 'Very Likely to succeed') {
            return 'RSelect RSelectNew';
          }
          break;
      }
    } else {
      return;
    }
  }

  getPageStyle() {
    if (this.viewReport) {
      if (this.viewReport?.report_data?.mparam_response) {
        if (this.viewReport.isThreePointReport) {
          return 'margin-managerial-data-three-scale';
        } else {
          return 'margin-managerial-data';
        }
      }
      if (this.viewReport?.comp_framework) {
        return 'margin-org-data';
      }
    }
  }

  headingAlignment(hideOrgComp, profileName, compFramework) {
    let className = '';
    if (this.hideOrgComp) {
      className = 'floatLeft firstBox-margin';
    } else {
      className = 'floatLeft';
    }
    if (
      profileName !== 'Senior Manager or Manager of Managers' &&
      profileName !== 'First-time or Junior Manager' &&
      !compFramework
    ) {
      // className += ' margin-85'; // commenting as we are using display flex instead of margins to center
    } else if (
      profileName !== 'Senior Manager or Manager of Managers' &&
      profileName !== 'First-time or Junior Manager' &&
      compFramework
    ) {
      // className += ' margin-120'; // commenting as we are using display flex instead of margins to center
    }

    return className;
  }

  safeHtml(explanation) {
    let strText = '';

    if (explanation) {
      if (this.viewReport.report_data.gender === 'Male') {
        strText = explanation
          .replace(/{@pronoun}/g, 'He')
          .replace(/{@spronoun}/g, 'he')
          .replace(/{@ppronoun}/g, 'His')
          .replace(/{@sppronoun}/g, 'his');
      } else if (this.viewReport.report_data.gender === 'Female') {
        strText = explanation
          .replace(/{@pronoun}/g, 'She')
          .replace(/{@spronoun}/g, 'she')
          .replace(/{@ppronoun}/g, 'Her')
          .replace(/{@sppronoun}/g, 'her');
      } else {
        strText = explanation
          .replace(
            /{@pronoun}/g,
            this.viewReport.report_data.candidate_first_name,
          )
          .replace(
            /{@spronoun}/g,
            this.viewReport.report_data.candidate_first_name,
          )
          .replace(/{@ppronoun}/g, 'Their')
          .replace(/{@sppronoun}/g, 'their');
      }
    }
    strText = strText.replace(
      /{@name}/g,
      this.viewReport.report_data.candidate_first_name,
    );
    return this.sanitizer.bypassSecurityTrustHtml(strText);
  }

  getFullCircleImage(score: number): string {
    return this._reportService.getFullCircleImage(score);
  }

  calculateVerdict(score: number): string {
    const customVerditBoundaries = this.companyData.customVerditBoundaries;
    let verdict = 'Pending';
    if (
      customVerditBoundaries &&
      customVerditBoundaries.length &&
      customVerditBoundaries.length > 3
    ) {
      // Handle the lowest boundry
      if (score < customVerditBoundaries[0].maxValue) {
        verdict = customVerditBoundaries[0].title;
      } else {
        for (let i = 0; i < customVerditBoundaries.length - 1; i++) {
          if (
            score >= customVerditBoundaries[i].maxValue &&
            score < customVerditBoundaries[i + 1].maxValue
          ) {
            verdict = customVerditBoundaries[i + 1].title;
          }
        }
        // Handle the highest boundry
        if (
          score >=
          customVerditBoundaries[customVerditBoundaries.length - 1].maxValue
        ) {
          verdict =
            customVerditBoundaries[customVerditBoundaries.length - 1].title;
        }
      }
    } else {
      if (score < 35) {
        verdict = 'Unlikely to succeed';
      }

      if (score >= 35 && score < 50) {
        verdict = 'Restricted likelihood of success';
      }

      if (score >= 50 && score < 65) {
        verdict = 'Likely to succeed';
      }

      if (score >= 65) {
        verdict = 'Very Likely to succeed';
      }
    }
    return verdict;
  }

  calculateValuesVerdict(score: number, competency_id: string): string {
    let verdict = 'Pending';
    const min = this.viewReport?.comp_framework?.coverpage_name.includes('LC1')
      ? 56
      : 61;
    const mid = this.viewReport?.comp_framework?.coverpage_name.includes('LC1')
      ? 63
      : 67;
    const max = this.viewReport?.comp_framework?.coverpage_name.includes('LC1')
      ? 70
      : 74;

    if (score < min) {
      verdict = 'Unlikely to succeed';
    }

    if (score >= min && score < mid) {
      verdict = 'Restricted likelihood of success';
    }

    if (score >= mid && score < max) {
      verdict = 'Likely to succeed';
    }

    if (score >= max) {
      verdict = 'Very Likely to succeed';
    }

    return verdict;
  }

  calculateBothVerdict(score: number, competency_id: string): string {
    let verdict = 'Pending';
    const min = this.viewReport?.comp_framework?.coverpage_name.includes('LC1')
      ? 46
      : 48;
    const mid = this.viewReport?.comp_framework?.coverpage_name.includes('LC1')
      ? 57
      : 59;
    const max = this.viewReport?.comp_framework?.coverpage_name.includes('LC1')
      ? 68
      : 69;

    if (score < min) {
      verdict = 'Unlikely to succeed';
    }

    if (score >= min && score < mid) {
      verdict = 'Restricted likelihood of success';
    }

    if (score >= mid && score < max) {
      verdict = 'Likely to succeed';
    }

    if (score >= max) {
      verdict = 'Very Likely to succeed';
    }

    return verdict;
  }

  private getCompanyDetail(companyId): void {
    this._api
      .getCompanyData(companyId)
      .pipe(takeUntil(this._unsubscribe$))
      .subscribe((res) => {
        this.valuesReportSpec = res.valuesReportSpec
          .map((item) => {
            item.text['Very Low'] = item.text.Low;
            item.score = this.scoreData?.processedValuesScore?.[item.value];
            return item;
          })
          .sort((a, b) => b.score - a.score);
        // this.valuesVerdict = res.valuesVerditBoundaries;
        if (this.viewReport) {
          this.valuesVerdict =
            this.assessmentDetail?.competency_id ===
              '636249c5cd1a6fb1d3746ff1' ||
            this.assessmentDetail?.competency_id === '642ad10e969c46989a026dcc'
              ? res.valuesVerditBoundaries
              : res.mgvaluesVerditBoundaries;
        }
        if (this.valuesVerdict._id) {
          delete this.valuesVerdict._id;
        }
        this.convertObjectToArray(this.valuesVerdict);
        this.companyData.isProctoring =
          res.Proctoring === 'On' || res.Proctoring === true;
        if (this.companyData.isProctoring) {
          this.getCandidateData();
        }
        if (
          res.customVerditBoundaries &&
          res.customVerditBoundaries.length > 3
        ) {
          this.companyData.customVerditBoundaries = res.customVerditBoundaries;
        }
      });
  }
}
